import { createPortal } from "react-dom";
import "./popup.scss";
import useDictionaries from "assets/commonHooks/useDictionaries";
import React, { useEffect, useRef, useState } from "react";

export default function Popup({
  handlePopupExit,
  handlePopupChoice,
  titlePopup,
  textPopup,
  modePopup,
  typePopup,
  payloadPopup,
}) {
  const text = useDictionaries();
  const [time, setTime] = useState(3);

  const copyLink = () => {
    navigator.clipboard.writeText(document.getElementById("popup_input_link").value);
  };

  useEffect(() => {
    let timer = null;

    if (modePopup === "result" && titlePopup?.type !== "error" && textPopup?.type !== "error") {
      let counter = 3;
      timer = setInterval(() => {
        if (counter === 1) {
          handlePopupExit(false);
        } else {
          counter--;
          setTime(counter);
        }
      }, 150);
    }

    return () => {
      clearInterval(timer);
    };
  }, [modePopup]);

  return createPortal(
    <dialog onClick={(e) => e.stopPropagation()} className="popup" open={true}>
      <div className="popup__contain">
        {titlePopup ? (
          <span className={`popup__contain__title popup--${titlePopup?.type}`}>{titlePopup?.value}</span>
        ) : null}
        {textPopup ? (
          <span className={`popup__contain__text popup--${textPopup?.type}`}>{textPopup?.value}</span>
        ) : null}

        {modePopup === "link" ? (
          <div className="popup__contain__link">
            <input
              value={payloadPopup?.inputVerifLink ? payloadPopup?.inputVerifLink : ""}
              id="popup_input_link"
              type="text"
              readOnly
            />
            <div className="popup__contain__link__copy">
              <i onClick={() => copyLink()} className="fa-solid fa-copy"></i>
            </div>
          </div>
        ) : null}

        {modePopup === "choice" ? (
          <div className="popup__contain__interact">
            <button
              onClick={() => handlePopupChoice(false)}
              className="popup__contain__interact__button popup__contain__interact__button--neg"
            >
              <span>{text("global-decline")}</span>
            </button>
            <button
              onClick={() => handlePopupChoice(true)}
              className="popup__contain__interact__button popup__contain__interact__button--pos"
            >
              <span>{text("global-confirm")}</span>
            </button>
          </div>
        ) : null}

        {modePopup === "load" ? (
          <div className="popup__contain__load">
            <div className="popup__contain__load__point"></div>
          </div>
        ) : null}

        {typePopup === "mail" && modePopup === "result" ? (
          <div className="popup__contain__valid">
            <div className="popup__contain__valid__point"></div>
          </div>
        ) : null}
      </div>
      <div className="popup__box popup__box--fill"></div>
      <div className="popup__box popup__box--path"></div>

      {handlePopupExit ? (
        <div className="popup__box popup__box--action">
          <i onClick={() => handlePopupExit(false)} className="popup__box__quit fa-solid fa-xmark"></i>

          {modePopup === "result" && titlePopup?.type !== "error" && textPopup?.type !== "error" ? (
            <span className="popup__box__time">{time}</span>
          ) : null}
        </div>
      ) : null}
    </dialog>,
    document.getElementById("root"),
  );
}
