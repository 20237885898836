import React from "react";

import useDictionaries from "assets/commonHooks/useDictionaries";
import MDTypography from "assets/@ndres-ui/MDTypography";

const MotoristCredentialsTable = ({ credentials }) => {
  const text = useDictionaries();
  return (
    <>
      <section className="section_details">
        <MDTypography className="global_xs_title" type="tableHeader" textTransform="first">
          {text("widgets-motorist-details-table-header-plates")}
        </MDTypography>
        <div className="global_separa"></div>
        <div className="place_rub">
          {credentials?.map(({ type, value, description }, index) => (
            <div key={`plate-${value}-${type}-${index + 1}`} className="flexTableRows">
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-header-plate")}
                </MDTypography>
              </div>
              <div className="flexTableData">
                <MDTypography type="tableData">{value}</MDTypography>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableData" textTransform="first">
                  {text("widgets-motorist-details-table-header-description")}
                </MDTypography>
              </div>
              <div className="flexTableData">
                <MDTypography component="span" type="tableData">
                  {description}
                </MDTypography>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default MotoristCredentialsTable;
