import React, { useEffect, useState } from "react";

import Step1 from "./stepsComponents/Step1";
import Step2 from "./stepsComponents/Step2";
import Step3 from "./stepsComponents/Step3";
import Step4 from "./stepsComponents/Step4";

// local hooks
import NewReportTechnicalDialogStepper from "./DialogStepper";
import useStateCallback from "assets/commonHooks/useStateCallback/useStateCallback";
import { useDispatch } from "react-redux";

const stepsObj = {
  "Wizard-provider-single-steps-motorist": Step1,
  "Wizard-provider-single-steps-pool": Step2,
  "Wizard-provider-single-steps-contract": Step3,
  "Wizard-provider-single-steps-resume": Step4,
};

const initForm = {};

const StepperWizard = ({ dialog, setDialog, onSubmit, onClose, parkingId }) => {
  const steps = [...Object.keys(stepsObj)];
  const [active, setActive] = useState(0);
  const [completed, setCompleted] = useState([true, false, false]);
  const [nextBt, setNextBt] = useState(steps.map(() => false));
  const [formData, setFormData] = useStateCallback({ ...initForm, parkingId });
  const dispatch = useDispatch();
  const Step = stepsObj[steps[active]];

  const reset = () => {
    setCompleted([true, false, false]);
    setActive(0);
    setFormData({ ...initForm, parkingId });
    dispatch({
      type: "WizardCreateContractUserProvider:reset",
      payload: {},
    });
  };

  useEffect(() => {
    if (dialog === "New Create Motorist With Local Motorist") {
      reset();
    }
  }, [dialog]);

  const addCompleted = (index, newValue) => {
    const newCompleted = [...completed].map((currentValue, i) => (i === index ? newValue : currentValue));
    setCompleted(newCompleted);
  };

  const addNextBt = (index, newValue) => {
    const newNextBt = [...nextBt].map((currentValue, i) => (i === index ? newValue : currentValue));
    setNextBt(newNextBt);
  };

  const setData = (data) => {
    setFormData({ ...formData, ...data, parkingId });
  };

  const handleSubmit = () => {
    onSubmit({
      type: "WizardCreateContractUserProvider:exit",
      payload: {},
    });
  };

  return (
    <NewReportTechnicalDialogStepper
      dialog={dialog}
      setDialog={setDialog}
      steps={steps}
      active={active}
      setActive={setActive}
      completed={completed}
      nextBt={nextBt}
      setCompleted={setCompleted}
      onClose={onClose}
      reset={reset}
      onSubmit={handleSubmit}
    >
      <Step addCompleted={addCompleted} addNextBt={addNextBt} setData={setData} formData={formData} reset={reset} />
    </NewReportTechnicalDialogStepper>
  );
};

export default StepperWizard;
