import React from "react";
import ProductsTable from "./materials";
import ContainerInGrid from "assets/@luc-ui/Container";
import { ContextProvider } from "./context";

const Products = () => {
  return (
    <div style={{ height: "100%", overflow: "scroll" }}>
      <ContainerInGrid headerName="widgets-product-title" headerTextColor="primary" headerBackgroundColor="#ffffff">
        <ContextProvider storeName="Products" events={{}}>
          <ProductsTable />
        </ContextProvider>
      </ContainerInGrid>
    </div>
  );
};

export default Products;
