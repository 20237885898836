import React from "react";
import Popper from "@mui/material/Popper";
import { useSelector } from "react-redux";

// Material ui
import Avatar from "@mui/material/Avatar";

import { NestedList, Fade } from "./ProfileComponent";
import MDButton from "assets/@ndres-ui/MDButton";

export default function SpringPopper() {
  const { photo } = useSelector((state) => state?.login?.context?.user ?? {});
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleEnter = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleLeave = (event) => {
    setAnchorEl(null);
    setOpen(false);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "spring-popper" : undefined;

  return (
    <div onMouseLeave={handleLeave}>
      <MDButton
        aria-describedby={id}
        type="button"
        variant="contained"
        onClick={handleClick}
        onMouseEnter={handleEnter}
        style={{
          color: "white",
          textTransform: "capitalize",
          background: "transparent",
          marginRight: "11px",
        }}
      >
        {photo ? (
          <Avatar alt="Remy Sharp" src={require(`assets/images/avatars/${photo}.png`)} sx={{ width: 39, height: 39 }} />
        ) : (
          <Avatar
            alt="Remy Sharp"
            src={require(`assets/images/avatars/6203e96506b61170228f9c4e.png`)}
            sx={{ width: 39, height: 39 }}
          />
        )}
      </MDButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom"
        style={{
          position: "relative",
          zIndex: "100",
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <NestedList />
          </Fade>
        )}
      </Popper>
    </div>
  );
}
