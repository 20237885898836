import React from "react";

import MotoristsTable from "./materials/table";

import { ContextProvider } from "./context";

const Motorists = (props) => {
  const onSelectPage = (newPage) => ({ newPage });
  const onSelectMotorist = (selectedMotorist) => ({ selectedMotorist });

  return (
    <div style={{ height: "100%", overflow: "scroll" }}>
      <ContextProvider
        storeName="Motorists"
        events={{
          onSelectPage,
          onSelectMotorist,
        }}
      >
        <MotoristsTable />
      </ContextProvider>
    </div>
  );
};

export default Motorists;
