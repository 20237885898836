import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import verifyMotoristGql from "./verifyMotorist.gql";
import updateMotoristGql from "./updateMotorist.gql";
const userModel = createModel({
  token: "",
});

const fetchMotorist = async ({ token }, { payload }) =>
  new Promise(async (resolve, reject) => {
    client
      .query({
        query: verifyMotoristGql,
        fetchPolicy: "cache-first",
        context: { headers: { "x-access-token": token } },
        variables: {
          token: payload.token,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

const updateMotorist = async ({ token }, { payload }) =>
  new Promise(async (resolve, reject) => {
    client
      .mutate({
        mutation: updateMotoristGql,
        context: { headers: { "x-access-token": token } },
        variables: {
          input: {
            token: payload.data.token,
            motorist: payload.data.motorist,
          },
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

const motoristValidation = createMachine(
  {
    context: userModel.initialContext,
    id: "motoristValidation",
    initial: "off",
    states: {
      off: {
        on: {
          "global:selected-parkings": { actions: "save parkings" },
          "MotoristValidation:wakeup": { target: "hydrate wakeup" },
        },
      },
      idle: {
        on: {
          "MotoristValidation:submitValidation": { target: "update motorist" },
          "MotoristValidation:sleep": { target: "off" },
        },
      },
      "hydrate wakeup": {
        entry: assign({
          token: () => localStorage.getItem("token"),
        }),
        invoke: {
          id: "hydrate validator",
          src: (context, event) => fetchMotorist(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ actions: "populate token error" }, { target: "idle" }],
        },
      },
      "update motorist": {
        entry: assign({
          token: () => localStorage.getItem("token"),
        }),
        invoke: {
          id: "update motorist",
          src: (context, event) => updateMotorist(context, event),
          onDone: [{ actions: "populate update", target: "idle" }],
          onError: [{ actions: "populate update error" }, { target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populate: assign({
        verifyData: (_, { data }) => data.verifyMotorist.motorist,
        fieldsToVerify: (_, { data }) => data.verifyMotorist.fieldsToVerify,
        tokenError: () => false,
      }),
      "populate token error": assign({
        verifyData: (_, { data }) => data.message,
        tokenError: () => true,
      }),
      "populate update": assign({
        updatedMotorist: (_, { data }) => data,
      }),
      "populate update error": assign({
        updatedMotorist: (_, { data }) => data.message,
      }),
      "save parkings": assign({
        selectedParkings: (ctx, { payload }) => payload.selectedParkings,
      }),
    },
  },
);

export const service = interpret(motoristValidation).start();

const initial = {
  state: motoristValidation.initialState.list,
  context: motoristValidation.initialState.context,
};

const widgetReducer = (state = initial, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
