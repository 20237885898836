import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "@xstate/react";
import { service } from "../../../reducer";
import DatePicker from "rsuite/DatePicker";
import { SelectPicker } from "rsuite";
import DateRangePicker from "rsuite/DateRangePicker";
import { subDays, format, addDays, addMinutes, addHours } from "date-fns";
import useDictionaries from "assets/commonHooks/useDictionaries";
import { DateTime } from "luxon";
import MDTypography from "../../../../../assets/@ndres-ui/MDTypography";

const { allowedRange, beforeToday, combine } = DateRangePicker;

export default function Step1({ addCompleted, setData, formData, addNextBt }) {
  const text = useDictionaries();
  const breakdownInputs = useSelector(service, ({ context }) => context.breakdownInputs);
  const blockAccessPointSelect = useSelector(service, ({ context }) => context.blockAccessPointSelect);

  const [disableOptionType, setDisableOptionType] = useState([]);

  useEffect(() => {
    service.send({
      type: "WizardCreateReportTechnical:search-breakdowns",
      payload: {},
    });
  }, []);

  useEffect(() => {
    if (blockAccessPointSelect && Array.isArray(blockAccessPointSelect)) setDisableOptionType(blockAccessPointSelect);
  }, [blockAccessPointSelect]);

  useEffect(() => {

    if (breakdownInputs?.startDate && breakdownInputs?.endDate && new Date() >= new Date(breakdownInputs?.endDate)) {
      service.send({
        type: "WizardCreateReportTechnical:breakdown:change",
        payload: {
          endDate: undefined,
        },
      });
      setData({ endDate: undefined });
    }

    const dataToTest = [breakdownInputs?.category, breakdownInputs?.endDate, breakdownInputs?.type];

    const resultOfTest =
      dataToTest.reduce((acc, value) => {
        if (value) acc = acc + 1;
        return acc;
      }, 0) === dataToTest.length;

    addNextBt(0, resultOfTest);
    addCompleted(0, resultOfTest);
  }, [breakdownInputs]);

  return (
    <div className="wizard">
      <div className="wizard__note">
        <h3 className="wizard__subtitle">{text("Wizard-report-technical-first-step-notice-title")}</h3>

        <p>{text("Wizard-report-technical-first-step-notice-text-first")}</p>

        {/* <p>
          ECT
        </p> */}
      </div>
      <div className="wizard__form">
        <div className="wizard__single">
          <div className="wizard__case">
            <MDTypography>
              {text("Wizard-report-technical-first-step-startdate-label")}: {text("Wizard-report-technical-now-text")}
            </MDTypography>
          </div>
        </div>

        <div className="wizard__single">
          <div className="wizard__case">
            <label htmlFor="enddate">{text("Wizard-report-technical-first-step-enddate-label")}</label>
            <DatePicker
              className="select_large"
              id="enddate"
              editable={false}
              cleanable={true}
              format="yyyy-MM-dd HH:mm"
              onClean={(field) => {
                service.send({
                  type: "WizardCreateReportTechnical:breakdown:change",
                  payload: {
                    endDate: DateTime.now().plus({ hour: 4 }).toISO(),
                  },
                });
                setData({ endDate: DateTime.now().plus({ hour: 4 }).toISO() });
              }}
              value={
                breakdownInputs?.endDate &&
                new Date(breakdownInputs?.endDate) instanceof Date &&
                !isNaN(new Date(breakdownInputs?.endDate))
                  ? new Date(breakdownInputs?.endDate)
                  : addHours(new Date(), 4)
              }
              calendarDefaultDate={new Date(DateTime.now().plus({ hour: 4 }).toISO())}
              placeholder={text("Wizard-pool-end-date-picker-placeholder")}
              onChange={(field) => {
                if (field) {
                  service.send({
                    type: "WizardCreateReportTechnical:breakdown:change",
                    payload: {
                      endDate: new Date(field) > new Date() ? new Date(field) : addMinutes(new Date(), 1),
                    },
                  });
                  setData({
                    endDate: new Date(field) > new Date() ? new Date(field) : addMinutes(new Date(), 1),
                  });
                }
              }}
              style={{ width: "100%" }}
              shouldDisableDate={allowedRange(
                DateTime.now().plus({ hours: 0 }).toISO(),
                DateTime.now().plus({ hours: 72 }).toISO(),
              )}
              shouldDisableHour={(hour, date) => {
                if (
                  new Date(date).getDate() === new Date().getDate() &&
                  new Date(date).getMonth() === new Date().getMonth() &&
                  new Date(date).getFullYear() === new Date().getFullYear()
                ) {
                  return hour < new Date().getHours();
                } else if (
                  new Date(date).getDate() === new Date(DateTime.now().plus({ days: 3 }).toISO()).getDate() &&
                  new Date(date).getMonth() === new Date(DateTime.now().plus({ days: 3 }).toISO()).getMonth() &&
                  new Date(date).getFullYear() === new Date(DateTime.now().plus({ days: 3 }).toISO()).getFullYear()
                ) {
                  return hour > new Date().getHours();
                } else {
                  return false;
                }
              }}
              shouldDisableMinute={(minute, date) => {
                if (
                  new Date(date).getDate() === new Date().getDate() &&
                  new Date(date).getMonth() === new Date().getMonth() &&
                  new Date(date).getFullYear() === new Date().getFullYear()
                ) {
                  if (new Date(date).getHours() === new Date().getHours()) {
                    return minute < new Date().getMinutes() + 1;
                  } else {
                    return false;
                  }
                } else if (new Date(date).getDay() === new Date(DateTime.now().plus({ days: 3 }).toISO()).getDay()) {
                  return minute > new Date().getMinutes() + 1;
                } else {
                  return false;
                }
              }}
            />
          </div>
        </div>

        <div className="wizard__single">
          <div className="wizard__case">
            <label htmlFor="type">{text("Wizard-report-technical-first-step-type-label")}</label>
            <SelectPicker
              className="select_large"
              value={breakdownInputs?.type ? breakdownInputs.type : null}
              searchable={false}
              disabledItemValues={disableOptionType}
              data={[
                { label: text("Wizard-report-technical-first-step-select-type-entry"), value: "ENTRY" },
                { label: text("Wizard-report-technical-first-step-select-type-exit"), value: "EXIT" },
              ]}
              onChange={(field) => {
                service.send({
                  type: "WizardCreateReportTechnical:breakdown:change",
                  payload: {
                    type: field,
                  },
                });
                setData({ type: field });
              }}
              style={{ width: "100%" }}
              placeholder={text("global-placeholder-default")}
            />
          </div>
        </div>

        <div className="wizard__single">
          <div className="wizard__case">
            <label htmlFor="type">{text("Wizard-report-technical-first-step-category-label")}</label>
            <SelectPicker
              className="select_large"
              value={breakdownInputs?.category ? breakdownInputs.category : null}
              searchable={false}
              data={[
                { label: text("Wizard-report-technical-first-step-select-category-c10"), value: "C10" },
                { label: text("Wizard-report-technical-first-step-select-category-c20"), value: "C20" },
                { label: text("Wizard-report-technical-first-step-select-category-c30"), value: "C30" },
                { label: text("Wizard-report-technical-first-step-select-category-c40"), value: "C40" },
              ]}
              onChange={(field) => {
                service.send({
                  type: "WizardCreateReportTechnical:breakdown:change",
                  payload: {
                    category: field,
                  },
                });
                setData({ category: field });
              }}
              placeholder={text("global-placeholder-default")}
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div className="wizard__single">
          <div className="wizard__case">
            <label htmlFor="description">{text("Wizard-report-technical-first-step-description-label")}</label>
            <textarea
              id="description"
              style={{
                borderRadius: "6px",
                padding: "8px",
                border: "1px solid #e5e5ea",
                width: "100%",
                backgroundColor: "transparent",
                resize: "none",
              }}
              onChange={(e) => {
                service.send({
                  type: "WizardCreateReportTechnical:breakdown:change",
                  payload: {
                    description: e.target.value,
                  },
                });
                setData({ description: e.target.value });
              }}
              rows={6}
              value={breakdownInputs?.description ? breakdownInputs.description : ""}
            ></textarea>
          </div>
        </div>
      </div>

      <style jsx="true">
        {`
          .wizard {
            width: 85%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            height: 50vh;
            min-height: 500px;
            box-sizing: border-box;
            padding: 20px;
            max-height: 600px;
          }

          .wizard * {
            font-size: 14px;
          }

          .wizard__subtitle {
            font-size: 18px;
            line-height: 1;
            margin-bottom: 15px;
          }

          .wizard__note {
            width: 35%;
            min-width: 230px;
            height: 100%;

            border-radius: 6px;
            padding: 15px;
            border: 1px solid #e5e5ea;
            margin-right: 25px;
            overflow: hidden;
          }

          .wizard__form {
            margin-left: 25px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 68%;
            height: 100%;
          }

          .wizard__duo,
          .wizard__single {
            margin-bottom: 10px;
          }

          .wizard__single:last-of-type {
            margin-bottom: 0px;
          }

          .wizard__duo {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .wizard__duo .wizard__case:first-of-type {
            margin-right: 20px;
          }

          .wizard__duo .wizard__case:last-of-type {
            margin-left: 20px;
          }

          .wizard label {
            margin-bottom: 4px;
          }

          .wizard__single {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }

          .wizard__case {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }

          @media screen and (max-width: 1000px) {
            .wizard {
              width: 90%;
              padding-right: 0px;
              padding-left: 0px;
            }

            .wizard * {
              font-size: 13px;
            }

            .wizard__subtitle {
              font-size: 16px;
            }

            .wizard__duo {
              flex-direction: column;
            }

            .wizard__duo .wizard__case:first-of-type {
              margin-right: 0px;
            }

            .wizard__duo .wizard__case:last-of-type {
              margin-left: 0px;
            }

            .wizard__form {
              margin-left: 15px;
              width: 100%;
              min-width: auto;
            }

            .wizard__note {
              margin-right: 15px;
            }
          }

          .rs-picker-default .rs-picker-toggle.rs-btn-lg {
            margin-top: 0.25rem;
            padding-top: 0.7rem;
            padding-bottom: 0.35rem;
            height: 2.8rem;
          }

          .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
            top: 0.4rem;
          }

          .rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
            padding-top: 0.35rem;
          }

          .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
            top: 0.7rem;
          }

          .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
            top: 0.7rem;
          }

          .rs-picker-menu {
            position: absolute;
            text-align: left;
            z-index: 1300;
            border-radius: 6px;
            background-color: #fff;
            background-color: var(--rs-bg-overlay);
            -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            -webkit-box-shadow: var(--rs-shadow-overlay);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: var(--rs-shadow-overlay);
            overflow: hidden;
            -webkit-transition: none;
            transition: none;
            color: #ababab;
            /* stylelint-disable-next-line */
          }

          .rs-picker-toggle-textbox {
            border: 1px solid #d2d6da;
          }

          .rs-picker-toggle-active {
            box-shadow: 0 0 0 3px transparent;
          }

          .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
          .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
          .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
            border: 2px solid black;
          }

          .rs-picker-toggle-value {
            color: #ababab;
          }
        `}
      </style>
    </div>
  );
}
