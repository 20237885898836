/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material UI
import { styled } from "@mui/material/styles";

// Material Dashboard 2 React base styles
import CheckIcon from "@mui/icons-material/Check";
// import colors from 'assets/@ndres-ui/theme/base/colors';
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";
import { capitalize } from "assets/commonHooks/capitalize";

const CardBox = styled(MDBox)(({ theme, selected }) => ({
  background: selected ? "#ababab10" : "transparent",
  // boxShadow: selected ? '0px 0px 8px 0px rgba(138,138,138,0.25)' : 'none',
  // cursor: 'pointer',
  // userSelect: 'none',
  borderRadius: "10px",
  border: selected ? "2px solid #ababab20" : "2px solid #ababab00",
  "&:hover": {
    background: "#ababab10",
  },
  "&:focus:not(:hover)": {
    color: "#c2c2c3",
  },
}));

const Check = styled(CheckIcon)(({ theme, selected }) => ({
  position: "absolute",
  top: "1.5rem",
  left: 10,
  width: "25px",
  height: "25px",
  background: "white",
  borderRadius: "50%",
  border: "2px solid green",
  color: "green",
  margin: "0px",
  padding: "2px",
  "&:hover": {
    background: "#ababab10",
  },
  "&:focus:not(:hover)": {
    color: "#c2c2c3",
  },
}));

const Provider = styled(MDBox)(({ theme, selected, image = "" }) => ({
  position: "absolute",
  top: 10,
  right: 10,
  width: "60px",
  height: "60px",
  backgroundSize: "130%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundImage: `url("${image}")`,
  // borderRadius: '50%',
  backgroundColor: "transparent",
  color: "green",
  margin: "0px",
  padding: "2px",
}));
function ProductInfoCard({
  title,
  id,
  ospContractId,
  description,
  zoneCondition = [],
  currency,
  price,
  type,
  selected = false,
  userData,
  iconLogo,
}) {
  const text = useDictionaries();
  // Render the card zoneCondition items
  const renderItems = zoneCondition?.map(({ zoneId, name, zoneProductConditionType }) => (
    <MDBox key={zoneId} display="flex" py={0} pr={2}>
      <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
        {`${name}`}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;
        {`${zoneProductConditionType}`}
      </MDTypography>
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%" }}>
      <CardBox
        height="380px"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="column"
        selected={selected}
        style={{ overflowY: "auto" }}
      >
        <MDBox position="absolute" width="calc(100% - 40px)" bgColor="#fafafa" pb={3}>
          {selected ? <Check /> : null}

          <Provider image={`/image/providers/${iconLogo}`} />

          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" mt={3} textAlign="center">
            {text("Wizard-third-step-contract-summary")}
          </MDTypography>
        </MDBox>

        <MDBox width="100%" display="flex" justifyContent="flex-start" alignItems="center" px={2} mt={2} pt="80px">
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {text(`product-${id}-name`)}
          </MDTypography>
          <MDBox display="flex" justifyContent="flex-start" alignItems="center" pl={4}>
            &nbsp;
            <MDTypography color="secondary" variant="caption" fontWeight="medium" textTransform="capitalize">
              {currency}
            </MDTypography>
            &nbsp;
            <MDTypography color="secondary" variant="h5" fontWeight="medium" textTransform="capitalize">
              {price}
            </MDTypography>
            &nbsp;
            <MDTypography color="secondary" variant="caption" fontWeight="medium" textTransform="capitalize">
              {type.length > 0 ? `/${type}` : ""}
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox width="100%" display="flex" justifyContent="flex-start" alignItems="center" px={2} mt={2}>
          <MDBox pb={0}>
            <MDBox display="flex" py={0} pr={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                {text("Wizard-third-step-contractId")}: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;
                {ospContractId}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" py={0} pr={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                {text("Wizard-third-step-email")}: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;
                {userData?.username}
              </MDTypography>
            </MDBox>

            <MDBox display="flex" py={0} pr={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                {text("Wizard-third-step-first-name")}: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;
                {userData.firstName ? capitalize(userData.firstName) : "--"}
              </MDTypography>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" ml={4}>
                {text("Wizard-third-step-last-name")}: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text">
                &nbsp;
                {userData.lastName ? capitalize(userData.lastName) : "--"}
              </MDTypography>
            </MDBox>

            <MDBox display="flex" py={0} pr={2} mt={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                {text("Wizard-third-step-address")}: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
                &nbsp;
                {userData.address1 ?? "--"}
                ,&nbsp;
                {userData.address2 ?? "--"}
                ,&nbsp;
                {userData.zipcode ?? "--"}
                ,&nbsp;
                {userData.city ?? "--"}
              </MDTypography>
            </MDBox>

            <MDBox display="flex" py={0} pr={2} mt={0}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                {text("Wizard-third-step-country")}: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
                &nbsp;
                {userData.country ?? "--"}
              </MDTypography>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize" ml={4}>
                {text("Wizard-third-step-city")}: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
                &nbsp;
                {userData.city ?? "--"}
              </MDTypography>
            </MDBox>

            <MDBox display="flex" py={0} pr={2} mt={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                {text("Wizard-third-step-phone")}: &nbsp;
              </MDTypography>
              <MDTypography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
                &nbsp;
                {userData.phone ?? "--"}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>

        <MDBox width="100%" display="flex" justifyContent="flex-start" alignItems="center" px={2} mt={4}>
          <MDBox mb={0} lineHeight={1}>
            <MDTypography variant="button" color="text" fontWeight="light">
              {description}
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox width="100%" display="flex" justifyContent="flex-start" alignItems="center" px={2} mt={2}>
          <MDBox pb={0}>{renderItems}</MDBox>
        </MDBox>
      </CardBox>
    </Card>
  );
}

// Setting default props for the ProductInfoCard
ProductInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProductInfoCard
ProductInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ProductInfoCard;
