import { DateTime } from "luxon";

export const checkContractValidity = ({ isEnabled, startDate, stopDate }) => {
  let contractStatus = "";
  let isValid = isEnabled;
  // if the end date of the contract is before the current date
  // then the status is expired
  if (DateTime.fromISO(stopDate) < Date.now()) {
    contractStatus = "motorist-contract-status-expired";
    isEnabled = false;
    isValid = false;
  }
  // if the end date of the contract is after the current date and the the contract is enabled
  // then the status is active
  if (DateTime.fromISO(stopDate) > Date.now() && isEnabled) {
    contractStatus = "motorist-contract-status-active";
    isValid = true;
  }
  // if the end date of the contract is after the current date and the the contract is disabled
  // then the status is suspended
  if (DateTime.fromISO(stopDate) > Date.now() && !isEnabled) {
    contractStatus = "motorist-contract-status-suspended";
    isValid = false;
  }
  // if the start date of the contract is after the current date
  // then the contract is not valid
  if (DateTime.fromISO(startDate) > Date.now()) {
    isValid = false;
  }

  return { contractStatus, isEnabled, isValid };
};
