/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from "react";

import { format } from "date-fns";
import Collapse from "@mui/material/Collapse";
import Switch from "@mui/material/Switch";

import useDictionaries from "assets/commonHooks/useDictionaries";

import Paginat from "assets/@luc-ui/Pagination";
import MDTypography from "assets/@ndres-ui/MDTypography";
import { DateTime } from "luxon";
import MDButton from "../../../@ndres-ui/MDButton";
import { Modal } from "rsuite";
import MDBox from "../../../@ndres-ui/MDBox";
import Popup from "assets/@redapuenta-ui/popup/popup";
import { useSelector } from "@xstate/react";
import { service } from "../../../../components/MotoristDetails/reducer";
import { useDispatch } from "react-redux";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

const ContractDetailsTable = ({
  motoristDetails,
  motoristContracts,
  onContractSwitch,
  onSendMail,
  onFreeFlag,
  onVerifLink,
  contractPaging,
  onSelectPage,
}) => {
  const text = useDictionaries();
  const dispatch = useDispatch();

  const contractStatus = {
    "motorist-contract-status-active": "success",
    "motorist-contract-status-suspended": "warning",
    "motorist-contract-status-expired": "dark",
  };

  const [open, setOpen] = useState({});
  const [openPopupFlag, setOpenPopupFlag] = useState();
  const [titlePopupFlag, setTitlePopupFlag] = useState();
  const [textPopupFlag, setTextPopupFlag] = useState();
  const [modePopupFlag, setModePopupFlag] = useState("choice");
  const [currentParamsFlag, setCurrentParamsFlag] = useState({});

  const [openPopupMail, setOpenPopupMail] = useState(false);

  const [openPopupLink, setOpenPopupLink] = useState(false);
  const [titlePopupLink, setTitlePopupLink] = useState();
  const [textPopupLink, setTextPopupLink] = useState();
  const [currentParamsLink, setCurrentParamsLink] = useState({});
  const [modePopupLink, setModePopupLink] = useState("load");
  const [payloadPopupLink, setPayloadPopupLink] = useState({});

  function handleClick(id) {
    setOpen((prevState) => ({ [id]: !prevState[id] }));
  }

  //: ------------- FLAG ---------------------
  //! Selector for Popup FLag
  const freeflagContract = useSelector(service, ({ context }) => context.freeflagContract);
  const errorFreeflagContract = useSelector(service, ({ context }) => context.errorFreeflagContract);

  //! SUCCESS for Popup Flag
  useEffect(() => {
    if (currentParamsFlag && freeflagContract?.contractId === currentParamsFlag?.contractId) {
      setTextPopupFlag({ type: "success", value: text(`popup-contract-${currentParamsFlag.mode}-success`) });
      setModePopupFlag("result");
      dispatch({
        type: "MotoristDetails:reset-error",
        payload: {},
      });
    }
  }, [freeflagContract]);

  //! ERROR for Popup Flag
  useEffect(() => {
    if (errorFreeflagContract) {
      setTitlePopupFlag({
        type: "basic",
        value: text(`global-error-title-${new String(errorFreeflagContract).toLowerCase()}`),
      });
      setTextPopupFlag({
        type: "error",
        value: text(`global-error-text-${new String(errorFreeflagContract).toLowerCase()}`),
      });
      setModePopupFlag("result");
    }
  }, [errorFreeflagContract]);

  //! Open Popup Flag
  const flagClick = (contractId, parkingId, mode) => {
    setModePopupFlag("choice");
    setTitlePopupFlag({ type: "basic", value: text(`global-confirm-${mode}-contract`) });
    setTextPopupFlag(undefined);
    setOpenPopupFlag(true);
    setCurrentParamsFlag({ contractId, parkingId, mode });
  };

  //! Exit in Popup Flag
  const popupExitFlag = () => {
    setOpenPopupFlag(false);
    setModePopupFlag("choice");
  };

  //! Choice in Popup Flag
  const popupChoiceFlag = (choice) => {
    if (!choice) return setOpenPopupFlag(false);

    dispatch({
      type: "MotoristDetails:reset-error",
      payload: {},
    });

    setModePopupFlag("load");
    onFreeFlag(currentParamsFlag);
  };

  //: ------------- MAIL ---------------------

  //! Open Popup Mail
  const sendMailClick = (contractId, motoristId) => {
    onSendMail({ contractId, motoristId });
    setOpenPopupMail(true);
  };

  //! Exit Popup Mail
  const popupExitMail = () => {
    setOpenPopupMail(false);
  };

  //: ------------- LINK ---------------------

  const verifLinkContract = useSelector(service, ({ context }) => context.verifLinkContract);
  const errorVerifLinkContract = useSelector(service, ({ context }) => context.errorVerifLinkContract);

  //! SUCCESS for Popup Link
  useEffect(() => {
    if (currentParamsLink && verifLinkContract?.contractId === currentParamsLink?.contractId) {
      setTextPopupLink({
        type: "basic",
        value: text("popup-verif-link-text"),
      });
      setPayloadPopupLink({ inputVerifLink: verifLinkContract?.verifLink });
      setModePopupLink("link");
    }
  }, [verifLinkContract]);

  //! ERROR for Popup lINK
  useEffect(() => {
    if (errorVerifLinkContract) {
      setTitlePopupLink({
        type: "basic",
        value: text(`global-error-title-${new String(errorVerifLinkContract).toLowerCase()}`),
      });
      setTextPopupLink({
        type: "error",
        value: text(`global-error-text-${new String(errorVerifLinkContract).toLowerCase()}`),
      });
      setModePopupLink("result");
    }
  }, [errorVerifLinkContract]);

  //! Open Popup Link
  const verifLinkClick = (contractId, motoristId) => {
    dispatch({
      type: "MotoristDetails:reset-error",
      payload: {},
    });
    setModePopupLink("load");
    setCurrentParamsLink({ contractId, motoristId });
    setTitlePopupLink({ type: "basic", value: text("popup-verif-link-title") });
    setTextPopupLink({
      type: "basic",
      value: "",
    });
    setOpenPopupLink(true);
    onVerifLink({ contractId });
  };

  //! Exit in Popup Link
  const popupExitLink = () => {
    setOpenPopupLink(false);
  };

  return (
    <>
      <section className="section_details">
        <MDTypography className="global_xs_title" type="tableHeader" textTransform="first">
          {text("widgets-motorist-details-table-header-contracts")}
        </MDTypography>
        <div className="global_separa"></div>
        <div>
          <div>
            <div className="flexTableRowsEqual2">
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-header-product")}
                </MDTypography>
              </div>

              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-header-start-date")}
                </MDTypography>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-header-end-date")}
                </MDTypography>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-motorist-address-status")}
                </MDTypography>
              </div>
            </div>
            {motoristContracts?.map(
              (
                {
                  contractId,
                  ospContractId,
                  status,
                  isActive,
                  startDate,
                  endDate,
                  parkingName,
                  product,
                  plates,
                  card,
                  motoristId,
                  freeflag,
                  parkingId,
                },
                index,
              ) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  key={`Contract-${index + 1}`}
                >
                  <div
                    aria-hidden="true"
                    className="flexTableRowsEqual2 details_accor hoverPink"
                    onClick={() => handleClick(contractId)}
                  >
                    <div className="flexTableHead">
                      <MDTypography type="tableDataVisible" textTransform="first">
                        {text(product)}
                      </MDTypography>
                    </div>

                    <div className="flexTableHead">
                      <MDTypography type="tableData">
                        {DateTime.fromISO(startDate).toFormat(
                          process.env.NODE_ENV === "development" ? "dd.MM.yyyy HH:mm:ss" : "dd.MM.yyyy",
                        )}
                      </MDTypography>
                    </div>
                    <div className="flexTableHead">
                      <MDTypography type="tableData">
                        {DateTime.fromISO(endDate).toFormat(
                          process.env.NODE_ENV === "development" ? "dd.MM.yyyy HH:mm:ss" : "dd.MM.yyyy",
                        )}
                      </MDTypography>
                    </div>
                    <div className="flexTableHead">
                      <MDTypography type="status" textTransform="first" color={contractStatus[status]}>
                        {text(status)}
                      </MDTypography>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "calc(100% - 10px)",
                    }}
                  >
                    <Collapse style={{ fontSize: "1em" }} in={open[contractId]}>
                      <div
                        key={contractId}
                        style={{
                          marginBottom: "10px",
                          padding: "8px",
                          width: "100%",
                          backgroundColor: open[contractId] ? "#F2F2F2" : "",
                          borderRadius: "3px",
                        }}
                      >
                        <div className="flexTableRows" style={{ border: "none" }}>
                          <div className="flexTableHead">
                            <MDTypography className="global_label" type="tableHeader" textTransform="first">
                              {text("widgets-motorist-details-table-header-parking")}
                            </MDTypography>
                          </div>
                          <div className="flexTableData">
                            <div>
                              <MDTypography type="tableData" textTransform="first">
                                {text(parkingName)}
                              </MDTypography>
                            </div>
                          </div>

                          <div className="flexTableHead">
                            <MDTypography className="global_label" type="tableHeader" textTransform="first">
                              {text("widgets-motorist-details-table-header-contractId")}
                            </MDTypography>
                          </div>
                          <div className="flexTableData">
                            <div>
                              <MDTypography type="tableData" textTransform="first">
                                {ospContractId}
                              </MDTypography>
                            </div>
                          </div>
                        </div>

                        {card?.value || plates.length > 0 ? (
                          <div className="flexTableRows" style={{ border: "none" }}>
                            <div className="flexTableHead">
                              {plates.length > 0 ? (
                                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                                  {text("widgets-motorist-details-table-header-plates")}
                                </MDTypography>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="flexTableData">
                              <div>
                                {plates.map(({ value }) => (
                                  <div key={value}>
                                    <MDTypography type="tableData" textTransform="uppercase">
                                      - {value}
                                    </MDTypography>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div className="flexTableHead">
                              {card?.value ? (
                                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                                  {text("widgets-motorist-details-table-header-card")}
                                </MDTypography>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="flexTableData">
                              <div>
                                <MDTypography type="tableData" textTransform="uppercase">
                                  {card?.value} ({card?.provider})
                                </MDTypography>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="flexTableRows" style={{ border: "none", marginTop: "-8px" }}>
                          <div className="flexTableHead">
                            <MDTypography className="global_label" type="tableHeader" textTransform="first">
                              {text("widgets-motorist-details-table-header-flag")}
                            </MDTypography>
                          </div>
                          <div className="flexTableData">
                            <div>
                              <MDTypography type="tableData" textTransform="first">
                                {freeflag
                                  ? text("widgets-motorist-details-table-antipassback-neuralized")
                                  : text("widgets-motorist-details-table-antipassback-active")}
                              </MDTypography>
                            </div>
                          </div>

                          <div className="flexTableHead">
                            <MDTypography className="global_label" type="tableHeader" textTransform="first">
                              status
                            </MDTypography>
                          </div>
                          <div style={{ transform: "translateX(-10px)" }} className="flexTableData">
                            <div>
                              <MDTypography
                                style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                                type="tableData"
                                textTransform="first"
                              >
                                <Switch
                                  size="medium"
                                  color="warning"
                                  checked={isActive}
                                  onChange={(event) =>
                                    onContractSwitch({
                                      event: event.target.checked,
                                      contractId,
                                    })
                                  }
                                />

                                <MDTypography type="status" textTransform="first" color={contractStatus[status]}>
                                  {text(status)}
                                </MDTypography>
                              </MDTypography>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ marginTop: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}
                        >
                          {motoristDetails?.isAnonymous === false ? (
                            <>
                              {motoristDetails && motoristDetails?.state !== "VERIFIED" ? (
                                <MDButton
                                  style={{ marginRight: "20px" }}
                                  size="small"
                                  color="primary"
                                  onClick={() => verifLinkClick(contractId, motoristId)}
                                >
                                  <i className="fa-solid fa-link" style={{ fontSize: "16px" }}></i>
                                </MDButton>
                              ) : null}

                              <MDButton
                                style={{ marginRight: "10px" }}
                                size="small"
                                color="primary"
                                onClick={() => sendMailClick(contractId, motoristId)}
                              >
                                {text("widgets-motorist-details-button-send-mail")}
                              </MDButton>
                            </>
                          ) : null}

                          {isActive ? (
                            !freeflag ? (
                              <MDButton
                                style={{ marginLeft: "10px" }}
                                size="small"
                                color="primary"
                                onClick={() => flagClick(contractId, parkingId, "flag")}
                              >
                                {text("widgets-motorist-details-table-button-flag")}
                              </MDButton>
                            ) : (
                              <MDButton
                                style={{ marginLeft: "10px" }}
                                size="small"
                                color="primary"
                                onClick={() => flagClick(contractId, parkingId, "deflag")}
                              >
                                {text("widgets-motorist-details-table-button-deflag")}
                              </MDButton>
                            )
                          ) : null}
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              ),
            )}
            <Paginat page={contractPaging?.page} totalPages={contractPaging?.total} onSelectPage={onSelectPage} />
          </div>
        </div>
      </section>
      {openPopupMail ? (
        <Popup
          titlePopup={{ type: "basic", value: text("widgets-motorist-details-contract-modal-first-p") }}
          modePopup={"result"}
          typePopup={"mail"}
          handlePopupExit={popupExitMail}
        />
      ) : null}
      {openPopupFlag ? (
        <Popup
          titlePopup={titlePopupFlag}
          textPopup={textPopupFlag}
          modePopup={modePopupFlag}
          handlePopupChoice={popupChoiceFlag}
          handlePopupExit={popupExitFlag}
        />
      ) : null}
      {openPopupLink ? (
        <Popup
          titlePopup={titlePopupLink}
          textPopup={textPopupLink}
          modePopup={modePopupLink}
          payloadPopup={payloadPopupLink}
          handlePopupExit={popupExitLink}
        />
      ) : null}
    </>
  );
};

export default ContractDetailsTable;
