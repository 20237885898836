/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Card from "@mui/material/Card";

// Material UI
import { styled } from "@mui/material/styles";

// Material Dashboard 2 React base styles
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";

const CardBox = styled(MDBox)(({ theme, selected }) => ({
  background: selected ? "#ababab10" : "transparent",
  // boxShadow: selected ? '0px 0px 8px 0px rgba(138,138,138,0.25)' : 'none',
  cursor: "pointer",
  userSelect: "none",
  borderRadius: "10px",
  border: selected ? "2px solid #ababab20" : "2px solid #ababab00",
  "&:hover": {
    background: "#ababab10",
  },
  "&:focus:not(:hover)": {
    color: "#c2c2c3",
  },
}));

function ProductInfoCard({ productId, currency, title, price, type, selected = false, onClick, iconLogo }) {
  const text = useDictionaries();

  return (
    <Card sx={{ height: "100%", textOverflow: "ellipsis", width: "240px" }}>
      <CardBox
        onClick={onClick}
        height="100%"
        width="100%"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="column"
        selected={selected}
      >
        <MDBox width="100%" height="40%" display="flex" justifyContent="flex-start" alignItems="center" pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {`${text(`product-${productId}-name`)}`.slice(0, 25)}
          </MDTypography>
        </MDBox>
        <MDBox
          width="100%"
          display="flex"
          justifyContent="flex-start"
          alignItems="flex-start"
          px={2}
          py={1}
          overflow="hidden"
        >
          <MDBox mb={0} lineHeight={1} width="100%">
            <MDTypography variant="button" color="text" fontWeight="light">
              <span style={{ fontSize: "0.9em" }}>{text(`product-${productId}-description`)}</span>
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox px={2} py={1} overflow="hidden" width="100%" />
      </CardBox>
    </Card>
  );
}

// Setting default props for the ProductInfoCard
ProductInfoCard.defaultProps = {
  shadow: false,
};

// Typechecking props for the ProductInfoCard
ProductInfoCard.propTypes = {};

export default ProductInfoCard;
