import React from "react";
import SessionDetailTable from "./materials/table";
import ContainerInGrid from "assets/@luc-ui/Container";
import { ContextProvider } from "./context";

const SessionDetails = () => {
  const onSelectSession = (newMotorist) => ({ newMotorist });
  const onForceClose = (sessionId) => {
    return { sessionId };
  };

  return (
    <div style={{ height: "100%", overflow: "scroll", position: "relative" }}>
      <ContainerInGrid
        headerName="widgets-session-details-title"
        headerTextColor="primary"
        headerBackgroundColor="#ffffff"
      >
        <ContextProvider
          storeName="SessionDetails"
          events={{
            onSelectSession,
            onForceClose,
          }}
        >
          <SessionDetailTable />
        </ContextProvider>
      </ContainerInGrid>
    </div>
  );
};

export default SessionDetails;
