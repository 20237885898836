import React from "react";

import BusinessTenantsTable from "./materials/table";
import ContainerInGrid from "assets/@luc-ui/Container";

import { ContextProvider } from "./context";

const Motorists = (props) => {
  const onSelectPage = (newPage) => ({ newPage });
  const onSelectContract = (selectedContract) => {

    return selectedContract;
  };

  return (
    <div style={{ height: "100%", overflow: "scroll" }}>
      <ContainerInGrid
        headerName="widgets-business-tenants-title"
        headerTextColor="error"
        headerBackgroundColor="light"
      >
        <ContextProvider
          storeName="BusinessTenants"
          events={{
            onSelectPage,
            onSelectContract,
          }}
        >
          <BusinessTenantsTable />
        </ContextProvider>
      </ContainerInGrid>
    </div>
  );
};

export default Motorists;
