import { gql } from "@apollo/client";

export default gql`
  query verifyMotorist($token: String!) {
    verifyMotorist(token: $token) {
      motorist {
        _id
        username
        firstName
        lastName
        isAnonymous
        address1
        address2
        zipcode
        city
        state
        country
        phone
        lang
        gender
      }
      fieldsToVerify {
        motorist
      }
    }
  }
`;
