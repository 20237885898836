import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "@xstate/react";

import { service } from "../../../reducer";
import { service as I18Nservice } from "../../../../I18n/reducer";

// @mui materials
import { styled } from "@mui/system";
import MDInput from "assets/@ndres-ui/MDInput";
import MenuItem from "@mui/material/MenuItem";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

// rsuite
import DatePicker from "rsuite/DatePicker";
import DateRangePicker from "rsuite/DateRangePicker";
import { subDays, format, addDays, addMinutes } from "date-fns";

import Grid from "@mui/material/Grid";
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";
import Switch from "@mui/material/Switch";
import { providersCredentials } from "../../../../../assets/commonFunctions/useProvider";
import TcsExample from "../../materials/csvExamples/Tcs";
import TcsOspExample from "../../materials/csvExamples/TcsOsp";
import Skidata from "../../materials/csvExamples/Skidata";
import Plates from "../../materials/csvExamples/Plates";
import Qrcode from "../../materials/csvExamples/Qrcode";
import { isToday } from "../../../../../assets/commonFunctions/isToday";
import { DateTime } from "luxon";
import { SelectPicker } from "rsuite";

// local hooks
const { allowedRange, beforeToday, combine } = DateRangePicker;

const Input = styled(MDInput)(({ theme }) => ({
  width: "calc(100% + 2px)",
  margin: "0px -5px",
  height: "2.2rem",
  borderColor: "#d2d6da",
  [`&:hover &:focused .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#d2d6da",
  },
}));

const SelectMUI = styled(({ value, ...rest }) => (
  <Input
    value={value ?? ""}
    InputProps={{
      style: {
        height: 44.125,
        // padding: '12px',
        width: "100%",
      },
    }}
    {...rest}
  />
))(({ theme }) => ({
  width: "100%",
  padding: "2px 2px",
  margin: "3px 0px 0px -3px",
  [`&:hover &:focused .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#d2d6da",
  },
}));

const Android12Switch = styled(({ label, ...rest }) => (
  <MDBox pt="12px" m="0px" width="100%" display="flex" alignItems="center" justifyContent="flex-start" height="100%">
    <Switch {...rest} />
    <MDTypography fontSize="0.9rem" pb="4px">
      {label}
    </MDTypography>
  </MDBox>
))(({ theme }) => ({
  padding: 10,
  width: "auto",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 5,
    transitionDuration: "300ms",
    "& .Mui-checked": {
      transform: "translateX(36px)",
      color: "#fff",
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        width: 25,
        height: 25,
        margin: 0,
        top: "-15px",
      },
      "& .Mui-disabled .MuiSwitch-track": {
        opacity: 0.5,
      },
      "& .MuiSwitch-track": {
        backgroundColor: "#4caf50!important",
        borderColor: "#4caf50!important",
      },
    },
    "& .Mui-focusVisible .MuiSwitch-thumb": {
      border: "6px solid #fff",
    },
    "& .Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "& .Mui-disabled .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    width: "40px",
    height: "15px",
    backgroundColor: theme.palette.grey[400],
    transition: theme.transitions.create(["background-color"], {
      duration: 300,
    }),
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "15%",
      transform: "translateY(-40%)",
      width: 32,
      height: 32,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 25,
    height: 25,
    margin: 0,
    top: "-15px",
  },
  "& .Mui-checked+.MuiSwitch-track": {
    backgroundColor: "#4caf50!important",
    borderColor: "#4caf50!important",
  },
}));
const CsvCredentialsExamplesSelector = {
  TCS: <TcsExample />,
  TCSOSP: <TcsOspExample />,
  SKIDATA: <Skidata />,
  PLATE: <Plates />,
  QRCODE: <Qrcode />,
};

export default function Step1({ addCompleted, setData, formData, addNextBt }) {
  const ref = useRef(null);
  const text = useDictionaries();

  const [datePickerPrevStartDate, setDatePickerPrevStartDate] = useState(new Date());
  const [datePickerPrevEndDate, setDatePickerPrevEndDate] = useState(new Date());

  const product = useSelector(service, ({ context }) => context.product);
  const csv = useSelector(service, ({ context }) => context.csv);
  const motorist = useSelector(service, ({ context }) => context.motorist);
  const selectedLang = useSelector(I18Nservice, ({ context }) => {
    return context.selectedLang;
  });

  const validMotoristList =
    Array.isArray(motorist) &&
    motorist.length > 0 &&
    !motorist.reduce((acc, { syntaxe }) => (syntaxe ? acc : acc + 1), 0);

  useEffect(() => {
    const testing = [
      !!formData?.productData,
      !!formData?.userProvider,
      !!formData?.startDate && formData?.startDate !== "",
      validMotoristList,
      formData?.lang !== "none",
    ];

    const result = testing.reduce((c, o) => c && o, true);
    addNextBt(0, result);
  }, [formData, validMotoristList, motorist, csv]);

  useEffect(() => {
    addCompleted(0, true);
  }, []);

  useEffect(() => {
    if (!formData?.productData && product) {
      setData({
        productData: product,
      });
    }
  }, [product, formData]);

  if (!formData?.productData || !product?.startDate) {
    return "loading...";
  }

  return (
    <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
      <MDBox
        pt={0}
        style={{
          transition: "opacity ease 1s",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={7}>
            <MDBox mt={2} pt={1} ml={4} style={{}}>
              <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="dark">
                {text("Wizard-pool-motorist-csv")}
              </MDTypography>
              <SelectPicker
                value={formData?.userProvider ? formData.userProvider : ""}
                // label={text("Wizard-second-step-motorist-form-label-country")}
                placeholder={text("global-placeholder-default")}
                searchable={false}
                style={{ width: "100%", marginBottom: "10px" }}
                className="select_large"
                onChange={(newValue) => {
                  service.send({
                    type: "csv:change",
                    payload: { value: formData.value, userProvider: newValue },
                  });
                  return setData({ userProvider: newValue });
                }}
                data={[
                  providersCredentials.map((value) => ({
                    label: text(`global-providers-${value.toLowerCase()}`),
                    value: value,
                  })),
                  [
                    { label: text("global-providers-plate"), value: "PLATE" },
                    { label: text("global-providers-qrcode"), value: "QRCODE" },
                  ],
                ].flat(1)}
              ></SelectPicker>
              <MDInput
                id="outlined-multiline-static"
                // label="Multiline"
                success={validMotoristList}
                error={!validMotoristList}
                multiline
                value={csv}
                rows={10}
                // defaultValue="Default Value"
                style={{ width: "100%", borderRadius: "0px" }}
                onChange={(e) => {
                  service.send({
                    type: "csv:change",
                    payload: { value: e.target.value, userProvider: formData.userProvider },
                  });
                  return setData({ value: e.target.value });
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={4}>
            <MDBox mt={2} pt={1} ml={2} style={{ maxWidth: "450px" }}>
              <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="dark">
                {text("Wizard-pool-start-date-picker-label")}
              </MDTypography>
              <MDBox width="100%" ref={ref} style={{ maxWidth: "350px" }} ml="0px" mb="10px">
                <DatePicker
                  className="select_large"
                  editable={false}
                  cleanable={true}
                  value={
                    formData?.startDate &&
                    new Date(formData?.startDate) instanceof Date &&
                    !isNaN(new Date(formData?.startDate))
                      ? new Date(formData.startDate)
                      : null
                  }
                  format="yyyy-MM-dd HH:mm"
                  onChange={(date) => {
                    if (date) {
                      if (isToday(date)) {
                        setData({
                          startDate: new Date() < new Date(date) ? new Date(date) : new Date(),
                          endDate: null,
                        });
                      } else {
                        setData({
                          startDate: new Date(date),
                          endDate: null,
                        });
                      }
                    }
                  }}
                  onClean={() => {
                    setData({ startDate: null, endDate: null });
                  }}
                  placeholder={text("Wizard-pool-start-date-picker-placeholder")}
                  style={{ width: "246px" }}
                  onChangeCalendarDate={(date) => {
                    if (
                      new Date(date).getDate() !== new Date(datePickerPrevStartDate).getDate() ||
                      new Date(date).getMonth() !== new Date(datePickerPrevStartDate).getMonth() ||
                      new Date(date).getFullYear() !== new Date(datePickerPrevStartDate).getFullYear()
                    ) {
                      setDatePickerPrevStartDate(() => new Date(date));
                      if (isToday(date)) {
                        date.setHours(new Date().getHours(), new Date().getMinutes(), 0);
                      } else {
                        date.setHours(0, 0, 0);
                      }
                    }
                  }}
                  shouldDisableDate={combine(
                    allowedRange(
                      format(new Date(formData?.productData?.startDate), "yyyy-MM-dd HH:mm"),
                      format(subDays(new Date(formData.productData?.stopDate), 1), "yyyy-MM-dd HH:mm"),
                    ),
                    beforeToday(),
                  )}
                  shouldDisableHour={(hour, date) => {
                    if (isToday(date)) {
                      return parseInt(hour) >= DateTime.now().toObject().hour ? false : true;
                    } else {
                      return false;
                    }
                  }}
                  shouldDisableMinute={(minute, date) => {
                    if (isToday(date)) {
                      if (new Date().getHours() === new Date(date).getHours()) {
                        return parseInt(minute) > DateTime.now().toObject().minute ? false : true;
                      } else {
                        return false;
                      }
                    } else {
                      return false;
                    }
                  }}
                />
              </MDBox>
              <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="dark">
                {text("Wizard-pool-end-date-picker-label")}
              </MDTypography>
              <MDBox width="100%" ref={ref} style={{ maxWidth: "350px" }} ml="0px" mb="10px">
                <DatePicker
                  disabled={!formData?.startDate}
                  className="select_large"
                  editable={false}
                  cleanable={true}
                  value={
                    formData?.endDate &&
                    new Date(formData?.endDate) instanceof Date &&
                    !isNaN(new Date(formData?.endDate))
                      ? new Date(formData.endDate)
                      : null
                  }
                  format="yyyy-MM-dd HH:mm"
                  onChange={(date) => {
                    if (date) {
                      if (new Date(date) <= new Date(formData.startDate)) {
                        setData({
                          endDate: addMinutes(new Date(formData.startDate), 1),
                        });
                      } else {
                        setData({
                          endDate: new Date(date),
                        });
                      }
                    }
                  }}
                  onClean={() => {
                    setData({ endDate: null });
                  }}
                  placeholder={text("Wizard-pool-end-date-picker-placeholder")}
                  style={{ width: "246px" }}
                  shouldDisableDate={combine(
                    allowedRange(
                      format(
                        formData?.startDate
                          ? addMinutes(new Date(formData?.startDate), 1)
                          : new Date(formData?.productData?.startDate),
                        "yyyy-MM-dd HH:mm",
                      ),
                      format(subDays(new Date("2099-12-31"), 1), "yyyy-MM-dd HH:mm"),
                    ),
                    beforeToday(),
                  )}
                  onChangeCalendarDate={(date) => {
                    if (
                      new Date(date).getDate() !== new Date(datePickerPrevEndDate).getDate() ||
                      new Date(date).getMonth() !== new Date(datePickerPrevEndDate).getMonth() ||
                      new Date(date).getFullYear() !== new Date(datePickerPrevEndDate).getFullYear()
                    ) {
                      setDatePickerPrevEndDate(() => new Date(date));
                      if (
                        new Date(date).getDate() == new Date(formData?.startDate).getDate() &&
                        new Date(date).getMonth() == new Date(formData?.startDate).getMonth() &&
                        new Date(date).getFullYear() == new Date(formData?.startDate).getFullYear()
                      ) {
                        date.setHours(
                          new Date(formData?.startDate).getHours(),
                          addMinutes(new Date(formData?.startDate), 1).getMinutes(),
                          0,
                        );
                      } else {
                        date.setHours(23, 59, 0);
                      }
                    }
                  }}
                  shouldDisableHour={(hour, date) => {
                    if (
                      new Date(date).getDate() == new Date(formData?.startDate).getDate() &&
                      new Date(date).getMonth() == new Date(formData?.startDate).getMonth() &&
                      new Date(date).getFullYear() == new Date(formData?.startDate).getFullYear()
                    ) {
                      return parseInt(hour) >= new Date(formData?.startDate).getHours() ? false : true;
                    } else {
                      return false;
                    }
                  }}
                  shouldDisableMinute={(minute, date) => {
                    if (
                      new Date(date).getDate() == new Date(formData?.startDate).getDate() &&
                      new Date(date).getMonth() == new Date(formData?.startDate).getMonth() &&
                      new Date(date).getFullYear() == new Date(formData?.startDate).getFullYear()
                    ) {
                      if (new Date(date).getHours() === new Date(formData?.startDate).getHours()) {
                        return parseInt(minute) < new Date(formData?.startDate).getMinutes() + 1;
                      } else {
                        return false;
                      }
                    } else {
                      return false;
                    }
                  }}
                />
              </MDBox>
              <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="dark">
                {text("Wizard-pool-second-step-motorist-form-label-lang")}
              </MDTypography>
              <SelectPicker
                className="select_large"
                defaultValue={selectedLang}
                onClean={() => {
                  setData({ lang: "undefined" });
                }}
                value={formData?.lang ? formData.lang : undefined}
                onChange={(newValue) => setData({ lang: newValue })}
                placeholder={text("global-placeholder-default")}
                searchable={false}
                style={{ maxWidth: "246px", width: "100%" }}
                data={[
                  { label: text("global-select-lang-en_EN"), value: "en_EN" },
                  { label: text("global-select-lang-fr_FR"), value: "fr_FR" },
                  { label: text("global-select-lang-de_DE"), value: "de_DE" },
                  { label: text("global-select-lang-it_IT"), value: "it_IT" },
                ]}
              ></SelectPicker>
              <Grid item xs={6} />

              <Grid item xs={12}>
                <MDBox
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  height="100%"
                  width="100%"
                  pt="1em"
                >
                  <Android12Switch
                    checked={formData?.freeFlagIsEnabled}
                    label={text("Wizard-pool-second-step-activation-freeflag")}
                    color="success"
                    onChange={(evt) =>
                      setData({
                        freeFlagIsEnabled: !formData?.freeFlagIsEnabled,
                      })
                    }
                  />
                </MDBox>
              </Grid>
            </MDBox>
            {CsvCredentialsExamplesSelector[formData.userProvider]}
          </Grid>
        </Grid>
      </MDBox>

      <style jsx="true">
        {`
          .rs-picker-default .rs-picker-toggle.rs-btn-lg {
            padding-top: 0.4rem;
            padding-bottom: 0.2rem;
            height: 2.2rem;
          }

          .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
            top: 0.4rem;
          }

          .rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
            padding-top: 0.2rem;
          }

          .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
            top: 0.5rem;
          }

          .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
            top: 0.4rem;
          }

          .rs-picker-menu {
            position: absolute;
            text-align: left;
            z-index: 1300;
            border-radius: 6px;
            background-color: #fff;
            background-color: var(--rs-bg-overlay);
            -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            -webkit-box-shadow: var(--rs-shadow-overlay);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: var(--rs-shadow-overlay);
            overflow: hidden;
            -webkit-transition: none;
            transition: none;
            color: #ababab;
            /* stylelint-disable-next-line */
          }

          .rs-picker-toggle-textbox {
            border: 1px solid #d2d6da;
          }

          .rs-picker-toggle-active {
            box-shadow: 0 0 0 3px transparent;
          }

          .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
          .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
          .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
            border-color: #8e0205;
            border: 2px solid #8e0205;
          }

          .rs-picker-toggle-value {
            color: #ababab;
          }
        `}
      </style>
    </MDBox>
  );
}
