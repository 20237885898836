import React from "react";
import WizardNewPool from "./assets";
import { ContextProvider } from "./context";

const Wizard = () => {
  return (
    <ContextProvider storeName="WizardNewPool" events={{}}>
      <WizardNewPool />
    </ContextProvider>
  );
};

export default Wizard;
