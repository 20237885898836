import React from "react";
import MDBox from "../../../../../assets/@ndres-ui/MDBox";
import MDTypography from "../../../../../assets/@ndres-ui/MDTypography";

const Skidata = () => {
  return (
    <MDBox mt={2} pt={1} ml={2} style={{ maxWidth: "450px" }}>
      <MDTypography fontSize="0.7em" fontStyle="italic">
        Example :
        <br />
        16147133534681070211;john@comp.ch;john;doe;
        <br />
        16147133534681070212;alice@comp.ch;alice;meyer;
      </MDTypography>
      <br />
      <MDTypography fontSize="0.7em" fontStyle="italic">
        16147133534681070211
        <br />
        16147133534681070212
      </MDTypography>
      <br />
      <MDTypography fontSize="0.7em" fontStyle="italic">
        16147133534681070211;;john;doe;
        <br />
        16147133534681070212;;alice;meyer;
      </MDTypography>
    </MDBox>
  );
};

export default Skidata;
