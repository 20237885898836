import React from "react";
import { Input } from "rsuite";

const InputHidable = (props) => {
  const { name, data, isEditable, children, onChange, width, type, isRequired, ...rest } = props;
  if (name === "_id") return <></>;
  return !isEditable ? (
    <span>{data}</span>
  ) : (
    <Input
      type={type ? type : "text"}
      required={isRequired}
      name={name}
      value={data}
      onChange={onChange}
      style={{ width: width, display: "inline" }}
    ></Input>
  );
};

export default InputHidable;
