/* eslint-disable no-undef */
import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import productsGql from "./products.gql";
import createContract from "./createContract.gql";
import existUserGql from "./existUserGql";

const userModel = createModel({
  token: "",
  showWizard: false,
  selectedParking: undefined,
  products: [],
  loadingEmail: "void",
  queryStatus: "idle",
  chargedEmails: {},
});

const fetchProducts = async ({ token, selectedParking }) => {
  const { data } = await client.query({
    query: productsGql,
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": token } },
    variables: {
      parkingsIds: [selectedParking],
      limit: 300,
    },
  });

  return data.products?.list;
};

const submitNewSubscriptionPool = async ({ token }, { payload }) => {
  try {
    const response = await client.mutate({
      mutation: createContract,
      fetchPolicy: "no-cache",
      context: { headers: { "x-access-token": token } },
      variables: {
        input: { ...payload.contract },
      },
    });

    return response.data.createPool;
  } catch (e) {
    throw e;
  }
};

const fetchAdminEmailData = async ({ token }, { payload: { email } }) =>
  new Promise(async (resolve, reject) => {
    const { data } = await client.query({
      query: existUserGql,
      fetchPolicy: "no-cache",
      context: { headers: { "x-access-token": token } },
      variables: {
        username: email,
      },
    });
    resolve(data.existUser);
  });

const Wizard = createMachine(
  {
    context: userModel.initialContext,
    id: "WizardNewPool",
    initial: "off",
    states: {
      off: {
        on: {
          "WizardNewPool:wakeup": { target: "loading" },
        },
      },
      idle: {
        on: {
          "WizardNewPool:sleep": { actions: "hide wizard", target: "off" },
          "WizardNewPool:create-contract-reset": {
            actions: "createContractReset",
          },
          "WizardNewPool:NewPoll:exists-admin": {
            actions: "save status loading admin email",
            target: "gettingEmail",
          },
          "WizardNewPool:NewPoll:copied-exists-admin": {
            actions: "restart search",
          },
          "WizardNewPool:NewSubscriptionPool": {
            actions: "save status send NewSubscriptionPool",
            target: "newSubscriptionPool",
          },
        },
      },
      newSubscriptionPool: {
        invoke: {
          id: "submitWizard",
          src: submitNewSubscriptionPool,
          onDone: [
            {
              actions: "save status send NewSubscriptionPool success",
              target: "idle",
            },
          ],
          onError: [
            {
              actions: "save status send NewSubscriptionPool error",
              target: "idle",
            },
          ],
        },
      },
      gettingEmail: {
        invoke: {
          id: "getAdminEmailData",
          src: fetchAdminEmailData,
          onDone: [{ actions: "save user admin", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      loading: {
        entry: ["save token", "save parking"],
        invoke: {
          id: "getWizardProducts",
          src: fetchProducts,
          onDone: [{ actions: "save products", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      "hide wizard": assign({
        queryStatus: (ctx, { payload }) => "idle",
        showWizard: (ctx, { payload }) => false,
        chargedEmails: (ctx, { payload }) => {},
        products: (ctx, { payload }) => [],
      }),
      "save token": assign({
        token: () => localStorage.getItem("token"),
      }),
      "save parking": assign({
        selectedParking: (ctx, { payload }) => payload.selectedParking,
      }),
      "save products": assign({
        showWizard: true,
        products: (_, { data }) => data,
      }),
      "save status loading admin email": assign({
        loadingEmail: (ctx, { payload }) => "loading",
      }),
      "save status send NewSubscriptionPool": assign({
        queryStatus: (ctx, { payload }) => "send",
        showWizard: false,
      }),
      "save status send NewSubscriptionPool success": assign({
        queryStatus: (ctx, { payload }) => "success",
        error: (_, { data }) => false,
        state: () => "validated",
        errorMessage: (_, { data }) => "",
        lastCreatedContracts: (_, { data }) => data,
      }),
      "save status send NewSubscriptionPool error": assign({
        error: (_, { data }) => true,
        errorMessage: (_, { data }) => {
          return data;
        },
      }),
      "save user admin": assign({
        loadingEmail: (ctx, { data: { found, user } }) => {
          return found ? "charged" : "notFound";
        },
        chargedEmails: (ctx, { data: { found, user } }) => (found ? { [user?.username]: user } : {}),
      }),
      createContractReset: assign({
        error: (_, { data }) => null,
        externalId: (_, { data }) => null,
        loadingEmail: "void",
        queryStatus: "idle",
        chargedEmails: {},
      }),
      "restart search": assign({
        loadingEmail: (ctx, { data }) => "void",
        // chargedEmails: (ctx, { data })=> {},
      }),
    },
  },
);

export const service = interpret(Wizard).start();

const DEBUG = false;

const widgetReducer = (state, { type, payload }) => {
  const newState = service.send({ type, payload });

  if (window && DEBUG)
    window.postMessage(
      {
        type: "XSTATE_MACHINE_REDUCER",
        payload: {
          event: type,
          machine: service.id,
          state: `${service.id}:${newState.value}`,
          context: newState.context,
          // type,
          // payload,
          // startState: startState,
          // endState: newState.value,
          // history: newState.history,
          // at: new Date()
        },
      },
      "*",
    );

  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
