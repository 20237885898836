import { gql } from "@apollo/client";

export default gql`
  mutation createMotorist($input: CreateMotoristDto!) {
    createMotorist(input: $input) {
      motorist {
        _id
      }
      contracts {
        _id
      }
    }
  }
`;
