import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "@xstate/react";

import { service } from "../reducer";
import { service as contractService } from "../../BusinessTenants/reducer";

const defaultContextData = {
  data: {},
};

export const LocalContext = createContext(defaultContextData);

export const ContextProvider = ({ children, storeName, events = {} }) => {
  const dispatch = useDispatch();

  const selectedContract = useSelector(contractService, ({ context }) => context?.selectedContract);
  const poolContract = useSelector(service, ({ context }) => context?.poolContract);

  useEffect(() => {
    dispatch({
      type: `${storeName}:wakeup`,
      payload: { selectedContract },
    });
  }, []);

  useEffect(() => {
    dispatch({
      type: `${storeName}:show`,
      payload: { selectedContract },
    });
  }, [selectedContract]);

  const eventDispatch = (key, fn, src) => {
    const payload = fn(src);
    dispatch({ type: `${storeName}:${key}`, payload });
  };

  const eventsActions = useMemo(
    () =>
      Object.entries(events).reduce(
        (acc, [key, fn]) => ({
          [key]: (src) => eventDispatch(key, fn, src),
          ...acc,
        }),
        {},
      ),
    [events],
  );

  const renderContract = useMemo(() => {
    if (!poolContract) return;
    let { operatorContract } = poolContract;

    return !operatorContract;
  }, [poolContract]);

  return (
    <LocalContext.Provider
      value={{
        isParent: renderContract,
        ...eventsActions,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

ContextProvider.defaultProps = {
  storeName: "BusinessTenantsDetails",
  events: {},
};

ContextProvider.propTypes = {
  storeName: PropTypes.string,
  events: PropTypes.shape({}),
};
