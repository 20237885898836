import { gql } from "@apollo/client";

export default gql`
  mutation updatePoolContract($contractId: String!, $input: UpdatePoolContractDto!) {
    updatePoolContract(contractId: $contractId, input: $input) {
      _id
      isEnabled
      startDate
      stopDate
      spacesAllocated
      spacesOccupied
      name1
      name2
      city
      zipcode
      country
      address1
      address2
    }
  }
`;
