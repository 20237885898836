import React, { createContext, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "@xstate/react";
import { service } from "../reducer";

const defaultContextData = {
  list: [],
};

export const LocalContext = createContext(defaultContextData);

export const ContextProvider = ({ children, storeName, events = {} }) => {
  const dispatch = useDispatch();

  const { list } = useSelector(service, ({ context: { list } }) => ({ list }));

  const eventDispatch = (key, fn, src) => {
    const payload = fn(src);
    dispatch({ type: `${storeName}:${key}`, payload });
  };

  const eventsActions = useMemo(
    () =>
      Object.entries(events).reduce(
        (acc, [key, fn]) => ({
          [key]: (src) => eventDispatch(key, fn, src),
          ...acc,
        }),
        {},
      ),
    [events],
  );

  useEffect(() => {
    dispatch({ type: `${storeName}:wakeup`, payload: {} });
    return () => {
      dispatch({ type: `${storeName}:sleep`, payload: {} });
    };
  }, []);

  const renderList = useMemo(
    () =>
      list.map((product) => ({
        _id: product._id,
        operatorId: product.operatorId,
        parkingId: product.parkingId,
        code: product.code,
        createdAt: product.createdAt,
        stopDate: product.stopDate,
        startDate: product.startDate,
        name: product.name,
        durationValue: product?.parameters?.durationValue,
        durationUnit: product?.parameters?.durationUnit,
        provider: product?.rules?.credentialsTypes?.types[0].provider,
        category: product?.category,
        price: (product?.parameters?.cents ?? 0) / 100,
        currency: product?.parameters?.currency ?? "?",
        type: product?.parameters?.durationUnit ?? "?",
        zoneCondition: product?.zoneProductConditions
          ? product.zoneProductConditions.map(({ zoneId, zoneProductConditionType }) => ({
              zoneId,
              zoneProductConditionType,
            }))
          : [],
      })),
    [list],
  );

  return (
    <LocalContext.Provider
      value={{
        list: renderList,
        ...eventsActions,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

ContextProvider.defaultProps = {
  storeName: "Products",
  events: {},
};

ContextProvider.propTypes = {
  storeName: PropTypes.string,
  events: PropTypes.shape({}),
};
