import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

import LastEvents from "components/LastEvents";
import LastIncidents from "components/LastIncidents";
import Transactions from "components/Transactions";

import { layouts } from "./layoutHalf.conf";
import { ENV, GRID_ACTIVE } from "../../config";

const ResponsiveGridLayout = WidthProvider(Responsive);
export const Dashboard = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ hd: 1700, xl: 1400, lg: 1200, md: 960 }}
        cols={{ hd: 170, xl: 140, lg: 120, md: 96 }}
        rowHeight={10}
        isResizable={GRID_ACTIVE}
        isDraggable={GRID_ACTIVE}
        isBounded
        margin={[0, 0]}
        items={3}
        compactType={null}
        // preventCollision
        containerPadding={[0, 0]}
      >
        <div key="incidents" className="containerInGrid">
          <LastIncidents />
        </div>
        {ENV === "development" && (
          <div key="transactions" className="containerInGrid">
            <Transactions />
          </div>
        )}
        <div key="events" className="containerInGrid">
          <LastEvents />
        </div>
      </ResponsiveGridLayout>
    </div>
  );
};
