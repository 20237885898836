/* eslint-disable implicit-arrow-linebreak */
import React from "react";
import PropTypes from "prop-types";

import useDictionaries from "assets/commonHooks/useDictionaries";
import MDTypography from "assets/@ndres-ui/MDTypography";

const MotoristTable = ({ motoristInfos }) => {
  const text = useDictionaries();
  const { firstName, address, addressDetails, lastName, email, city, phone, country, state, isAnonymous } =
    motoristInfos;

  const verifiedColor = state === "VERIFIED" ? "success" : "warning";

  return (
    <>
      {/* Motorist  */}
      <section className="section_details">
        <MDTypography className="global_xs_title" type="tableData" textTransform="first">
          {text("widgets-session-details-table-header-motorist-infos")}
        </MDTypography>
        <div className="global_separa"></div>

        <div>
          {isAnonymous ? (
            <MDTypography type="tableData" textTransform="first">
              {text("widgets-session-details-table-motorist-anonymous")}
            </MDTypography>
          ) : (
            <div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-session-details-table-motorist-first-name")}
                  </MDTypography>
                </div>
                <div className="flexTableData ">
                  <MDTypography type="tableData" textTransform="first">
                    {firstName}
                  </MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-session-details-table-motorist-address")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {address}
                  </MDTypography>
                </div>
              </div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-session-details-table-motorist-last-name")}
                  </MDTypography>
                </div>
                <div className="flexTableData ">
                  <MDTypography type="tableData" textTransform="first">
                    {lastName}
                  </MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-session-details-table-motorist-address-comp")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {addressDetails}
                  </MDTypography>
                </div>
              </div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-session-details-table-motorist-email")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="lowercase">
                    {email}
                  </MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-session-details-table-motorist-zip-city")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {city}
                  </MDTypography>
                </div>
              </div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-session-details-table-motorist-mobile")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData">{phone}</MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-session-details-table-motorist-country")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {country ? text(`global-country-${country.toUpperCase()}`) : ""}
                  </MDTypography>
                </div>
              </div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-session-details-table-motorist-address-status")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="status" color={verifiedColor} textTransform="first">
                    {text(`widgets-motorist-details-table-motorist-address-${state?.toLowerCase()}`)}
                  </MDTypography>
                </div>
                <div className="flexTableHead" />
                <div className="flexTableData" />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

MotoristTable.defaultProps = {
  firstName: "",
  address: "",
  addressDetails: "",
  lastName: "",
  email: "",
  city: "",
  phone: "",
  country: "",
  state: "",
  addressVerified: "",
  isAnonymous: true,
};

MotoristTable.propTypes = {
  firstName: PropTypes.string,
  address: PropTypes.string,
  addressDetails: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  city: PropTypes.string,
  phone: PropTypes.string,
  country: PropTypes.string,
  state: PropTypes.string,
  addressVerified: PropTypes.string,
  isAnonymous: PropTypes.bool,
};
export default MotoristTable;
