import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import contractsGql from "./contracts.gql";
import createBreakdownGql from "./createBreakdown.gql";
import breakdownsGql from "./breakdowns.gql";
import { DateTime } from "luxon";
import { addHours } from "date-fns";

const searchBreakdownsFunction = async ({ token, parkingId }, { payload }) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: breakdownsGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          page: 1,
          limit: 300,
          parkingsIds: [parkingId],
        },
      })
      .then((response) => {
        resolve(response.data.breakdowns);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const searchContractsFunction = async ({ token, parkingId, selectedParkings }, { payload }) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: contractsGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          page: payload?.newPage ? payload.newPage : 1,
          limit: 12,
          parkingsIds: [parkingId],
          isActive: true,
        },
      })
      .then((response) => {
        resolve(response.data.contracts);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const createBreakdownFunction = async ({ token, parkingId }, { payload }) => {
  return new Promise((resolve, reject) => {
    const { endDate, description, type, category } = payload.breakdown;
    client
      .mutate({
        mutation: createBreakdownGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          input: {
            endDate: endDate ? endDate : addHours(new Date(), 4),
            category,
            description,
            type,
            parkingId,
          },
        },
      })
      .then((response) => {
        resolve(response.data.createBreakdown);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const breakdownModel = createModel({
  token: "",
  showWizard: false,
  parkingId: undefined,
  breakdownInputs: { endDate: new Date().toISOString() },
  currentIndex: 0,
  finish: false,
  contracts: undefined,
  blockAccessPointSelect: undefined,
});

const Wizard = createMachine(
  {
    context: breakdownModel.initialContext,
    id: "WizardCreateReportTechnical",
    initial: "off",
    states: {
      off: {
        on: {
          "WizardCreateReportTechnical:wakeup": { actions: "reload data model", target: "idle" },
        },
      },
      idle: {
        on: {
          "WizardCreateReportTechnical:wakeup": { actions: "reload data model" },
          "WizardCreateReportTechnical:sleep": {
            actions: "hide wizard",
            target: "off",
          },
          "WizardCreateReportTechnical:reset": {
            actions: "breakdownReset",
          },
          "WizardCreateReportTechnical:exit": {
            actions: "breakdownExit",
          },
          "WizardCreateReportTechnical:breakdown:change": {
            actions: ["update:breakdownInputs"],
          },
          "WizardCreateReportTechnical:search:contracts": {
            target: "searchContracts",
          },
          "WizardCreateReportTechnical:createBreakdown": {
            target: "createBreakdown",
          },
          "WizardCreateReportTechnical:onSelectPage": {
            target: "searchContracts",
          },
          "WizardCreateReportTechnical:search-breakdowns": {
            target: "searchBreakdowns",
          },
        },
      },
      searchBreakdowns: {
        entry: "save token",
        invoke: {
          id: "searchBreakdowns",
          src: searchBreakdownsFunction,
          onDone: [{ actions: "populateSearchBreakdowns", target: "idle" }],
          onError: [{ actions: "populateSearchBreakdownsError", target: "idle" }],
        },
      },
      searchContracts: {
        entry: "save token",
        invoke: {
          id: "searchContracts",
          src: searchContractsFunction,
          onDone: [{ actions: "populateSearchContracts", target: "idle" }],
          onError: [{ actions: "populateSearchContractsError", target: "idle" }],
        },
      },
      createBreakdown: {
        entry: "save token",
        invoke: {
          id: "createBreakdown",
          src: createBreakdownFunction,
          onDone: [{ actions: "populateCreateReport", target: "idle" }],
          onError: [{ actions: "populateCreateReportError", target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populateSearchBreakdowns: assign({
        blockAccessPointSelect: (_, { data }) => {
          const { list } = data;

          return list.reduce((acc, { endDate, type }) => {
            if (DateTime.fromISO(endDate) >= DateTime.now()) acc.push(type);
            return acc;
          }, []);
        },
      }),
      populateSearchBreakdownsError: assign({
        error: (_, { data }) => true,
        errorMessage: (_, { data }) => {
          return data;
        },
      }),
      populateCreateReport: assign({
        queryStatus: (ctx, { payload }) => "success",
        error: (_, { data }) => false,
        state: () => "validated",
        errorMessage: (_, { data }) => "",
      }),
      populateCreateReportError: assign({
        error: (_, { data }) => true,
        errorMessage: (_, { data }) => {
          return data;
        },
      }),
      populateSearchContracts: assign({
        contracts: (_, { data }) => {
          return data;
        },
      }),
      populateSearchContractsError: assign({
        error: (_, { data }) => true,
        errorMessage: (_, { data }) => {
          return data;
        },
      }),
      "hide wizard": assign({
        queryStatus: (ctx, { payload }) => "idle",
        showWizard: (ctx, { payload }) => false,
        parkingId: (ctx, { payload }) => undefined,
      }),
      breakdownReset: assign({
        queryStatus: (ctx, { payload }) => "off",
        breakdownInputs: () => {
          return { endDate: addHours(new Date(), 4).toISOString() };
        },
        error: (_, { data }) => undefined,
        errorMessage: (_, { data }) => "",
      }),
      breakdownExit: assign({
        queryStatus: (ctx, { payload }) => "off",
        showWizard: (ctx, { payload }) => false,
        breakdownInputs: () => {
          return { endDate: addHours(new Date(), 4).toISOString() };
        },

        error: (_, { data }) => undefined,
        errorMessage: (_, { data }) => "",
        blockAccessPointSelect: (_, { data }) => undefined,
      }),
      "reload data model": assign({
        showWizard: (ctx, { payload }) => {
          return true;
        },
        token: () => localStorage.getItem("token"),
        parkingId: (ctx, { payload }) => payload.selectedParking,
        finish: () => false,
        currentIndex: () => 0,
      }),
      "update:breakdownInputs": assign({
        breakdownInputs: (ctx, { payload }) => {
          return {
            ...ctx.breakdownInputs,
            ...payload,
          };
        },
      }),
      "save token": assign({
        token: () => {
          return localStorage.getItem("token");
        },
      }),
    },
  },
);

export const service = interpret(Wizard)
  .start()
  .onTransition((state) => {
    if (window)
      window.postMessage(
        {
          type: "XSTATE_MACHINE_REDUCER",
          payload: {
            event: `${state?.event?.type}`,
            machine: state.machine.id,
            state: `${state.value}`,
            context: { context: state.context, payload: state?.event?.payload ?? {} },
            // type,
            // payload,
            // startState: startState,
            // endState: newState.value,
            // history: newState.history,
            // at: new Date()
          },
        },
        "*",
      );
  });

const widgetReducer = (state = service.initialState, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
