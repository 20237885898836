const hd = [
  {
    w: 84,
    h: 41,
    x: 0,
    y: 0,
    i: "incidents",
  },
  {
    w: 84,
    h: 41,
    x: 0,
    y: 43,
    i: "transactions",
  },
  {
    w: 84,
    h: 84,
    x: 116,
    y: 0,
    i: "events",
  },
];

const xl = [
  {
    w: 69,
    h: 41,
    x: 0,
    y: 0,
    i: "incidents",
  },
  {
    w: 69,
    h: 41,
    x: 0,
    y: 43,
    i: "transactions",
  },
  {
    w: 69,
    h: 84,
    x: 86,
    y: 0,
    i: "events",
  },
];

const lg = [
  {
    w: 59,
    h: 41,
    x: 0,
    y: 0,
    i: "incidents",
  },
  {
    w: 59,
    h: 41,
    x: 0,
    y: 43,
    i: "transactions",
  },
  {
    w: 59,
    h: 84,
    x: 66,
    y: 0,
    i: "events",
  },
];

const md = [
  {
    w: 47,
    h: 41,
    x: 0,
    y: 0,
    i: "incidents",
  },
  {
    w: 47,
    h: 41,
    x: 0,
    y: 43,
    i: "transactions",
  },
  {
    w: 47,
    h: 84,
    x: 52,
    y: 0,
    i: "events",
  },
];
export const layouts = {
  hd,
  xl,
  lg,
  md,
};
