import React from "react";
import WizardNewSubscription from "./WizardnewSubcription";
import { ContextProvider } from "./context";

const Wizard = () => {
  return (
    <ContextProvider storeName="Wizard" events={{}}>
      <WizardNewSubscription />
    </ContextProvider>
  );
};

export default Wizard;
