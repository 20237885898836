import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "@xstate/react";
import Slider from "react-slick";
import { service } from "../../../reducer";
import countries from "assets/store/countries.json";
import { service as serviceHierarchy } from "components/I18n/reducer";

// @mui icons
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// @mui materials
import { styled } from "@mui/system";
import MDInput from "assets/@ndres-ui/MDInput";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import OutlinedInput, { outlinedInputClasses } from "@mui/material/OutlinedInput";

// rsuite
import DatePicker from "rsuite/DatePicker";
import DateRangePicker from "rsuite/DateRangePicker";
import { subDays, format, addDays } from "date-fns";

import Grid from "@mui/material/Grid";
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";
import ProductPoolInfoCard from "assets/@ndres-ui/Cards/ProductPoolInfoCard";
import useDictionaries from "assets/commonHooks/useDictionaries";
import { isToday } from "../../../../../assets/commonFunctions/isToday";
import { DateTime } from "luxon";
import { SelectPicker } from "rsuite";

// local hooks

function ucwords(str) {
  return (str + "").replace(/^([a-z])|\s+([a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowForwardIosIcon
      className={className}
      style={{ ...style, display: "block", color: "gray" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowBackIosIcon className={className} style={{ ...style, display: "block", color: "gray" }} onClick={onClick} />
  );
}

const CardInput = styled(OutlinedInput)(({ theme, required }) => ({
  width: "calc(100% + 2px)",
  margin: "0px 0px",
  height: "39px",
  borderColor: "#d2d6da",
  [`&:hover &:focused .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    // borderColor: "#d2d6da",
  },
}));

const Input = styled(MDInput)(({ theme }) => ({
  width: "calc(100% + 2px)",
  margin: "0px 0px",
  height: "39px",
  borderColor: "#d2d6da",
  [`&:hover &:focused .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#d2d6da",
  },
}));

const SelectMUI = styled(({ value, ...rest }) => (
  <Input
    value={value ?? ""}
    InputProps={{
      style: {
        height: 44.125,
        // padding: '12px',
        width: "100%",
      },
    }}
    {...rest}
  />
))(({ theme }) => ({
  width: "100%",
  padding: "2px 2px",
  margin: "3px 0px 0px -3px",
  [`&:hover &:focused .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#d2d6da",
  },
}));

const useProducts = (parkingId) => {
  const products = useSelector(service, ({ context }) => context.products);

  const memoProducts = useMemo(
    () => products.filter((product) => product.parkingId === parkingId && product.category.includes("OPERATOR_POOL")),
    [products, parkingId],
  );

  return memoProducts;
};

const { allowedRange, beforeToday, combine } = DateRangePicker;

export default function Step1({ addCompleted, setData, formData, addNextBt }) {
  const ref = useRef(null);
  const text = useDictionaries();
  const products = useProducts(formData.parkingId);

  const selectedLang = useSelector(serviceHierarchy, ({ context }) => context.selectedLang);

  const selectedLangMemo = useMemo(() => selectedLang, [selectedLang]);

  function compare(a, b) {
    if (a[1][selectedLangMemo ?? "en_EN"] < b[1][selectedLangMemo ?? "en_EN"]) {
      return -1;
    }
    if (a[1][selectedLangMemo ?? "en_EN"] > b[1][selectedLangMemo ?? "en_EN"]) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    const testing = [
      formData?.productIndex >= 0 && formData.productIndex < products.length,
      !!formData?.productData,
      !!formData?.startDate && formData?.startDate !== "",
      !!formData?.places && formData?.places >= 1,
      !!formData?.name1 && formData?.name1 !== "",
    ];

    const result = testing.reduce((c, o) => c && o, true);
    addNextBt(0, result);
  }, [formData]);

  useEffect(() => {
    addCompleted(0, true);
  }, []);

  const selectProduct = (i) => {
    setData({ productIndex: i, productData: products[i] });
  };

  return (
    <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
      {formData?.productData ? (
        <Grid container spacing={1}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <MDBox pl={4}>
              <MDTypography textTransform="first" fontSize="1.2rem" fontWeight="regular" color="dark">
                {text(`Wizard-pool-colum-specifics`)}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={4}>
            <MDBox>
              <MDTypography textTransform="first" fontSize="1.2rem" fontWeight="regular" color="dark">
                {text(`Wizard-pool-colum-partner`)}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      ) : (
        <MDBox pl={2}>
          <MDTypography fontSize="1.5rem" fontWeight="regular" color="dark">
            {text("Wizard-first-step-select-product")}
          </MDTypography>
          <MDTypography fontSize="0.9rem" fontWeight="light" color="text">
            {text("Wizard-first-step-select-product-description")}
          </MDTypography>
        </MDBox>
      )}

      {/* Carousell Cards */}
      <MDBox
        style={{
          transition: "opacity ease 1s",
          ...(formData.productIndex === undefined
            ? { opacity: 1 }
            : { opacity: 0, position: "absolute", top: "-100vh" }),
        }}
      >
        <MDBox my={3} width="100%">
          <MDBox
            overflow="scroll"
            height="400px"
            display="flex"
            justifyContent="center"
            flexDirection="row"
            width="100%"
            flexWrap="wrap"
          >
            {products.map(({ name, _id }, i) => {
              return (
                <div key={`${i + 1}${name}`} className="productCard">
                  <MDBox height="370px" my={1} py={1} mx={0.5} px={0.5} width="90%">
                    <ProductPoolInfoCard
                      title={name}
                      productId={_id}
                      description=""
                      iconLogo=""
                      currency=""
                      price={0}
                      type={0}
                      selected={i === formData.productIndex}
                      onClick={() => selectProduct(i)}
                      action={{ route: "", tooltip: "Edit Profile" }}
                    />
                  </MDBox>
                </div>
              );
            })}
          </MDBox>
        </MDBox>
      </MDBox>

      {/* Product verificator */}
      <MDBox
        pt={0}
        style={{
          transition: "opacity ease 1s",
          ...(formData.productIndex !== undefined
            ? { opacity: 1 }
            : { opacity: 0, position: "absolute", top: "-100vh" }),
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <MDBox width="100%" height="380px" my={1} py={2} mx={1} marginRight="5px" pl={1}>
              {products.length > 0 ? (
                <ProductPoolInfoCard
                  title={products?._id}
                  productId={formData?.productData?._id}
                  description=""
                  iconLogo=""
                  currency=""
                  price={0}
                  type={0}
                  selected
                  action={{ route: "", tooltip: "Edit Profile" }}
                  onClick={() => true}
                />
              ) : null}
            </MDBox>
          </Grid>
          <Grid item xs={4}>
            <MDBox mt={2} pt={1} ml={4} style={{ maxWidth: "450px" }}>
              <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="dark">
                {text("Wizard-pool-start-date-picker-label")}
              </MDTypography>
              <MDBox width="100%" ref={ref} style={{ maxWidth: "350px" }} ml="0px" mb="10px">
                {formData?.productData ? (
                  <DatePicker
                    className="select_large"
                    editable={false}
                    cleanable={true}
                    oneTap
                    value={
                      formData?.startDate &&
                      new Date(formData?.startDate) instanceof Date &&
                      !isNaN(new Date(formData?.startDate))
                        ? new Date(formData?.startDate)
                        : null
                    }
                    format="yyyy-MM-dd"
                    onChange={(date) => {
                      if (date) {
                        setData({
                          startDate: isToday(date)
                            ? date.toISOString()
                            : new Date(date.setHours(0, 0, 0, 0)).toISOString(),
                          endDate: null,
                        });
                      }
                    }}
                    onClean={() => {
                      setData({
                        startDate: null,
                        endDate: null,
                      });
                    }}
                    placeholder={text("Wizard-pool-start-date-picker-placeholder")}
                    style={{ width: "246px" }}
                    shouldDisableDate={combine(
                      allowedRange(
                        format(new Date(formData?.productData?.startDate), "yyyy-MM-dd hh:mm"),
                        format(subDays(new Date(formData.productData?.stopDate), 1), "yyyy-MM-dd hh:mm"),
                      ),
                      beforeToday(),
                    )}
                  />
                ) : null}
              </MDBox>
              <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="dark">
                {text("Wizard-pool-end-date-picker-label")}
              </MDTypography>
              <MDBox width="100%" ref={ref} style={{ maxWidth: "350px" }} ml="0px" mb="10px">
                {formData?.productData ? (
                  <DatePicker
                    disabled={!formData?.startDate}
                    className="select_large"
                    oneTap
                    editable={false}
                    cleanable={true}
                    onClean={() => {
                      setData({
                        endDate: null,
                      });
                    }}
                    value={
                      formData?.endDate &&
                      new Date(formData?.endDate) instanceof Date &&
                      !isNaN(new Date(formData?.endDate))
                        ? new Date(formData.endDate)
                        : null
                    }
                    format="yyyy-MM-dd"
                    onChange={(date) => {
                      if (date) {
                        setData({ endDate: new Date(DateTime.fromISO(date.toISOString()).endOf("day")) });
                      }
                    }}
                    ranges={[]}
                    placeholder={text("Wizard-pool-end-date-picker-placeholder")}
                    style={{ width: "246px" }}
                    shouldDisableDate={combine(
                      allowedRange(
                        format(
                          formData?.startDate &&
                            new Date(formData?.startDate) instanceof Date &&
                            !isNaN(new Date(formData?.startDate))
                            ? addDays(new Date(formData.startDate), 1)
                            : new Date(formData?.productData?.startDate),
                          "yyyy-MM-dd",
                        ),
                        format(subDays(new Date("2099-12-31"), 1), "yyyy-MM-dd"),
                      ),
                      beforeToday(),
                    )}
                  />
                ) : null}
              </MDBox>
              <MDBox>
                <MDTypography fontSize="0.9rem" fontWeight="regular" color="dark">
                  {text("Wizard-pool-enter-spaces-label")}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItems="center" justifyContent="around">
                <FormControl sx={{ mt: 1, width: "100%", maxWidth: "246px" }} variant="outlined">
                  <CardInput
                    type="number"
                    value={formData?.places ?? 2}
                    onChange={(e) => setData({ places: e.target.value })}
                  />
                </FormControl>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={4}>
            <MDBox mt={2} pt={1} ml={1} style={{ width: "100%" }}>
              <Grid container spacing={1}>
                {[
                  { grid: 12, key: "name1", type: "name", required: true },
                  { grid: 12, key: "name2", type: "name" },
                  { grid: 12, key: "address1", type: "address" },
                  { grid: 12, key: "address2", type: "address" },
                  { grid: 4, key: "zip", type: "zip" },
                  { grid: 8, key: "city", type: "city" },
                ].map(({ grid, key, type, required }) => (
                  <React.Fragment key={key}>
                    <Grid item xs={grid}>
                      <MDBox>
                        <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="dark">
                          {text(`Wizard-pool-form-label-${key}`)}
                          {required ? " *" : ""}
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" alignItems="center" justifyContent="around">
                        <FormControl sx={{ width: "100%" }} variant="outlined">
                          <CardInput
                            required={required}
                            type={type}
                            value={ucwords(formData[key] ?? "")}
                            onChange={(e) => setData({ [key]: e.target.value })}
                            endAdornment={
                              <p style={{ color: "red" }}>
                                {required && (!formData[key] || formData[key] === "") ? text("Required-label") : ""}
                              </p>
                            }
                          />
                        </FormControl>
                      </MDBox>
                    </Grid>
                  </React.Fragment>
                ))}
                <Grid item xs={12}>
                  <MDBox>
                    <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="dark">
                      {text(`Wizard-pool-form-label-country`)}
                    </MDTypography>
                  </MDBox>
                  <SelectPicker
                    className="select_large"
                    value={formData?.country ? formData.country : ""}
                    onChange={(newValue) => {
                      setData({ country: newValue });
                    }}
                    searchable={false}
                    placeholder={text("global-placeholder-default")}
                    data={[
                      { label: text("global-select-state_CH"), value: "CH" },
                      { label: text("global-select-state_FR"), value: "FR" },
                      { label: text("global-select-state_DE"), value: "DE" },
                      { label: text("global-select-state_IT"), value: "IT" },
                    ]}
                    style={{ width: "100%" }}
                  ></SelectPicker>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={4} />
          <Grid item xs={8}>
            <MDBox width="100%" display="flex" alignItems="flex-start" justifyContent="flex-start" mt={2} ml={5}>
              <MDBox>
                <FormGroup>
                  <FormControlLabel
                    checked={!!formData?.tenantCanCreateSubContract}
                    control={<Checkbox style={{ marginRight: "5px" }} />}
                    onChange={(e) => setData({ tenantCanCreateSubContract: e.target.checked })}
                    label={text("Wizard-pool-form-label-confirm-sub-contract-creation")}
                  />
                </FormGroup>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <style jsx="true">
        {`
          .rs-picker-default .rs-picker-toggle.rs-btn-lg {
            padding-top: 0.4rem;
            padding-bottom: 0.2rem;
            height: 2.2rem;
          }

          .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
            top: 0.4rem;
          }

          .rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
            padding-top: 0.2rem;
          }

          .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
            top: 0.5rem;
          }

          .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
            top: 0.4rem;
          }

          .rs-picker-menu {
            position: absolute;
            text-align: left;
            z-index: 1300;
            border-radius: 6px;
            background-color: #fff;
            background-color: var(--rs-bg-overlay);
            -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            -webkit-box-shadow: var(--rs-shadow-overlay);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: var(--rs-shadow-overlay);
            overflow: hidden;
            -webkit-transition: none;
            transition: none;
            color: #ababab;
            /* stylelint-disable-next-line */
          }

          .rs-picker-toggle-textbox {
            border: 1px solid #d2d6da;
          }

          .rs-picker-toggle-active {
            box-shadow: 0 0 0 3px transparent;
          }

          .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
          .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
          .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
            border-color: #8e0205;
            border: 2px solid #8e0205;
          }

          .rs-picker-toggle-value {
            color: #ababab;
          }
        `}
      </style>
    </MDBox>
  );
}
