import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import productGql from "./product.gql";
import createContractGQL from "./createContract.gql";
import controllMotoristGql from "./controlMotorist.gql";
import existsMotoristGql from "./existsMotorist.gql";

const fetchProducts = async ({ token }, { payload }) => {
  const productId = payload.productId;
  const { data } = await client.query({
    query: productGql,
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": token } },
    variables: {
      productId: productId,
    },
  });
  return data.product;
};

const controlMotoristByCredential = async ({ token, motoristInputs }, { payload }) =>
  new Promise(async (resolve, reject) => {
    setTimeout(() => {
      client
        .query({
          query: controllMotoristGql,
          fetchPolicy: "no-cache",
          context: { headers: { "x-access-token": token } },
          variables: {
            externalId: payload.credentialValue ?? motoristInputs.credentialValue,
            provider: payload.credentialProvider ?? motoristInputs.credentialProvider,
          },
        })
        .then((response) => {
          resolve({
            ...response.data,
            externalId: payload.credentialValue ?? motoristInputs.credentialValue,
          });
        })
        .catch((e) => {
          reject(e);
        });
    }, 1000);
  });

const controlMotoristByUsername = async ({ token }, { payload }) =>
  new Promise(async (resolve, reject) => {
    setTimeout(() => {
      client
        .query({
          query: existsMotoristGql,
          fetchPolicy: "no-cache",
          context: { headers: { "x-access-token": token } },
          variables: {
            username: payload.username,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    }, 1000);
  });

const createContract = async (
  {
    token,
    productId,
    contractId,
    motoristInputs: {
      username,
      firstName,
      lastName,
      address1,
      address2,
      zipcode,
      city,
      country,
      phone,
      lang,
      gender,
      credentialValue,
      credentialProvider,
      credentialType,
      contractStatus,
      startDate,
      endDate,
    },
  },
  { payload },
) =>
  new Promise(async (resolve, reject) => {
    client
      .mutate({
        mutation: createContractGQL,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          input: {
            username,
            firstName,
            lastName,
            address1,
            address2,
            zipcode,
            city,
            country,
            phone,
            lang,
            gender,
            credentials: [
              {
                type: credentialType ?? "PROVIDER_EXTERNAL_ID",
                provider: credentialProvider ?? "TCSOSP",
                value: credentialValue,
              },
            ],
            contract: {
              productId: productId,
              linkedContractId: contractId,
              isEnabled: contractStatus ?? false,
              startDate: startDate,
              stopDate: endDate,
            },
          },
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

const defaultMotorist = {
  _id: undefined,
  username: "",
  firstName: "",
  lastName: "",
  provider: "",
  address1: "",
  address2: "",
  zipcode: "",
  city: "",
  country: "",
  phone: "",
  lang: "en_EN",
  gender: "UNKNOWN",
  state: "",
};

const userModel = createModel({
  token: "",
  showWizard: false,
  selectedParking: undefined,
  parkingId: undefined,
  productId: undefined,
  contractId: undefined,
  product: undefined,
  motoristInputs: {
    loading: false,
    error: false,
    success: false,
    ...defaultMotorist,
    credentialValue: "",
    startDate: null,
    endDate: null,
    credentialProvider: "TCSOSP",
    credentialType: "PROVIDER_EXTERNAL_ID",
    contractStatus: true,
  },
  currentIndex: 0,
  finish: false,
});

const Wizard = createMachine(
  {
    context: userModel.initialContext,
    id: "WizardCreateSingleMotoristContractPool",
    initial: "off",
    states: {
      off: {
        on: {
          "WizardCreateSingleMotoristContractPool:wakeup": { target: "loading" },
        },
      },
      idle: {
        on: {
          "WizardCreateSingleMotoristContractPool:wakeup": { target: "loading" },
          "motoristCredential:change": {
            actions: ["update:motoristInputs", "setLoading"],
            target: "controlingMotoristByCredential",
            cond: (ctx, { payload }) => payload.control,
          },
          "motoristUsername:change": {
            actions: ["update:motoristInputs", "setLoading"],
            target: "controlingMotoristByUsername",
            cond: (ctx, { payload }) => payload.control,
          },
          "motorist:change": {
            actions: ["update:motoristInputs"],
          },
          "WizardCreateSingleMotoristContractPool:sleep": {
            actions: "hide wizard",
            target: "off",
          },
          "WizardCreateSingleMotoristContractPool:reset": {
            actions: "createContractReset",
          },
          "WizardCreateSingleMotoristContractPool:createContract": {
            target: "createContract",
          },
        },
      },
      controlingMotoristByCredential: {
        actions: ["update:motoristInputs"],
        on: {
          "motoristCredential:change": {
            actions: ["update:motoristInputs", "setLoading"],
            target: "controlingMotoristByCredential",
            cond: (ctx, { payload }) => payload.control,
          },
          "motorist:change": {
            actions: ["update:motoristInputs"],
          },
        },
        invoke: {
          id: "controlingCredentials",
          src: controlMotoristByCredential,
          onDone: [{ actions: "save result", target: "idle" }],
          onError: [{ actions: "controlByCredential error", target: "idle" }],
        },
      },
      controlingMotoristByUsername: {
        actions: ["update:motoristInputs"],
        on: {
          "motoristUsername:change": {
            actions: ["update:motoristInputs", "setLoading"],
            target: "controlingMotoristByUsername",
            cond: (ctx, { payload }) => payload.control,
          },
          "motorist:change": {
            actions: ["update:motoristInputs"],
          },
        },
        invoke: {
          id: "controlingUsername",
          src: controlMotoristByUsername,
          onDone: [{ actions: "controlByUsername save result", target: "idle" }],
          onError: [{ actions: "controlByUsername error", target: "idle" }],
        },
      },
      loading: {
        entry: ["reload data model"],
        invoke: {
          id: "getSingleWizardProducts",
          src: fetchProducts,
          onDone: [{ actions: "save products", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      createContract: {
        entry: "save token",
        invoke: {
          id: "createContract",
          src: createContract,
          onDone: [{ actions: "populateCreateContract", target: "idle" }],
          onError: [{ actions: "populateCreateContractError", target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populateCreateContract: assign({
        error: (_, { data }) => false,
        state: () => "validated",
        errorMessage: (_, { data }) => "",
        lastCreatedContracts: (_, { data }) => data.createMotorist.motorist,
      }),
      populateCreateContractError: assign({
        error: (_, { data }) => true,
        errorMessage: (_, { data }) => {
          return data;
        },
      }),
      "hide wizard": assign({
        queryStatus: (ctx, { payload }) => "idle",
        showWizard: (ctx, { payload }) => false,
        parkingId: (ctx, { payload }) => undefined,
        productId: (ctx, { payload }) => undefined,
        contractId: (ctx, { payload }) => undefined,
      }),
      "reload data model": assign({
        showWizard: (ctx, { payload }) => true,
        token: () => localStorage.getItem("token"),
        parkingId: (ctx, { payload }) => payload.parkingId,
        productId: (ctx, { payload }) => payload.productId,
        contractId: (ctx, { payload }) => payload.contractId,
        queryStatus: (ctx, { payload }) => undefined,
        finish: () => false,
        currentIndex: () => 0,
      }),
      "save products": assign({
        product: (_, { data }) => data,
      }),
      createContractReset: assign({
        error: (_, { data }) => null,
        externalId: (_, { data }) => null,
        queryStatus: (ctx, { payload }) => undefined,
        finish: () => false,
        currentIndex: () => 0,
        motoristInputs: (ctx, { data }) => ({
          loading: false,
          error: false,
          success: false,
          ...defaultMotorist,
          exist: undefined,
          startDate: null,
          endDate: null,
          credentialValue: "",
          credentialProvider: "TCSOSP",
          credentialType: "PROVIDER_EXTERNAL_ID",
        }),
      }),
      "update:motoristInputs": assign({
        motoristInputs: (ctx, { payload }) => ({
          ...ctx.motoristInputs,
          ...payload,
        }),
      }),
      "save result": assign({
        motoristInputs: (ctx, { data }) => ({
          ...ctx.motoristInputs,
          loading: false,
          error: !data.controlMotorist.found,
          found: data.controlMotorist.found,
          success: true,
          ...defaultMotorist,
          exist: true,
          ...data.controlMotorist.motorist,
          credentialLinkedUsername: data.controlMotorist.motorist?.username,
        }),
      }),
      "controlByUsername save result": assign({
        motoristInputs: (ctx, { data }) => ({
          ...ctx.motoristInputs,
          loading: false,
          error: !!data.existsMotorist.found,
          found: data.existsMotorist.found,
          ...defaultMotorist,
          ...(data.existsMotorist.found ? data.existsMotorist.motorist : {}),
          username: ctx.motoristInputs.username,
        }),
      }),
      "controlByCredential error": assign({
        motoristInputs: (ctx, { data }) => ({
          ...ctx.motoristInputs,
          loading: false,
          error: true,
          success: false,
          ...defaultMotorist,
          exist: false,
        }),
      }),
      "controlByUsername error": assign({
        motoristInputs: (ctx, { data }) => ({
          ...ctx.motoristInputs,
          loading: false,
        }),
      }),
      setLoading: assign({
        motoristInputs: (ctx) => ({
          ...ctx.motoristInputs,
          loading: true,
        }),
      }),
      "save token": assign({
        token: () => localStorage.getItem("token"),
      }),
    },
  },
);

export const service = interpret(Wizard)
  .start()
  .onTransition((state) => {
    if (window)
      window.postMessage(
        {
          type: "XSTATE_MACHINE_REDUCER",
          payload: {
            event: `${state?.event?.type}`,
            machine: state.machine.id,
            state: `${state.value}`,
            context: { context: state.context, payload: state?.event?.payload ?? {} },
            // type,
            // payload,
            // startState: startState,
            // endState: newState.value,
            // history: newState.history,
            // at: new Date()
          },
        },
        "*",
      );
  });

const widgetReducer = (state = service.initialState, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
