import { createPortal } from "react-dom";
import "./popinfo.scss";
import useDictionaries from "assets/commonHooks/useDictionaries";
import React, { useEffect, useState } from "react";
import MDTypography from "../../@ndres-ui/MDTypography";

export default function Popinfo({ textPopinfo, style }) {
  const text = useDictionaries();

  return (
    <div className="popinfo" style={style}>
      <MDTypography className="popinfo__text">{textPopinfo}</MDTypography>
    </div>
  );
}
