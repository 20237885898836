import { gql } from "@apollo/client";

export default gql`
  query localMotorist($input: ReadLocalMotoristsDto!) {
    localMotorist(input: $input) {
      _id
      operatorId
      externalId
      plates {
        type
        value
        description
      }
      uids {
        type
        value
        provider
        description
      }
      email
      firstName
      lastName
      address1
      address2
      zipcode
      city
      country
      phone
      lang
    }
  }
`;
