import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import contractsAndSubGQL from "./contacts-and-sub.gql";

const userModel = createModel({
  token: "",
  list: [],
  paging: {},
  selectedMotorist: undefined,
  selectedParkings: [],
});

const fetchList = async ({ token, selectedParkings }, { newPage }) => {
  const { data } = await client.query({
    query: contractsAndSubGQL,
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": token } },
    variables: {
      category: "OPERATOR_POOL",
      page: newPage,
      limit: 11,
      channels: [{ channel: "parking", _id: selectedParkings }],
    },
  });

  return data;
};

const BusinessTenants = createMachine(
  {
    context: userModel.initialContext,
    id: "BusinessTenants",
    initial: "off",
    states: {
      off: {
        on: {
          "BusinessTenants:wakeup": [
            { cond: "has selected parkings", target: "loading" },
            { cond: "has no parkings", target: "idle" },
          ],
          "global:selected-parkings": { actions: "save parkings" },
        },
      },
      idle: {
        on: {
          "events:contracts:created": { target: "loading" },
          "events:contracts:updated": { target: "loading" },
          "events:pools:created": { target: "loading" },
          "events:pools:updated": {
            target: "loading",
          },
          "BusinessTenants:onSelectPage": { target: "changingPage" },
          "BusinessTenants:onSelectContract": { actions: "select contract" },
          "BusinessTenants:sleep": { target: "off" },
          "global:selected-parkings": {
            actions: "save parkings",
            target: "loading",
          },
        },
      },
      loading: {
        entry: assign({
          token: () => localStorage.getItem("token"),
        }),
        invoke: {
          id: "getBusinessTenants",
          src: (context, event) => fetchList(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ actions: "populateError", target: "idle" }],
        },
      },
      changingPage: {
        invoke: {
          id: "getNewPage",
          src: (context, { payload }) => fetchList(context, payload),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populate: assign({
        list: (_, { data }) => data.poolContractsAndSub.list,
        paging: (_, { data }) => data.poolContractsAndSub.paging,
      }),
      populateError: assign({
        list: (_, { data }) => null,
        paging: (_, { data }) => null,
      }),
      "save parkings": assign({
        selectedParkings: (ctx, { payload }) => payload.selectedParkings,
      }),
      "select contract": assign({
        selectedContract: (ctx, { payload }) => payload,
      }),
    },
    guards: {
      "has selected parkings": ({ selectedParkings }) => !!selectedParkings.length,
      "has no parkings": ({ selectedParkings }) => !selectedParkings.length,
    },
  },
);

export const service = interpret(BusinessTenants).start();

const widgetReducer = (state, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
