import { gql } from "@apollo/client";

export default gql`
  query poolContractsAndSub($page: Int!, $limit: Int!, $category: String, $channels: [ChannelInput]) {
    poolContractsAndSub(page: $page, limit: $limit, category: $category, channels: $channels) {
      list {
        _id
        ospContractId
        name
        name1
        name2
        address1
        address2
        zipcode
        city
        country
        category
        operatorId
        parentContractId
        isEnabled
        parking {
          _id
          name
        }
        spacesAllocated
        spacesOccupied
        startDate
        stopDate
        product {
          _id
          name
          category
        }
        poolUsers {
          list {
            _id
            lastName
            firstName
            username
            state
            phone
          }
        }
        contractCount
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
