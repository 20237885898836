import { gql } from "@apollo/client";

export default gql`
  query globalSearch($parkingsIds: [String], $content: String!) {
    globalSearch(parkingsIds: $parkingsIds, content: $content) {
      motorists {
        _id
        firstName
        lastName
        credentials {
          provider
          type
          value
        }
        city
        username
        shortUid
      }
      localMotorists {
        _id
        externalId
        plates {
          value
        }
        uids {
          value
          description
        }
        firstName
        lastName
        email
      }
    }
  }
`;
