import React, { useContext, useEffect, useMemo, useState } from "react";

import useDictionaries from "assets/commonHooks/useDictionaries";

import { LocalContext } from "../../context";
import { useDispatch } from "react-redux";
import Table from "assets/@luc-ui/Table";
import Paginat from "assets/@luc-ui/Pagination";
import { DateTime } from "luxon";
import { format } from "date-fns";

const AccessPointTable = () => {
  const text = useDictionaries();

  const { list, page, totalPages, onSelectPage, onSelectEventAccess, selectEventAccessId } = useContext(LocalContext);

  const [listTemp, setListTemp] = useState([]);

  const renderLine = ({ _id, startDate, endDate, category, type, state }) => {
    const startDateFormatted = format(new Date(startDate), "dd/MM/yy HH:mm:ss");
    const endDateFormatted = format(new Date(endDate), "dd/MM/yy HH:mm:ss");

    return {
      options: {
        error: false,
        id: _id,
      },
      elements: [
        startDateFormatted,
        endDateFormatted,
        text(`Wizard-report-technical-first-step-select-category-${new String(category).toLowerCase()}`),
        text(`Wizard-report-technical-first-step-select-type-${new String(type).toLowerCase()}`),

        <span style={{ color: state.color }}>{state.text}</span>,
      ],
    };
  };

  const listFormated = useMemo(() => listTemp.map(renderLine), [listTemp, text]);

  useEffect(() => {
    let timer = null;

    const updateListTemp = () => {
      const listTempWithState = list.map((value) => {
        if (new Date() < new Date(value.startDate)) {
          return {
            ...value,
            state: {
              text: text("widgets-report-technical-state-planned"),
              color: "#f48c06",
            },
          };
        } else if (new Date() < new Date(value.endDate)) {
          return {
            ...value,
            state: {
              text: text("widgets-report-technical-state-pending"),
              color: "red",
            },
          };
        } else {
          return {
            ...value,
            state: {
              text: text("widgets-report-technical-state-finished"),
              color: "#008000",
            },
          };
        }
      });

      setListTemp(listTempWithState);
    };

    updateListTemp();

    timer = setInterval(() => {
      updateListTemp();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [list]);

  return (
    <>
      <div
        style={{
          position: "relative",
          padding: "0 1em",
        }}
      >
        <div role="tabpanel" index={0}>
          <Table
            headers={[
              {
                title: text("widgets-report-technical-details-table-header-startdate"),
                size: "small",
              },
              {
                title: text("widgets-report-technical-details-table-header-enddate"),
                size: "small",
              },
              {
                title: text("widgets-report-technical-details-table-header-category"),
                size: "small",
              },
              {
                title: text("widgets-report-technical-details-table-header-type"),
                size: "xsmall",
              },
              {
                title: text("widgets-report-technical-details-table-header-state"),
                size: "small",
              },
            ]}
            rows={listFormated}
            onClick={(id) => {
              onSelectEventAccess(id);
            }}
            sessionId={selectEventAccessId}
            vAlign="top"
          />
          <Paginat page={page} totalPages={totalPages} onSelectPage={onSelectPage} />
        </div>
      </div>
    </>
  );
};

export default AccessPointTable;
