import React, { useEffect, useState } from "react";
import { useSelector } from "@xstate/react";
import { service } from "../../../reducer";

// @mui icons
import DoneAllIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";

// @mui materials
import DataTable from "assets/@ndres-ui/DataTable/index";
import Grid from "@mui/material/Grid";

import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";
import Popup from "assets/@redapuenta-ui/popup/popup";
import { useDispatch } from "react-redux";

export default function Step3({ addCompleted, setData, formData, addNextBt }) {
  const text = useDictionaries();
  const dispatch = useDispatch();

  const motorist = useSelector(service, ({ context }) => context.motoristChecked);
  const finishProcess = useSelector(service, ({ context }) => context.finish);
  const motoristDispatched = useSelector(service, ({ context }) => context.motoristDispatched);
  const motoristAcepted = useSelector(service, ({ context }) => context.motoristAcepted);
  const motoristRejected = useSelector(service, ({ context }) => context.motoristRejected);

  const errors = motorist.reduce((acc, { valid }) => (valid ? acc : acc + 1), 0);

  const [dialogErrorDetails, setDialogErrorDetails] = useState(false);
  const [dialogErrorContain, setDialogErrorContain] = useState({ title: "Titre", text: "Text" });

  const openDialogErrorDetails = (params) => {
    const { errors } = params;
    const code = errors.message.toLowerCase();
    const newContain = {
      title: `global-error-title-${code}`,
      text: `global-error-text-${code}`,
    };
    setDialogErrorContain(newContain);
    setDialogErrorDetails(true);
  };

  const closeDialogErrorDetails = () => {
    setDialogErrorDetails(false);
  };

  useEffect(() => {
    addNextBt(2, finishProcess);
  }, [motoristDispatched, finishProcess]);

  useEffect(() => {
    addCompleted(2, true);

    if (finishProcess === false) {
      dispatch({
        type: "WizardCreateContractUserProvider:motorists:createContract",
        payload: {},
      });
    }
  }, []);

  useEffect(() => {
    setData({ blockBack: true });
  }, []);

  return (
    <>
      <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
        <MDBox
          pt={0}
          style={{
            transition: "opacity ease 1s",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <MDBox mt={2} pt={1} ml={1} pr={2}>
                <DataTable
                  table={{
                    columns: [
                      {
                        Header: text("Wizard-user-provider-third-step-username-label"),
                        accessor: "username",
                        width: "15%",
                        align: "left",
                      },
                      {
                        Header: text("Wizard-user-provider-third-step-firstname-label"),
                        accessor: "firstName",
                        width: "15%",
                        align: "left",
                      },
                      {
                        Header: text("Wizard-user-provider-third-step-lastname-label"),
                        accessor: "lastName",
                        width: "15%",
                        align: "left",
                      },
                      {
                        Header: <div />,
                        accessor: "check",
                        width: "8%",
                        align: "right",
                      },
                    ],
                    rows: motoristDispatched.map((params) => ({
                      ...params,
                      check: params.valid ? (
                        <DoneAllIcon
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "green",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            cursor: "pointer",
                          }}
                          onClick={(e) => openDialogErrorDetails(params)}
                        >
                          <MDTypography
                            fontSize="0.9rem"
                            style={{
                              lineHeight: 1,
                              color: "red",
                              paddingRight: "50px",
                              pointerEvent: "none",
                              textDecoration: "underline",
                            }}
                          >
                            {text("global-show-error")}
                          </MDTypography>
                        </div>
                      ),
                    })),
                  }}
                  isSorted={false}
                  showTotalEntries={true}
                  entriesPerPage={{ defaultValue: 8, entries: [8] }}
                  // noEndBorder
                  canSearch
                  showTotalErrors={`${errors} Errors`}
                  pagination={{ variant: "contained", color: "info" }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={4}>
              <MDBox mt={2} pt={1} ml={4} style={{ maxWidth: "450px" }}>
                <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="success">
                  {`${text("Wizard-user-provider-third-step-accepted-label")}: ${motoristAcepted.length}`}
                </MDTypography>
                <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="error">
                  {`${text("Wizard-user-provider-third-step-rejected-label")}: ${motoristRejected.length}`}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <style jsx="true">
          {`
            .rs-picker-default .rs-picker-toggle.rs-btn-lg {
              padding-top: 0.4rem;
              padding-bottom: 0.2rem;
              height: 2.2rem;
            }

            .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
              top: 0.4rem;
            }

            .rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
              padding-top: 0.2rem;
            }

            .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
              top: 0.5rem;
            }

            .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
              top: 0.4rem;
            }

            .rs-picker-menu {
              position: absolute;
              text-align: left;
              z-index: 1300;
              border-radius: 6px;
              background-color: #fff;
              background-color: var(--rs-bg-overlay);
              -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
              -webkit-box-shadow: var(--rs-shadow-overlay);
              box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
              box-shadow: var(--rs-shadow-overlay);
              overflow: hidden;
              -webkit-transition: none;
              transition: none;
              color: #ababab;
              /* stylelint-disable-next-line */
            }

            .rs-picker-toggle-textbox {
              border: 1px solid #d2d6da;
            }

            .rs-picker-toggle-active {
              box-shadow: 0 0 0 3px transparent;
            }

            .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
            .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
            .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
            .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
            .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
            .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
              border-color: #8e0205;
              border: 2px solid #8e0205;
            }

            .rs-picker-toggle-value {
              color: #ababab;
            }
          `}
        </style>
      </MDBox>
      {dialogErrorDetails ? (
        <Popup
          titlePopup={{ type: "basic", value: text(`${dialogErrorContain.title.trim()}`) }}
          textPopup={{ type: "basic", value: text(`${dialogErrorContain.text.trim()}`) }}
          handlePopupExit={closeDialogErrorDetails}
        />
      ) : null}
    </>
  );
}
