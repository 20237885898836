import React from "react";
import SessionsTable from "./materials/table";
import ContainerInGrid from "assets/@luc-ui/Container";
import { ContextProvider } from "./context";

const Sessions = () => {
  const onSelectPage = (page) => ({ page });
  const onSelectSession = (selectedSession) => ({ selectedSession });

  return (
    <div style={{ height: "100%", overflow: "scroll" }}>
      <ContainerInGrid headerName="widgets-sessions-title" headerTextColor="primary" headerBackgroundColor="#ffffff">
        <ContextProvider
          storeName="Sessions"
          events={{
            onSelectPage,
            onSelectSession,
          }}
        >
          <SessionsTable />
        </ContextProvider>
      </ContainerInGrid>
    </div>
  );
};

export default Sessions;
