import React, { createContext, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { service } from "../reducer";
import { useSelector } from "@xstate/react";

const defaultContextData = {
  list: [],
  page: 1,
  totalPages: 1,
};

export const LocalContext = createContext(defaultContextData);

export const ContextProvider = ({ children, storeName, events = {} }) => {
  const dispatch = useDispatch();

  const { list, paging } = useSelector(service, ({ context: { list, paging } }) => {
    return { list, paging };
  });

  useEffect(() => {
    dispatch({ type: `${storeName}:wakeup`, payload: {} });
    return () => {
      dispatch({ type: `${storeName}:sleep`, payload: {} });
    };
  }, []);

  const eventDispatch = (key, fn, src) => {
    const payload = fn(src);
    dispatch({ type: `${storeName}:${key}`, payload });
  };

  const eventsActions = useMemo(
    () =>
      Object.entries(events).reduce(
        (acc, [key, fn]) => ({
          [key]: (src) => eventDispatch(key, fn, src),
          ...acc,
        }),
        {},
      ),
    [events],
  );

  const findLast = (array, filter) => {
    const result = array.filter(filter);
    return result[result.length - 1];
  };

  const renderList = useMemo(
    () =>
      list.map(({ _id, isInconsistent = false, parkingId, parking, occupancyDuration, events = [], state }) => {
        const ENTRY_AUTHORIZED = findLast(events, ({ state }) => state === "ENTRY_AUTHORIZED");
        const ENTRY_DENIED = findLast(events, ({ state }) => state === "ENTRY_DENIED");
        const ENTERED = findLast(events, ({ state }) => state === "ENTERED");
        const EXITED = findLast(events, ({ state }) => state === "EXITED");

        const { accessPoints } = parking;

        const filteredEvents = events
          ?.filter((event) => !["CREATED", "EXIT_AUTHORIZE_PENDING"].includes(event.state))
          .map((ev) => {
            const credential = ev.credentials ? ev?.credentials?.find((c) => c?.type !== "RFID") : {};

            const accessPoint = accessPoints.find((ap) => ap?._id === ev?.accessPointId);

            return {
              state: ev?.state,
              reason: ev?.reason,
              credentialDisplay: credential?.type
                ? credential?.type === "PROVIDER_EXTERNAL_ID"
                  ? `${credential?.provider}`
                  : `${credential?.type}`
                : "",
              credentialType: credential?.type,
              credentialValue: credential?.value,
              accessPointCode: accessPoint?.shortCode,
              createdAt: ev?.createdAt,
              error: false,
            };
          });

        const entry = ENTERED?.createdAt ?? ENTRY_AUTHORIZED?.createdAt ?? ENTRY_DENIED?.createdAt;
        const isEntered = ENTERED ? true : false;
        const exit = EXITED?.createdAt;

        const isError = isInconsistent || state?.includes("DENIED");

        return {
          sessionId: _id,
          parkingName: `parking-${parkingId}-name`,
          entry,
          exit,
          duration: occupancyDuration ? parseInt(occupancyDuration, 10) : 0,
          eventList: filteredEvents,
          isEntered,
          error: isError,
        };
      }),
    [list],
  );

  const renderPaging = useMemo(() => {
    const { count = 0, limit = 10 } = paging;
    const total = Math.ceil(count / limit);
    return {
      page: paging?.current,
      totalPages: total,
    };
  }, [paging]);

  return (
    <LocalContext.Provider
      value={{
        list: renderList,
        ...renderPaging,
        ...eventsActions,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

ContextProvider.propTypes = {
  storeName: PropTypes.string,
  events: PropTypes.shape({}),
};
