import React from "react";
import MDBox from "../../../../../assets/@ndres-ui/MDBox";
import MDTypography from "../../../../../assets/@ndres-ui/MDTypography";

const TcsExample = () => {
  return (
    <MDBox mt={2} pt={1} ml={2} style={{ maxWidth: "450px" }}>
      <MDTypography fontSize="0.7em" fontStyle="italic">
        Example :
        <br />
        123456;john@mycompany.ch;john;doe;
        <br />
        234567;alice.meyer@mycompany.ch;alice;meyer;
      </MDTypography>
      <br />
      <MDTypography fontSize="0.7em" fontStyle="italic">
        10001;;;
        <br />
        10002;;;
      </MDTypography>
      <br />
      <MDTypography fontSize="0.7em" fontStyle="italic">
        100001;;Emily;Adams
        <br />
        100002;;Marianne;von Brandstetter
      </MDTypography>
    </MDBox>
  );
};

export default TcsExample;
