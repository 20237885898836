/* eslint-disable implicit-arrow-linebreak */
import Collapse from "@mui/material/Collapse";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";

import useDictionaries from "assets/commonHooks/useDictionaries";
import SessionStatus from "components/Sessions/materials/SessionStatus";

import { useSelector } from "@xstate/react";
import { Duration } from "assets/@luc-ui/Duration";
import Paginat from "assets/@luc-ui/Pagination";
import MDButton from "assets/@ndres-ui/MDButton";
import MDTypography from "assets/@ndres-ui/MDTypography";
import Popup from "assets/@redapuenta-ui/popup/popup";
import { service } from "components/MotoristDetails/reducer";
import { useDispatch } from "react-redux";

const SessionsDetailsTable = ({ sessions, onSelectPage, sessionPaging, onForceClose }) => {
  const text = useDictionaries();
  const dispatch = useDispatch();

  const [modePopup, setModePopup] = useState("choice");
  const [openPopup, setOpenPopup] = useState(false);
  const [open, setOpen] = useState({});
  const [currentSession, setCurrentSession] = useState({});

  const [titlePopup, setTitlePopup] = useState();
  const [textPopup, setTextPopup] = useState();

  //! Selector for Popup
  const forceCloseSession = useSelector(service, ({ context }) => context.forceCloseSession);
  const errorForceCloseSession = useSelector(service, ({ context }) => context.errorForceCloseSession);

  //! SUCCESS for Popup
  useEffect(() => {
    if (currentSession.id === forceCloseSession.id && modePopup === "load") {
      setTextPopup({
        type: "success",
        value: text("popup-session-force-close-success"),
      });
      setModePopup("result");
    }
  }, [forceCloseSession]);

  //! ERROR for Popup
  useEffect(() => {
    if (errorForceCloseSession) {
      setTitlePopup({
        type: "basic",
        value: text(`global-error-title-${new String(errorForceCloseSession).toLowerCase()}`),
      });
      setTextPopup({
        type: "error",
        value: text(`global-error-text-${new String(errorForceCloseSession).toLowerCase()}`),
      });
      setModePopup("result");
    }
  }, [errorForceCloseSession]);

  //! Open Popup
  const forceCloseClick = (e, sessionID) => {
    setTitlePopup({
      type: "basic",
      value: `${text("widgets-motorist-details-modal-first-p")} ${text("widgets-motorist-details-modal-second-p")}`,
    });
    setTextPopup(undefined);
    setModePopup("choice");
    setCurrentSession({ id: sessionID });
    e.stopPropagation();
    setOpenPopup(true);
  };

  //! Exit Popup
  const popupExit = () => {
    setOpenPopup(false);
    setModePopup("choice");
  };

  //! Choice in Popup
  const popupChoice = (choice) => {
    const { id: sessionID } = currentSession;

    if (choice) {
      dispatch({
        type: "MotoristDetails:reset-error",
        payload: {},
      });

      setModePopup("load");
      onForceClose(sessionID);
    } else {
      setModePopup("choice");
      setOpenPopup(false);
    }
  };

  function handleClick(id) {
    setOpen((prevState) => ({ [id]: !prevState[id] }));
  }

  return (
    <>
      <section style={{ height: "100vh" }} className="section_details">
        <MDTypography className="global_xs_title" type="tableHeader" textTransform="first">
          {text("widgets-motorist-session-section-title")}
        </MDTypography>

        <div className="global_separa"></div>
        <div>
          <div className="flexTableRowsEqual" style={{ marginBottom: "0px" }}>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-motorist-details-table-header-parking")}
              </MDTypography>
            </div>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-motorist-details-table-header-entry")}
              </MDTypography>
            </div>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-motorist-details-table-header-end-date")}
              </MDTypography>
            </div>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-motorist-details-table-header-status")}
              </MDTypography>
            </div>
          </div>
          <div className="flexTableRowsEqual" style={{ marginBottom: "0px" }}>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-motorist-details-table-header-type")}
              </MDTypography>
            </div>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-motorist-details-table-header-credential")}
              </MDTypography>
            </div>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-motorist-details-table-header-duration")}
              </MDTypography>
            </div>
            <div className="flexTableHead">
              <MDTypography className="global_label" type="tableHeader" textTransform="first">
                {text("widgets-motorist-details-table-header-fee")}
              </MDTypography>
            </div>
          </div>
          <div style={{ overflowY: "scroll", height: "calc(420px - 30px)", paddingTop: "5px", paddingBottom: "60px" }}>
            {sessions?.map(
              (
                {
                  sessionID,
                  isClosed,
                  status,
                  entry,
                  exit,
                  reason,
                  credentialType,
                  credentialValue,
                  parkingName,
                  duration,
                  isEntered,
                  productName = "-",
                  plates,
                  RFID,
                },
                index,
              ) => (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  aria-hidden="true"
                  key={`Contract-${index + 1}`}
                  onClick={() => handleClick(sessionID)}
                >
                  <div className="hoverPink details_accor">
                    <div className="flexTableRowsEqual" style={{ cursor: "pointer", padding: "0px", margin: "0px" }}>
                      <div className="flexTableHead">
                        <MDTypography type="tableData" textTransform="first">
                          {text(parkingName)}
                        </MDTypography>
                      </div>
                      <div className="flexTableHead">
                        <MDTypography type="tableData">
                          {entry ? format(new Date(entry), "dd.MM.yyyy HH:mm:ss") : "-"}
                        </MDTypography>
                      </div>
                      <div className="flexTableHead">
                        <MDTypography type="tableData">
                          {exit ? format(new Date(exit), "dd.MM.yyyy HH:mm:ss") : "-"}
                        </MDTypography>
                      </div>
                      <div className="flexTableHead">
                        <MDTypography type="tableData">
                          <SessionStatus status={status} reason={reason} />
                        </MDTypography>
                      </div>
                    </div>
                    <div
                      aria-hidden="true"
                      className="flexTableRowsEqual"
                      style={{ cursor: "pointer", padding: "0px", margin: "0px" }}
                    >
                      <div className="flexTableHead">
                        <MDTypography type="tableData" textTransform="first">
                          {productName ? text(productName) : "-"}
                        </MDTypography>
                      </div>
                      <div className="flexTableHead">
                        <MDTypography type="tableData">
                          {credentialType ? text(`global-providers-${credentialType.toLowerCase()}`) : "-"}{" "}
                          {credentialValue ? credentialValue : ""}
                        </MDTypography>
                      </div>
                      <div className="flexTableHead">
                        <MDTypography type="tableData">
                          {isEntered ? <Duration startDate={entry} endDate={exit} duration={duration} /> : <>-</>}
                        </MDTypography>
                      </div>
                      <div className="flexTableHead">
                        <MDTypography type="tableData">-</MDTypography>
                      </div>
                    </div>
                    {!isClosed && (
                      <Collapse style={{ width: "calc(100% - 10px)" }} in={open[sessionID]}>
                        <div
                          style={{
                            marginBottom: "10px",
                            padding: "10px 8px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#F2F2F2",
                          }}
                        >
                          <MDButton
                            size="small"
                            color="primary"
                            onClick={(e) => forceCloseClick(e, sessionID)}
                            style={{ zIndex: 999 }}
                          >
                            {text("widgets-sessions-button-force-close")}
                          </MDButton>
                        </div>
                        {openPopup ? (
                          <Popup
                            titlePopup={titlePopup}
                            textPopup={textPopup}
                            modePopup={modePopup}
                            handlePopupChoice={popupChoice}
                            handlePopupExit={popupExit}
                          />
                        ) : null}
                      </Collapse>
                    )}
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <Paginat page={sessionPaging?.page} totalPages={sessionPaging?.total} onSelectPage={onSelectPage} />
        </div>
      </section>
    </>
  );
};

export default SessionsDetailsTable;
