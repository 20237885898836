import React from "react";

import SessionsTable from "./materials/table";
import ContainerInGrid from "assets/@luc-ui/Container";

import { ContextProvider } from "./context";

const LastEvents = (props) => {
  const onSelectPage = (page) => ({ page });
  return (
    <div style={{ height: "100%", overflow: "scroll" }}>
      <ContainerInGrid
        headerName="widgets-latest-events-title"
        headerTextColor="primary"
        headerBackgroundColor="#ffffff"
      >
        <ContextProvider
          storeName="LastEvents"
          events={{
            onSelectPage,
          }}
        >
          <SessionsTable />
        </ContextProvider>
      </ContainerInGrid>
    </div>
  );
};

export default LastEvents;
