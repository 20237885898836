import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "@xstate/react";
import { service } from "../reducer";
import { service as serviceAccessPointHistory } from "../../AccessPointHistory/reducer";

const defaultContextData = {
  data: {},
};

export const LocalContext = createContext(defaultContextData);

export const ContextProvider = ({ children, storeName, events = {} }) => {
  const dispatch = useDispatch();

  // Motorist we have to show
  const currentAccessEvent = useSelector(service, ({ context }) => context.currentAccessEvent);
  const selectEventAccessId = useSelector(serviceAccessPointHistory, ({ context }) => context.selectEventAccessId);

  useEffect(() => {
    if (!selectEventAccessId) return;

    dispatch({
      type: `${storeName}:show`,
      payload: { selectEventAccessId: selectEventAccessId },
    });
  }, [selectEventAccessId]);

  const eventDispatch = (key, fn, src) => {
    const payload = fn(src);
    dispatch({ type: `${storeName}:${key}`, payload });
  };

  const eventsActions = useMemo(() =>
    Object.entries(events).reduce(
      (acc, [key, fn]) => ({
        [key]: (src) => eventDispatch(key, fn, src),
        ...acc,
      }),
      {},
    ),
  );

  const renderAccessPoint = useMemo(() => {
    if (!currentAccessEvent) return;

    const { _id, startDate, endDate, description, type, category } = currentAccessEvent;

    return {
      accessPointDetails: {
        _id,
        startDate,
        endDate,
        description,
        type,
        category,
      },
    };
  }, [currentAccessEvent]);

  return (
    <LocalContext.Provider
      value={{
        data: renderAccessPoint,
        ...eventsActions,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

ContextProvider.defaultProps = {
  storeName: "AccessPointDetails",
  events: {},
};

ContextProvider.propTypes = {
  storeName: PropTypes.string,
  events: PropTypes.shape({}),
};
