import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { layouts } from "./layoutHalf.conf";
import BusinessTenants from "components/BusinessTenants";

import { GRID_ACTIVE } from "../../config";
import BusinessTenantsDetails from "components/BusinessTenantsDetails";

const ResponsiveGridLayout = WidthProvider(Responsive);

const BusinessTenantPage = () => {
  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ hd: 1700, xl: 1400, lg: 1200, md: 960 }}
        cols={{ hd: 170, xl: 140, lg: 120, md: 96 }}
        rowHeight={10}
        isResizable={GRID_ACTIVE}
        isDraggable={GRID_ACTIVE}
        isBounded
        margin={[0, 0]}
        items={2}
        compactType={null}
        onLayoutChange={(e) => console.debug(e)}
        // preventCollision
        containerPadding={[0, 0]}
      >
        <div key="Main" className="containerInGrid">
          <BusinessTenants />
        </div>
        <div key="details" className="containerInGrid">
          <BusinessTenantsDetails />
        </div>
      </ResponsiveGridLayout>
    </div>
  );
};

export default BusinessTenantPage;
