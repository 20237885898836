import React from "react";
import colors from "../../@ndres-ui/theme/base/colors";

const { primary } = colors;

const DriveOut = () => (
  <svg
    id="driveOut"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMin slice"
    width="100%"
    height="100%"
    viewBox="0 0 47.465 36.357"
  >
    <defs>
      <style>
        {`.cls-1 {
            fill: #575757;
            opacity: 0.6;
          }

          .cls-1, .cls-2 {
            fill-rule: evenodd;
          }

          .cls-2 {
            fill: #575757;
            opacity: 0.6;
          }`}
      </style>
    </defs>
    <path
      id="Tracé_28"
      data-name="Tracé 28"
      className="cls-1"
      d="M19.689,44.583a.784.784,0,0,1-.753.8h-2.31a.753.753,0,0,1-.753-.8v-.1a.909.909,0,0,1,.753-.8l2.31-.4a.753.753,0,0,1,.753.8ZM7.283,38.857a8.187,8.187,0,0,0-2.26.352c-.1.05-.251.05-.352.151l.452-2.009a4.134,4.134,0,0,1,4.018-3.164h4.721a4.053,4.053,0,0,1,4.018,3.164l.452,1.858-.452-.151a5.736,5.736,0,0,0-1.858-.251H7.283Zm.4,5.726a.784.784,0,0,1-.753.8H4.621a.753.753,0,0,1-.753-.8v-.5a.784.784,0,0,1,.753-.8l2.31.4c.452.1.753.352.753.8ZM3.415,49.254a24.9,24.9,0,0,0,3.215.4h.251v2.059a1,1,0,0,1-.9.954H4.42a1,1,0,0,1-1-.854Zm16.676,0v2.461a1,1,0,0,1-1,.954H17.63a1,1,0,0,1-1-.954V49.656a19.48,19.48,0,0,0,3.365-.452h.1ZM9.141,32.98h4.671a5.309,5.309,0,0,1,5.123,3.968l.05.151.553,2.16a.884.884,0,0,1,.653-.452H22.2a.964.964,0,0,1,.9.854v.653a.924.924,0,0,1-.854.9H20.794a5.023,5.023,0,0,1,.8,3.415v.2L21.045,48.1c-.251,1.607-18.434,1.457-18.835,0a21.336,21.336,0,0,1-.553-3.315,5.023,5.023,0,0,1,.653-3.415l.1-.151H.9a.964.964,0,0,1-.9-.854V39.71a.924.924,0,0,1,.854-.9H2.813a1,1,0,0,1,.653.3l.05.05.5-2.11A5.254,5.254,0,0,1,9.041,32.98Z"
      transform="translate(0 -16.413)"
    />
    <path
      id="Tracé_29"
      data-name="Tracé 29"
      className="cls-2"
      d="M53.067,21.786a1.607,1.607,0,1,1,1.607-1.607,1.587,1.587,0,0,1-1.607,1.607Zm3.817,12.959V16.261H50.606L24.438.189A1.14,1.14,0,0,0,22.83.54a1.221,1.221,0,0,0,.4,1.658L49.351,18.22V34.745H47.643v1.607h11.1V34.745Z"
      transform="translate(-11.278 0.005)"
    />
  </svg>
);

export default DriveOut;
