import React, { useContext, useEffect, useMemo, useState } from "react";

import useDictionaries from "assets/commonHooks/useDictionaries";

import { Tab, Tabs } from "@mui/material";
import Paginat from "assets/@luc-ui/Pagination";
import Table from "assets/@luc-ui/Table";
import { LocalContext } from "../../context";
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";

const MotoristsTable = () => {
  const text = useDictionaries();
  const { list, page, totalPages, onSelectPage, onSelectMotorist, selectedMotorist, showFilteredMotorists, motorist } =
    useContext(LocalContext);
  const [tab, setTab] = useState(motorist ? 1 : 0);
  const handleChange = (_, t) => setTab(t);

  useEffect(() => {
    setTab(motorist ? 1 : 0);
  }, [motorist]);

  const renderLine = ({ _id, lastName, firstName, city, contracts, credentials, email }) => ({
    options: {
      error: false,
      id: _id,
    },
    elements: [
      <MDTypography type="tableData" overflow="visible" textTransform="lowercase">
        {email || "-"}
      </MDTypography>,

      <MDTypography type="tableData" style={{ display: "block", minHeight: "1.6em" }} textTransform="first">
        {lastName || "-"}
      </MDTypography>,

      <MDTypography type="tableData" style={{ display: "block", minHeight: "1.6em" }} textTransform="first">
        {firstName || "-"}
      </MDTypography>,
      city || "-",
      contracts,
      credentials,
    ],
  });

  const listFormated = useMemo(() => list.map(renderLine), [list, text]);

  const filteredFormated = useMemo(() => motorist && [renderLine(motorist)], [motorist, text]);

  return (
    <>
      <div
        style={{
          position: "relative",
          padding: "0 1em",
        }}
      >
        <MDBox sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            style={{ height: "60px", borderRadius: "0px", backgroundColor: "transparent" }}
            value={tab}
            onChange={handleChange}
            aria-label="motorists tabs"
          >
            <Tab label={text("widgets-motorists-tab-all-contract")} />
            <Tab label={text("widgets-motorists-tab-filtered-contract")} disabled={!showFilteredMotorists} />
          </Tabs>
        </MDBox>
        <div role="tabpanel" hidden={tab !== 0} index={0}>
          <Table
            headers={[
              {
                title: text("widgets-motorists-table-email"),
                size: "large",
              },
              {
                title: text("widgets-motorists-table-last-name"),
                size: "smaller",
              },
              {
                title: text("widgets-motorists-table-first-name"),
                size: "smaller",
              },
              { title: text("widgets-motorists-table-city"), size: "small" },
              {
                title: text("widgets-motorists-table-contracts"),
                size: "xsmall2",
              },
              {
                title: text("widgets-motorists-table-credentials"),
                size: "xsmall",
              },
            ]}
            rows={listFormated}
            onClick={(data) => onSelectMotorist(data)}
            sessionId={selectedMotorist}
            vAlign="top"
          />
          <Paginat page={page} totalPages={totalPages} onSelectPage={onSelectPage} />
        </div>
        <div role="tabpanel" hidden={tab !== 1} index={1}>
          {showFilteredMotorists && (
            <Table
              headers={[
                {
                  title: text("widgets-motorists-table-email"),
                  size: "large",
                },
                {
                  title: text("widgets-motorists-table-last-name"),
                  size: "smaller",
                },
                {
                  title: text("widgets-motorists-table-first-name"),
                  size: "smaller",
                },
                { title: text("widgets-motorists-table-city"), size: "small" },
                {
                  title: text("widgets-motorists-table-contracts"),
                  size: "xsmall2",
                },
                {
                  title: text("widgets-motorists-table-credentials"),
                  size: "xsmall",
                },
              ]}
              rows={filteredFormated}
              onClick={(data) => onSelectMotorist(data)}
              sessionId={selectedMotorist}
              vAlign="baseline"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MotoristsTable;
