import { gql } from "@apollo/client";

export default gql`
  query controlMotorist($externalId: String!, $provider: String!) {
    controlMotorist(externalId: $externalId, provider: $provider) {
      found
      motorist {
        _id
        username
        lastName
        firstName
        address1
        address2
        zipcode
        city
        country
        phone
        state
        lang
        gender
      }
    }
  }
`;
