import { gql } from "@apollo/client";

export default gql`
  query existUser($username: String!) {
    existUser(username: $username) {
      found
      user {
        username
        firstName
        lastName
        phone
        lang
        state
      }
    }
  }
`;
