/* eslint-disable implicit-arrow-linebreak */
import { format } from "date-fns";
import React, { useEffect, useState } from "react";

import useDictionaries from "assets/commonHooks/useDictionaries";
import SessionStatus from "components/Sessions/materials/SessionStatus";

import { useSelector } from "@xstate/react";
import { Duration } from "assets/@luc-ui/Duration";
import Paginat from "assets/@luc-ui/Pagination";
import MDTypography from "assets/@ndres-ui/MDTypography";
import { service } from "components/MotoristDetails/reducer";

const SessionsDetailsTable = ({ sessions, onSelectPage, sessionPaging, onForceClose }) => {
  const text = useDictionaries();

  const [modePopup, setModePopup] = useState("choice");
  const [titlePopup, setTitlePopup] = useState();
  const [textPopup, setTextPopup] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [open, setOpen] = useState({});
  const [currentSession, setCurrentSession] = useState({});

  //! Selector for Popup
  const forceCloseSession = useSelector(service, ({ context }) => context.forceCloseSession);

  //! SUCCESS for Popup
  useEffect(() => {
    if (currentSession.id === forceCloseSession.id && modePopup === "load") {
      setTextPopup({
        type: "success",
        value: text("popup-session-force-close-success"),
      });
      setModePopup("result");
    }
  }, [forceCloseSession]);

  //! Open Popup
  const forceCloseClick = (e, sessionID) => {
    setTitlePopup({
      type: "basic",
      value: `${text("widgets-motorist-details-modal-first-p")} ${text("widgets-motorist-details-modal-second-p")}`,
    });
    setModePopup("choice");
    setCurrentSession({ id: sessionID });
    e.stopPropagation();
    setOpenPopup(true);
  };

  //! Exit Popup
  const popupExit = () => {
    setOpenPopup(false);
    setModePopup("choice");
  };

  //! Choice in Popup
  const popupChoice = (choice) => {
    const { id: sessionID } = currentSession;

    if (choice) {
      setModePopup("load");
      onForceClose(sessionID);
    } else {
      setModePopup("choice");
      setOpenPopup(false);
    }
  };

  function handleClick(id) {
    setOpen((prevState) => ({ [id]: !prevState[id] }));
  }

  return (
    <>
      <section style={{ height: "100vh" }} className="section_details">
        <MDTypography className="global_xs_title" type="tableHeader" textTransform="first">
          {text("widgets-motorist-session-section-title")}
        </MDTypography>
        <div className="global_separa"></div>
        <div className="place_rub">
          <div>
            <div style={{ marginBottom: "0px" }} className="flexTableRowsEqual">
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-header-parking")}
                </MDTypography>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-header-entry")}
                </MDTypography>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-header-end-date")}
                </MDTypography>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-header-status")}
                </MDTypography>
              </div>
            </div>
            <div className="flexTableRowsEqual">
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-header-type")}
                </MDTypography>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-header-credential")}
                </MDTypography>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-header-duration")}
                </MDTypography>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-header-fee")}
                </MDTypography>
              </div>
            </div>
            <div style={{ overflowY: "scroll", height: "60vh", paddingTop: "5px" }}>
              {sessions?.map(
                (
                  {
                    sessionID,
                    isClosed,
                    status,
                    entry,
                    exit,
                    reason,
                    credentialType,
                    credentialValue,
                    parkingName,
                    duration,
                    isEntered,
                    productName = "-",
                    plates,
                    RFID,
                  },
                  index,
                ) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    key={`Contract-${index + 1}`}
                    onClick={() => handleClick(sessionID)}
                  >
                    <div className="hoverPink details_accor" style={{ width: "calc(100% - 10px)" }}>
                      <div className="flexTableRowsEqual" style={{ cursor: "pointer", paddingTop: "0.2em" }}>
                        <div className="flexTableHead">
                          <MDTypography type="tableData" textTransform="first">
                            {text(parkingName)}
                          </MDTypography>
                        </div>
                        <div className="flexTableHead">
                          <MDTypography type="tableData">
                            {entry ? format(new Date(entry), "dd.MM.yyyy HH:mm:ss") : "-"}
                          </MDTypography>
                        </div>
                        <div className="flexTableHead">
                          <MDTypography type="tableData">
                            {exit ? format(new Date(exit), "dd.MM.yyyy HH:mm:ss") : "-"}
                          </MDTypography>
                        </div>
                        <div className="flexTableHead">
                          <SessionStatus status={status} reason={reason} />
                        </div>
                      </div>
                      <div
                        aria-hidden="true"
                        className="flexTableRowsEqual"
                        style={{ cursor: "pointer", marginBottom: "0px" }}
                      >
                        <div className="flexTableHead">
                          <MDTypography type="tableData" textTransform="first">
                            {text(productName)}
                          </MDTypography>
                        </div>
                        <div className="flexTableHead">
                          <MDTypography type="tableData">
                            {credentialType ? text(`global-providers-${credentialType.toLowerCase()}`) : "-"}{" "}
                            {credentialValue ? credentialValue : ""}
                          </MDTypography>
                        </div>
                        <div className="flexTableHead">
                          {isEntered ? <Duration startDate={entry} endDate={exit} duration={duration} /> : <>-</>}
                        </div>
                        <div className="flexTableHead">-</div>
                      </div>
                    </div>
                  </div>
                ),
              )}
            </div>
            <Paginat page={sessionPaging?.page} totalPages={sessionPaging?.total} onSelectPage={onSelectPage} />
          </div>
        </div>
      </section>
    </>
  );
};

export default SessionsDetailsTable;
