import React, { createContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "@xstate/react";
import { service } from "../reducer";

const defaultContextData = {
  products: [],
};

export const LocalContext = createContext(defaultContextData);

export const ContextProvider = ({ children, storeName, events = {} }) => {
  const dispatch = useDispatch();

  const showWizard = useSelector(service, ({ context: { showWizard } }) => showWizard);

  const parkingId = useSelector(service, ({ context: { selectedParking } }) => selectedParking);

  const eventDispatch = (key, fn, src) => {
    const payload = fn(src);
    dispatch({ type: `${storeName}:${key}`, payload });
  };

  const eventsActions = useMemo(
    () =>
      Object.entries(events).reduce(
        (acc, [key, fn]) => ({
          [key]: (src) => eventDispatch(key, fn, src),
          ...acc,
        }),
        {},
      ),
    [events],
  );

  const hide = () => {
    dispatch({ type: "WizardCreateContractUserProvider:reset", payload: {} });
    dispatch({ type: "WizardCreateContractUserProvider:exit", payload: {} });
    dispatch({ type: "WizardCreateContractUserProvider:sleep", payload: {} });
  };

  return (
    <LocalContext.Provider
      value={{
        parkingId,
        selection: showWizard ? "New Create Motorist With Local Motorist" : "",
        setSelection: hide,
        ...eventsActions,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

ContextProvider.defaultProps = {
  storeName: "WizardCreateContractUserProvider",
  events: {},
};

ContextProvider.propTypes = {
  storeName: PropTypes.string,
  events: PropTypes.shape({}),
};
