import React from "react";
import { format } from "date-fns";

import useDictionaries from "assets/commonHooks/useDictionaries";
import MDTypography from "assets/@ndres-ui/MDTypography";

const ContractTable = ({ contractInfos }) => {
  const text = useDictionaries();

  const { contractId, status, startDate, parkingName, stopDate, product, isActive, isVisitor, ospContractId } =
    contractInfos;

  const activeColor = isActive ? "success" : "warning";

  return (
    <>
      {/* Motorist  */}
      <section className="section_details">
        <MDTypography className="global_xs_title" type="tableHeader" textTransform="first">
          {text("widgets-session-details-table-header-contract-infos")}
        </MDTypography>
        <div className="global_separa"></div>
        <div>
          {isVisitor ? (
            <div className="flexTableRows">
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-session-details-table-session")}
                </MDTypography>
              </div>
              <div className="flexTableData">
                <MDTypography type="tableData">{text("widgets-session-details-table-visitor")}</MDTypography>
              </div>
            </div>
          ) : (
            <div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableHeader">
                    {text("widgets-session-details-table-contract-id")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData">{ospContractId}</MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableHeader" textTransform="first">
                    {text("widgets-session-details-table-contract-status")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="status" color={activeColor} textTransform="first">
                    {text(status)}
                  </MDTypography>
                </div>
              </div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableHeader" textTransform="first">
                    {text("widgets-session-details-table-contract-start")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData">
                    {startDate
                      ? format(
                          new Date(startDate),
                          process.env.NODE_ENV === "development" ? "dd.MM.yyyy HH:mm:ss" : "dd.MM.yyyy",
                        )
                      : ""}
                  </MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableHeader" textTransform="first">
                    {text("widgets-session-details-table-contract-parking")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {text(parkingName)}
                  </MDTypography>
                </div>
              </div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableHeader" textTransform="first">
                    {text("widgets-session-details-table-contract-end")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData">
                    {stopDate
                      ? format(
                          new Date(stopDate),
                          process.env.NODE_ENV === "development" ? "dd.MM.yyyy HH:mm:ss" : "dd.MM.yyyy",
                        )
                      : ""}
                  </MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableHeader" textTransform="first">
                    {text("widgets-session-details-table-contract-product-name")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {text(`product-${product}-name`)}
                  </MDTypography>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ContractTable;
