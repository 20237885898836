import React from "react";

import ContainerInGrid from "assets/@luc-ui/Container";

import { ContextProvider } from "../context";
import AccessPointAboutTable from "./materials/table";

const AccessPointAbout = () => (
  <div style={{ height: "100%", overflow: "scroll", position: "relative" }}>
    <ContainerInGrid
      headerName="widgets-report-technicals-details-title"
      headerTextColor="primary"
      headerBackgroundColor="#ffffff"
    >
      <AccessPointAboutTable />
    </ContainerInGrid>
  </div>
);

export default AccessPointAbout;
