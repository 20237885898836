import React from "react";

// @ndres-ui
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import MDBox from "assets/@ndres-ui/MDBox";

export const NavigationBarContainer = styled((props) => <MDBox shadow="md" bgColor="primary" {...props} />)(
  ({ theme, ContentProps }) => ({
    variant: "gradient",
    height: "55px",
    width: "100%",
    color: "white",
    top: "0px",
    borderRadius: "0px 0px 5px 0px",
    position: "fixed",
    zIndex: "99",
  }),
);

export const MarginedBox = styled(MDBox)({
  width: "100%",
  alignItems: "center",
  display: "flex",
});

export const LogoBox = styled(MDBox)({
  width: "calc(8vw + 20px)",
  height: "45px",
  paddingLeft: "10px",
  minWidth: "180px",
  alignItems: "center",
  justifyContent: "flex-start",
  display: "flex",
  cursor: "pointer",
});

export const MenuBox = styled(MDBox)({
  width: "100%",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  display: "flex",
});

export const MenuGrid = styled((props) => (
  <Grid container direction="row" justifyContent="flex-end" alignItems="center" {...props} />
))(({ theme, ContentProps }) => ({
  width: "200px",
  height: "calc(100% - 5px)",
  padding: "10px 20px 0px 20px",
}));

export const MenuGroupActions = styled(({ children, ...rest }) => (
  <Grid item xs={10} lg={6}>
    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
      {children}
    </Grid>
  </Grid>
))(({ theme, ContentProps }) => ({
  width: "100%",
  background: "green",
}));

export const MenuItem = styled(MDBox)({
  color: "white",
  marginRight: "15px",
  fontSize: "1rem",
});

export const MenuItemSearch = styled(MDBox)({
  color: "white",
  marginRight: "15px",
  fontSize: "1rem",
  width: "100%",
  paddingTop: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
