import React, { useEffect, useState } from "react";
import { useSelector } from "@xstate/react";
import { service } from "../../../reducer";

import Grid from "@mui/material/Grid";
import ContractCard from "assets/@ndres-ui/Cards/ContractCard";
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";
import { Card } from "@mui/material";
import { DateTime } from "luxon";

export default function Step1({ addCompleted, formData, addNextBt }) {
  const text = useDictionaries();

  const contracts = useSelector(service, ({ context }) => context.contracts);
  const breakdownInputs = useSelector(service, ({ context }) => context.breakdownInputs);

  const [load, setLoad] = useState(true);

  useEffect(() => {
    addNextBt(2, true);
    addCompleted(2, true);
  }, [breakdownInputs]);

  useEffect(() => {
    if (breakdownInputs && contracts) setLoad(false);
  }, [breakdownInputs, contracts]);

  return (
    <>
      {load ? (
        <div className="load">
          <span className="load__text">Loading</span>
        </div>
      ) : (
        <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
          <MDBox
            pt={0}
            style={{
              transition: "opacity ease 1s",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MDBox ml={2} mt={2} pl={2}>
                  <MDTypography fontSize="1.5rem" fontWeight="regular" color="dark">
                    {text("Wizard-report-technical-third-step-title")}
                  </MDTypography>
                  <MDTypography fontSize="1rem" fontWeight="light" color="text">
                    {text("Wizard-report-technical-third-step-description")}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={1}>
                <MDBox height="5px" />
              </Grid>

              <Grid item xs={10}>
                <MDBox width="100%" my={1} py={1} mx={1} marginRight="5px">
                  <Card sx={{ height: "100%" }}>
                    <div
                      selected={true}
                      style={{
                        overflowY: "auto",
                        backgroundColor: "#fafafa",
                        height: "380px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <MDBox position="absolute" width="calc(100%)" pb={3}>
                        <MDTypography
                          variant="h6"
                          fontWeight="medium"
                          textTransform="capitalize"
                          mt={3}
                          textAlign="center"
                        >
                          {text("Wizard-report-technical-third-step-title-summary")}
                        </MDTypography>
                      </MDBox>

                      <MDBox
                        width="100%"
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        px={2}
                        mt={2}
                        pt="80px"
                      ></MDBox>

                      <MDBox width="100%" display="flex" justifyContent="flex-start" alignItems="center" px={2} mt={2}>
                        <MDBox pb={0}>
                          <MDBox display="flex" py={0} pr={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="first">
                              {text("Wizard-report-technical-third-step-label-startdate")}
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp; {text("Wizard-report-technical-now-text")}
                            </MDTypography>
                          </MDBox>

                          <MDBox display="flex" py={0} pr={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="first">
                              {text("Wizard-report-technical-third-step-label-enddate")}
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp;{" "}
                              {breakdownInputs?.endDate
                                ? DateTime.fromISO(breakdownInputs.endDate).toFormat("dd/MM/yy hh:mm:ss")
                                : null}
                            </MDTypography>
                          </MDBox>

                          <MDBox display="flex" py={0} pr={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="first">
                              {text("Wizard-report-technical-third-step-label-description")}
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp; {breakdownInputs?.description ? breakdownInputs?.description : null}
                            </MDTypography>
                          </MDBox>

                          <MDBox display="flex" py={0} pr={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="first">
                              {text("Wizard-report-technical-third-step-label-type")}
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp; {breakdownInputs?.type ? breakdownInputs?.type : null}
                            </MDTypography>
                          </MDBox>

                          <MDBox display="flex" py={1} pr={2}>
                            <MDTypography variant="button" fontWeight="bold" textTransform="first">
                              {text("Wizard-report-technical-third-step-label-contract-count")}
                            </MDTypography>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                              &nbsp; {contracts.paging.count}
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </div>
                  </Card>
                </MDBox>
              </Grid>

              <Grid item xs={1}>
                <MDBox height="5px" />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      )}

      <style jsx="true">{`
        .load {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 40vh;
        }

        .load__text {
          font-size: 14px;
        }
      `}</style>
    </>
  );
}
