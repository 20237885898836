import React, { useState, useContext } from "react";
import MDBox from "assets/@ndres-ui/MDBox";
import NewSubscriptionDialogStepper from "./stepsScreen";
import SweetAlert2 from "react-sweetalert2";

import { ContextProvider, LocalContext } from "../context";
let timerInterval;

export default function SpringPopper() {
  const { selection, setSelection, parkingId } = useContext(LocalContext);
  const [swalAwait, setSwalAwait] = useState();
  const [swalSuccess, setSwalSuccess] = useState();
  const [swalError, setSwalError] = useState();

  const onClose = () => {
    setSelection("");
  };

  const handleSubmit = () => {
    onClose();
    setSelection("");
    clearInterval(timerInterval);
    setSwalSuccess({ show: false });
  };

  return (
    <>
      <ContextProvider storeName="WizardCreateLocalMotorist" events={{}}>
        <SweetAlert2 {...swalAwait} />
        <SweetAlert2 {...swalSuccess} />
        <SweetAlert2 {...swalError} />
        <NewSubscriptionDialogStepper
          dialog={selection}
          setDialog={setSelection}
          parkingId={parkingId}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
        <MDBox onClick={() => setSelection("New Local Motorist")}></MDBox>
      </ContextProvider>
    </>
  );
}
