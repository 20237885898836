import { assign, createMachine, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import loginGql from "./login.gql";

const userModel = createModel({
  connected: false,
  error: undefined,
  token: undefined,
});

const loginMutation = async (context, { payload }) => {
  try {
    const { data } = await client.mutate({
      mutation: loginGql,
      variables: payload,
      fetchPolicy: "no-cache",
    });
    return data.login;
  } catch (e) {
    throw new Error("Authentication failed");
  }
};

const loginMachine = createMachine(
  {
    context: userModel.initialContext,
    id: "login-machine",
    initial: "idle",
    states: {
      idle: {
        entry: ["load token if exists"],
        on: {
          "login:login": { target: "connecting" },
        },
        always: [{ cond: (context) => context.connected, target: "logged" }],
      },
      connecting: {
        invoke: {
          src: (context, event) => loginMutation(context, event),
          id: "login",
          onDone: [
            {
              actions: ["populate context", "save to localstorage"],
              target: "logged",
            },
          ],
          onError: [
            {
              actions: ["set login error"],
              target: "idle",
            },
          ],
        },
      },
      logged: {
        on: {
          "login:logout": { target: "logout" },
        },
      },
      logout: {
        entry: ["remove token", "logout user"],
        after: {
          500: { target: "idle" },
        },
      },
    },
  },
  {
    actions: {
      "load token if exists": assign({
        connected: () => !!localStorage.getItem("token"),
        token: () => localStorage.getItem("token"),
      }),
      "populate context": assign({
        connected: () => true,
        token: (_, { data }) => data.token,
        error: () => false,
      }),
      "save to localstorage": (context, { data }) => {
        localStorage.setItem("token", data.token);
      },
      "remove token": () => {
        localStorage.removeItem("token");
      },
      "logout user": assign({
        connected: () => false,
        token: () => undefined,
      }),
      "set login error": assign({
        connected: () => false,
        token: () => undefined,
        error: () => true,
      }),
    },
  },
);

export const service = interpret(loginMachine).start();

const initial = {
  state: loginMachine.initialState.value,
  context: loginMachine.initialState.context,
};

const widgetReducer = (state = initial, { type, payload }) => {
  const loginStatus = service.send({ type, payload });

  var event = document.createEvent("Event");
  event.initEvent("hello");
  document.dispatchEvent(event);

  return { state: loginStatus.value, context: loginStatus.context };
};

export default widgetReducer;
