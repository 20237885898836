import { gql } from "@apollo/client";

export default gql`
  mutation updateLocalMotorist($localMotoristId: String!, $input: UpdateLocalMotoristsDto!) {
    updateLocalMotorist(localMotoristId: $localMotoristId, input: $input) {
      _id
      operatorId
      externalId
      plates {
        type
        value
        description
      }
      uids {
        type
        value
        description
      }
      email
      firstName
      lastName
      address1
      address2
      zipcode
      city
      country
      phone
      lang
    }
  }
`;
