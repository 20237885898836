import React, { useState, useEffect } from "react";

import Step1 from "./stepsComponents/Step1";
import Step2 from "./stepsComponents/Step2";
import Step3 from "./stepsComponents/Step3";
import Step4 from "./stepsComponents/Step4";

// local hooks
import NewSubscriptionDialogStepper from "./DialogStepper";
import useStateCallback from "assets/commonHooks/useStateCallback/useStateCallback";
import { useDispatch } from "react-redux";
import { useSelector } from "@xstate/react";

import { service as serviceHierarchy } from "components/I18n/reducer";

const stepsObj = {
  "Wizard-pool-steps-product-settings": Step1,
  "Wizard-pool-steps-motorist": Step2,
  "Wizard-pool-steps-contract": Step3,
  "Wizard-pool-steps-activation": Step4,
};

const initForm = {
  places: 2,
  startDate: null,
  isEnabled: true,
  tenantCanCloseSession: true,
  tenantCanCreateSubContract: true,
};

const StepperWizard = ({ dialog, setDialog, onSubmit, onClose, parkingId }) => {
  // const text = useDictionaries();
  const selectedLang = useSelector(serviceHierarchy, ({ context }) => context.selectedLang);
  const steps = [...Object.keys(stepsObj)];
  const [active, setActive] = useState(0);
  const [completed, setCompleted] = useState([true, false, false, false]);
  const [nextBt, setNextBt] = useState(steps.map(() => false));
  const [formData, setFormData] = useStateCallback({ ...initForm, parkingId });
  const dispatch = useDispatch();

  const Step = stepsObj[steps[active]];

  const reset = () => {
    setCompleted([true, false, false, false]);
    setActive(0);
    setFormData({ ...initForm, lang: selectedLang, parkingId });
    dispatch({
      type: "WizardNewPool:create-contract-reset",
      payload: {},
    });
  };

  useEffect(() => {
    if (dialog === "New subscription") {
      reset();
    }
  }, [dialog]);

  const addCompleted = (index, newValue) => {
    const newCompleted = [...completed].map((currentValue, i) => (i === index ? newValue : currentValue));
    setCompleted(newCompleted);
  };

  const addNextBt = (index, newValue) => {
    const newNextBt = [...nextBt].map((currentValue, i) => (i === index ? newValue : currentValue));
    setNextBt(newNextBt);
  };

  const setData = (data) => {
    setFormData({ ...formData, ...data, parkingId });
  };

  const handleSubmit = () => {
    onSubmit({
      type: "WizardNewPool:NewSubscriptionPool",
      payload: {
        contract: {
          tenant: {
            name1: formData.name1,
            name2: formData.name2,
            address1: formData.address1,
            address2: formData.address2,
            city: formData.city,
            zipcode: formData.zip,
            country: formData.country,
            tenantCanCloseSession: formData.tenantCanCloseSession,
            tenantCanCreateSubContract: formData.tenantCanCreateSubContract,
          },
          pool: {
            parkingId: formData.parkingId,
            productId: formData?.productData?._id,
            startDate: formData.startDate,
            stopDate: formData.endDate,
            isEnabled: formData.isEnabled,
            spacesAllocated: parseInt(formData.places),
          },
          user: {
            username: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            phone: formData.phone,
            lang: formData.lang,
          },
        },
      },
    });
  };

  return (
    <NewSubscriptionDialogStepper
      dialog={dialog}
      setDialog={setDialog}
      steps={steps}
      active={active}
      setActive={setActive}
      completed={completed}
      nextBt={nextBt}
      setCompleted={setCompleted}
      onClose={onClose}
      reset={reset}
      onSubmit={handleSubmit}
    >
      <Step addCompleted={addCompleted} addNextBt={addNextBt} setData={setData} formData={formData} />
    </NewSubscriptionDialogStepper>
  );
};

export default StepperWizard;
