/* eslint-disable implicit-arrow-linebreak */
import React, { useContext, useMemo } from "react";

import useDictionaries from "assets/commonHooks/useDictionaries";
import TenantsTable from "assets/@luc-ui/BusinessTenantsDetails/TenantsTable";
import PoolUsersTable from "assets/@luc-ui/BusinessTenantsDetails/PoolUsersTable";

import { LocalContext } from "../../../context";
import { LocalContext as BusinessTenantsDetailsContext } from "../../../../context";

const BusinessTenantsAboutTable = () => {
  const text = useDictionaries();
  const { onContractStatusChange, onContractPoolUpdate } = useContext(BusinessTenantsDetailsContext);

  const { poolContract } = useContext(LocalContext);
  if (!poolContract.tenant) return <></>;

  const { tenant, contract, poolUsers } = poolContract;

  return (
    <>
      <div>
        <TenantsTable
          onContractSwitch={onContractStatusChange}
          onContractUpdate={onContractPoolUpdate}
          tenant={tenant}
          contract={contract}
          isParent={true}
          allowEditing={true}
          allowEditingSpaces={false}
          title={text("widgets-business-tenant-details-table-header-contract-details")}
        />
      </div>
      <div style={{ paddingTop: "1em" }}>
        <PoolUsersTable users={poolUsers} title={text("widgets-business-tenant-details-table-header-pool-admins")} />
      </div>
    </>
  );
};

export default BusinessTenantsAboutTable;
