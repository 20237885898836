import React from "react";

import TCSOSP from "assets/images/providers/logo_tcs-by-osp.png";
import IEM from "assets/images/providers/logo_iem.png";
import TCS from "assets/images/providers/logo_tcs.svg";
import TCSPNG from "assets/images/providers/tcs-logo-yellow.png";
import SKIDATA from "assets/images/providers/logo_skidata.png";
import PLATE from "assets/images/providers/plate.png";
import QRCODE from "assets/images/providers/qr_code.png";
import PERSON from "assets/images/providers/person.png";

export const ProviderLogos = ({ provider, type, width, height }) => {
  if (type === "QRCODE") provider = "QRCODE";
  const logoSelector = {
    TCSOSP: TCSOSP,
    IEM: IEM,
    TCS: TCSPNG,
    SKIDATA: SKIDATA,
    PLATE: PLATE,
    QRCODE: QRCODE,
    LOCAL: PERSON,
  };

  return provider ? (
    <img
      style={{ width: width, height: height }}
      src={provider && logoSelector[provider] ? logoSelector[provider] : logoSelector["default"]}
      alt={`${provider}`}
    ></img>
  ) : (
    <></>
  );
};

export const providersCredentials = ["TCS", "TCSOSP", "SKIDATA"];
