/* eslint-disable implicit-arrow-linebreak */
import React, { useContext, useMemo } from "react";

import useDictionaries from "assets/commonHooks/useDictionaries";

import MotoristTable from "assets/@luc-ui/MotoristDetails/MotoristTable";
import SessionsDetailsTable from "assets/@luc-ui/BusinessTenantsDetails/SessionsDetailsTable";

import { LocalContext } from "../../../context";
import { LocalContext as widgetContext } from "../../../../context";

// may not be implemented

const MotoristSessionsTable = () => {
  const text = useDictionaries();
  const { poolContract } = useContext(LocalContext);

  const { onSelectSessionPage } = useContext(widgetContext);

  const sessionReactiv = useMemo(() => poolContract.sessions, [poolContract, text]);
  const pagingReactiv = useMemo(() => poolContract.sessionPaging, [poolContract, text]);

  return (
    <>
      <SessionsDetailsTable
        sessions={sessionReactiv}
        onSelectPage={onSelectSessionPage}
        sessionPaging={pagingReactiv}
      />
    </>
  );
};

export default MotoristSessionsTable;
