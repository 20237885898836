import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import localMotoristGql from "./localMotorist.gql";
import updateMotoristGql from "./updateMotorist.gql";

const UserProviderModel = createModel({
  token: "",
  selectedMotoristId: "",
  currentMotorist: undefined,
  updateMotorist: {},
  errorUpdateMotorist: undefined,
  paging: {},
  selectedParkings: [],
});

const getMotorist = async ({ token, motoristId, selectedParkings }, { payload }) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: localMotoristGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          input: { localMotoristId: motoristId },
        },
      })
      .then((response) => {
        resolve(response.data.localMotorist);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const updateMotoristFunction = async ({ token }, { payload }) => {
  return new Promise((resolve, reject) => {
    const { _id, plates, uids, email, firstName, lastName, address1, address2, zipcode, city, country, phone, lang } =
      payload.data;

    client
      .mutate({
        mutation: updateMotoristGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          localMotoristId: _id,
          input: {
            plates: plates.map(({ description, value }) => {
              return { description, type: "PLATE", value };
            }),
            uids: uids
              .map(({ description, type, value }) => {
                return { description, type, value, provider: "LOCAL" };
              })
              .filter(({ value }) => {
                return value !== "";
              }),
            email,
            firstName,
            lastName,
            address1,
            address2,
            zipcode,
            city,
            country,
            phone,
            lang,
          },
        },
      })
      .then((response) => {
        const localMotorist = response.data.updateLocalMotorist;
        const { uids, plates } = localMotorist;

        localMotorist.uids = uids && uids.length === 0 ? [] : uids;
        localMotorist.plates = plates && plates.length === 0 ? [] : plates;
        resolve(localMotorist);
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

const UserProviderDetails = createMachine(
  {
    context: UserProviderModel.initialContext,
    id: "UserProviderDetails",
    initial: "off",
    states: {
      off: {
        on: {
          "UserProviderDetails:show": {
            target: "loading",
          },
          "global:selected-parkings": { actions: ["reset select motorist", "save parkings"] },
        },
      },
      idle: {
        on: {
          "UserProviderList:onSelectMotorist": { actions: "select motorist", target: "loading" },
          "UserProviderDetails:show": { target: "loading" },
          "UserProviderDetails:onUpdateMotorist": {
            target: "updateMotorist",
          },
          "UserProviderDetails:reset-error": {
            actions: "reset error",
          },
          "global:selected-parkings": {
            actions: ["reset select motorist", "save parkings"],
            target: "loading",
          },
          "UserProviderDetails:reset-error": {
            actions: "reset error",
          },
        },
      },
      loading: {
        entry: assign({
          token: () => localStorage.getItem("token"),
          motoristId: (ctx, { payload }) => {
            return payload.selectedMotoristId ? payload.selectedMotoristId : ctx.selectedMotoristId;
          },
        }),
        invoke: {
          id: "getMotoristDetails",
          src: (context, event) => getMotorist(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      updateMotorist: {
        entry: assign({
          token: () => localStorage.getItem("token"),
          updateMotorist: () => undefined,
        }),
        invoke: {
          id: "updateMotorist",
          src: (context, event) => updateMotoristFunction(context, event),
          onDone: [{ actions: "update motorist", target: "idle" }],
          onError: [{ actions: "error update motorist", target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populate: assign({
        currentMotorist: (_, { data }) => {
          const uids = data?.uids && data?.uids?.length > 0 ? data.uids : [];
          const plates = data?.plates && data?.plates?.length > 0 ? data.plates : [];

          return { ...data, uids, plates };
        },
      }),

      "update motorist": assign({
        updateMotorist: (ctx, { data }) => {
          return data;
        },
        currentMotorist: (ctx, { data }) => data,
      }),

      "error update motorist": assign({
        errorUpdateMotorist: ({}, { data }) => {
          return data;
        },
      }),
      "save parkings": assign({
        selectedParkings: (ctx, { payload }) => payload.selectedParkings,
      }),
      "select motorist": assign({
        selectedMotoristId: (ctx, { payload }) => {
          return payload.selectedMotoristId;
        },
      }),
      "reset select motorist": assign({
        selectedMotoristId: (ctx, { payload }) => null,
        currentMotorist: (ctx, { payload }) => null,
      }),
      "reset error": assign({
        errorUpdateMotorist: () => undefined,
      }),
    },
  },
);

export const service = interpret(UserProviderDetails).start();

const initial = {
  state: UserProviderDetails.initialState.list,
  context: UserProviderDetails.initialState.context,
};

const widgetReducer = (state = initial, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
