import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "@xstate/react";
import { service } from "../reducer";
import { DateTime } from "luxon";

const defaultContextData = {
  list: [],
  page: 1,
  totalPages: 1,
};

export const LocalContext = createContext(defaultContextData);

export const ContextProvider = ({ children, storeName, events = {} }) => {
  const dispatch = useDispatch();

  const { list, paging } = useSelector(service, ({ context: { list, paging } }) => {
    return { list, paging };
  });

  const selectedParkings = useSelector(service, ({ context: { selectedParkings } }) => selectedParkings);

  // If we clicked on an incident or a search result
  const selectEventAccessId = useSelector(
    service,
    ({ context: { selectEventAccessId } }) => selectEventAccessId,
    (old, newSession) => old?._id !== newSession?._id,
  );

  useEffect(() => {
    dispatch({ type: `${storeName}:wakeup`, payload: {} });
    return () => {
      dispatch({ type: `${storeName}:sleep`, payload: {} });
    };
  }, []);

  const eventDispatch = (key, fn, src) => {
    const payload = fn(src);
    dispatch({ type: `${storeName}:${key}`, payload });
  };

  const eventsActions = useMemo(() =>
    Object.entries(events).reduce(
      (acc, [key, fn]) => ({
        [key]: (src) => eventDispatch(key, fn, src),
        ...acc,
      }),
      {},
    ),
  );

  const renderEventAccess = ({ _id, startDate, endDate, description, type, category }) => ({
    _id,
    startDate,
    endDate,
    description,
    type,
    category,
  });

  const renderList = useMemo(() => list.map(renderEventAccess), [list, selectedParkings]);

  const renderPaging = useMemo(() => {
    const { count = 0, limit = 10 } = paging;
    const total = Math.ceil(count / limit);

    return {
      page: paging.current,
      totalPages: total,
    };
  }, [paging]);

  return (
    <LocalContext.Provider
      value={{
        list: renderList,
        selectEventAccessId,
        ...renderPaging,
        ...eventsActions,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

ContextProvider.defaultProps = {
  storeName: "AccessPointHistory",
  events: {},
};

ContextProvider.propTypes = {
  storeName: PropTypes.string,
  events: PropTypes.shape({}),
};
