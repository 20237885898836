import React from "react";
import MDBox from "../../../../../assets/@ndres-ui/MDBox";
import MDTypography from "../../../../../assets/@ndres-ui/MDTypography";

const TcsOspExample = () => {
  return (
    <MDBox mt={2} pt={1} ml={2} style={{ maxWidth: "450px" }}>
      <MDTypography fontSize="0.7em" fontStyle="italic">
        Example :
        <br />
        101043007;Bernhard@tcs.ch;Bernhard;Meyer
        <br />
        103988500;Fabio@tcs.ch;Fabio;Lanzoni
        <br />
        105586802;Heidi@tcs.ch;Heidi;Spyri
      </MDTypography>
      <br />
      <MDTypography fontSize="0.7em" fontStyle="italic">
        101043007
        <br />
        103988500
      </MDTypography>
      <br />
      <MDTypography fontSize="0.7em" fontStyle="italic">
        101043007;;Bernhard;Meyer
        <br />
        103988500;;Fabio;Lanzoni
      </MDTypography>
    </MDBox>
  );
};

export default TcsOspExample;
