/* eslint-disable implicit-arrow-linebreak */
import React from "react";
import PropTypes from "prop-types";

import useDictionaries from "assets/commonHooks/useDictionaries";
import MDTypography from "assets/@ndres-ui/MDTypography";

const PopOverHeader = (props) => {
  const { parkingName, entry, exit } = props;
  const text = useDictionaries();

  return (
    <header style={{ borderBottom: "1px solid #B4AFAA", paddingBottom: "0.5em" }}>
      <p style={{ fontSize: "1.4em", padding: "0.5em 0", fontWeight: "bold" }}>{text(parkingName)}</p>
      <div>
        <MDTypography type="tableHeader" component="span" textTransform="capitalize">
          {text("widgets-latest-events-popover-label-entry")}:
        </MDTypography>
        <MDTypography type="tableData" component="span" pl="5px">
          {entry}
        </MDTypography>
      </div>
      <div>
        <MDTypography type="tableHeader" component="span" textTransform="capitalize">
          {text("widgets-latest-events-popover-label-exit")}:{"  "}
        </MDTypography>
        <MDTypography type="tableData" component="span" pl="10px">
          {exit}
        </MDTypography>
      </div>
    </header>
  );
};

PopOverHeader.propTypes = {
  parkingName: PropTypes.string.isRequired,
  entry: PropTypes.node.isRequired,
  exit: PropTypes.node.isRequired,
};

export default PopOverHeader;
