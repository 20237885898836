import React from "react";
import ContainerInGrid from "assets/@luc-ui/Container";
import UserProviderAboutTable from "./materials/table";

const About = () => (
  <div style={{ height: "100%", overflow: "scroll", position: "relative" }}>
    <ContainerInGrid
      headerName="widgets-user-provider-details-title"
      headerTextColor="primary"
      headerBackgroundColor="#ffffff"
    >
      <UserProviderAboutTable />
    </ContainerInGrid>
  </div>
);

export default About;
