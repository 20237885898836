/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector } from "@xstate/react";
import { service } from "../../../reducer";
import { service as serviceHierarchy } from "components/I18n/reducer";
import { useDispatch } from "react-redux";

// @mui materials
import { styled } from "@mui/system";
import MDInput from "assets/@ndres-ui/MDInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import Grid from "@mui/material/Grid";
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";
import { Typography } from "@mui/material";

const verifiedEmail = (username) =>
  String(username)
    ?.toLowerCase()
    ?.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

function phone(str) {
  str = str.replace(/[a-zA-Z]/gi, "");
  str = str.replace(/(\d{3})(\d{3})(\d{4})/gi, "$1 $2 $3");
  return str;
}

function ucwords(str) {
  return (str + "").replace(/^([a-z])|\s+([a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
}

const Input = styled(MDInput)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: "100%",
  margin: "0px",
  marginTop: "5px",
  padding: "0px 2px",
}));

const Select = styled(({ ...rest }) => (
  <Input
    {...rest}
    InputProps={{
      style: {
        height: 44.125,
        // padding: '12px',
        width: "100%",
      },
    }}
  />
))(({ theme }) => ({
  width: "100%",
  padding: "2px 2px",
  margin: "8px 0px 0px 0px",
}));

export default function Step2({ addCompleted, setData, formData, addNextBt }) {
  const text = useDictionaries();
  const dispatch = useDispatch();

  const loadingEmail = useSelector(service, ({ context }) => context.loadingEmail);
  const chargedEmails = useSelector(service, ({ context }) => context.chargedEmails ?? {});

  useEffect(() => {
    const testing = [
      !!formData?.productData,
      !!formData?.startDate && formData?.startDate !== "",
      !!formData?.places && formData?.places >= 1,
      !!formData?.name1 && formData?.name1 !== "",
      !!chargedEmails[formData?.emailTemp] || (!!formData?.email && verifiedEmail(formData?.emailTemp)),
      loadingEmail !== "loading",
    ];

    const result = testing.reduce((c, o) => c && o, true);
    addNextBt(1, result);
  }, [formData, chargedEmails, loadingEmail]);

  useEffect(() => {
    addCompleted(1, true);
  }, []);

  useEffect(() => {
    if (verifiedEmail(formData?.emailTemp) && chargedEmails[formData?.emailTemp] === undefined) {
      const timer = setTimeout(() => {
        dispatch({
          type: "WizardNewPool:NewPoll:exists-admin",
          payload: { email: formData.emailTemp },
        });
      }, 800);

      return () => clearTimeout(timer);
    }

    if (
      chargedEmails[formData?.emailTemp]?.email === formData.emailTemp &&
      !!chargedEmails[formData?.emailTemp]?.firstName
    ) {
      const { phone, firstName, lastName, lang } = chargedEmails[formData?.emailTemp];

      setData({
        email: formData?.emailTemp,
        firstName,
        lastName,
        lang,
        phone,
      });
    }
  }, [formData.emailTemp]);

  useEffect(() => {
    if (loadingEmail === "notFound") {
      setData({
        email: formData?.emailTemp,
      });
    } else {
      if (chargedEmails[formData?.emailTemp]) {
        const { phone, firstName, lastName, lang } = chargedEmails[formData?.emailTemp];
        setData({
          email: formData?.emailTemp,
          firstName,
          lastName,
          lang,
          phone,
        });

        dispatch({
          type: "WizardNewPool:NewPoll:copied-exists-admin",
          payload: {},
        });
      }
    }
  }, [loadingEmail, chargedEmails]);

  return (
    <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
      <MDBox pl={2}>
        <MDTypography fontSize="1.2rem" fontWeight="regular" color="dark">
          {text("Wizard-pool-second-step-title")}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" alignItems="center" justifyContent="around" ml={2} mt={4}>
        <FormControl sx={{ m: 1, width: "60%" }} variant="outlined">
          <Grid container spacing={1}>
            <Grid item xs={8} pb={0}>
              <Input
                required
                value={formData?.emailTemp ?? ""}
                autoComplete="off"
                label={ucwords(text("Wizard-pool-second-step-form-label-email"))}
                type="email"
                id="email"
                onChange={(e) => setData({ emailTemp: e.target.value })}
                loading={loadingEmail === "loading"}
                error={!verifiedEmail(formData?.emailTemp)}
                success={!Array.isArray(verifiedEmail(formData?.emailTemp)) && verifiedEmail(formData?.emailTemp)}
              />
            </Grid>

            <Grid item xs={4} pb={0}>
              <MDBox display="flex" alignItems="center" justifyContent="flex-start" height="100%">
                <Typography fontSize="1rem">{loadingEmail === "loading" ? "search" : ""}</Typography>
              </MDBox>
            </Grid>

            <Grid item xs={8} pb={0}>
              <FormControl sx={{ mt: 0.35, width: "100%" }} variant="outlined">
                <Select
                  label={ucwords(text("Wizard-second-step-motorist-form-label-lang"))}
                  type="lang"
                  id="lang"
                  select
                  // loading={loadingEmail === 'loading'}
                  value={formData?.lang}
                  onChange={(e) => setData({ lang: e.target.value })}
                  disabled={
                    ["charged", "loading"].includes(`${loadingEmail}`) ||
                    !!chargedEmails[formData?.emailTemp] ||
                    loadingEmail === "loading"
                  }
                >
                  <MenuItem value="en_EN">EN - English</MenuItem>
                  <MenuItem value="fr_FR">FR - Français</MenuItem>
                  <MenuItem value="it_IT">IT - Italiano</MenuItem>
                  <MenuItem value="de_DE">DE - Deutsch</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={8} pb={0}>
              <Input
                value={ucwords(formData?.firstName ?? "")}
                disabled={
                  ["charged", "loading"].includes(`${loadingEmail}`) ||
                  !!chargedEmails[formData?.emailTemp] ||
                  loadingEmail === "loading"
                }
                label={ucwords(text("Wizard-pool-second-step-form-label-firstName"))}
                type="firstName"
                id="firstName"
                // loading={loadingEmail === 'loading'}
                onChange={(e) => setData({ firstName: e.target.value })}
              />
            </Grid>
            <Grid item xs={8} pb={0}>
              <Input
                value={ucwords(formData?.lastName ?? "")}
                disabled={
                  ["charged", "loading"].includes(`${loadingEmail}`) ||
                  !!chargedEmails[formData?.emailTemp] ||
                  loadingEmail === "loading"
                }
                label={ucwords(text("Wizard-pool-second-step-form-label-lastName"))}
                type="lastName"
                id="lastName"
                // loading={loadingEmail === 'loading'}
                onChange={(e) => setData({ lastName: e.target.value })}
              />
            </Grid>

            <Grid item xs={8} pb={0}>
              <Input
                value={formData?.phone ?? ""}
                disabled={
                  ["charged", "loading"].includes(`${loadingEmail}`) ||
                  !!chargedEmails[formData?.emailTemp] ||
                  loadingEmail === "loading"
                }
                label={ucwords(text("Wizard-pool-second-step-form-label-phone"))}
                type="phone"
                id="phone"
                // loading={loadingEmail === 'loading'}
                onChange={(e) => setData({ phone: phone(e.target.value) })}
              />
            </Grid>
          </Grid>
        </FormControl>
      </MDBox>
    </MDBox>
  );
}
