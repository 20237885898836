import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import MDTypography from "assets/@ndres-ui/MDTypography";
import MDButton from "assets/@ndres-ui/MDButton";
import MDBox from "assets/@ndres-ui/MDBox";
import useDictionaries from "assets/commonHooks/useDictionaries";

const TypographyButtom = styled(MDTypography)(({ theme }) => ({
  ...theme.typography.body2,
}));

const FooterStepper = ({ active, back, nextBt, next, steps, submit, blockBack }) => {
  const text = useDictionaries();
  return (
    <MDBox sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <MDBox display="flex" justifyContent="flex-start" alignItems="center" flexDirection="row">
            <MDButton
              disabled={active === 0 || blockBack}
              onClick={back}
              variant={active === 0 || blockBack ? "text" : "gradient"}
              color="primary"
              style={{ minWidth: "100px" }}
            >
              <TypographyButtom color={active === 0 ? "text" : "white"} fontWeight="light">
                {text("wizard-button-back")}
              </TypographyButtom>
            </MDButton>
          </MDBox>
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <MDBox display="flex" justifyContent="flex-end" alignItems="center" flexDirection="row">
            <MDButton
              disabled={!nextBt[active]}
              onClick={next}
              variant={!nextBt[active] ? "text" : "gradient"}
              color="primary"
              style={active < steps.length - 1 ? { minWidth: "100px", cursor: "pointer" } : { display: "none" }}
            >
              <TypographyButtom color={!nextBt[active] ? "text" : "white"} fontWeight="light">
                {text("wizard-button-next")}
              </TypographyButtom>
            </MDButton>
            <MDButton
              onClick={submit}
              variant="gradient"
              color="primary"
              style={active >= steps.length - 1 ? { minWidth: "100px" } : { display: "none" }}
            >
              <TypographyButtom color="white" fontWeight="light">
                {text("wizard-button-submit")}
              </TypographyButtom>
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default FooterStepper;
