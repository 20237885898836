import { gql } from "@apollo/client";

export default gql`
  mutation verifyAndUpdateMotorist($input: SetVerifyDto!) {
    verifyAndUpdateMotorist(input: $input) {
      motorist {
        _id
        state
      }
    }
  }
`;
