import React from "react";
import PropTypes from "prop-types";
import ArticleIcon from "@mui/icons-material/Article";

const MotoristContractState = (props) => {
  const { motoristContractExist } = props;
  const iconColor = motoristContractExist ? "#504b46" : "#b4afaa";
  return <ArticleIcon fontSize="medium" sx={{ color: iconColor }} />;
};

MotoristContractState.defaultProps = {
  motoristContractExist: false,
};

MotoristContractState.propTypes = {
  motoristContractExist: PropTypes.bool,
};

export default MotoristContractState;
