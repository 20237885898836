import React, { useEffect, useMemo } from "react";
import { styled, keyframes } from "@mui/system";
import { useDispatch } from "react-redux";
import { useSelector as XSelector } from "@xstate/react";
import countries from "assets/store/countries.json";
import { service } from "components/WizardNewSubscription/reducer";
import { service as serviceHierarchy } from "components/I18n/reducer";

// @mui materials
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";

import Grid from "@mui/material/Grid";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import OutlinedInput, { outlinedInputClasses } from "@mui/material/OutlinedInput";
import MDInput from "assets/@ndres-ui/MDInput";
import MDTypography from "assets/@ndres-ui/MDTypography";
import MDBox from "assets/@ndres-ui/MDBox";
import useDictionaries from "assets/commonHooks/useDictionaries";
import { capitalize } from "assets/commonHooks/capitalize";
import useStateCallback from "assets/commonHooks/useStateCallback/useStateCallback";

const verifiedEmail = (username) =>
  !!String(username)
    ?.toLowerCase()
    ?.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

const blink = keyframes`
  0% { opacity: 0.3; }
  50% { opacity: 1; }
  100% { opacity: 0.3; }
`;

const BlinkedBox = styled(MDBox)({
  animation: `${blink} 2s linear infinite`,
});

const VerifiedBox = styled(MDBox)({
  color: "blue",
  padding: "2px 5px",
});

const RefusedBox = styled(MDBox)({
  color: "green",
  padding: "2px 5px",
});

const SelectMUI = styled(({ value, ...rest }) => (
  <Input
    value={value ?? ""}
    InputProps={{
      style: {
        height: 44.125,
        // padding: '12px',
        width: "100%",
      },
    }}
    {...rest}
  />
))(({ theme }) => ({
  width: "100%",
  padding: "2px 2px",
  margin: "8px 0px 0px 0px",
}));

const Input = styled(MDInput)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: "100%",
  margin: "0px",
  marginTop: "5px",
  padding: "0px 2px",
}));

const Select = styled(({ ...rest }) => (
  <Input
    {...rest}
    InputProps={{
      style: {
        height: 39,
        // padding: '12px',
        width: "100%",
      },
    }}
  />
))(({ theme }) => ({
  width: "100%",
  padding: "2px 2px",
  margin: "8px 0px 0px 0px",
}));

const cssStauts = {
  refused: {
    color: "#C10303",
    border: "solid 1px #C10303",
  },
  verified: {
    color: "green",
    border: "solid 1px green",
  },
  checking: {
    color: "#068A9F",
    border: "solid 1px #068A9F",
  },
};

const CardInput = styled(OutlinedInput)(({ theme, status }) => ({
  width: "100%",
  margin: "5px -5px",
  border: "solid 1px #B0B0B0",
  ...cssStauts[status],
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: "solid 1px #B0B0B0",
    ...cssStauts[status],
  },

  [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
    border: "solid 1px #B0B0B0",
    ...cssStauts[status],
  },

  [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    border: "solid 1px #B0B0B0",
    ...cssStauts[status],
  },
}));

export default function Step2({ addCompleted, setData, formData, addNextBt }) {
  const text = useDictionaries();
  const existsMotorist = XSelector(service, ({ context }) => context.existsMotorist?.motorist);
  const selectedLang = XSelector(serviceHierarchy, ({ context }) => context.selectedLang);

  const existsMotoristMemo = useMemo(() => existsMotorist, [existsMotorist]);
  const selectedLangMemo = useMemo(() => selectedLang, [selectedLang]);

  function compare(a, b) {
    if (a[1][selectedLangMemo ?? "en_EN"] < b[1][selectedLangMemo ?? "en_EN"]) {
      return -1;
    }
    if (a[1][selectedLangMemo ?? "en_EN"] > b[1][selectedLangMemo ?? "en_EN"]) {
      return 1;
    }
    return 0;
  }

  const [validateEmail, setValidateEmail] = useStateCallback("unverified");

  const dispatch = useDispatch();

  useEffect(() => {
    const testing = [
      verifiedEmail(formData.user.username),
      ["success", "unverified"].includes(validateEmail),
      formData?.verificationEmailStatus !== "searching",
    ];
    const result = !!formData.exitUser || !!testing.reduce((c, o) => c && o, true);
    addNextBt(1, result);
  }, [formData, validateEmail]);

  useEffect(() => {
    addCompleted(1, true);
  }, []);

  useEffect(() => {
    if (formData.user.username !== "") {
      const newStatus = verifiedEmail(formData.user.username) ? "success" : "error";

      if (newStatus === "success") {
        setData({ verificationEmailStatus: "searching" });
      } else {
        setData({ verificationEmailStatus: "unverified" });
      }

      setValidateEmail(newStatus);
    }

    if (formData.user.username === "" || !formData.user.username) {
      setValidateEmail("unverified");
    }
  }, [formData?.user?.username]);

  useEffect(() => {
    if (existsMotoristMemo?._id) {
      setData({
        verificationEmailStatus: "found",
        exitUser: existsMotoristMemo,
        user: { ...existsMotoristMemo },
      });
    } else if (formData.verificationEmailStatus === "searching" && !existsMotoristMemo) {
      const timer = setTimeout(() => {
        dispatch({
          type: "Wizard:NewSubscription:exists-motorist",
          payload: { username: formData.user.username },
        });
      }, 800);
      return () => clearTimeout(timer);
    }
  }, [formData.verificationEmailStatus, formData.user.username]);

  useEffect(() => {
    if (existsMotoristMemo) {
      setValidateEmail("verified");

      setData({
        verificationEmailStatus: "found",
        exitUser: existsMotoristMemo,
        user: { ...existsMotoristMemo },
      });
    } else {
      setValidateEmail("unverified");

      setData({ verificationEmailStatus: "not found" });
    }
  }, [existsMotoristMemo]);

  const setUserData = (data) => {
    setData({
      user: {
        ...formData.user,
        ...data,
      },
    });
  };
  return (
    <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
      <MDBox pl={2}>
        <MDTypography fontSize="1.5rem" fontWeight="regular" color="dark">
          {text("Wizard-second-step-motorist")}
        </MDTypography>
        <MDTypography fontSize="1rem" fontWeight="light" color="text">
          {text("Wizard-second-step-motorist-description")}
        </MDTypography>
      </MDBox>

      <MDBox display="flex" alignItems="center" justifyContent="around" ml={2} mt={4}>
        <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
          <Grid container spacing={1}>
            {formData?.exitUser?.username ? (
              <Grid item xs={6} pb={0}>
                <FormControl sx={{ mt: 1, width: "100%", maxWidth: "380px" }} variant="outlined">
                  <Input
                    className="select_large"
                    value={formData.exitUser.username ?? ""}
                    disabled={!!formData?.exitUser?.username}
                    label={text("Wizard-second-step-motorist-form-label-email")}
                    type="username"
                    id="username"
                    required
                    error={validateEmail === "error"}
                    success={validateEmail === "success"}
                    onChange={(e) =>
                      setUserData({
                        username: `${e.target.value}`.toLowerCase(),
                      })
                    }
                  />
                  <MDTypography
                    fontSize="0.8rem"
                    color={validateEmail === "error" ? "error" : "white"}
                    pl={1}
                    style={{ transition: "all ease 0.5s" }}
                  >
                    {text("Wizard-second-step-motorist-form-label-email-format-error")}
                  </MDTypography>
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={6} pb={0}>
                <MDBox display="flex" alignItems="center" justifyContent="around" ml={1}>
                  <FormControl sx={{ mt: 1, width: "100%", maxWidth: "380px" }}>
                    <CardInput
                      className="select_large"
                      value={formData?.user?.username ?? ""}
                      onChange={(e) =>
                        setUserData({
                          username: `${e.target.value}`.toLowerCase(),
                        })
                      }
                      status={formData.verificationEmailStatus}
                      // disabled={formData?.exitUser !== undefined}
                      startAdornment={<MDBox>Email:</MDBox>}
                      endAdornment={
                        <InputAdornment position="end">
                          {formData.verificationEmailStatus === "searching" && validateEmail !== "error" ? (
                            <BlinkedBox>{formData.verificationEmailStatus}</BlinkedBox>
                          ) : null}
                          {formData.verificationEmailStatus === "found" && validateEmail !== "error" ? (
                            <VerifiedBox>found</VerifiedBox>
                          ) : null}
                          {formData.user.username !== "" &&
                          formData.verificationEmailStatus === "not found" &&
                          validateEmail !== "error" ? (
                            <RefusedBox>{text("Wizard-second-step-motorist-form-error-new-motorist")}</RefusedBox>
                          ) : null}
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => true}
                            onMouseDown={() => true}
                            edge="end"
                          >
                            {formData.verificationEmailStatus === "unverified" ||
                            validateEmail === "error" ? null : formData.verificationEmailStatus === "searching" ? (
                              <CircularProgress
                                style={{
                                  color: "GrayText",
                                  height: "20px",
                                  width: "20px",
                                }}
                              />
                            ) : formData.verificationEmailStatus === "found" ? (
                              <CheckIcon style={{ color: "blue" }} />
                            ) : null}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <MDTypography
                      fontSize="0.8rem"
                      color={validateEmail === "error" ? "error" : "white"}
                      pl={1}
                      style={{ transition: "all ease 0.5s" }}
                    >
                      {text("Wizard-second-step-motorist-form-label-email-format-error")}
                    </MDTypography>
                  </FormControl>
                </MDBox>
              </Grid>
            )}
            <Grid item xs={4} pb={0} />
            <Grid item xs={3} pb={0}>
              <FormControl sx={{ mt: 0.35, width: "100%" }} variant="outlined">
                <Select
                  className="select_large"
                  label={text("Wizard-second-step-motorist-form-label-lang")}
                  type="lang"
                  id="lang"
                  select
                  disabled={!!formData?.exitUser?.username && formData?.exitUser?.state !== "TO_VERIFY"}
                  value={formData?.user?.lang ?? selectedLangMemo ?? "en_EN"}
                  onChange={(e) => setUserData({ lang: e.target.value })}
                >
                  <MenuItem value="en_EN">EN - English</MenuItem>
                  <MenuItem value="fr_FR">FR - Français</MenuItem>
                  <MenuItem value="it_IT">IT - Italiano</MenuItem>
                  <MenuItem value="de_DE">DE - Deutsch</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3} pb={0}>
              <FormControl sx={{ mt: 0.35, width: "100%" }} variant="outlined">
                <Select
                  className="select_large"
                  label={text("Wizard-second-step-motorist-form-label-gender")}
                  type="gender"
                  id="gender"
                  select
                  disabled={!!formData?.exitUser?.username && formData?.exitUser?.state !== "TO_VERIFY"}
                  value={formData?.user?.gender?.length > 0 ? formData?.user?.gender : "UNKNOWN"}
                  onChange={(e) => setUserData({ gender: e.target.value })}
                >
                  <MenuItem value="MALE">{text(`Wizard-second-step-motorist-form-gender-male`)}</MenuItem>
                  <MenuItem value="FEMALE">{text(`Wizard-second-step-motorist-form-gender-female`)}</MenuItem>
                  <MenuItem value="UNKNOWN">{text(`Wizard-second-step-motorist-form-gender-unknown`)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} pb={0} />
            <Grid item xs={6}>
              <Input
                className="select_large"
                value={formData?.user?.firstName ?? ""}
                disabled={!!formData?.exitUser?.username && formData?.exitUser?.state !== "TO_VERIFY"}
                label={text("Wizard-second-step-motorist-form-label-first-name")}
                type="fname"
                id="fname"
                onChange={(e) => setUserData({ firstName: e.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                className="select_large"
                value={formData?.user?.lastName ?? ""}
                disabled={!!formData?.exitUser?.username && formData?.exitUser?.state !== "TO_VERIFY"}
                label={text("Wizard-second-step-motorist-form-label-last-name")}
                type="lname"
                id="lname"
                onChange={(e) => setUserData({ lastName: e.target.value })}
              />
            </Grid>

            <Grid item xs={6}>
              <Input
                className="select_large"
                value={formData?.user?.address1 ?? formData?.user?.address2 ?? ""}
                disabled={!!formData?.exitUser?.username && formData?.exitUser?.state !== "TO_VERIFY"}
                label={text("Wizard-second-step-motorist-form-label-address1")}
                type="address"
                id="address"
                onChange={(e) => setUserData({ address1: e.target.value })}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                className="select_large"
                value={formData?.user?.city ? capitalize(formData?.user?.city) : ""}
                disabled={!!formData?.exitUser?.username && formData?.exitUser?.state !== "TO_VERIFY"}
                label={text("Wizard-second-step-motorist-form-label-city")}
                type="city"
                id="city"
                onChange={(e) => setUserData({ city: capitalize(e.target.value) })}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                className="select_large"
                value={formData?.user?.zipcode ?? ""}
                disabled={!!formData?.exitUser?.username && formData?.exitUser?.state !== "TO_VERIFY"}
                label={text("Wizard-second-step-motorist-form-label-zip")}
                type="zipcode"
                id="zipcode"
                onChange={(e) => setUserData({ zipcode: e.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                className="select_large"
                value={formData?.user?.address2 ?? ""}
                disabled={!!formData?.exitUser?.username && formData?.exitUser?.state !== "TO_VERIFY"}
                label={text("Wizard-second-step-motorist-form-label-address2")}
                type="address"
                id="complement of address"
                onChange={(e) => setUserData({ address2: e.target.value })}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectMUI
                className="select_large"
                value={formData?.user?.country ?? ""}
                disabled={!!formData?.exitUser?.username && formData?.exitUser?.state !== "TO_VERIFY"}
                label={text("Wizard-second-step-motorist-form-label-country")}
                type="country"
                id="country"
                select
                selected="none"
                multiple
                onChange={(e) => setUserData({ country: e.target.value })}
                style={{ transform: "translateY(-5px)" }}
                SelectProps={{
                  MenuProps: {
                    style: {
                      height: "300px",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    height: 39,
                    width: "100%",
                  },
                }}
              >
                <MenuItem value="none" key="none"></MenuItem>
                {Object.entries(countries)
                  .sort(compare)
                  .map(([code, traductions]) => (
                    <MenuItem value={code} key={code}>
                      {traductions[selectedLangMemo ?? "en_EN"] ?? traductions.en_EN}
                    </MenuItem>
                  ))}
              </SelectMUI>
            </Grid>
            <Grid item xs={3}>
              <Input
                className="select_large"
                value={formData?.user?.phone ?? ""}
                disabled={!!formData?.exitUser?.username && formData?.exitUser?.state !== "TO_VERIFY"}
                label={text("Wizard-second-step-motorist-form-label-phone")}
                type="phone"
                id="phone"
                onChange={(e) => setUserData({ phone: e.target.value })}
              />
            </Grid>
          </Grid>
        </FormControl>
      </MDBox>
    </MDBox>
  );
}
