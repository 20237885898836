import React, { createContext, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

const defaultContextData = {
  list: [],
  page: 1,
  totalPages: 1,
};

export const LocalContext = createContext(defaultContextData);

export const ContextProvider = ({ children, storeName, events = {} }) => {
  const dispatch = useDispatch();

  //   const { list, paging } = useSelector(service, ({ context: { list, paging } }) => {
  //     return { list, paging };
  //   });
  const { token } = useParams();
  useEffect(() => {
    dispatch({ type: `${storeName}:wakeup`, payload: { token } });
    return () => {
      dispatch({ type: `${storeName}:sleep`, payload: {} });
    };
  }, []);

  const eventDispatch = (key, fn, src) => {
    const payload = fn(src);
    dispatch({ type: `${storeName}:${key}`, payload });
  };

  const eventsActions = useMemo(
    () =>
      Object.entries(events).reduce(
        (acc, [key, fn]) => ({
          [key]: (src) => eventDispatch(key, fn, src),
          ...acc,
        }),
        {},
      ),
    [events],
  );

  const renderList = useMemo(() => {}, []);

  return (
    <LocalContext.Provider
      value={{
        list: renderList,
        ...eventsActions,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

ContextProvider.propTypes = {
  storeName: PropTypes.string,
  events: PropTypes.shape({}),
};
