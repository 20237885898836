import { useSelector } from "@xstate/react";
import { service } from "components/MotoristValidation/reducer";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";

import useStateCallback from "assets/commonHooks/useStateCallback/useStateCallback";

import SweetAlert2 from "react-sweetalert2";
import Swal from "sweetalert2";

// material-ui
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MenuItem from "@mui/material/MenuItem";

// @ndres-ui
import MDBox from "assets/@ndres-ui/MDBox";
import MDButton from "assets/@ndres-ui/MDButton";
import MDInput from "assets/@ndres-ui/MDInput";
import MDTypography from "assets/@ndres-ui/MDTypography";
import countries from "assets/store/countries.json";

import { capitalize } from "assets/commonHooks/capitalize";
import useDictionaries from "../../../../assets/commonHooks/useDictionaries";
import { LocalContext } from "../../context";

// SSR

const MySwal = Swal;
let timerInterval;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Input = styled(MDInput)(({ theme, error }) => ({
  color: theme.palette.text.secondary,
  width: "100%",
  margin: "0px",
  marginTop: "5px",
  padding: "0px 2px",
  "& .MuiOutlinedInput-root": {
    background: error ? "#f5d5d5" : "",
  },
  "& .MuiOutlinedInput-notchedOutline, MuiFormControl-root-MuiTextField-root .Mui-focused:after": {
    borderColor: error ? "red" : "",
  },
}));

const InputDisabled = styled(MDInput)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: "100%",
  margin: "0px",
  marginTop: "5px",
  padding: "0px 2px",
}));

const SelectMUI = styled(({ value, ...rest }) => (
  <Input
    value={value ?? ""}
    InputProps={{
      style: {
        height: 44.125,
        // padding: '12px',
        width: "100%",
      },
    }}
    {...rest}
  />
))(({ theme }) => ({
  width: "100%",
  padding: "2px 2px",
  margin: "8px 0px 0px 0px",
}));

const AddPlate = styled(({ onClick, label, ...rest }) => (
  <MDBox p={1} {...rest}>
    <MDBox onClick={onClick} style={{ cursor: "pointer", userSelect: "none" }}>
      <MDTypography fontWeight="bold" variant="body" color="secondary">
        {label}
      </MDTypography>
    </MDBox>
  </MDBox>
))(({ theme }) => ({
  borderRadius: "20%",
  position: "relative",
  left: "-5px",
  "&:hover": {
    background: "#ababab20",
  },
}));

const InputPlate = ({ index, value, description, setDescription, setValue, delByIndex }) => (
  <MDBox width="100%" p={1} style={{ position: "relative", left: "-10px" }}>
    <MDBox width="100%">
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Input
            value={value}
            label="Plate"
            type="text"
            id="plate"
            onChange={(e) => setValue({ value: e.target.value, index })}
          />
        </Grid>
        <Grid item xs={4}>
          <Input
            value={description}
            label="Description"
            type="plateName"
            id="plateName"
            onChange={(e) => setDescription({ value: e.target.value, index })}
          />
        </Grid>
        <Grid item xs={3}>
          <MDBox display="flex" alignItems="center" justifyContent="flex-start" width="100%" height="100%" pt="5px">
            <MDButton iconOnly variant="text" onClick={() => delByIndex(index)}>
              <DeleteForeverIcon style={{ width: "25px", height: "25px", color: "grey" }} />
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  </MDBox>
);

const ValidationForm = (props) => {
  //   const router = useRouter();
  const { token } = useParams();
  const navigate = useNavigate();
  const { submitValidation } = useContext(LocalContext);
  const { verifyData, updatedMotorist, tokenError } = useSelector(service, ({ context }) => context);
  const text = useDictionaries();

  const updatedMotoristMemo = useMemo(() => updatedMotorist, [updatedMotorist]);
  const tokenErrorMemo = useMemo(() => tokenError, [tokenError]);
  const verifyDataMotoristMemo = useMemo(() => verifyData, [verifyData]);
  const [swalAwait, setSwalAwait] = useState();
  const [swalSuccess, setSwalSuccess] = useState();
  const [swalError, setSwalError] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "I18n:change", payload: { language: verifyData?.lang } });
  }, [verifyData]);

  const [states, setStates] = useStateCallback({
    motorist: {},
    contract: { ckeck1: false },
    plates: [],
  });

  const verifyChaps = useMemo(
    () =>
      setStates({
        ...states,
        motorist: verifyData,
      }),
    [verifyData],
  );

  function compare(a, b) {
    if (a[1][states?.motorist?.lang ?? "en_EN"] < b[1][states?.motorist?.lang ?? "en_EN"]) {
      return -1;
    }
    if (a[1][states?.motorist?.lang ?? "en_EN"] > b[1][states?.motorist?.lang ?? "en_EN"]) {
      return 1;
    }
    return 0;
  }

  const [errors, setErros] = useStateCallback({});

  const setMotoristData = ({ ...params }) => {
    setStates({ ...states, motorist: { ...states.motorist, ...params } });
  };

  const setContracttData = ({ ...params }) => {
    setStates({ ...states, contract: { ...states.contract, ...params } });
  };

  const editPlateValue = ({ index, value }) => {
    const { plates } = states;
    plates[index].value = value.replace(/[^a-zA-Z0-9ÖÜÄËÏöüäëï]/g, "").toUpperCase();
    setStates({ ...states, plates });
  };

  const editPlateDescription = ({ index, value }) => {
    const { plates } = states;
    plates[index].description = value;
    setStates({ ...states, plates });
  };

  const addOnePlate = () => {
    setStates({
      ...states,
      plates: [...states.plates, { value: "", description: "", type: "PLATE" }],
    });
  };

  const delPlateByIndex = (index) => {
    const newPlateArray = [...states.plates].filter((plate, indexPlate) => index !== indexPlate);
    setStates({ ...states, plates: newPlateArray });
  };

  const submit = async () => {
    const errorsWarnming = Object.values(errors);

    setSwalAwait({
      show: true,
      allowOutsideClick: false,
      title: text("widgets-validator-contract-creating"),
      html: `${text("widgets-validator-contract-creating-description")} <b></b>`,
      timerProgressBar: true,
      allowEscapeKey: false,
      customClass: {
        title: "SwalTitle",
      },
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
        setSwalAwait({ show: false });
      },
    });

    if (errorsWarnming.length > 0) {
      setSwalSuccess({
        show: true,
        title: text("widgets-validator-correct-error"), // 'An error occurred and the contract could not be created.',
        html: `<div style="display:flex;align-items:flex-start;justify-content:flex-start;flex-direction:column">${errorsWarnming
          .map((error) => `<p><span style="font-weight:bold"> - ${error}</span></p>`)
          .join("")}</div>`,
        icon: "error",
        showConfirmButton: false,
        cancelButtonText: text("widgets-validator-cancel-button"),
        showCancelButton: true,
        cancelButtonColor: "#c81400",
        timer: 8000,
        customClass: {
          title: "SwalTitle",
        },
        didOpen: () => {
          Swal.hideLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
          setSwalSuccess({ show: false });
        },
      });
    }

    const data = {
      token,
      motorist: {
        firstName: states?.motorist?.firstName,
        lastName: states?.motorist?.lastName,
        username: states?.motorist?.username,
        address1: states?.motorist?.address1,
        address2: states?.motorist?.address2,
        zipcode: states?.motorist?.zipcode,
        city: states?.motorist?.city,
        country: states?.motorist?.country,
        phone: states?.motorist?.phone,
        lang: states?.motorist?.lang,
        gender: states?.motorist?.gender,
        password: states?.motorist?.password,
        plates: [...states?.plates],
      },
    };

    try {
      //   const res = await postPromise("/api/verificator", data, "VERIFICATOR");
      if (errorsWarnming.length < 1) {
        submitValidation(data);
      }

      const res = {};
    } catch (error) {
      if (error) {
        setSwalSuccess({
          show: true,
          title: text("widgets-validator-contract-rejected"), // 'An error occurred and the contract could not be created.',
          html: '<div style="display:flex;align-items:flex-start;justify-content:flex-start;flex-direction:column"><p><span style="font-weight:bold">ERROR</span></p></div>',
          icon: "error",
          showConfirmButton: false,
          cancelButtonText: text("widgets-validator-cancel-button"),
          showCancelButton: true,
          cancelButtonColor: "#c8161b",
          timer: 8000,
          customClass: {
            title: "SwalTitle",
          },
          didOpen: () => {
            Swal.hideLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
            setSwalSuccess({ show: false });
            // navigate("/verificator/notValidated");
            // router.push(
            //   {
            //     pathname: "/verificator/notValidated",
            //   },
            //   undefined,
            //   { scroll: false }
            // );
          },
        });
      }
    }
  };
  const isInitialMount = useRef(true);
  let MotoristIsVerified;
  useEffect(() => {
    if (isInitialMount.current && errors) {
      isInitialMount.current = false;
    } else {
      if (updatedMotoristMemo && typeof updatedMotoristMemo == "object" && Object.keys(errors).length === 0) {
        setSwalError({
          show: true,

          title: text("widgets-validator-contract-created"),
          icon: "success",
          showConfirmButton: false,
          timer: 1000,
          customClass: {
            title: "SwalTitle",
          },
          didOpen: () => {
            // Swal.showLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
            setSwalError({ show: false });

            window.location.replace(`${process.env.REACT_APP_HOST_MOTORIST}/confirmation/`);
            // router.push(
            //   {
            //     pathname: `${process.env.HOST_MOTORIST}/confirmation/`,
            //   },
            //   undefined,
            //   { scroll: false }
            // );
          },
        });
      }
      if (updatedMotoristMemo && typeof updatedMotoristMemo !== "object" && Object.keys(errors).length === 0) {
        setSwalSuccess({
          show: true,
          title: text("widgets-validator-contract-rejected"), // 'An error occurred and the contract could not be created.',
          html: `<div style="display:flex;align-items:flex-start;justify-content:flex-start;flex-direction:column"><p><span style="font-weight:bold">ERROR : ${updatedMotoristMemo}</span></p></div>`,
          icon: "error",
          showConfirmButton: false,
          cancelButtonText: "Agree",
          showCancelButton: true,
          cancelButtonColor: "#c81400",
          timer: 8000,
          customClass: {
            title: "SwalTitle",
          },
          didOpen: () => {
            Swal.hideLoading();
          },
          willClose: () => {
            clearInterval(timerInterval);
            setSwalSuccess({ show: false });
            navigate("/verificator/notValidated");
            // router.push(
            //   {
            //     pathname: "/verificator/notValidated",
            //   },
            //   undefined,
            //   { scroll: false }
            // );
          },
        });
      }
    }
  }, [updatedMotoristMemo]);

  useEffect(() => {
    let errorsDectector = [];
    if (verifyDataMotoristMemo?.state !== "VERIFIED") {
      errorsDectector = [
        {
          condition: states?.motorist?.password ? states?.motorist?.password.length < 8 : false,
          effect: { motorisPassMin8Char: "motorisPassMin8Char" },
        },
        {
          condition: states?.motorist?.confirmPassword
            ? states?.motorist?.password !== states?.motorist?.confirmPassword
            : false,
          effect: {
            confirmPasswordNotEqualToPass: "confirmPasswordNotEqualToPass",
          },
        },
        {
          condition: states?.contract?.ckeck1 === false,
          effect: { notCheck: "notCheck" },
        },
        {
          condition: states?.motorist?.password === undefined || states?.motorist?.password === "",
          effect: { notPassword: "notPassword" },
        },
        {
          condition: states?.motorist?.confirmPassword === undefined || states?.motorist?.confirmPassword === "",
          effect: { notConfirmPassword: "notConfirmPassword" },
        },
      ];
    } else {
      errorsDectector = [
        {
          condition: states?.contract?.ckeck1 === false,
          effect: { notCheck: "notCheck" },
        },
      ];
    }

    const errosEffects = errorsDectector?.reduce((acc, current) => {
      if (current.condition) return { ...acc, ...current.effect };
      return { ...acc };
    }, {});

    setErros(errosEffects);
  }, [states]);

  useEffect(() => {
    if (tokenErrorMemo) {
      navigate("/verificator/invalidToken");
    }
  }, [verifyDataMotoristMemo]);

  return (
    <MDBox
      py={0}
      px={0}
      width="100vw"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDirection="column"
    >
      <MDBox
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        height="55px"
        bgColor="#C81400"
        position="fixed"
        left="0px"
        top="0px"
        zIndex="10"
      >
        <MDBox
          height="28px"
          width="94.4px"
          position="relative"
          left="15px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {/* <Image
              src="/image/illustrations/logoOSP.png"
              alt="container logo"
              layout="fill"
              objectFit="contain"
            /> */}
        </MDBox>
      </MDBox>

      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="column"
        width="100%"
        mt="55px"
      >
        <MDBox width="100%">
          <Grid container spacing={1}>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={8}>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="around"
                ml={2}
                p={0.5}
                mt={4}
                bgColor="#BECCBF"
                borderRadius="6px"
                style={{ boxShadow: "-2px 6px 11px -3px rgba(0,0,0,0.11)" }}
              >
                <MDBox ml={2}>
                  <MDTypography variant="h5" color="white">
                    {text("widgets-validator-my-account")}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={2} />
          </Grid>
        </MDBox>
      </MDBox>

      <MDBox display="flex" alignItems="center" justifyContent="flex-start" flexDirection="column" width="100%">
        <MDBox width="100%">
          <Grid container spacing={1}>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={8}>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="around"
                ml={2}
                mt={1}
                bgColor="#f5f5f5"
                borderRadius="6px"
                pt={4}
                pb={4}
                pl={4}
                pr={4}
                style={{ boxShadow: "-2px 6px 11px -3px rgba(0,0,0,0.11)" }}
              >
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <SelectMUI
                        label={text("widgets-validator-label-lang")}
                        type="lang"
                        id="lang"
                        className={"disabledInput"}
                        disabled={verifyDataMotoristMemo?.state === "VERIFIED" ? true : false}
                        select
                        value={states?.motorist?.lang ?? ""}
                        onChange={(e) => {
                          dispatch({ type: "I18n:change", payload: { language: e.target.value } });
                          setMotoristData({ lang: e.target.value });
                        }}
                      >
                        <MenuItem className="disabledInput" value="en_EN">
                          EN - English
                        </MenuItem>
                        <MenuItem className="disabledInput" value="fr_FR">
                          FR - Français
                        </MenuItem>
                        <MenuItem className="disabledInput" value="it_IT">
                          IT - Italiano
                        </MenuItem>
                        <MenuItem className="disabledInput" value="de_DE">
                          DE - Deutsch
                        </MenuItem>
                      </SelectMUI>
                    </Grid>
                  </Grid>
                </FormControl>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={2} />
          </Grid>
        </MDBox>
      </MDBox>

      <MDBox display="flex" alignItems="center" justifyContent="flex-start" flexDirection="column" width="100%">
        <MDBox width="100%">
          <Grid container spacing={1}>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={8}>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="around"
                ml={2}
                mt={4}
                bgColor="#f5f5f5"
                borderRadius="6px"
                style={{ boxShadow: "-2px 6px 11px -3px rgba(0,0,0,0.11)" }}
                pt={4}
                pb={4}
                pl={4}
                pr={4}
              >
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <InputDisabled
                        value={states?.motorist?.username ?? ""}
                        disabled
                        label={text("widgets-validator-label-email")}
                        type="email"
                        id="email"
                        onChange={(e) => setMotoristData({ username: e.target.value })}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <MDBox height="5px" />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectMUI
                        label={text("widgets-validator-label-gender")}
                        type="gender"
                        id="gender"
                        disabled={verifyDataMotoristMemo?.state === "VERIFIED" ? true : false}
                        select
                        value={states?.motorist?.gender ?? ""}
                        onChange={(e) => setMotoristData({ gender: e.target.value })}
                      >
                        <MenuItem value="FEMALE">{text("widgets-validator-label-gender-female")}</MenuItem>
                        <MenuItem value="MALE">{text("widgets-validator-label-gender-male")}</MenuItem>
                        <MenuItem value="UNKNOWN">{text("widgets-validator-label-gender-unkown")}</MenuItem>
                      </SelectMUI>
                    </Grid>
                    <Grid item xs={1}>
                      <MDBox height="5px" />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        value={states?.motorist?.firstName ? capitalize(states?.motorist?.firstName) : ""}
                        // disabled={!!formData?.exitUser?.username && formData?.exitUser?.state !== 'TO_VERIFY'}
                        disabled={verifyDataMotoristMemo?.state === "VERIFIED" ? true : false}
                        label={text("widgets-validator-label-first-name")}
                        type="fname"
                        id="fname"
                        onChange={(e) => setMotoristData({ firstName: e.target.value })}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        value={states?.motorist?.lastName ? capitalize(states.motorist.lastName) : ""}
                        disabled={verifyDataMotoristMemo?.state === "VERIFIED" ? true : false}
                        // disabled={!!formData?.exitUser?.username && formData?.exitUser?.state !== 'TO_VERIFY'}
                        label={text("widgets-validator-label-last-name")}
                        type="lname"
                        id="lname"
                        onChange={(e) => setMotoristData({ lastName: e.target.value })}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox height="5px" />
                    </Grid>

                    <Grid item xs={6}>
                      <Input
                        value={states?.motorist?.address1 ?? ""}
                        disabled={verifyDataMotoristMemo?.state === "VERIFIED" ? true : false}
                        // disabled={!!formData?.exitUser?.username && formData?.exitUser?.state !== 'TO_VERIFY'}
                        label={text("widgets-validator-label-address")}
                        type="address"
                        id="address"
                        onChange={(e) => setMotoristData({ address1: e.target.value })}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        value={states?.motorist?.address2 ?? ""}
                        disabled={verifyDataMotoristMemo?.state === "VERIFIED" ? true : false}
                        // disabled={!!formData?.exitUser?.username && formData?.exitUser?.state !== 'TO_VERIFY'}
                        label={text("widgets-validator-label-address2")}
                        type="address2"
                        id="address2"
                        onChange={(e) => setMotoristData({ address2: e.target.value })}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        value={states?.motorist?.city ? capitalize(states?.motorist?.city) : ""}
                        disabled={verifyDataMotoristMemo?.state === "VERIFIED" ? true : false}
                        // disabled={!!formData?.exitUser?.username && formData?.exitUser?.state !== 'TO_VERIFY'}
                        label={text("widgets-validator-label-city")}
                        type="city"
                        id="city"
                        onChange={(e) => setMotoristData({ city: e.target.value })}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        value={states?.motorist?.zipcode ?? ""}
                        disabled={verifyDataMotoristMemo?.state === "VERIFIED" ? true : false}
                        // disabled={!!formData?.exitUser?.username && formData?.exitUser?.state !== 'TO_VERIFY'}
                        label={text("widgets-validator-label-zip")}
                        type="zipcode"
                        id="zipcode"
                        onChange={(e) => setMotoristData({ zipcode: e.target.value })}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectMUI
                        label={text("widgets-validator-label-country")}
                        type="country"
                        id="country"
                        disabled={verifyDataMotoristMemo?.state === "VERIFIED" ? true : false}
                        select
                        selected="none"
                        multiple
                        value={states?.motorist?.country ?? ""}
                        onChange={(e) => setMotoristData({ country: e.target.value })}
                        SelectProps={{
                          MenuProps: {
                            style: {
                              height: "300px",
                            },
                          },
                        }}
                        InputProps={{
                          style: {
                            height: 44.125,
                            width: "100%",
                            marginTop: "-5px",
                          },
                        }}
                      >
                        <MenuItem value="none" key="none"></MenuItem>
                        {Object.entries(countries)
                          .sort(compare)
                          .map(([code, traductions]) => (
                            <MenuItem value={code} key={code}>
                              {traductions[states?.motorist?.lang] ?? traductions.en_EN}
                            </MenuItem>
                          ))}
                      </SelectMUI>
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        value={states?.motorist?.phone ?? ""}
                        disabled={verifyDataMotoristMemo?.state === "VERIFIED" ? true : false}
                        // disabled={!!formData?.exitUser?.email && formData?.exitUser?.state !== 'TO_VERIFY'}
                        label={text("widgets-validator-label-phone")}
                        type="phone"
                        id="phone"
                        onChange={(e) => setMotoristData({ phone: e.target.value })}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={2} />
          </Grid>
        </MDBox>
      </MDBox>

      <MDBox display="flex" alignItems="center" justifyContent="flex-start" flexDirection="column" width="100%">
        <MDBox width="100%">
          <Grid container spacing={1}>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={8}>
              <MDBox
                ml={2}
                mt={4}
                bgColor="#f5f5f5"
                borderRadius="6px"
                style={{ boxShadow: "-2px 6px 11px -3px rgba(0,0,0,0.11)" }}
                pt={4}
                pb={4}
                pl={4}
                pr={4}
              >
                <MDBox
                  width="100%"
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="around"
                  ml={2}
                  flexDirection="column"
                >
                  <MDTypography variant="body" color="secondary">
                    {text("widgets-validator-label-plates")}
                  </MDTypography>

                  {[...states.plates].map(({ value, description }, i) => (
                    <InputPlate
                      key={`${i + 1}`}
                      value={value}
                      description={description}
                      index={i}
                      setDescription={editPlateDescription}
                      setValue={editPlateValue}
                      delByIndex={delPlateByIndex}
                    />
                  ))}

                  <AddPlate label={text("widgets-validator-add-plate")} onClick={addOnePlate} />
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={2} />
          </Grid>
        </MDBox>
      </MDBox>

      <MDBox display="flex" alignItems="center" justifyContent="flex-start" flexDirection="column" width="100%" pb={4}>
        <MDBox width="100%">
          <Grid container spacing={1}>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={8}>
              {verifyDataMotoristMemo?.state !== "VERIFIED" ? (
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="around"
                  ml={2}
                  mt={4}
                  bgColor="#f5f5f5"
                  borderRadius="6px"
                  style={{ boxShadow: "-2px 6px 11px -3px rgba(0,0,0,0.11)" }}
                  pt={4}
                  pb={4}
                  pl={4}
                  pr={4}
                >
                  <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Input
                          value={states?.motorist?.password}
                          // disabled={!!formData?.exitUser?.email && formData?.exitUser?.state !== 'TO_VERIFY'}
                          label={text("widgets-validator-label-password")}
                          type="password"
                          id="password"
                          onChange={(e) => setMotoristData({ password: e.target.value })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {errors?.motorisPassMin8Char ? (
                          <MDBox p={1}>
                            <MDTypography color="error" fontSize="1rem" p={0} lineHeight="1em">
                              {text("widgets-validator-password-requirement-character")}
                            </MDTypography>
                          </MDBox>
                        ) : null}
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          value={states?.motorist?.confirmPassword}
                          // disabled={!!formData?.exitUser?.email && formData?.exitUser?.state !== 'TO_VERIFY'}
                          label={text("widgets-validator-password-confirm")}
                          type="password"
                          id="confirm_password"
                          onChange={(e) => setMotoristData({ confirmPassword: e.target.value })}
                          error={states?.errors?.confirmPassword}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {errors?.confirmPasswordNotEqualToPass ? (
                          <MDBox p={1}>
                            <MDTypography color="error" fontSize="1rem" p={0} lineHeight="1em">
                              {text("widgets-validator-password-confirmation-match")}
                            </MDTypography>
                          </MDBox>
                        ) : null}
                      </Grid>
                    </Grid>
                  </FormControl>
                </MDBox>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12} md={2} />
          </Grid>
        </MDBox>
      </MDBox>

      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="column"
        width="100%"
        pt={1}
        pb={1}
      >
        <MDBox width="100%">
          <Grid container spacing={1}>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={8}>
              <MDBox display="flex" alignItems="center" justifyContent="around" ml={2} pl={4} pr={4}>
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label={text("widgets-validator-accept-terms")}
                  onChange={(e) => setContracttData({ ckeck1: !states.contract.ckeck1 })}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={2} />
          </Grid>
        </MDBox>
      </MDBox>

      <SweetAlert2 {...swalAwait} />
      <SweetAlert2 {...swalSuccess} />
      <SweetAlert2 {...swalError} />

      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="column"
        width="100%"
        pt={4}
        pb={4}
      >
        <MDButton variant="gradient" color="primary" onClick={submit}>
          {text("widgets-validator-submit")}
        </MDButton>
      </MDBox>

      {/* <pre>{JSON.stringify(verifyData, null, 2)}</pre> */}
    </MDBox>
  );
  // } else {
  //   <div>Token not valid</div>;
  // }
};

export default ValidationForm;
