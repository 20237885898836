import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "@xstate/react";

import { service } from "../reducer";
import { DateTime } from "luxon";

const defaultContextData = {
  list: [],
  page: 1,
  totalPages: 1,
};

const calculateContracts = (contracts, selectedParkings) => {
  if (!selectedParkings || !contracts || !Array.isArray(contracts)) return "-";

  // Get contracts only for theses parkings
  const list = contracts?.filter((contract) => selectedParkings?.includes(contract.parkingId));

  // Get only active contracts
  const activeContracts = list?.filter((contract) => {
    const { stopDate } = contract;
    const now = DateTime.now();
    const stop = DateTime.fromISO(stopDate);

    return contract?.isEnabled && stop > now;
  });

  return `${activeContracts?.length} / ${list?.length}`;
};

export const LocalContext = createContext(defaultContextData);

export const ContextProvider = ({ children, storeName, events = {} }) => {
  const dispatch = useDispatch();

  const { list, paging } = useSelector(service, ({ context: { list, paging } }) => {
    return { list, paging };
  });

  const selectedParkings = useSelector(service, ({ context: { selectedParkings } }) => selectedParkings);

  // If we clicked on an incident or a search result
  const selectedFilterMotorist = useSelector(
    service,
    ({ context: { selectedFilterMotorist } }) => selectedFilterMotorist,
    (old, newItem) => old?._id !== newItem?._id,
  );
  const selectedMotorist = useSelector(
    service,
    ({ context: { selectedMotorist } }) => selectedMotorist,
    (old, newSession) => old?._id !== newSession?._id,
  );
  useEffect(() => {
    dispatch({ type: `${storeName}:wakeup`, payload: {} });
    return () => {
      dispatch({ type: `${storeName}:sleep`, payload: {} });
    };
  }, []);

  const eventDispatch = (key, fn, src) => {
    const payload = fn(src);
    dispatch({ type: `${storeName}:${key}`, payload });
  };

  const eventsActions = useMemo(() =>
    Object.entries(events).reduce(
      (acc, [key, fn]) => ({
        [key]: (src) => eventDispatch(key, fn, src),
        ...acc,
      }),
      {},
    ),
  );

  const renderMotorist = ({ _id, firstName, lastName, credentials, city, username, contracts }) => ({
    _id,
    lastName,
    firstName,
    city,
    contracts: calculateContracts(contracts?.list, selectedParkings),
    credentials: credentials?.filter((c) => c?.type !== "RFID").length,
    email: username,
  });

  const renderList = useMemo(() => list.map(renderMotorist), [list, selectedParkings]);

  let renderMotoristMemo = useMemo(() => {
    return selectedFilterMotorist && renderMotorist(selectedFilterMotorist?.motorist);
  }, [selectedFilterMotorist, selectedParkings]);

  const renderPaging = useMemo(() => {
    const { count = 0, limit = 10 } = paging;
    const total = Math.ceil(count / limit);

    return {
      page: paging.current,
      totalPages: total,
    };
  }, [paging]);

  return (
    <LocalContext.Provider
      value={{
        list: renderList,
        selectedMotorist: selectedMotorist,
        showFilteredMotorists: !!selectedFilterMotorist,
        motorist: renderMotoristMemo,
        ...renderPaging,
        ...eventsActions,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

ContextProvider.defaultProps = {
  storeName: "Motorists",
  events: {},
};

ContextProvider.propTypes = {
  storeName: PropTypes.string,
  events: PropTypes.shape({}),
};
