import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "@xstate/react";
import { service } from "../reducer";
import { service as motoristsService } from "../../Motorists/reducer";
import { DateTime } from "luxon";

const defaultContextData = {
  data: {},
};

const translateExternalId = ({ type, provider, value }) => ({
  description: provider,
  icon: `logo_${provider ? provider.toLowerCase() : "tcsosp"}.png`,
  value,
  type,
});

export const LocalContext = createContext(defaultContextData);

export const ContextProvider = ({ children, storeName, events = {} }) => {
  const dispatch = useDispatch();

  // Motorist actually displayed
  const currentMotorist = useSelector(service, ({ context }) => context.selectedMotorist);
  const pdfToSAve = useSelector(service, ({ context }) => context.pdf);

  // Motorist we have to show
  const selectedMotorist = useSelector(motoristsService, ({ context }) => context.selectedMotorist);
  useEffect(() => {
    if (!selectedMotorist) return;

    dispatch({
      type: `${storeName}:show`,
      payload: { motoristId: selectedMotorist },
    });
  }, [selectedMotorist]);

  const eventDispatch = (key, fn, src) => {
    const payload = fn(src);
    dispatch({ type: `${storeName}:${key}`, payload });
  };

  const findLast = (array, filter) => {
    const result = array.filter(filter);
    return result[result.length - 1];
  };

  const eventsActions = useMemo(
    () =>
      Object.entries(events).reduce(
        (acc, [key, fn]) => ({
          [key]: (src) => eventDispatch(key, fn, src),
          ...acc,
        }),
        {},
      ),
    [events],
  );

  const renderMotorist = useMemo(() => {
    if (!currentMotorist) return;

    const {
      _id: motorsitId,
      firstName,
      lastName,
      credentials,
      address1,
      address2,
      city,
      phone,
      country,
      username,
      state,
      isAnonymous,
      qrcode,
      shortUid,
      contracts: { list: contractsList, paging: contractPaging },
      sessions: { list: sessions, paging: sessionPaging },
    } = currentMotorist;

    return {
      motoristDetails: {
        motoristId: motorsitId,
        firstName,
        lastName,
        shortUid,
        address: address1,
        addressDetails: address2,
        email: username,
        city,
        mobile: phone,
        country,
        state: state,
        addressVerified: state === "VERIFIED",
        isAnonymous,
        plates: credentials?.filter(({ type }) => type === "PLATE"),
        cards: credentials?.filter(({ type }) => type === "PROVIDER_EXTERNAL_ID").map(translateExternalId),
        qrCode: credentials?.filter(({ type }) => type === "QRCODE"),
        qrcode,
      },
      motoristContracts: contractsList.map(
        ({ _id, isEnabled, startDate, stopDate, parking, product, credentials, ospContractId, freeflag }) => {
          let contractStatus = "";
          if (DateTime.fromISO(stopDate) < Date.now()) {
            contractStatus = "motorist-contract-status-expired";
            isEnabled = false;
          }
          if (DateTime.fromISO(stopDate) > Date.now() && isEnabled) {
            contractStatus = "motorist-contract-status-active";
          }
          if (DateTime.fromISO(stopDate) > Date.now() && !isEnabled) {
            contractStatus = "motorist-contract-status-suspended";
          }

          return {
            motoristId: motorsitId,
            contractId: _id,
            ospContractId: ospContractId || "",
            status: contractStatus,
            isActive: isEnabled,
            startDate,
            endDate: stopDate,
            parkingName: `parking-${parking._id}-name`,
            product: `product-${product._id}-name`,
            card: credentials?.find(({ type }) => type === "PROVIDER_EXTERNAL_ID"),
            plates: credentials?.filter(({ type }) => type === "PLATE"),
            qrCode: credentials?.filter(({ type }) => type === "QRCODE"),
            freeflag,
            parkingId: parking._id,
          };
        },
      ),
      contractPaging: {
        page: contractPaging.current,
        total: Math.ceil(contractPaging.count / contractPaging.limit),
      },
      sessions: sessions?.map(
        ({ _id, parking, entryDate, exitDate, state, occupancyDuration, isInconsistent, product, events }) => {
          const ENTRY_AUTHORIZED = findLast(events, ({ state }) => state === "ENTRY_AUTHORIZED");
          const ENTRY_DENIED = findLast(events, ({ state }) => state === "ENTRY_DENIED");
          const ENTERED = findLast(events, ({ state }) => state === "ENTERED");
          const EXITED = findLast(events, ({ state }) => state === "EXITED");
          const isEntered = ENTERED ? true : false;
          const isClosed = EXITED || ENTRY_DENIED ? true : false;

          const entry = ENTERED?.createdAt ?? ENTRY_AUTHORIZED?.createdAt ?? ENTRY_DENIED?.createdAt ?? undefined;
          const exit = EXITED?.createdAt ?? undefined;

          const eventsFiltered = events?.filter(
            (event) => !["CREATED", "EXIT_AUTHORIZE_PENDING"].includes(event.state),
          );
          const credentialToShow = ENTRY_AUTHORIZED?.credentials?.find(({ type }) => type !== "RFID");
          const credentialLabel = credentialToShow?.provider ?? credentialToShow?.type ?? credentialToShow?.value;
          const { reason } = findLast(events, ({ state }) => state !== "");
          return {
            sessionID: _id,
            isClosed,
            parkingName: `parking-${parking._id}-name`,
            entry: entry,
            exit: exit,
            reason,
            error: !!isInconsistent,
            duration: occupancyDuration,
            status: state === "EXIT_AUTHORIZE_PENDING" ? "ENTERED" : state,
            credentialValue: credentialToShow?.value || undefined,
            credentialType: credentialLabel || undefined,
            eventList: eventsFiltered,
            productName: product ? `product-${product._id}-name` : "",
            isEntered,
          };
        },
      ),
      sessionPaging: {
        page: sessionPaging?.current,
        total: Math.ceil(sessionPaging?.count / sessionPaging?.limit),
      },
    };
  }, [currentMotorist]);

  return (
    <LocalContext.Provider
      value={{
        data: renderMotorist,
        pdfToSAve: pdfToSAve,
        ...eventsActions,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

ContextProvider.defaultProps = {
  storeName: "MotoristDetails",
  events: {},
};

ContextProvider.propTypes = {
  storeName: PropTypes.string,
  events: PropTypes.shape({}),
};
