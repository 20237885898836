import { gql } from "@apollo/client";

export default gql`
  query sessions($parkingsIds: [String], $page: Int, $limit: Int) {
    sessions(parkingsIds: $parkingsIds, page: $page, limit: $limit) {
      list {
        _id
        isInconsistent
        state
        entryDate
        exitDate
        events {
          state
          credentials {
            type
            provider
            value
            description
          }
          accessPointId
          createdAt
          reason
        }
        parkingId
        occupancyDuration
        motoristId
        motorist {
          _id
          username
          firstName
          lastName
          isAnonymous
          address1
          address2
          zipcode
          city
          country
          phone
          lang
          gender
          createdAt
        }
        contractId
        contract {
          _id
          productId
          product {
            _id
            parkingId
            code
            name
            category
            startDate
            stopDate
            createdAt
            operatorId
            parameters
            rules
          }
          parkingId
          startDate
          stopDate
          isEnabled
          credentials {
            type
            provider
            value
            description
          }
          createdAt
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
