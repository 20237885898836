import React, { useState, useEffect, useMemo, useRef } from "react";
import { styled, keyframes } from "@mui/system";
import { useDispatch } from "react-redux";
import { useSelector } from "@xstate/react";
import Slider from "react-slick";
import { service } from "components/WizardNewSubscription/reducer";
// @mui icons
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// @mui materials
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput, { outlinedInputClasses } from "@mui/material/OutlinedInput";

// rsuite
import DateRangePicker from "rsuite/DateRangePicker";
import { subDays, addMonths, format, addYears, addWeeks, addDays } from "date-fns";

import Grid from "@mui/material/Grid";
import CheckIcon from "@mui/icons-material/Check";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";
import ProfileInfoCard from "assets/@ndres-ui/Cards/ProductInfoCard";
import useDictionaries from "assets/commonHooks/useDictionaries";
import { DateTime } from "luxon";
import { isToday } from "../../../../../assets/commonFunctions/isToday";

// local hooks

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowForwardIosIcon
      className={className}
      style={{ ...style, display: "block", color: "gray" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowBackIosIcon className={className} style={{ ...style, display: "block", color: "gray" }} onClick={onClick} />
  );
}

const blink = keyframes`
  0% { opacity: 0.3; }
  50% { opacity: 1; }
  100% { opacity: 0.3; }
`;

const BlinkedBox = styled(MDBox)({
  animation: `${blink} 2s linear infinite`,
});

const VerifiedBox = styled(MDBox)({
  color: "green",
  padding: "2px 5px",
});

const RefusedBox = styled(MDBox)({
  color: "#C10303",
  padding: "2px 5px",
});

const cssStauts = {
  refused: {
    color: "#C10303",
    border: "solid 1px #C10303",
  },
  verified: {
    color: "green",
    border: "solid 1px green",
  },
  checking: {
    color: "#068A9F",
    border: "solid 1px #068A9F",
  },
};

const CardInput = styled(OutlinedInput)(({ theme, status }) => ({
  width: "100%",
  margin: "5px 0px",
  border: "solid 1px #B0B0B0",
  padding: "0px",
  ...cssStauts[status],
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: "solid 1px #B0B0B0",
    ...cssStauts[status],
  },

  [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
    border: "solid 1px #B0B0B0",
    ...cssStauts[status],
  },

  [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    border: "solid 1px #B0B0B0",
    ...cssStauts[status],
  },
}));

const useProducts = (parkingId) => {
  const products = useSelector(service, ({ context }) => context.products);

  const memoProducts = useMemo(
    () =>
      products.filter((product) => product.parkingId === parkingId && product.category.includes("PERIOD_SUBSCRIPTION")),
    [products, parkingId],
  );

  return memoProducts;
};

const { allowedRange, beforeToday, combine } = DateRangePicker;

export default function Step1({ addCompleted, setData, formData, addNextBt }) {
  const ref = useRef(null);
  const text = useDictionaries();
  const products = useProducts(formData.parkingId);
  const foundMotorist = useSelector(service, ({ context }) => context.motorist);
  const externalsID = useSelector(service, ({ context }) => context.externalId);
  const MotoristExist = useSelector(service, ({ context }) => context.found);

  const memoExternalsID = useMemo(() => externalsID, [externalsID]);
  const memoMotorist = useMemo(() => foundMotorist, [foundMotorist]);
  const memoMotoristExist = useMemo(() => MotoristExist, [MotoristExist]);

  const dispatch = useDispatch();

  useEffect(() => {
    const testing = [
      formData.productIndex >= 0 && formData.productIndex < products.length,
      formData.productData !== undefined,
      formData.verificationCardStatus === "verified",
      formData?.rangeDate && formData?.rangeDate[0] !== undefined,
      formData?.rangeDate && formData?.rangeDate[1] !== undefined,
      formData?.rangeDate && formData?.rangeDate[0] !== "",
      formData?.rangeDate && formData?.rangeDate[1] !== "",
    ];

    const result = testing.reduce((c, o) => c && o, true);
    addNextBt(0, result);
  }, [formData]);

  useEffect(() => {
    addCompleted(0, true);
  }, []);

  const [externalId, setExternalId] = useState(formData.externalId || "");
  const [externalOK, setExternalOK] = useState(formData.externalOK || false);

  const changeCardNumber = (e) => {
    setExternalId(e.target.value);
    setExternalOK(false);
  };

  const selectProduct = (i) => {
    setData({ productIndex: i, productData: products[i] });
    setExternalId("");
  };

  useEffect(() => {
    if (externalId !== "" && externalOK === false) {
      const timer = setTimeout(() => {
        setData({
          externalId,
          verificationCardStatus: "checking",
          externalOK: true,
          user: {},
        });
        setExternalOK(true);
      }, 800);
      return () => clearTimeout(timer);
    }
  }, [externalId]);

  useEffect(() => {
    const credentialProvider = formData?.productData?.rules?.credentialsTypes?.types?.filter(
      (element) => element.type === "PROVIDER_EXTERNAL_ID",
    );

    if (externalOK === true) {
      dispatch({
        type: "Wizard:NewSubscription:control-motorist",
        payload: {
          provider: credentialProvider[0]?.provider ?? "TCSOSP",
          externalId,
        },
      });
    }
  }, [externalOK]);

  useEffect(() => {
    const credentialProvider = formData?.productData?.rules?.credentialsTypes?.types?.filter(
      (element) => element.type === "PROVIDER_EXTERNAL_ID",
    );
    if (externalOK === true && formData.verificationCardStatus === "checking") {
      const externalIdUser = memoMotorist;

      if (externalIdUser?.username) {
        setData({
          verificationCardStatus: "verified",
          exitUser: externalIdUser,
          user: { ...externalIdUser },
          credential: {
            value: externalId,
            provider: credentialProvider[0]?.provider,
            type: "PROVIDER_EXTERNAL_ID",
          },
        });
      } else if (memoMotoristExist) {
        setData({
          verificationCardStatus: "verified",
          exitUser: undefined,
          user: { ...externalIdUser },
          credential: {
            value: externalId,
            provider: credentialProvider[0]?.provider,
            type: "PROVIDER_EXTERNAL_ID",
          },
        });
      } else {
        setData({
          verificationCardStatus: "refused",
          exitUser: undefined,
          user: { ...formData.user },
          credential: {},
        });
      }
    }
  }, [memoExternalsID]);

  const settingsBrand = {
    dotsClass: "slick-dots slick-thumb",
    centerMode: true,
    dots: false,
    centerPadding: "10px",
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    speed: 400,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // afterChange: (current) => setProduct(current),
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          infinite: products.length > 3,
        },
      },
      {
        breakpoint: 1424,
        settings: {
          slidesToShow: 3,
          infinite: products.length > 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: products.length > 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          infinite: products.length > 1,
        },
      },
    ],
  };

  const [valueStartDate, setValueStartDate] = React.useState([]);

  const setRangeDate = (date, durationValue, durationUnit) => {
    if (durationUnit === "YEAR") {
      return new Date(subDays(addYears(new Date(date), durationValue), 1));
    }
    if (durationUnit === "MONTH") {
      return new Date(subDays(addMonths(new Date(date), durationValue), 1));
    }
    if (durationUnit === "WEEK") {
      return new Date(subDays(addWeeks(new Date(date), durationValue), 1));
    }
    if (durationUnit === "DAY") {
      return new Date(subDays(addDays(new Date(date), durationValue), 1));
    }
  };

  return (
    <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
      <MDBox pl={2}>
        <MDTypography fontSize="1.5rem" fontWeight="regular" color="dark">
          {text("Wizard-first-step-select-product")}
        </MDTypography>
        <MDTypography fontSize="1rem" fontWeight="light" color="text">
          {text("Wizard-first-step-select-product-description")}
        </MDTypography>
      </MDBox>

      {/* Carousell Cards */}
      <MDBox
        style={{
          transition: "opacity ease 1s",
          ...(formData.productIndex === undefined
            ? { opacity: 1 }
            : { opacity: 0, position: "absolute", top: "-100vh" }),
        }}
      >
        <MDBox my={3} pl={1} width="100%">
          <Slider {...settingsBrand}>
            {products.map(({ name, parameters, provider, rules, _id, category, ...rest }, i) => {
              return (
                <div key={`${i + 1}${name}`}>
                  <MDBox height="370px" my={1} py={1} mx={1} px={1}>
                    <ProfileInfoCard
                      title={name}
                      productId={_id}
                      description=""
                      iconLogo={rules?.credentialsTypes?.types[0]?.provider}
                      currency={parameters.currency}
                      price={Math.round(parameters.cents / 100)}
                      type={parameters.durationUnit}
                      selected={i === formData.productIndex}
                      onClick={() => selectProduct(i)}
                      action={{ route: "", tooltip: "Edit Profile" }}
                    />
                  </MDBox>
                </div>
              );
            })}
          </Slider>
        </MDBox>
      </MDBox>

      {/* Product verificator */}
      <MDBox
        pt={2}
        style={{
          transition: "opacity ease 1s",
          ...(formData.productIndex !== undefined
            ? { opacity: 1 }
            : { opacity: 0, position: "absolute", top: "-100vh" }),
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <MDBox width="100%" height="380px" my={1} py={2} mx={1} marginRight="5px" pl={1}>
              {products.length > 0 ? (
                <ProfileInfoCard
                  productId={formData?.productData?._id}
                  iconLogo={
                    formData?.productData?.rules?.credentialsTypes?.types?.filter(
                      (element) => element.type === "PROVIDER_EXTERNAL_ID",
                    )[0].provider
                  }
                  description=""
                  currency={products[formData.productIndex ?? 0].parameters.currency}
                  price={products[formData.productIndex ?? 0].parameters.cents / 100}
                  type={products[formData.productIndex ?? 0].parameters.durationUnit}
                  selected
                  onClick={() => true}
                  action={{ route: "", tooltip: "Edit Profile" }}
                />
              ) : null}
            </MDBox>
          </Grid>
          <Grid item xs={8}>
            <MDBox mt={2} pt={1} ml={5} style={{ maxWidth: "450px" }}>
              <MDTypography fontSize="1rem" fontWeight="regular" color="dark">
                {text("Wizard-first-step-date-picker-label")}
              </MDTypography>
              <MDBox width="100%" ref={ref} style={{ maxWidth: "350px" }} ml="0px" mb="10px">
                {formData?.productData ? (
                  <DateRangePicker
                    className="select_large"
                    cleanable={true}
                    editable={false}
                    value={formData.rangeDate ?? valueStartDate}
                    onChange={(date) => {
                      if (date) {
                        const [startRange, endRange] = date;

                        const editedStartDate = isToday(startRange)
                          ? startRange
                          : new Date(DateTime.fromISO(startRange.toISOString()).startOf("day"));

                        const editedEndDate = new Date(DateTime.fromISO(endRange.toISOString()).endOf("day"));

                        setData({ rangeDate: [editedStartDate, editedEndDate] });
                      }
                    }}
                    onClean={() => {
                      setData({ rangeDate: [] });
                    }}
                    onEnter={() => setData({ rangeDate: [] })}
                    placeholder={text("Wizard-pool-start-date-picker-placeholder")}
                    shouldDisableDate={combine(
                      allowedRange(
                        format(new Date(formData?.productData?.startDate), "yyyy-MM-dd"),
                        format(subDays(new Date(formData.productData?.stopDate), 1), "yyyy-MM-dd"),
                      ),
                      beforeToday(),
                    )}
                    ranges={[]}
                    oneTap
                    // showOneCalendar
                    hoverRange={(date) => {
                      return [
                        date,
                        setRangeDate(
                          date,
                          formData?.productData?.parameters.durationValue,
                          formData?.productData?.parameters.durationUnit,
                        ),
                      ];
                    }}
                    style={{ width: "100%" }}
                  />
                ) : null}
              </MDBox>
              <MDBox>
                <MDTypography fontSize="1rem" fontWeight="regular" color="dark">
                  {text("Wizard-first-step-enter-motorist-card")}{" "}
                  {text(
                    `global-providers-${products[
                      formData.productIndex ?? 0
                    ]?.rules?.credentialsTypes?.types[0]?.provider?.toLowerCase()}`,
                  )}{" "}
                  {text("Wizard-first-step-card-number")}
                </MDTypography>
                <MDTypography fontSize="1rem" fontWeight="light" color="text">
                  {text("Wizard-first-step-verify-card")}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" alignItems="center" justifyContent="around">
                <FormControl sx={{ mt: 0, width: "100%", maxWidth: "350px" }} variant="outlined">
                  <CardInput
                    value={externalId}
                    onChange={changeCardNumber}
                    status={formData.verificationCardStatus}
                    endAdornment={
                      <InputAdornment position="end">
                        {formData.verificationCardStatus === "checking" ? (
                          <BlinkedBox>{formData.verificationCardStatus}</BlinkedBox>
                        ) : null}
                        {formData.verificationCardStatus === "verified" ? <VerifiedBox>Verified</VerifiedBox> : null}
                        {formData.verificationCardStatus === "refused" ? <RefusedBox>Refused</RefusedBox> : null}
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => true}
                          onMouseDown={() => true}
                          edge="end"
                        >
                          {formData.verificationCardStatus === "unverified" ? null : formData.verificationCardStatus ===
                            "checking" ? (
                            <CircularProgress
                              style={{
                                color: "GrayText",
                                height: "20px",
                                width: "20px",
                              }}
                            />
                          ) : formData.verificationCardStatus === "verified" ? (
                            <CheckIcon style={{ marginRight: "10px" }} />
                          ) : (
                            <ErrorOutlineIcon style={{ marginRight: "10px" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </MDBox>

              <MDBox pl={1}>
                {formData.verificationCardStatus === "verified" && formData.exitUser !== undefined ? (
                  <MDBox>
                    <MDTypography fontSize="1rem">
                      {text("Wizard-first-step-card-linked")}
                      &nbsp;
                      <span style={{ fontWeight: "bold" }}>{formData.exitUser.username}</span>
                    </MDTypography>
                    <br />
                    <MDTypography fontSize="1rem">{text("Wizard-first-step-card-validated")}</MDTypography>
                  </MDBox>
                ) : null}
                {formData.verificationCardStatus === "verified" && formData.exitUser === undefined ? (
                  <MDBox>
                    <MDTypography fontSize="1rem">{text("Wizard-first-step-card-not-linked")}</MDTypography>
                    <br />
                    <MDTypography fontSize="1rem">{text("Wizard-first-step-card-validated")}</MDTypography>
                  </MDBox>
                ) : null}
                {formData.verificationCardStatus === "refused" && formData.exitUser === undefined ? (
                  <MDBox>
                    <MDTypography fontSize="1rem">
                      {`${text("Wizard-first-step-card-refused-by")} ${text(
                        `global-providers-${formData?.productData?.rules?.credentialsTypes?.types
                          ?.find((element) => element.type === "PROVIDER_EXTERNAL_ID")
                          .provider.toLowerCase()}`,
                      )}`}
                    </MDTypography>
                  </MDBox>
                ) : null}
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <style jsx="true">
        {`
          .rs-picker-menu {
            position: absolute;
            text-align: left;
            z-index: 1300;
            border-radius: 6px;
            background-color: #fff;
            background-color: var(--rs-bg-overlay);
            -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            -webkit-box-shadow: var(--rs-shadow-overlay);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: var(--rs-shadow-overlay);
            overflow: hidden;
            -webkit-transition: none;
            transition: none;
            /* stylelint-disable-next-line */
          }
        `}
      </style>
    </MDBox>
  );
}
