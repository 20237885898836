export const ENV = process.env.REACT_APP_ENV || "local";
console.log("ENV", ENV);

export const BFF_HOST =
  // "http://10.0.10.144:3103/graphql";
  // process.env.REACT_APP_BFF_HOST || "https://bff-operators.cleverapps.io/graphql";
  process.env.REACT_APP_HOST_BFF_OSP_OPERATORS
    ? `${process.env.REACT_APP_HOST_BFF_OSP_OPERATORS}/graphql`
    : "http://localhost:3103/graphql";
export const BFF_MOTORIST_HTTP = process.env.REACT_APP_HOST_BFF_OSP_MOTORISTS || "http://localhost:3101";

export const BFF_WS_HOST = process.env.REACT_APP_HOST_BFF_EVENTS || "http://localhost:3333";
// export const BFF_WS_HOST = process.env.REACT_APP_BFF_WS_HOST || "wss://bff-events.cleverapps.io";

export const BFF_TOKEN =
  process.env.REACT_APP_BFF_TOKEN ||
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBLZXkiOiIxIiwiYXBwTmFtZSI6ImJmZi1vcGVyYXRvciIsImFwcFZlcnNpb24iOiJvcGVyYXRvci12MSIsImlhdCI6MTY0NDgzMTE4MH0.CpD_7IdXEJA4wZbTiFC8FI_PSW4o3poGMAHFx29Rwyc";

export const BFF_MOTORIST_TOKEN =
  process.env.REACT_APP_BFF_MOTORIST_TOKEN ||
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBLZXkiOiIxIiwiYXBwTmFtZSI6ImZyb250LW1vdG9yaXN0cyIsImFwcFZlcnNpb24iOiJ2MSIsImlhdCI6MTY1NDg2Mzg4NX0.kb7b4DPauPBQL4x2sllAQTa7iCJvkyDyLwj0YikFAwk";

// export const GRID_ACTIVE = ENV === "development";
export const GRID_ACTIVE = false;
