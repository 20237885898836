import React, { useContext, useMemo } from "react";
import { format } from "date-fns";
import useDictionaries from "assets/commonHooks/useDictionaries";
import Paginat from "assets/@luc-ui/Pagination";
import Table from "assets/@luc-ui/Table";
import { LocalContext } from "../../context";

const TransactionsTable = () => {
  const text = useDictionaries();
  const { list, page, totalPages, onSelectPage } = useContext(LocalContext);

  const listFormated = useMemo(
    () =>
      list.map(({ _id, type, psp, amount, currency, status, pspTransactionDate }) => ({
        options: {
          error: null,
          id: _id,
        },
        elements: [type, psp, amount, currency, status, format(new Date(pspTransactionDate), "dd.MM.yyyy HH:mm:ss")],
      })),
    [list, text],
  );

  return (
    <>
      <div
        style={{
          height: "100%",
          paddingLeft: "1rem",
          position: "relative",
        }}
      >
        <Table
          headers={[
            {
              title: "Type",
              size: "small",
            },
            {
              title: "PSP",
              size: "small",
            },
            {
              title: "Amount",
              size: "small",
            },
            {
              title: "Currency",
              size: "small",
            },
            {
              title: "Status",
              size: "small",
            },
            {
              title: "Date",
              size: "large",
            },
          ]}
          rows={listFormated}
          onClick={() => {}}
          sessionId={null}
        />
        <Paginat page={page} totalPages={totalPages} onSelectPage={onSelectPage} />
      </div>
    </>
  );
};

export default TransactionsTable;
