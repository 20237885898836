import { gql } from "@apollo/client";

export default gql`
  query parkings($page: Int!) {
    parkings(page: $page) {
      list {
        _id
        code
      }
    }
  }
`;
