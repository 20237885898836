import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Popper from "@mui/material/Popper";
// web.cjs is required for IE11 support
import { useSpring, animated } from "react-spring";
import { useDispatch } from "react-redux";
import { useSelector } from "@xstate/react";

// Material ui
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { withStyles } from "@mui/styles";

// Material icons
import LanguageIcon from "@mui/icons-material/Language";
import colors from "assets/@ndres-ui/theme/base/colors";
import boxShadows from "assets/@ndres-ui/theme/base/boxShadows";
import MDButton from "assets/@ndres-ui/MDButton";
import { service } from "components/I18n/reducer";

const { primary } = colors;

const ListItem = withStyles({
  root: {
    borderRadius: "5px",
    transition: "background-color 0.5s ease-out, color 0.5s ease-out",
    color: "grey",
    "&:hover": {
      backgroundColor: primary.light,
      color: "black",
      "& .MuiListItemIcon-root": {
        color: "black",
      },
    },
  },
  selected: {},
})(ListItemButton);

const { colored } = boxShadows;
const styleListItemP = { fontSize: "0.8rem", paddingLeft: "5px" };

const languageListMap = {
  en_EN: "EN",
  fr_FR: "FR",
  it_IT: "IT",
  de_DE: "DE",
};

const languageMapToCode = {
  EN: "en_EN",
  FR: "fr_FR",
  IT: "it_IT",
  DE: "de_DE",
};

function NestedList({ onSelectLanguage, languageList = [] }) {
  const selectLanguage = (language) => {
    onSelectLanguage(languageMapToCode[language]);
  };

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        borderRadius: "5px",
        margin: "0px 0px",
        boxShadow: colored.dark,
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {[...languageList].map((language) => (
        <ListItem key={language} onClick={() => selectLanguage(language)}>
          <p style={styleListItemP}>{language}</p>
        </ListItem>
      ))}
    </List>
  );
}

NestedList.propTypes = {
  onSelectLanguage: PropTypes.func.isRequired,
  languageList: PropTypes.array,
};
NestedList.defaultProps = {
  languageList: [],
};

const Fade = React.forwardRef((props, ref) => {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
};

export default function SpringPopper() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const selectedLang = useSelector(service, ({ context }) => context.selectedLang);

  const langs = useSelector(service, ({ context }) => context.langs);

  const languageList = useMemo(
    () => langs.filter((language) => language !== selectedLang).map((language) => languageListMap[language]),
    [langs, selectedLang],
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleEnter = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleLeave = (event) => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleSelectLanguage = (language) => {
    setAnchorEl(null);
    setOpen(false);
    dispatch({ type: "I18n:change", payload: { language } });
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "spring-popper" : undefined;

  return (
    <div onMouseLeave={handleLeave}>
      <MDButton
        aria-describedby={id}
        type="button"
        variant="contained"
        onClick={handleClick}
        onMouseEnter={handleEnter}
        style={{
          color: "white",
          textTransform: "none",
          fontSize: "0.8rem",
          padding: "8px 13px 2px 8px",
          margin: "2px",
          background: "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "60px",
        }}
      >
        <LanguageIcon style={{ width: "22px", height: "22px" }} />
        {languageListMap[selectedLang] ?? "EN"}
      </MDButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom"
        style={{
          position: "relative",
          zIndex: "100",
          width: "65px",
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <NestedList onSelectLanguage={handleSelectLanguage} languageList={languageList} />
          </Fade>
        )}
      </Popper>
    </div>
  );
}
