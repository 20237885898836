/* eslint-disable implicit-arrow-linebreak */
import React, { useContext, useEffect, useMemo, useState } from "react";
import useDictionaries from "assets/commonHooks/useDictionaries";
import { LocalContext } from "../../../context";
import MDTypography from "assets/@ndres-ui/MDTypography";
import MDButton from "assets/@ndres-ui/MDButton";
import InputHidable from "assets/@luc-ui/InputHidable";
import { DateTime, Duration, Interval } from "luxon";
import { DatePicker, SelectPicker } from "rsuite";
import { subDays, format, addDays, formatDistance, intervalToDuration } from "date-fns";
import DateRangePicker from "rsuite/DateRangePicker";
import Popup from "assets/@redapuenta-ui/popup/popup";
import { service } from "components/AccessPointDetails/reducer";
import { useSelector } from "@xstate/react";
import { useDispatch } from "react-redux";

const AccessPointAboutTable = () => {
  const text = useDictionaries();
  const dispatch = useDispatch();

  const { allowedRange, beforeToday, combine } = DateRangePicker;

  const { data, onUpdateAccessPoint } = useContext(LocalContext);

  const [isEditable, setEditable] = useState(false);
  const [newData, setNewData] = useState(undefined);
  const [modePopup, setModePopup] = useState("choice");
  const [openPopup, setOpenPopup] = useState(false);
  const [authorizeSubmit, setAuthorizeSubmit] = useState(false);
  const [distance, setDistance] = useState("");

  const [titlePopup, setTitlePopup] = useState();
  const [textPopup, setTextPopup] = useState();

  //! Selector for Popup
  const updateBreakdown = useSelector(service, ({ context }) => context.updateAccessEvent);
  const errorUpdateBreakdown = useSelector(service, ({ context }) => context.errorUpdateAccessEvent);

  useEffect(() => {
    if (newData?.startDate && newData?.endDate && new Date(newData?.startDate) >= new Date(newData?.endDate)) {
      setNewData({ ...newData, endDate: null });
    }

    const dataToTest = [newData?.startDate, newData?.endDate, newData?.type];

    const resultOfTest =
      dataToTest.reduce((acc, value) => {
        if (value) acc = acc + 1;
        return acc;
      }, 0) === dataToTest.length;

    setAuthorizeSubmit(!resultOfTest);
  }, [newData]);

  //! SUCCESS for Popup
  useEffect(() => {
    if (updateBreakdown?._id && updateBreakdown?._id === newData?._id) {
      setEditable(false);
      setTextPopup({ type: "success", value: text("popup-report-technical-details-update-success") });
      setModePopup("result");
    }
  }, [updateBreakdown]);

  //! ERROR for Popup
  useEffect(() => {
    if (errorUpdateBreakdown) {
      setTitlePopup({
        type: "basic",
        value: text(`global-error-title-${new String(errorUpdateBreakdown).toLowerCase()}`),
      });
      setTextPopup({
        type: "error",
        value: text(`global-error-text-${new String(errorUpdateBreakdown).toLowerCase()}`),
      });
      setModePopup("result");
    }
  }, [errorUpdateBreakdown]);

  //! Open Popup
  const submitClick = (e) => {
    setTitlePopup({
      type: "basic",
      value: `${text("widgets-report-technical-details-modal-first-p")} ${text(
        "widgets-report-technical-details-modal-second-p",
      )}`,
    });
    setTextPopup(undefined);
    setModePopup("choice");
    e.stopPropagation();
    setOpenPopup(true);
  };

  //! Exit Popup
  const popupExit = () => {
    setOpenPopup(false);
    setModePopup("choice");
  };

  //! Choice in Popup
  const popupChoice = (choice) => {
    if (choice) {
      dispatch({
        type: "AccessPointDetails:reset-error",
        payload: {},
      });

      setModePopup("load");
      onUpdateAccessPoint(newData);
    } else {
      setModePopup("choice");
      setOpenPopup(false);
    }
  };

  const fDate = (data) => {
    return data < 10 ? `0${data}` : data;
  };

  useEffect(() => {
    if (data) setNewData(data.accessPointDetails);

    setDistance(
      setDistance({
        text: "...",
        isActive: false,
      }),
    );

    let timer = null;
    const startDate = data?.accessPointDetails?.startDate;
    const endDate = data?.accessPointDetails?.endDate;

    const stateDynamic = () => {
      if (new Date() < new Date(startDate)) {
        setDistance({
          text: text("widgets-report-technical-state-planned"),
          color: "#f48c06",
          isActive: true,
        });
      } else if (new Date() < new Date(endDate)) {
        const start = DateTime.now();
        const end = DateTime.fromISO(endDate);
        const diff = end.diff(start, ["days", "hours", "minutes", "seconds"]);
        const { days, hours, minutes, seconds } = diff.toObject();

        let trad = text("widgets-report-technical-state-counter")
          .replace("[DAYS]", days)
          .replace("[HOURS]", fDate(hours))
          .replace("[MINUTES]", fDate(minutes))
          .replace("[SECONDS]", fDate(Math.round(seconds)));

        setDistance({
          text: `${trad}`,
          color: "red",
          isActive: true,
        });
      } else {
        setDistance({
          text: text("widgets-report-technical-state-finished"),
          color: "#008000",
          isActive: false,
        });
        clearInterval(timer);
      }
    };

    if (startDate && endDate) {
      stateDynamic();

      timer = setInterval(() => {
        stateDynamic();
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [data]);

  return (
    <>
      {newData ? (
        <section style={{ paddingBottom: "50px" }} className="section_details">
          <MDTypography className="global_xs_title" type="tableHeader">
            {text("widgets-report-technical-details-table-header-breakdown-infos")}
          </MDTypography>

          <div className="global_separa"></div>
          <div>
            <div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableHeader" textTransform="first">
                    {text("widgets-report-technical-details-table-breakdown-startdate")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  {/* <DatePicker
                       format="yyyy-MM-dd HH:mm:ss"
                       value={newData?.startDate ? new Date(newData?.startDate) : null}
                       ranges={[
                         {
                           label: "Now",
                           value: new Date(),
                         },
                       ]}
                       onOk={(field) => {
                         setNewData({
                           ...newData,
                           startDate: new Date(field).toISOString(),
                         });
                       }}
                       onClean={() => {
                         setNewData({ ...newData, startDate: undefined });
                       }}
                       style={{ width: "90%" }}
                       shouldDisableDate={allowedRange(
                         format(newData?.startDate ? new Date(newData.startDate) : new Date(), "yyyy-MM-dd HH:mm:ss"),
                         format(subDays(new Date("2099-12-31"), 1), "yyyy-MM-dd"),
                       )}
                     /> */}
                  <MDTypography type="tableData" textTransform="first">
                    {DateTime.fromISO(newData.startDate).toFormat("dd.MM.yyyy HH:mm:ss")}
                  </MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableHeader" textTransform="first">
                    {text("widgets-report-technical-details-table-breakdown-enddate")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  {isEditable ? (
                    <DatePicker
                      format="yyyy-MM-dd HH:mm"
                      editable={false}
                      cleanable={true}
                      value={newData?.endDate ? new Date(newData.endDate) : null}
                      ranges={[
                        {
                          label: "Now",
                          value: new Date(),
                        },
                      ]}
                      onClean={() => {
                        setNewData({ ...newData, endDate: undefined });
                      }}
                      onChange={(field) => {
                        setNewData({ ...newData, endDate: new Date(field).toISOString() });
                      }}
                      style={{ width: "90%", overflow: "visible" }}
                      shouldDisableDate={allowedRange(
                        DateTime.now().plus({ hours: 0 }).toISO(),
                        DateTime.now().plus({ hours: 72 }).toISO(),
                      )}
                      shouldDisableHour={(hour, date) => {
                        if (
                          new Date(date).getDate() === new Date().getDate() &&
                          new Date(date).getMonth() === new Date().getMonth() &&
                          new Date(date).getFullYear() === new Date().getFullYear()
                        ) {
                          return hour < new Date().getHours();
                        } else if (
                          new Date(date).getDay() === new Date(DateTime.now().plus({ days: 3 }).toISO()).getDay()
                        ) {
                          return hour > new Date().getHours();
                        } else {
                          return false;
                        }
                      }}
                      shouldDisableMinute={(minute, date) => {
                        if (
                          new Date(date).getDate() === new Date().getDate() &&
                          new Date(date).getMonth() === new Date().getMonth() &&
                          new Date(date).getFullYear() === new Date().getFullYear()
                        ) {
                          if (new Date(date).getHours() === new Date().getHours()) {
                            return minute < new Date().getMinutes() + 1;
                          } else {
                            return false;
                          }
                        } else if (
                          new Date(date).getDay() === new Date(DateTime.now().plus({ days: 3 }).toISO()).getDay()
                        ) {
                          return minute > new Date().getMinutes() + 1;
                        } else {
                          return false;
                        }
                      }}
                    />
                  ) : (
                    <MDTypography type="tableData" textTransform="first">
                      {DateTime.fromISO(newData.endDate).toFormat("dd.MM.yyyy HH:mm:ss")}
                    </MDTypography>
                  )}
                </div>
              </div>

              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-report-technical-details-table-breakdown-category")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  {isEditable ? (
                    <SelectPicker
                      onChange={(value) => {
                        setNewData({ ...newData, category: value });
                      }}
                      value={newData?.category ? newData.category : ""}
                      searchable={false}
                      placeholder={text("global-placeholder-default")}
                      style={{ width: "90%" }}
                      data={[
                        { label: text("Wizard-report-technical-first-step-select-category-c10"), value: "C10" },
                        { label: text("Wizard-report-technical-first-step-select-category-c20"), value: "C20" },
                        { label: text("Wizard-report-technical-first-step-select-category-c30"), value: "C30" },
                        { label: text("Wizard-report-technical-first-step-select-category-c40"), value: "C40" },
                      ]}
                    ></SelectPicker>
                  ) : (
                    <MDTypography type="tableData">
                      {text(
                        `Wizard-report-technical-first-step-select-category-${new String(
                          newData.category,
                        ).toLowerCase()}`,
                      )}
                    </MDTypography>
                  )}
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-report-technical-details-table-breakdown-type")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  {false ? (
                    <select
                      onChange={(e) => {
                        setNewData({ ...newData, type: e.target.value });
                      }}
                      value={newData.type}
                      style={{
                        borderRadius: "6px",
                        padding: "5px",
                        border: "1px solid #e5e5ea",
                        outline: "none",
                        width: "90%",
                        backgroundColor: "transparent",
                      }}
                    >
                      <option value={"EXIT"}>{text(`Wizard-report-technical-first-step-select-type-exit`)}</option>
                      <option value={"ENTRY"}>{text(`Wizard-report-technical-first-step-select-type-entry`)}</option>
                    </select>
                  ) : (
                    <MDTypography type="tableData">
                      {text(`Wizard-report-technical-first-step-select-type-${new String(newData.type).toLowerCase()}`)}
                    </MDTypography>
                  )}
                </div>
              </div>

              <div className="flexTableRowsDuo">
                <MDTypography className="global_label" type="tableData" textTransform="first">
                  {text("widgets-report-technical-details-table-breakdown-description")}
                </MDTypography>

                <div className="flexTableData">
                  {isEditable ? (
                    <textarea
                      style={{
                        borderRadius: "4px",
                        padding: "5px",
                        border: "1px solid #e5e5ea",
                        outline: "none",
                        width: "100%",
                        backgroundColor: "transparent",
                        resize: "none",
                      }}
                      onChange={(e) => {
                        setNewData({ ...newData, description: e.target.value });
                      }}
                      rows={5}
                      value={newData.description}
                    ></textarea>
                  ) : (
                    <MDTypography type="tableData">{newData.description}</MDTypography>
                  )}
                </div>
              </div>

              <div className="flexTableRowsDuo">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-report-technical-details-table-breakdown-state")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography style={{ color: distance?.color }} type="tableData">
                    {distance?.text}
                  </MDTypography>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: "20px",
            }}
          >
            {distance.isActive ? (
              !isEditable ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                  <MDButton color="primary" size="small" onClick={() => setEditable(true)}>
                    {text("wizard-button-edit")}
                  </MDButton>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", width: "100%" }}>
                  <MDButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      setEditable(false);
                      setNewData(data.accessPointDetails);
                    }}
                  >
                    {text("widgets-validator-cancel-button")}
                  </MDButton>
                  <MDButton
                    disabled={authorizeSubmit}
                    color="primary"
                    size="small"
                    onClick={(e) => {
                      submitClick(e);
                    }}
                  >
                    {text("widgets-validator-submit")}
                  </MDButton>
                </div>
              )
            ) : null}
          </div>
        </section>
      ) : (
        <div></div>
      )}
      {openPopup ? (
        <Popup
          titlePopup={titlePopup}
          textPopup={textPopup}
          modePopup={modePopup}
          handlePopupChoice={popupChoice}
          handlePopupExit={popupExit}
        />
      ) : null}
    </>
  );
};

export default AccessPointAboutTable;
