/* eslint-disable implicit-arrow-linebreak */
import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import useDictionaries from "assets/commonHooks/useDictionaries";
import MDTypography from "assets/@ndres-ui/MDTypography";

/* Denied Component */
const DeniedTable = (props) => {
  const { event } = props;
  const { reason } = event;
  const { createdAt, accessPointCode, state, credentialDisplay, credentialValue, credentialType, ...rest } = event;
  const credentialIcons = {
    PLATE: <DirectionsCarIcon fontSize="small" />,
    PROVIDER_EXTERNAL_ID: <CreditCardIcon fontSize="small" />,
    RFID: <CreditCardIcon fontSize="small" />,
    QRCODE: <QrCode2Icon fontSize="small" />,
  };
  const translationTag = `global-state-${state?.toLowerCase().replace("_", "-")}`;
  const text = useDictionaries();

  return (
    <div className="flexTableRowsThreeMin details_accor">
      <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }} className="flexTableData">
        <div style={{ marginRight: "10px" }}>
          {credentialType ? credentialIcons[credentialType] : <CreditCardIcon fontSize="small" />}
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}
        >
          <MDTypography
            style={{ lineHeight: 1, marginBottom: "3px" }}
            type="status"
            color="error"
            textTransform="first"
          >
            {text(`${translationTag}`.toLowerCase())}
          </MDTypography>
          <MDTypography
            style={{ lineHeight: 1, fontSize: "0.8em" }}
            fontWeight="bold"
            type="smallStatus"
            color="error"
            textTransform="first"
          >
            {reason ? text(`global-reason-${reason}`) : ""}
          </MDTypography>
          {credentialDisplay ? (
            <div style={{ marginTop: "3px", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
              <MDTypography
                style={{ marginRight: "5px", fontSize: "0.8em", lineHeight: 1 }}
                type="smallStatus"
                component="span"
                color="error"
                fontSize="0.75em"
              >
                {credentialDisplay ? text(`global-providers-${credentialDisplay.toLowerCase()}`) : " "}
              </MDTypography>

              <MDTypography
                style={{ lineHeight: 1, fontSize: "0.8em" }}
                textTransform={credentialType === "PLATE" ? "uppercase" : "none"}
                type="smallStatus"
                component="span"
                fontWeight="bold"
                color="error"
              >
                {credentialValue?.split(";")?.pop()}
              </MDTypography>
            </div>
          ) : null}
        </div>
      </div>
      <div className="flexTableData">
        <MDTypography type="tableData">{`${accessPointCode}`.toUpperCase()}</MDTypography>
      </div>
      <div className="flexTableData">
        <MDTypography type="tableData">{format(new Date(createdAt), "dd.MM.yyyy HH:mm:ss")}</MDTypography>
      </div>
    </div>
  );
};

DeniedTable.propTypes = {
  event: PropTypes.shape({
    state: PropTypes.string,
    credentialType: PropTypes.string,
    credentialValue: PropTypes.string,
    accessPointCode: PropTypes.string,
    createdAt: PropTypes.string,
    error: PropTypes.bool,
  }),
};
DeniedTable.defaultProps = {
  event: {},
};

/* Authorised Component */
const AuthorizedTable = (props) => {
  const { event } = props;
  const { createdAt, accessPointCode, state, credentialType, credentialDisplay, credentialValue } = event;
  const translationTag = `global-state-${state.toLowerCase().replace("_", "-")}`;

  const credentialIcons = {
    PROVIDER_EXTERNAL_ID: <CreditCardIcon fontSize="small" />,
    RFID: <CreditCardIcon fontSize="small" />,
    PLATE: <DirectionsCarIcon fontSize="small" />,
    QRCODE: <QrCode2Icon fontSize="small" />,
  };
  const text = useDictionaries();

  return (
    <div className="flexTableRowsThreeMin details_accor">
      <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }} className="flexTableData">
        <div style={{ marginRight: "10px" }}>{credentialIcons[credentialType]}</div>
        <div
          style={{
            marginTop: "3px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <MDTypography style={{ marginBottom: "5px", lineHeight: 1 }} id="meee" type="tableData" component="span">
            {text(`${translationTag}`.toLowerCase())}
          </MDTypography>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
            <MDTypography style={{ marginRight: "5px", lineHeight: 1 }} type="providerLight" component="span">
              {credentialDisplay ? text(`global-providers-${credentialDisplay?.toLowerCase()}`) : " "}
            </MDTypography>

            <MDTypography
              textTransform={credentialType === "PLATE" ? "uppercase" : "none"}
              fontSize="0.8em"
              fontWeight="bold"
              component="span"
              style={{ lineHeight: 1 }}
            >
              {credentialValue?.split(";")?.pop()}
            </MDTypography>
          </div>
        </div>
      </div>
      <div className="flexTableData">
        <MDTypography type="tableData">{`${accessPointCode}`.toUpperCase()}</MDTypography>
      </div>
      <div className="flexTableData">
        <MDTypography type="tableData">{format(new Date(createdAt), "dd.MM.yyyy HH:mm:ss")}</MDTypography>
      </div>
    </div>
  );
};

AuthorizedTable.propTypes = {
  event: PropTypes.shape({
    state: PropTypes.string,
    credentialType: PropTypes.string,
    credentialValue: PropTypes.string,
    accessPointCode: PropTypes.string,
    createdAt: PropTypes.string,
    error: PropTypes.bool,
  }),
};

AuthorizedTable.defaultProps = {
  event: {},
};
const AuthorizedPedestrianTable = (props) => {
  const { event } = props;
  const { createdAt, accessPointCode, state, credentialType, credentialDisplay, credentialValue } = event;
  const translationTag = `global-state-${state.toLowerCase().replace("_", "-")}`;

  const credentialIcons = {
    PROVIDER_EXTERNAL_ID: <CreditCardIcon fontSize="small" />,
    RFID: <CreditCardIcon fontSize="small" />,
    PLATE: <DirectionsCarIcon fontSize="small" />,
    QRCODE: <QrCode2Icon fontSize="small" />,
  };
  const text = useDictionaries();

  return (
    <div className="flexTableRowsThreeMin details_accor">
      <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }} className="flexTableData">
        <div style={{ marginRight: "10px" }}>
          <DirectionsWalkIcon fontSize="small" />
          {credentialIcons[credentialType]}
        </div>
        <div
          style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}
        >
          <MDTypography type="tableData" component="span">
            {text(`${translationTag}`.toLowerCase())}
          </MDTypography>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
            <MDTypography style={{ marginRight: "5px" }} type="providerLight" component="span">
              {credentialDisplay ? text(`global-providers-${credentialDisplay.toLowerCase()}`) : " "}
            </MDTypography>

            <MDTypography
              textTransform={credentialType === "PLATE" ? "uppercase" : "none"}
              fontSize="0.75em"
              fontWeight="bold"
              component="span"
            >
              {credentialValue?.split(";")?.pop()}
            </MDTypography>
          </div>
        </div>
      </div>
      <div className="flexTableData">
        <MDTypography type="tableData">{`${accessPointCode}`.toUpperCase()}</MDTypography>
      </div>
      <div className="flexTableData">
        <MDTypography type="tableData">{format(new Date(createdAt), "dd.MM.yyyy HH:mm:ss")}</MDTypography>
      </div>
    </div>
  );
};

AuthorizedPedestrianTable.propTypes = {
  event: PropTypes.shape({
    state: PropTypes.string,
    credentialType: PropTypes.string,
    credentialValue: PropTypes.string,
    accessPointCode: PropTypes.string,
    createdAt: PropTypes.string,
    error: PropTypes.bool,
  }),
};

AuthorizedPedestrianTable.defaultProps = {
  event: {},
};
/* Default Component */
const DefaultTable = (props) => {
  const { event } = props;
  const { createdAt, accessPointCode, state } = event;
  const translationTag = `global-state-${state.toLowerCase().replace("_", "-")}`;

  const text = useDictionaries();

  return (
    <div className="flexTableRowsThreeMin details_accor">
      <div className="flexTableData">
        <MDTypography type="tableData" textTransform="first">
          {text(translationTag)}
        </MDTypography>
      </div>
      <div className="flexTableData">
        <MDTypography type="tableData">{`${accessPointCode}`.toUpperCase()}</MDTypography>
      </div>
      <div className="flexTableData">
        <MDTypography type="tableData" textTransform="first">
          {format(new Date(createdAt), "dd.MM.yyyy HH:mm:ss")}
        </MDTypography>
      </div>
    </div>
  );
};

DefaultTable.propTypes = {
  event: PropTypes.shape({
    state: PropTypes.string,
    credentialType: PropTypes.string,
    credentialValue: PropTypes.string,
    accessPointCode: PropTypes.string,
    createdAt: PropTypes.string,
    error: PropTypes.bool,
  }),
};

DefaultTable.defaultProps = {
  event: {},
};

const SessionTypeSelector = ({ type, ...rest }) => {
  const Types = {
    ENTRY_DENIED: <DeniedTable {...rest} />,
    EXIT_DENIED: <DeniedTable {...rest} />,
    ENTRY_AUTHORIZED: <AuthorizedTable {...rest} />,
    AUTHORIZE_PEDESTRIAN: <AuthorizedPedestrianTable {...rest} />,
    EXIT_AUTHORIZED: <AuthorizedTable {...rest} />,
    ENTERED: <DefaultTable {...rest} />,
    EXITED: <DefaultTable {...rest} />,
  };

  return Types[type] ?? <DefaultTable {...rest} />;
};

SessionTypeSelector.defaultProps = {
  type: "",
};
SessionTypeSelector.propTypes = {
  type: PropTypes.string,
};

export default SessionTypeSelector;
