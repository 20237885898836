import { gql } from "@apollo/client";

export default gql`
  query sessions($parkingsIds: [String], $page: Int, $order: String, $limit: Int) {
    sessions(parkingsIds: $parkingsIds, page: $page, order: $order, limit: $limit) {
      list {
        _id
        isInconsistent
        entryDate
        exitDate
        state
        events {
          state
          credentials {
            type
            provider
            value
            description
          }
          accessPointId
          createdAt
          reason
        }
        parking {
          _id
          accessPoints {
            _id
            shortCode
          }
        }
        parkingId
        occupancyDuration
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
