import React, { useState } from "react";

import NavigationTabs from "components/MotoristDetails/NavigationTabs";
import BusinessTenantsChildDetails from "./Child";
import BusinessTenantsParentDetails from "./Parent";

import useDictionaries from "assets/commonHooks/useDictionaries";

import { ContextProvider } from "./context";

const BusinessTenantsDetails = (props) => {
  // Handle to show Sessions tab if we are from a click on an incident

  const onSelectMotoristPage = (newPage) => ({ newPage });
  const onSelectSessionPage = (sessionPage) => ({ sessionPage });

  const [tap, setTab] = useState(0);
  const text = useDictionaries();

  const onContractStatusChange = (payload) => payload;
  const onContractUpdate = (payload) => payload;
  const onContractPoolUpdate = (payload) => payload;

  const displayAbout = tap !== 0 ? "none" : "block";
  const displayContract = tap !== 1 ? "none" : "block";
  const displaySessions = tap !== 2 ? "none" : "block";

  return (
    <div
      style={{
        height: "100%",
        position: "relative",
        overflow: "hidden",
        padding: "0",
      }}
    >
      <div style={{ position: "fixed", width: "100%", backgroundColor: "white" }}>
        <NavigationTabs
          tap={tap}
          onChange={(tab) => setTab(tab)}
          ml={2}
          mr={2}
          optionsTabs={[
            text("widgets-business-tenants-details-navigation-about"),
            text("widgets-business-tenants-details-navigation-motorists"),
            text("widgets-business-tenants-details-navigation-sessions"),
          ]}
        />
      </div>
      <div style={{ paddingTop: "5em", paddingLeft: "0.8em" }}>
        <ContextProvider
          storeName="BusinessTenantsDetails"
          events={{
            onContractStatusChange,
            onSelectMotoristPage,
            onContractUpdate,
            onContractPoolUpdate,
            onSelectSessionPage,
          }}
        >
          <BusinessTenantsParentDetails
            displayAbout={displayAbout}
            displayContract={displayContract}
            displaySessions={displaySessions}
          />
          <BusinessTenantsChildDetails
            displayAbout={displayAbout}
            displayContract={displayContract}
            displaySessions={displaySessions}
          />
        </ContextProvider>
      </div>
    </div>
  );
};

export default BusinessTenantsDetails;
