/* eslint-disable react/destructuring-assignment */
import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import MDTypography from "assets/@ndres-ui/MDTypography";
import colors from "assets/@ndres-ui/theme/base/colors";
import useDictionaries from "assets/commonHooks/useDictionaries";

const { disabled, success, white } = colors;

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      transition: "all ease 1.5s",
      backgroundImage: "linear-gradient( 95deg,rgb(104 187 69) 0%,rgb(36 133 36) 50%,rgb(56 138 35) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    transition: "all ease 1.5s",
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient( 95deg,rgb(104 187 69) 0%,rgb(36 133 36) 50%,rgb(56 138 35) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    transition: "all ease 1.5s",
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  transition: "all 0.5s",
  ...(!ownerState.active && {
    transition: "all 0.5s",
    background: "white",
    border: `solid 2px ${disabled.light}`,
    color: disabled.light,
    boxShadow: "none",
    cursor: "not-allowed",
  }),
  ...(ownerState.completed && {
    border: `solid 2px ${success.focus}`,
    transition: "all 0.5s",
    background: white.main,
    boxShadow: `0 2px 12px -4px ${success.dark}80`,
    color: success.focus,
    cursor: "not-allowed", // cursor: "pointer",
  }),
  ...(ownerState.active && {
    transition: "all 0.5s",
    border: `solid 2px ${success.main}`,
    background: success.main,
    boxShadow: `0 2px 12px -2px ${success.focus}`,
    color: white.main,
    cursor: "not-allowed",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <Inventory2Icon style={{ width: "1.4rem", height: "1.4rem" }} />,
    2: <AssignmentIcon style={{ width: "1.4rem", height: "1.4rem" }} />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const selectStyle = {
  fontSize: "0.85rem",
  transition: "all ease 1.5s",
};

const unselectStyle = {
  fontSize: "0.85rem",
  transition: "all ease 1.5s",
};

export default function CustomizedSteppers({ active, setActive, steps, completed }) {
  const text = useDictionaries();
  return (
    <Stepper
      alternativeLabel
      activeStep={active}
      connector={<ColorlibConnector />}
      style={{
        background: "transparent",
        boxShadow: "none",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      {steps.map((label, i) => (
        <Step
          key={label}
          completed={completed[i]}
          // onClick={() => (completed[i] ? setActive(i) : true)}
        >
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            <MDTypography
              style={i === active ? selectStyle : unselectStyle}
              color={i === active ? "success" : completed[i] ? "text" : "disabled"}
              fontWeight="light"
            >
              {text(label)}
            </MDTypography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
