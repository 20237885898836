import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "@xstate/react";

import { service } from "./reducer";
import MDBox from "assets/@ndres-ui/MDBox";

import logoOSP from "assets/images/icons/logo_osp.png";
import logoOSPSvg from "assets/images/icons/logo_osp.svg";
import logoTCS from "assets/images/icons/logo_tcs.png";
import logoLokwerk from "assets/images/icons/lokwerk.png";
import parkingLogo from "assets/images/icons/parking.svg";
import logoParkingLuzern from "assets/images/icons/parkingLuzern.png";
import { ParentParkingCards } from "./materials/ParentParkingCards";
import { ParkingCards } from "./materials/ParkingCards";

const LeftBarTopology = () => {
  const dispatch = useDispatch();
  const imageSelector = {
    default: logoParkingLuzern,
  };
  const selectedParkings = useSelector(service, ({ context }) => context.selectedParkings);
  const parkings = useSelector(service, ({ context }) => context.parkings);
  const operator = useSelector(service, ({ context }) => context.operator);

  const parkingsIds = useSelector(service, ({ context }) => context.parkingsIds);

  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    dispatch({
      type: "hierarchy:initialize",
    });
  }, []);

  useEffect(() => {
    // Dispatch only if there is some parkings into hierarchy
    if (selectedParkings.length) {
      dispatch({
        type: "global:selected-parkings",
        payload: { selectedParkings },
      });
    }
  }, [selectedParkings]);

  const openMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOpenPopoverId(id);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenPopoverId(null);
  };

  const handleClick = (evt) => {
    dispatch({
      type: "hierarchy:select-item",
      payload: { selectedParkings: evt },
    });
  };

  const showWizardNewSubscription = (parkingId) => {
    dispatch({
      type: "Wizard:NewSubscription:wakeup",
      payload: { selectedParking: parkingId },
    });
  };
  const showWizardNewLocalMotorist = (parkingId) => {
    dispatch({
      type: "WizardCreateLocalMotorist:wakeup",
      payload: { selectedParking: parkingId },
    });
  };
  const showWizardNewPool = (parkingId) => {
    dispatch({
      type: "WizardNewPool:wakeup",
      payload: { selectedParking: parkingId },
    });
  };

  const showWizardNewReportTechnical = (parkingId) => {
    dispatch({
      type: "WizardCreateReportTechnical:wakeup",
      payload: { selectedParking: parkingId },
    });
  };

  const sections = {
    "New subscription": showWizardNewSubscription,
    "New Pool": showWizardNewPool,
    "New Create Report": showWizardNewReportTechnical,
    "New Local Motorist": showWizardNewLocalMotorist,
  };
  return (
    <MDBox width="100%" display="flex">
      <MDBox
        width="8vw"
        bgColor="transparent"
        height="100%"
        position="fixed"
        maxWidth="260px"
        minWidth="200px"
        zIndex="1"
      >
        <MDBox width="8vw" height="calc(100vh - 88px)" position="relative" maxWidth="260px" minWidth="200px">
          <MDBox
            width="100%"
            color="black"
            position="absolute"
            zIndex="99"
            top="58px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            borderRadius="0px 0px 50px 0px"
            mx={2}
          >
            <MDBox
              height="25px"
              width="180px"
              position="relative"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              fontSize="17px"
              left="-20px"
              style={{ cursor: "pointer", marginTop: "2em" }}
            >
              <img
                src={operator?.display?.logoSecondary ? operator?.display?.logoSecondary : imageSelector["default"]}
                alt="container logo"
                style={{
                  width: "50%",
                  height: "25px",
                  marginBottom: "1em",
                  alignSelf: "left",
                }}
              />
            </MDBox>
            <MDBox
              height="40px"
              width="190px"
              position="relative"
              left="-20px"
              top="5px"
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              fontSize="17px"
              style={{ cursor: "pointer" }}
            >
              {/* Operator Logo */}
              <img
                src={operator?.display?.logoMain ? operator?.display?.logoMain : imageSelector["default"]}
                alt="container logo"
                style={{ width: "100%", height: "50px" }}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>

      <MDBox
        width="8vw"
        bgColor="transparent"
        position="fixed"
        maxWidth="200px"
        minWidth="200px"
        zIndex="3"
        top="190px"
        onMouseLeave={() => handlePopoverClose()}
        px={0}
      >
        {/* <Tree /> */}
        <ParentParkingCards
          handlePopoverClose={handlePopoverClose}
          handleClick={handleClick}
          openMenu={openMenu}
          label={"hierarchy-header-all-parkings"}
          parkingsIds={parkingsIds}
          selectedParkings={selectedParkings}
          openPopoverId={openPopoverId}
          anchorEl={anchorEl}
        />
        {parkings?.length ? (
          parkings?.map((parking, index) => {
            return (
              <ParkingCards
                handlePopoverClose={handlePopoverClose}
                setSelection={(section) => {
                  return sections[section](parking?._id);
                }}
                key={parking._id}
                handleClick={handleClick}
                openMenu={openMenu}
                parking={parking}
                selectedParkings={selectedParkings}
                openPopoverId={openPopoverId}
                anchorEl={anchorEl}
              />
            );
          })
        ) : (
          <></>
        )}
      </MDBox>
    </MDBox>
  );
};

export default LeftBarTopology;
