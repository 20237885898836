import React from "react";
import MDBox from "../../../../../assets/@ndres-ui/MDBox";
import MDTypography from "../../../../../assets/@ndres-ui/MDTypography";

const Qrcode = () => {
  return (
    <MDBox mt={2} pt={1} ml={2} style={{ maxWidth: "450px" }}>
      <MDTypography fontSize="0.7em" fontStyle="italic">
        Example :
        <br />
        john@mycompanya.ch;john;doe
        <br />
        emily@mycompany.ch;emily;adams
      </MDTypography>
      <br />
      <MDTypography fontSize="0.7em" fontStyle="italic">
        <br />
      </MDTypography>
    </MDBox>
  );
};

export default Qrcode;
