import React from "react";
import ValidationForm from "./materials/TokenValidation";
import { ContextProvider } from "./context";

const submitValidation = (data) => ({ data });
const MotoristValidation = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ContextProvider
        storeName="MotoristValidation"
        events={{
          submitValidation,
        }}
      >
        <ValidationForm />
      </ContextProvider>
    </div>
  );
};

export default MotoristValidation;
