import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "@xstate/react";
import { service } from "../../../reducer";
import { service as serviceHierarchy } from "components/I18n/reducer";

// @mui materials
import MenuItem from "@mui/material/MenuItem";

import Grid from "@mui/material/Grid";
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";

import MDInput from "assets/@ndres-ui/MDInput";
import { styled } from "@mui/system";
import { DatePicker } from "rsuite";
// local hooks

import DateRangePicker from "rsuite/DateRangePicker";
import { subDays, format, addDays, addYears } from "date-fns";
import { isToday } from "../../../../../assets/commonFunctions/isToday";
import { DateTime } from "luxon";

// local hooks
const { allowedRange, beforeToday, combine } = DateRangePicker;
const Input = styled(MDInput)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: "100%",
  margin: "0px 0px",
  marginTop: "5px",
  padding: "0px",
}));
const Select = styled(({ ...rest }) => (
  <Input
    {...rest}
    InputProps={{
      style: {
        height: "39px",
        // padding: '12px',
        width: "100%",
      },
    }}
  />
))(({ theme }) => ({
  width: "100%",
  padding: "2px 2px",
  margin: "8px 0px 0px 0px",
}));

export default function Step1({ addCompleted, setData, formData, addNextBt }) {
  const ref = useRef(null);
  const text = useDictionaries();
  const motoristInputs = useSelector(service, ({ context }) => context.motoristInputs);

  const selectedLang = useSelector(serviceHierarchy, ({ context }) => context.selectedLang);
  const selectedLangMemo = useMemo(() => selectedLang, [selectedLang]);

  useEffect(() => {
    const testing = [
      !!motoristInputs?.startDate && motoristInputs?.startDate !== "",
      motoristInputs.contractName !== "",
      !motoristInputs.error,
    ];

    const result = testing.reduce((c, o) => c && o, true);

    addNextBt(0, result);
  }, [motoristInputs]);

  useEffect(() => {
    service.send({
      type: "motorist:change",
      payload: { lang: motoristInputs.lang ? motoristInputs.lang : selectedLangMemo },
    });
    addCompleted(0, true);
  }, []);

  return (
    <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
      <MDBox
        pt={0}
        style={{
          transition: "opacity ease 1s",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MDBox ml={2} mt={2} pl={2}>
              <MDTypography fontSize="1.5rem" fontWeight="regular" color="dark">
                {text("wizard-sub-contract-first-step-title")}
              </MDTypography>
              <MDTypography fontSize="1rem" fontWeight="light" color="text">
                {text("wizard-sub-contract-first-step-sub-title")}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={5}>
            <MDBox mt={0} pt={1} ml={5} style={{ maxWidth: "450px" }}>
              <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="dark" mb={0}>
                {text("wizard-sub-contract-first-step-start-date-picker-label")}
              </MDTypography>
              <MDBox width="100%" ref={ref} style={{ maxWidth: "350px" }} ml="0px" mb="15px" mt="5px">
                <DatePicker
                  className="select_large"
                  editable={false}
                  cleanable={true}
                  oneTap
                  value={
                    motoristInputs?.startDate &&
                    new Date(motoristInputs?.startDate) instanceof Date &&
                    !isNaN(new Date(motoristInputs?.startDate))
                      ? new Date(motoristInputs.startDate)
                      : null
                  }
                  onClean={() => {
                    service.send({
                      type: "motorist:change",
                      payload: {
                        startDate: null,
                        stopDate: null,
                      },
                    });
                  }}
                  format="yyyy-MM-dd"
                  onChange={(date) => {
                    if (date) {
                      service.send({
                        type: "motorist:change",
                        payload: {
                          startDate: isToday(date)
                            ? new Date().toISOString()
                            : new Date(DateTime.fromISO(date.toISOString()).startOf("day")).toISOString(),
                          stopDate: null,
                        },
                      });
                    }
                  }}
                  placeholder={text("Wizard-pool-start-date-picker-placeholder")}
                  style={{ width: "246px" }}
                  shouldDisableDate={combine(
                    allowedRange(format(new Date(), "yyyy-MM-dd"), format(addYears(new Date(), 99), "yyyy-MM-dd")),
                    beforeToday(),
                  )}
                />
              </MDBox>
              <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="dark" mt={0}>
                {text("wizard-sub-contract-first-step-end-date-picker-label")}
              </MDTypography>
              <MDBox width="100%" ref={ref} style={{ maxWidth: "350px" }} ml="0px" mb="10px" mt="5px">
                <DatePicker
                  disabled={!motoristInputs?.startDate}
                  editable={false}
                  cleanable={true}
                  oneTap
                  value={
                    motoristInputs?.stopDate &&
                    new Date(motoristInputs?.stopDate) instanceof Date &&
                    !isNaN(new Date(motoristInputs?.stopDate))
                      ? new Date(motoristInputs.stopDate)
                      : null
                  }
                  className="select_large"
                  format="yyyy-MM-dd"
                  onChange={(date) => {
                    if (date) {
                      service.send({
                        type: "motorist:change",
                        payload: {
                          stopDate: date ? new Date(DateTime.fromISO(date.toISOString()).endOf("day")) : null,
                        },
                      });
                    }
                  }}
                  onClean={() => {
                    service.send({
                      type: "motorist:change",
                      payload: {
                        stopDate: null,
                      },
                    });
                  }}
                  placeholder={text("Wizard-pool-end-date-picker-placeholder")}
                  style={{ width: "246px" }}
                  shouldDisableDate={combine(
                    allowedRange(
                      format(
                        motoristInputs?.startDate ? addDays(new Date(motoristInputs?.startDate), 1) : new Date(),
                        "yyyy-MM-dd",
                      ),
                      format(subDays(new Date("2099-12-31"), 1), "yyyy-MM-dd"),
                    ),
                    beforeToday(),
                  )}
                />
              </MDBox>
            </MDBox>
          </Grid>

          <Grid item xs={6}>
            <MDBox mt={1}>
              <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="dark" mb={0}>
                {text("wizard-sub-contract-first-step-contract-name-label")}
              </MDTypography>
              <Input
                required
                className="select_large"
                value={motoristInputs.contractName ?? ""}
                autoComplete="off"
                type="contractName"
                id="contractName"
                onChange={(e) =>
                  service.send({
                    type: "motorist:change",
                    payload: { contractName: e.target.value },
                  })
                }
              />
            </MDBox>

            <MDBox mt={2}>
              <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="dark" mb={0}>
                {text("wizard-sub-contract-first-step-contract-allocated-label")}
              </MDTypography>
              <Input
                required
                className="select_large"
                value={motoristInputs.newContractSpacesAllocated ?? 2}
                autoComplete="off"
                type="number"
                min={"0"}
                InputProps={{ inputProps: { min: 0 } }}
                id="spaceAllocated"
                onChange={(e) =>
                  service.send({
                    type: "motorist:change",
                    payload: { newContractSpacesAllocated: parseInt(e.target.value) },
                  })
                }
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <style jsx="true">
        {`
          .rs-picker-default .rs-picker-toggle.rs-btn-lg {
            padding-top: 0.4rem;
            padding-bottom: 0.2rem;
            height: 2.2rem;
          }

          .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
            top: 0.4rem;
          }

          .rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
            padding-top: 0.2rem;
          }

          .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
            top: 0.5rem;
          }

          .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
            top: 0.4rem;
          }

          .rs-picker-menu {
            position: absolute;
            text-align: left;
            z-index: 1300;
            border-radius: 6px;
            background-color: #fff;
            background-color: var(--rs-bg-overlay);
            -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            -webkit-box-shadow: var(--rs-shadow-overlay);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: var(--rs-shadow-overlay);
            overflow: hidden;
            -webkit-transition: none;
            transition: none;
            color: #ababab;
            /* stylelint-disable-next-line */
          }

          .rs-picker-toggle-textbox {
            border: 1px solid #d2d6da;
          }

          .rs-picker-toggle-active {
            box-shadow: 0 0 0 3px transparent;
          }

          .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
          .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
          .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
            border-color: #8e0205;
            border: 2px solid #8e0205;
          }

          .rs-picker-toggle-value {
            color: #ababab;
          }
        `}
      </style>
    </MDBox>
  );
}
