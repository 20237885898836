import React, { useEffect } from "react";
import { useSelector } from "@xstate/react";
import { service } from "../../../reducer";

import Switch from "@mui/material/Switch";

import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";

const Android12Switch = styled(({ label, ...rest }) => (
  <MDBox pt="12px" m="0px" width="100%" display="flex" alignItems="center" justifyContent="flex-start" height="100%">
    <Switch {...rest} />
    <MDTypography fontSize="0.9rem" fontWeight="light" pb="4px">
      {label}
    </MDTypography>
  </MDBox>
))(({ theme }) => ({
  padding: 10,
  width: "auto",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 5,
    transitionDuration: "300ms",
    "& .Mui-checked": {
      transform: "translateX(36px)",
      color: "#fff",
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        width: 25,
        height: 25,
        margin: 0,
        top: "-15px",
      },
      "& .Mui-disabled .MuiSwitch-track": {
        opacity: 0.5,
      },
      "& .MuiSwitch-track": {
        backgroundColor: "#4caf50!important",
        borderColor: "#4caf50!important",
      },
    },
    "& .Mui-focusVisible .MuiSwitch-thumb": {
      border: "6px solid #fff",
    },
    "& .Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "& .Mui-disabled .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    width: "40px",
    height: "15px",
    backgroundColor: theme.palette.grey[400],
    transition: theme.transitions.create(["background-color"], {
      duration: 300,
    }),
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "15%",
      transform: "translateY(-40%)",
      width: 32,
      height: 32,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 25,
    height: 25,
    margin: 0,
    top: "-15px",
  },
  "& .Mui-checked+.MuiSwitch-track": {
    backgroundColor: "#4caf50!important",
    borderColor: "#4caf50!important",
  },
}));
export default function Step1({ addCompleted, setData, formData, addNextBt }) {
  const text = useDictionaries();
  const product = useSelector(service, ({ context }) => context.product);
  const motoristInputs = useSelector(service, ({ context }) => context.motoristInputs);

  useEffect(() => {
    const testing = [
      !!product,
      !!motoristInputs?.startDate && motoristInputs?.startDate !== "",
      motoristInputs.credentialProvider !== "",
      motoristInputs.credentialValue !== "",
      motoristInputs.success,
      !motoristInputs.verifing,
      !motoristInputs.error,
    ];

    const result = testing.reduce((c, o) => c && o, true);
    addNextBt(3, result);
  }, [formData, motoristInputs]);

  useEffect(() => {
    addCompleted(3, true);
  }, []);

  if (!product?.startDate) {
    return "loading...";
  }

  return (
    <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
      <MDBox pl={2}>
        <MDTypography fontSize="1.5rem" fontWeight="regular" color="dark">
          {text("Wizard-forth-step-activation")}
        </MDTypography>
        <MDTypography fontSize="1rem" fontWeight="light" color="text">
          {text("Wizard-forth-step-activation-description")}
        </MDTypography>
      </MDBox>
      <MDBox display="flex" alignItems="center" justifyContent="around" ml={2} mt={4}>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" height="100%" width="100%">
                  <Android12Switch
                    checked={motoristInputs.contractStatus ?? true}
                    label={text("Wizard-forth-step-toggle-activation")}
                    color="success"
                    onChange={(e) => {
                      service.send({
                        type: "motorist:change",
                        payload: { contractStatus: !motoristInputs.contractStatus },
                      });
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <MDBox height="5px" />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}
