import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "@xstate/react";
import { service } from "../reducer";
import { service as serviceUserProviderList } from "../../UserProviderList/reducer";

const defaultContextData = {
  data: {},
};

export const LocalContext = createContext(defaultContextData);

export const ContextProvider = ({ children, storeName, events = {} }) => {
  const dispatch = useDispatch();

  // Motorist we have to show
  const currentMotorist = useSelector(service, ({ context }) => context.currentMotorist);
  const selectedMotoristId = useSelector(serviceUserProviderList, ({ context }) => context.selectedMotoristId);

  useEffect(() => {}, [currentMotorist, selectedMotoristId]);

  useEffect(() => {
    if (!selectedMotoristId) return;
    dispatch({
      type: `${storeName}:show`,
      payload: { selectedMotoristId: selectedMotoristId },
    });
  }, [selectedMotoristId]);

  const eventDispatch = (key, fn, src) => {
    const payload = fn(src);
    dispatch({ type: `${storeName}:${key}`, payload });
  };

  const eventsActions = useMemo(() =>
    Object.entries(events).reduce(
      (acc, [key, fn]) => ({
        [key]: (src) => eventDispatch(key, fn, src),
        ...acc,
      }),
      {},
    ),
  );

  const renderAccessPoint = useMemo(() => {
    if (!currentMotorist) return;

    const {
      _id,
      firstName,
      lastName,
      email,
      address1,
      address2,
      city,
      phone,
      country,
      uids,
      plates,
      lang,
      status,
      externalId,
    } = currentMotorist;

    return {
      UserProviderDetails: {
        _id,
        firstName,
        lastName,
        email,
        address1,
        address2,
        city,
        phone,
        country,
        uids,
        plates,
        lang,
        status,
        externalId,
      },
    };
  }, [currentMotorist]);

  return (
    <LocalContext.Provider
      value={{
        data: renderAccessPoint,
        ...eventsActions,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

ContextProvider.defaultProps = {
  storeName: "UserProviderDetails",
  events: {},
};

ContextProvider.propTypes = {
  storeName: PropTypes.string,
  events: PropTypes.shape({}),
};
