import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import transactionsGql from "./transactions.gql";

const widgetModel = createModel({
  token: "",
  list: [],
  paging: {},
  selectedSession: undefined,
  selectedParkings: [],
});

const fetchList = async ({ token, selectedParkings }, { page }) => {
  const { data } = await client.query({
    query: transactionsGql,
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": token } },
    variables: {
      page,
      limit: 9,
      parkingsIds: selectedParkings,
    },
  });

  return data.transactions;
};

const transactions = createMachine(
  {
    context: widgetModel.initialContext,
    id: "transactions",
    initial: "off",
    states: {
      off: {
        on: {
          "global:selected-parkings": { actions: "save parkings" },
          "Transactions:wakeup": [
            { cond: "has selected parkings", target: "loading" },
            { cond: "has no parkings", target: "idle" },
          ],
        },
      },
      idle: {
        on: {
          "events:transactions:created": { target: "loading" },
          "events:transactions:updated": { target: "loading" },
          "Transactions:onSelectPage": { target: "changingPage" },
          "Transactions:sleep": { target: "off" },
          "global:selected-parkings": {
            actions: "save parkings",
            target: "loading",
          },
        },
      },
      loading: {
        entry: assign({
          token: () => localStorage.getItem("token"),
        }),
        invoke: {
          id: "getTransactions",
          src: (context, event) => fetchList(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      changingPage: {
        invoke: {
          id: "getNewPage",
          src: (context, { payload }) => fetchList(context, payload),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populate: assign({
        list: (_, { data }) => data.list,
        paging: (_, { data }) => data.paging,
      }),
      "save parkings": assign({
        selectedParkings: (ctx, { payload }) => payload.selectedParkings,
      }),
    },
    guards: {
      "has selected parkings": ({ selectedParkings }) => !!selectedParkings.length,
      "has no parkings": ({ selectedParkings }) => !selectedParkings.length,
    },
  },
);

export const service = interpret(transactions).start();

const initial = {
  state: transactions.initialState.list,
  context: transactions.initialState.context,
};

const widgetReducer = (state = initial, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
