import React, { useEffect, useRef } from "react";
import { useSelector } from "@xstate/react";

import { service } from "../../../reducer";
import { service as I18Nservice } from "../../../../I18n/reducer";

// @mui materials
import { styled } from "@mui/system";
import MDInput from "assets/@ndres-ui/MDInput";
import MenuItem from "@mui/material/MenuItem";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

import Grid from "@mui/material/Grid";
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";

import LocalProvider from "../../materials/csvExamples/LocalProvider";
import { SelectPicker } from "rsuite";

const Input = styled(MDInput)(({ theme }) => ({
  width: "calc(100% + 2px)",
  margin: "0px -5px",
  height: "2.2rem",
  borderColor: "#d2d6da",
  [`&:hover &:focused .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#d2d6da",
  },
}));

const SelectMUI = styled(({ value, ...rest }) => (
  <Input
    value={value ?? ""}
    InputProps={{
      style: {
        height: 44.125,
        // padding: '12px',
        width: "100%",
      },
    }}
    {...rest}
  />
))(({ theme }) => ({
  width: "100%",
  padding: "2px 2px",
  margin: "3px 0px 0px -3px",
  [`&:hover &:focused .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#d2d6da",
  },
}));

export default function Step1({ addCompleted, setData, formData, addNextBt }) {
  const text = useDictionaries();

  const csv = useSelector(service, ({ context }) => context.csv);
  const motorist = useSelector(service, ({ context }) => context.motorist);
  const selectedLang = useSelector(I18Nservice, ({ context }) => {
    return context.selectedLang;
  });

  const validMotoristList =
    Array.isArray(motorist) &&
    motorist.length > 0 &&
    !motorist.reduce((acc, { syntaxe }) => (syntaxe ? acc : acc + 1), 0);

  useEffect(() => {
    const testing = [validMotoristList, formData?.lang !== "none"];

    const result = testing.reduce((c, o) => c && o, true);
    addNextBt(0, result);
  }, [formData, validMotoristList, motorist, csv]);

  useEffect(() => {
    addCompleted(0, true);
  }, []);

  return (
    <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
      <MDBox
        pt={0}
        style={{
          transition: "opacity ease 1s",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <MDBox mt={2} pt={1} ml={4} style={{}}>
              <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="dark">
                {text("wizard-local-motorist-csv")}
              </MDTypography>

              <MDInput
                id="outlined-multiline-static"
                // label="Multiline"
                success={validMotoristList}
                error={!validMotoristList}
                multiline
                value={csv}
                rows={10}
                // defaultValue="Default Value"
                style={{ width: "calc(100% - 5px)" }}
                onChange={(e) => {
                  service.send({
                    type: "WizardCreateLocalMotorist:csv:change",
                    payload: { value: e.target.value, userProvider: "LOCAL" },
                  });
                  return setData({ value: e.target.value });
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={3} justifyContent="flex-end">
            <MDBox mt={2} pt={1} ml={2} style={{ maxWidth: "450px" }}>
              <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="dark">
                {text("Wizard-pool-second-step-motorist-form-label-lang")}
              </MDTypography>
              <SelectPicker
                className="select_large"
                value={formData?.lang ? formData.lang : selectedLang}
                onClean={() => {
                  setData({ lang: "undefined" });
                }}
                placeholder={text("global-placeholder-default")}
                searchable={false}
                style={{ width: "100%" }}
                onChange={(newValue) => setData({ lang: newValue })}
                data={[
                  { label: text("global-select-lang-en_EN"), value: "en_EN" },
                  { label: text("global-select-lang-fr_FR"), value: "fr_FR" },
                  { label: text("global-select-lang-de_DE"), value: "de_DE" },
                  { label: text("global-select-lang-it_IT"), value: "it_IT" },
                ]}
              ></SelectPicker>
            </MDBox>
            <LocalProvider />
          </Grid>
        </Grid>
      </MDBox>

      <style jsx="true">
        {`
          .rs-picker-default .rs-picker-toggle.rs-btn-lg {
            padding-top: 0.4rem;
            padding-bottom: 0.2rem;
            height: 2.2rem;
          }

          .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
            top: 0.4rem;
          }

          .rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
            padding-top: 0.2rem;
          }

          .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
            top: 0.5rem;
          }

          .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
            top: 0.4rem;
          }

          .rs-picker-menu {
            position: absolute;
            text-align: left;
            z-index: 1300;
            border-radius: 6px;
            background-color: #fff;
            background-color: var(--rs-bg-overlay);
            -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            -webkit-box-shadow: var(--rs-shadow-overlay);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: var(--rs-shadow-overlay);
            overflow: hidden;
            -webkit-transition: none;
            transition: none;
            color: #ababab;
            /* stylelint-disable-next-line */
          }

          .rs-picker-toggle-textbox {
            border: 1px solid #d2d6da;
          }

          .rs-picker-toggle-active {
            box-shadow: 0 0 0 3px transparent;
          }

          .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
          .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
          .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
            border-color: #8e0205;
            border: 2px solid #8e0205;
          }

          .rs-picker-toggle-value {
            color: #ababab;
          }
        `}
      </style>
    </MDBox>
  );
}
