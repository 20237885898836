import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import breakdownsGql from "./breakdowns.gql";

const AccessPointModel = createModel({
  token: "",
  list: [],
  paging: {},
  selectedParkings: [],
  selectEventAccessId: "",
});

const fetchList = async ({ token, selectedParkings }, { payload }) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: breakdownsGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          page: payload.newPage ? payload.newPage : 1,
          limit: 20,
          parkingsIds: selectedParkings,
        },
      })
      .then((response) => {
        resolve(response.data.breakdowns);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const AccessPointHistory = createMachine(
  {
    context: AccessPointModel.initialContext,
    id: "AccessPoint",
    initial: "off",
    states: {
      off: {
        on: {
          "AccessPointHistory:wakeup": [
            { cond: "has selected parkings", target: "loading" },
            { cond: "has no parkings", target: "idle" },
          ],
          "global:selected-parkings": { actions: "save parkings" },
        },
      },
      idle: {
        on: {
          "AccessPointHistory:onSelectPage": { target: "loading" },
          "events:breakdowns:created": { target: "loading" },
          "events:breakdowns:updated": { target: "loading" },
          onSelectEventAccess: { target: "loading" },
          "AccessPointHistory:onSelectEventAccess": { actions: "select access point" },
          "AccessPointHistory:sleep": { target: "off" },
          "global:selected-parkings": {
            actions: "save parkings",
            target: "loading",
          },
        },
      },
      loading: {
        entry: assign({
          token: () => localStorage.getItem("token"),
        }),
        invoke: {
          id: "getAccessPoint",
          src: (context, event) => fetchList(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ actions: "populateError", target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populate: assign({
        list: (_, { data }) => {
          return data.list;
        },
        paging: (_, { data }) => {
          return data.paging;
        },
      }),
      populateError: assign({
        list: (_, { data }) => null,
        paging: (_, { data }) => null,
      }),
      "save parkings": assign({
        selectedParkings: (ctx, { payload }) => {
          return payload.selectedParkings;
        },
      }),
      "select access point": assign({
        selectEventAccessId: (ctx, { payload }) => {
          return payload.selectEventAccessId;
        },
      }),
    },
    guards: {
      "has selected parkings": ({ selectedParkings }) => {
        return !!selectedParkings.length;
      },
      "has no parkings": ({ selectedParkings }) => {
        return !selectedParkings.length;
      },
    },
  },
);

export const service = interpret(AccessPointHistory).start();

const widgetReducer = (state, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
