import React, { useState, useEffect } from "react";

import Step1 from "./stepsComponents/Step1";
import Step2 from "./stepsComponents/Step2";
import Step3 from "./stepsComponents/Step3";
import Step4 from "./stepsComponents/Step4";

// local hooks
import NewSubscriptionDialogStepper from "./DialogStepper";
import useStateCallback from "assets/commonHooks/useStateCallback/useStateCallback";
import { useDispatch } from "react-redux";
import { useSelector as XSelector } from "@xstate/react";
import { service as serviceHierarchy } from "components/I18n/reducer";
const stepsObj = {
  "Wizard-steps-product-settings": Step1,
  "Wizard-steps-motorist": Step2,
  "Wizard-steps-contract": Step3,
  "Wizard-steps-activation": Step4,
};

const initForm = {
  verificationCardStatus: "unverified",
  verificationEmailStatus: "unverified",
  contract: {
    isEnabled: true,
    freeFlagIsEnabled: false,
  },
};

const StepperWizard = ({ dialog, setDialog, onSubmit, onClose, parkingId }) => {
  const selectedLang = XSelector(serviceHierarchy, ({ context }) => context.selectedLang);

  // const text = useDictionaries();
  const steps = [...Object.keys(stepsObj)];
  const [active, setActive] = useState(0);
  const [completed, setCompleted] = useState([true, false, false, false]);
  const [nextBt, setNextBt] = useState(steps.map(() => false));
  const [formData, setFormData] = useStateCallback({ ...initForm, parkingId });
  const dispatch = useDispatch();

  const Step = stepsObj[steps[active]];

  const reset = () => {
    setCompleted([true, false, false, false]);
    setActive(0);
    setFormData({ ...initForm, parkingId });
    dispatch({
      type: "Wizard:NewSubscription:create-contract-reset",
      payload: {},
    });
  };

  useEffect(() => {
    if (dialog === "New subscription") {
      reset();
    }
  }, [dialog]);

  const addCompleted = (index, newValue) => {
    const newCompleted = [...completed].map((currentValue, i) => (i === index ? newValue : currentValue));
    setCompleted(newCompleted);
  };

  const addNextBt = (index, newValue) => {
    const newNextBt = [...nextBt].map((currentValue, i) => (i === index ? newValue : currentValue));
    setNextBt(newNextBt);
  };

  const setData = (data) => {
    setFormData({ ...formData, ...data, parkingId });
  };

  const handleSubmit = () => {
    onSubmit("NewSubscription", {
      // onSubmit
      username: formData?.user?.username,
      firstName: formData?.user?.firstName ?? "",
      lastName: formData?.user?.lastName ?? "",
      address1: formData?.user?.address1 ?? "",
      address2: formData?.user?.address2 ?? "",
      zipcode: formData?.user?.zipcode ?? "",
      city: formData?.user?.city ?? "",
      country: formData?.user?.country ?? "",
      phone: formData?.user?.phone ?? "",
      lang: formData?.user?.lang ?? selectedLang,
      gender: formData?.user?.gender.length > 0 ? formData?.user?.gender : "UNKNOWN",
      contract: {
        productId: formData?.productData?._id,
        startDate: formData.rangeDate[0],
        isEnabled: !!formData?.contract?.isEnabled,
        freeFlagIsEnabled: !!formData?.contract?.freeFlagIsEnabled,
      },
      credentials: formData.credential,
    });
  };

  return (
    <NewSubscriptionDialogStepper
      dialog={dialog}
      setDialog={setDialog}
      steps={steps}
      active={active}
      setActive={setActive}
      completed={completed}
      nextBt={nextBt}
      setCompleted={setCompleted}
      onClose={onClose}
      reset={reset}
      onSubmit={handleSubmit}
    >
      <Step addCompleted={addCompleted} addNextBt={addNextBt} setData={setData} formData={formData} />
    </NewSubscriptionDialogStepper>
  );
};

export default StepperWizard;
