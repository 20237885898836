import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "@xstate/react";
import { service } from "../../../reducer";
import useDictionaries from "assets/commonHooks/useDictionaries";
import Paginat from "assets/@luc-ui/Pagination";
import { useDispatch } from "react-redux";
import { DatePicker, Toggle } from "rsuite";
import { DateTime } from "luxon";
import { Checkbox } from "@mui/material";
import { subDays, format, addDays, addYears, isToday, addMinutes } from "date-fns";

import DateRangePicker from "rsuite/DateRangePicker";

export default function Step2({ addCompleted, setData, formData, addNextBt }) {
  const { allowedRange, beforeToday, combine } = DateRangePicker;
  const text = useDictionaries();
  const dispatch = useDispatch();
  const poolContracts = useSelector(service, ({ context }) => context.poolContracts);
  const formContract = useSelector(service, ({ context }) => context.formContract);
  const [checkChoice, setCheckChoice] = useState(undefined);

  const [load, setLoad] = useState(true);

  const [datePickerPrevStartDate, setDatePickerPrevStartDate] = useState(new Date());
  const [datePickerPrevEndDate, setDatePickerPrevEndDate] = useState(new Date());

  const choiceContract = (linkedContractId, productId) => {
    setCheckChoice(linkedContractId);
    dispatch({
      type: "WizardCreateContractUserProvider:form:change",
      payload: { pool: { linkedContractId, productId } },
    });
  };

  useEffect(() => {
    service.send({
      type: "WizardCreateContractUserProvider:search:poolcontracts",
      payload: {},
    });
  }, []);

  useEffect(() => {
    let valid;
    formContract?.startDate && formContract?.pool ? (valid = true) : (valid = false);

    addNextBt(1, valid);
    addCompleted(1, valid);
  }, [formContract]);

  useEffect(() => {
    if (poolContracts) setLoad(false);
  }, [poolContracts]);

  return (
    <>
      {load ? (
        <div className="load">
          <span className="load__text">Loading</span>
        </div>
      ) : (
        <div className="wizard">
          <div className="wizard__form">
            <div style={{ width: "35%" }} className="wizard__form__item">
              <label htmlFor="startdate">{text("Wizard-user-provider-second-step-startdate-label")}</label>
              <DatePicker
                className="select_large"
                editable={false}
                cleanable={true}
                value={
                  formContract?.startDate &&
                  new Date(formContract?.startDate) instanceof Date &&
                  !isNaN(new Date(formContract?.startDate))
                    ? new Date(formContract.startDate)
                    : null
                }
                format="yyyy-MM-dd HH:mm"
                onChange={(date) => {
                  if (date) {
                    if (isToday(date)) {
                      service.send({
                        type: "WizardCreateContractUserProvider:form:change",
                        payload: {
                          startDate: new Date() < new Date(date) ? new Date(date) : new Date(),
                          stopDate: null,
                        },
                      });
                    } else {
                      service.send({
                        type: "WizardCreateContractUserProvider:form:change",
                        payload: {
                          startDate: new Date(date),
                          stopDate: null,
                        },
                      });
                    }
                  }
                }}
                onClean={() => {
                  service.send({
                    type: "WizardCreateContractUserProvider:form:change",
                    payload: {
                      startDate: null,
                      stopDate: null,
                    },
                  });
                }}
                placeholder={text("Wizard-pool-start-date-picker-placeholder")}
                style={{ width: "246px" }}
                onChangeCalendarDate={(date) => {
                  if (
                    new Date(date).getDate() !== new Date(datePickerPrevStartDate).getDate() ||
                    new Date(date).getMonth() !== new Date(datePickerPrevStartDate).getMonth() ||
                    new Date(date).getFullYear() !== new Date(datePickerPrevStartDate).getFullYear()
                  ) {
                    setDatePickerPrevStartDate(() => new Date(date));
                    if (isToday(date)) {
                      date.setHours(new Date().getHours(), new Date().getMinutes(), 0);
                    } else {
                      date.setHours(0, 0, 0);
                    }
                  }
                }}
                shouldDisableDate={combine(
                  allowedRange(
                    format(new Date(), "yyyy-MM-dd HH:mm"),
                    format(addYears(new Date(), 99), "yyyy-MM-dd HH:mm"),
                  ),
                  beforeToday(),
                )}
                shouldDisableHour={(hour, date) => {
                  if (isToday(date)) {
                    return parseInt(hour) >= DateTime.now().toObject().hour ? false : true;
                  } else {
                    return false;
                  }
                }}
                shouldDisableMinute={(minute, date) => {
                  if (isToday(date)) {
                    if (new Date().getHours() === new Date(date).getHours()) {
                      return parseInt(minute) > DateTime.now().toObject().minute ? false : true;
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                }}
              />
            </div>
            <div style={{ width: "35%" }} className="wizard__form__item">
              <label htmlFor="stopdate">{text("Wizard-user-provider-second-step-stopdate-label")}</label>
              <DatePicker
                disabled={!formContract?.startDate}
                className="select_large"
                editable={false}
                cleanable={true}
                value={
                  formContract?.stopDate &&
                  new Date(formContract?.stopDate) instanceof Date &&
                  !isNaN(new Date(formContract?.stopDate))
                    ? new Date(formContract.stopDate)
                    : null
                }
                format="yyyy-MM-dd HH:mm"
                onChange={(date) => {
                  if (date) {
                    if (new Date(date) <= new Date(formContract.startDate)) {
                      service.send({
                        type: "WizardCreateContractUserProvider:form:change",
                        payload: {
                          stopDate: addMinutes(new Date(formContract.startDate), 1),
                        },
                      });
                    } else {
                      service.send({
                        type: "WizardCreateContractUserProvider:form:change",
                        payload: {
                          stopDate: new Date(date),
                        },
                      });
                    }
                  }
                }}
                onClean={() => {
                  service.send({
                    type: "WizardCreateContractUserProvider:form:change",
                    payload: {
                      stopDate: null,
                    },
                  });
                }}
                placeholder={text("Wizard-pool-end-date-picker-placeholder")}
                style={{ width: "246px" }}
                shouldDisableDate={combine(
                  allowedRange(
                    format(
                      formContract?.startDate ? addMinutes(new Date(formContract?.startDate), 1) : new Date(),
                      "yyyy-MM-dd HH:mm",
                    ),
                    format(subDays(new Date("2099-12-31"), 1), "yyyy-MM-dd HH:mm"),
                  ),
                  beforeToday(),
                )}
                onChangeCalendarDate={(date) => {
                  if (
                    new Date(date).getDate() !== new Date(datePickerPrevEndDate).getDate() ||
                    new Date(date).getMonth() !== new Date(datePickerPrevEndDate).getMonth() ||
                    new Date(date).getFullYear() !== new Date(datePickerPrevEndDate).getFullYear()
                  ) {
                    setDatePickerPrevEndDate(() => new Date(date));
                    if (
                      new Date(date).getDate() == new Date(formContract?.startDate).getDate() &&
                      new Date(date).getMonth() == new Date(formContract?.startDate).getMonth() &&
                      new Date(date).getFullYear() == new Date(formContract?.startDate).getFullYear()
                    ) {
                      date.setHours(
                        new Date(formContract?.startDate).getHours(),
                        addMinutes(new Date(formContract?.startDate), 1).getMinutes(),
                        0,
                      );
                    } else {
                      date.setHours(23, 59, 0);
                    }
                  }
                }}
                shouldDisableHour={(hour, date) => {
                  if (
                    new Date(date).getDate() == new Date(formContract?.startDate).getDate() &&
                    new Date(date).getMonth() == new Date(formContract?.startDate).getMonth() &&
                    new Date(date).getFullYear() == new Date(formContract?.startDate).getFullYear()
                  ) {
                    return parseInt(hour) >= new Date(formContract?.startDate).getHours() ? false : true;
                  } else {
                    return false;
                  }
                }}
                shouldDisableMinute={(minute, date) => {
                  if (
                    new Date(date).getDate() == new Date(formContract?.startDate).getDate() &&
                    new Date(date).getMonth() == new Date(formContract?.startDate).getMonth() &&
                    new Date(date).getFullYear() == new Date(formContract?.startDate).getFullYear()
                  ) {
                    if (new Date(date).getHours() === new Date(formContract?.startDate).getHours()) {
                      return parseInt(minute) < new Date(formContract?.startDate).getMinutes() + 1;
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                }}
              />
              {/* <DatePicker
                disabled={!formContract?.startDate}
                className="select_large"
                id="stopdate"
                editable={false}
                cleanable={true}
                format="yyyy-MM-dd HH:mm"
                placeholder={text("Wizard-pool-end-date-picker-placeholder")}
                value={
                  formContract?.stopDate &&
                  new Date(formContract?.stopDate) instanceof Date &&
                  !isNaN(new Date(formContract?.stopDate))
                    ? new Date(formContract.stopDate)
                    : null
                }
                onChange={(date) => {
                  if (date) {
                    service.send({
                      type: "WizardCreateContractUserProvider:form:change",
                      payload: {
                        stopDate:
                          new Date(date) > new Date(formContract?.startDate)
                            ? new Date(date).toISOString()
                            : addDays(new Date(formContract.startDate), 1),
                      },
                    });
                  }
                }}
                onClean={() => {
                  service.send({
                    type: "WizardCreateContractUserProvider:form:change",
                    payload: {
                      stopDate: null,
                    },
                  });
                }}
                style={{ width: "100%" }}
                shouldDisableDate={combine(
                  allowedRange(
                    format(
                      formContract?.startDate &&
                        new Date(formContract?.startDate) instanceof Date &&
                        !isNaN(new Date(formContract?.startDate))
                        ? addDays(new Date(formContract?.startDate), 1)
                        : new Date(),
                      "yyyy-MM-dd HH:mm",
                    ),
                    format(subDays(new Date("2099-12-31"), 1), "yyyy-MM-dd HH:mm"),
                  ),
                  beforeToday(),
                )} */}
              {/* /> */}
            </div>
            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              className="wizard__form__item"
            >
              <label style={{ textAlign: "center" }} htmlFor="enddate">
                {text("Wizard-user-provider-second-step-flag-label")}
              </label>
              <Toggle
                onChange={(data) => {
                  service.send({
                    type: "WizardCreateContractUserProvider:form:change",
                    payload: {
                      freeFlagIsEnabled: data,
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className="wizard__contain">
            <div className="wizard__head">
              {/* <h3 className="wizard__subtitle">Il y a {poolContracts.list.length} contrats de pool</h3> */}
              <div className="wizard__line" style={{ padding: "5px 0" }}>
                <span className="wizard__legend">{text("Wizard-user-provider-second-step-contract-label")}</span>
                <span className="wizard__legend">{text("Wizard-user-provider-second-step-pool-label")}</span>
                <span className="wizard__legend">{text("Wizard-user-provider-second-step-parking-label")}</span>
                <span className="wizard__legend">{text("Wizard-user-provider-second-step-space-label")}</span>
                <span className="wizard__legend">{text("Wizard-user-provider-second-step-choice-label")}</span>
              </div>
            </div>
            <div className="wizard__list">
              {poolContracts.list.map(
                (
                  {
                    _id: linkedContractId,
                    ospContractId,
                    name1,
                    parking: { name: nameParking },
                    spacesAllocated,
                    spacesOccupied,
                    product: { _id: productId },
                  },
                  index,
                ) => {
                  return (
                    <div key={linkedContractId} className="wizard__line">
                      <span className="wizard__field">{ospContractId}</span>
                      <span className="wizard__field">{name1}</span>
                      <span className="wizard__field">{nameParking}</span>
                      <span className="wizard__field">
                        {spacesOccupied}/{spacesAllocated}
                      </span>
                      <div className="wizard__case">
                        <Checkbox
                          onChange={() => choiceContract(linkedContractId, productId)}
                          type="checkbox"
                          checked={checkChoice === linkedContractId}
                        />
                      </div>
                    </div>
                  );
                },
              )}
            </div>
            <Paginat
              style={{
                bottom: "0px",
                marginTop: "0px",
                backgroundColor: "white",
                borderTop: "1px solid #e5e5ea",
                padding: "5px 0",
                zIndex: 5,
              }}
              page={poolContracts.paging.current}
              totalPages={Math.ceil(parseInt(poolContracts.paging.count) / parseInt(poolContracts.paging.limit))}
              position={"static"}
              onSelectPage={(newPage) =>
                service.send({
                  type: "WizardCreateContractUserProvider:onSelectPage",
                  payload: {
                    newPage,
                  },
                })
              }
            />
          </div>
        </div>
      )}

      <style jsx="true">{`
        .load {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 40vh;
        }

        .load__text {
          font-size: 14px;
        }

        .wizard {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 50vh;
          width: 100%;
        }

        .wizard * {
          line-height: 1;
          font-size: 14px;
        }

        .wizard__form {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 85%;
        }

        .wizard__form__item {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          position: relative;
          z-index: 99999;
        }

        .wizard__form__item label {
          margin-bottom: 5px;
        }

        .wizard__contain {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          border-radius: 6px;
          border: 1px solid #e5e5ea;
          min-height: 400px;
          overflow-y: scroll;
          position: relative;
          width: 85%;
          box-sizing: border-box;
          margin: 20px;
        }

        .wizard__head {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0px 0px 5px 0px;
          position: sticky;
          top: 0px;
          z-index: 10;
          background-color: white;
          border-bottom: 1px solid #e5e5ea;
        }

        .wizard__subtitle {
          padding: 12px 0;
          font-size: 17px;
        }

        .wizard__list {
          width: 100%;
          height: 100%;
          border-top: 1px solid #e5e5ea;
          overflow-y: scroll;
        }

        .wizard__line {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          width: 100%;
          justify-items: center;
          border-bottom: 1px solid #e5e5ea;
          padding: 10px 0;
        }

        .wizard__line:last-of-type {
          border: none;
        }

        .wizard__legend {
          display: inline-block;
          text-transform: capitalize;
          color: #b4afaa;
        }

        .wizard__line {
          overflow: hidden;
        }

        .wizard__case {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .wizard__choice .wizard__case {
          margin-right: 15px;
        }

        .wizard label {
          margin-right: 4px;
        }

        @media screen and (max-width: 1100px) {
          .wizard {
            width: 95%;
            padding-right: 0px;
            padding-left: 0px;
          }

          .media-hidden {
            display: none;
          }

          .wizard * {
            font-size: 13px;
          }

          .wizard__subtitle {
            font-size: 14px;
          }

          .wizard__line {
            grid-template-columns: repeat(3, 1fr);
          }
        }

        .rs-picker-default .rs-picker-toggle.rs-btn-lg {
          margin-top: 0.25rem;
          padding-top: 0.7rem;
          padding-bottom: 0.35rem;
          height: 2.8rem;
        }

        .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
          top: 0.4rem;
        }

        .rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
          padding-top: 0.35rem;
        }

        .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
          top: 0.7rem;
        }

        .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
          top: 0.7rem;
        }

        .rs-picker-menu {
          position: absolute;
          text-align: left;
          z-index: 1300;
          border-radius: 6px;
          background-color: #fff;
          background-color: var(--rs-bg-overlay);
          -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
          -webkit-box-shadow: var(--rs-shadow-overlay);
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
          box-shadow: var(--rs-shadow-overlay);
          overflow: hidden;
          -webkit-transition: none;
          transition: none;
          color: #ababab;
          /* stylelint-disable-next-line */
        }

        .rs-picker-toggle-textbox {
          border: 1px solid #d2d6da;
        }

        .rs-picker-toggle-active {
          box-shadow: 0 0 0 3px transparent;
        }

        .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
        .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
        .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
        .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
        .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
        .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
          border: 2px solid black;
        }

        .rs-picker-toggle-value {
          color: #ababab;
        }
      `}</style>
    </>
  );
}
