import { io } from "socket.io-client";
import { BFF_WS_HOST } from "../config";
import { service } from "../components/Login/reducer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "@xstate/react";

let socket = null;

export const useSocket = () => {
  const dispatch = useDispatch();
  const connected = useSelector(service, ({ context }) => context.connected);

  useEffect(() => {
    console.log("SOCKET", connected, socket);
    if (connected && !socket) {
      socket = io(BFF_WS_HOST, {
        autoConnect: true,
        secure: false,
        reconnection: true,
        auth: (cb) => {
          const token = localStorage.getItem("token");
          return cb({ token });
        },
      });

      socket.io.on("open", () => {
        console.log("Connecting......");
      });

      socket.io.on("reconnect", (attempt) => {
        console.log("WS reconnecting...", attempt);
      });

      socket.onAny((event, payload) => {
        console.log("SOCKET EVENT", { event, payload });
        dispatch({ type: `events:${event}`, payload });
      });
    }

    return () => {
      socket.offAny();
      socket.disconnect();
    };
  }, [connected]);

  return socket;
};

export default socket;
