import { assign, createMachine, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../../../../apolloService";
import searchGql from "./search.gql";
import { useDispatch } from "react-redux";

const ctx = createModel({
  token: "",
  parkingIds: [],
  isOpenFilter: false,
  isLoading: false,
  inputCurrentValue: "",
  inputCurrentChoice: "",
  filterData: [],
});

async function resultForSearch({ token, inputCurrentValue, parkingIds }) {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: searchGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          parkingsIds: parkingIds,
          content: inputCurrentValue,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

const searchMachine = createMachine(
  {
    id: "search",
    initial: "idle",
    context: ctx.initialContext,
    states: {
      idle: {
        on: {
          "global:selected-parkings": {
            actions: ["setParking"],
          },
          INPUT_WRITE: {
            actions: "inputWrite",
          },
          FILTER_DATA_FILL: {
            target: "filterDataGet",
          },
          FILTER_DATA_CLEAR: {
            actions: "filterDataClear",
          },
          SEARCH_RESET: {
            actions: "searchReset",
          },
          FILTER_SELECT_CHOICE: {
            actions: "filterSelectChoice",
          },
          SEARCH_FOCUS: {
            actions: "searchFocus",
          },
        },
      },
      filterDataGet: {
        on: {
          INPUT_WRITE: {
            actions: "inputWrite",
          },
          FILTER_DATA_FILL: {
            target: "filterDataGet",
          },
          SEARCH_RESET: {
            actions: "searchReset",
          },
          FILTER_SELECT_CHOICE: {
            actions: "filterSelectChoice",
          },
        },
        entry: assign({
          token: () => localStorage.getItem("token"),
        }),
        invoke: {
          src: (context, event) => resultForSearch(context),
          onDone: { actions: ["filterChangeData"], target: "idle" },
          onError: { target: "idle" },
        },
      },
    },
  },
  {
    actions: {
      setParking: assign({
        parkingIds: (_, { payload }) => payload.selectedParkings,
      }),

      inputWrite: assign({
        isLoading: () => true,
        inputCurrentValue: (_, { value }) => new String(value).trim(),
        inputCurrentChoice: (_, { value }) => "",
        isOpenFilter: (_, { value }) => value.length >= 3,
      }),

      filterChangeData: assign({
        isLoading: () => false,
        filterData: (_, { data }) => data.globalSearch,
      }),

      filterDataClear: assign({
        filterData: () => [],
      }),

      searchReset: assign({
        ...ctx.initialContext,
      }),

      filterSelectChoice: assign({
        inputCurrentValue: (_, { choice }) => choice.labels[0],
        inputCurrentChoice: ({ filterData }, { choice }) => {
          const { _id: choiceID, type } = choice;
          const motorist = filterData[type].find(({ _id }) => _id === choiceID);
          return { ...motorist, type };
        },
        isOpenFilter: () => false,
      }),

      searchFocus: assign({
        isOpenFilter: ({ inputCurrentValue }, { state }) => state && inputCurrentValue.length >= 3,
      }),
    },
  },
);

export const service = interpret(searchMachine).start();

const initial = {
  state: searchMachine.initialState.list,
  context: searchMachine.initialState.context,
};

const widgetReducer = (state = initial, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
