import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import controllMotoristGql from "./controlMotorist.gql";
import existsMotoristGql from "./existsMotorist.gql";
import createContractGQL from "./createContract.gql";
import productsGql from "./products.gql";
import { client } from "../../apolloService";

const userModel = createModel({
  token: "",
  showWizard: false,
  selectedParking: undefined,
  products: [],
});

const fetchProducts = async ({ token, selectedParking }) => {
  const { data } = await client.query({
    query: productsGql,
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": token } },
    variables: {
      parkingsIds: [selectedParking],
    },
  });

  return data.products;
};

const controlMotorist = async ({ token }, { payload }) =>
  new Promise(async (resolve, reject) => {
    client
      .query({
        query: controllMotoristGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          externalId: payload.externalId,
          provider: payload.provider,
        },
      })
      .then((response) => {
        resolve({ ...response.data, externalId: payload.externalId });
      })
      .catch((e) => {
        reject(e);
      });
  });

const existsMotoristV2 = async ({ token }, { payload }) =>
  new Promise(async (resolve, reject) => {
    client
      .query({
        query: existsMotoristGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          username: payload.username,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

const createContract = async ({ token }, { payload }) =>
  new Promise(async (resolve, reject) => {
    client
      .mutate({
        mutation: createContractGQL,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          input: { ...payload },
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });

const Wizard = createMachine(
  {
    context: userModel.initialContext,
    id: "Wizard",
    initial: "off",
    states: {
      off: {
        on: {
          "Wizard:NewSubscription:wakeup": { target: "loading" },
        },
      },
      idle: {
        on: {
          "Wizard:NewSubscription:sleep": {
            actions: "hide wizard",
            target: "off",
          },
          "Wizard:NewSubscription:control-motorist": {
            target: "controlMotorist",
          },
          "Wizard:NewSubscription:exists-motorist": {
            target: "existsMotorist",
          },
          "Wizard:NewSubscription:create-contract": {
            target: "createContract",
          },
          "Wizard:NewSubscription:create-contract-reset": {
            actions: "createContractReset",
          },
        },
      },
      loading: {
        entry: ["save token", "save parking"],
        invoke: {
          id: "getWizardProducts",
          src: fetchProducts,
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      controlMotorist: {
        entry: "save token",
        on: {
          "Wizard:NewSubscription:control-motorist": {
            target: "controlMotorist",
          },
        },
        invoke: {
          id: "controlMotorist",
          src: controlMotorist,
          onDone: [{ actions: "populateControl", target: "idle" }],
          onError: [{ actions: "populateControlError", target: "idle" }],
        },
      },
      existsMotorist: {
        entry: ["save token", "reload exist"],
        on: {
          "Wizard:NewSubscription:exists-motorist": {
            target: "existsMotorist",
          },
        },
        invoke: {
          id: "existsMotorist",
          src: existsMotoristV2,
          onDone: [{ actions: "populateExists", target: "idle" }],
          onError: [{ actions: "populateExistsError", target: "idle" }],
        },
      },
      createContract: {
        entry: "save token",
        invoke: {
          id: "createContract",
          src: createContract,
          onDone: [{ actions: "populateCreateContract", target: "idle" }],
          onError: [{ actions: "populateCreateContractError", target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      "hide wizard": assign({
        showWizard: false,
      }),
      populate: assign({
        showWizard: true,
        products: (_, { data }) => data.list,
      }),
      populateControl: assign({
        found: (_, { data }) => data.controlMotorist.found,
        motorist: (_, { data }) => data.controlMotorist.motorist,
        externalId: (_, { data }) => data.externalId,
      }),
      populateControlError: assign({
        found: (_, { data }) => false,
        motorist: (_, { data }) => null,
        externalId: (_, { data }) => 0,
      }),
      populateExists: assign({
        existsMotorist: (_, { data }) => data.existsMotorist,
      }),
      populateExistsError: assign({
        existsMotorist: (_, { data }) => null,
      }),
      createContractReset: assign({
        existsMotorist: (_, { data }) => null,
        found: (_, { data }) => null,
        motorist: (_, { data }) => null,
        error: (_, { data }) => null,
        lastCreatedContracts: (_, { data }) => null,
        externalId: (_, { data }) => null,
      }),
      populateCreateContract: assign({
        error: (_, { data }) => false,
        state: () => "validated",
        errorMessage: (_, { data }) => "",
        lastCreatedContracts: (_, { data }) => data.createMotorist.motorist,
      }),
      populateCreateContractError: assign({
        error: (_, { data }) => true,
        errorMessage: (_, { data }) => {
          return data;
        },
      }),
      "save token": assign({
        token: () => localStorage.getItem("token"),
      }),
      "reload exist": assign({
        existsMotorist: (_, { data }) => null,
      }),
      "save parking": assign({
        selectedParking: (ctx, { payload }) => payload.selectedParking,
      }),
    },
  },
);

export const service = interpret(Wizard).start();

const initial = {
  state: Wizard.initialState.products,
  context: Wizard.initialState.context,
};

const widgetReducer = (state = initial, { type, payload }) => {
  const newState = service.send({ type, payload });

  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
