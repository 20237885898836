import React, { useEffect, useMemo } from "react";

// @mui materials
import FormControl from "@mui/material/FormControl";

import Grid from "@mui/material/Grid";
import MDTypography from "assets/@ndres-ui/MDTypography";
import ContractCard from "assets/@ndres-ui/Cards/ContractCard";
import MDBox from "assets/@ndres-ui/MDBox";
import useDictionaries from "assets/commonHooks/useDictionaries";

export default function Step2({ addCompleted, formData, addNextBt }) {
  const text = useDictionaries();
  useEffect(() => {
    const testing = [formData?.user?.username !== undefined, true];

    const result = testing.reduce((c, o) => c && o, true);
    addNextBt(2, result);
  }, [formData]);

  useEffect(() => {
    addCompleted(2, true);
  }, []);

  const contract = useMemo(
    () => ({
      acces: {
        status: formData?.verificationCardStatus,
        externalId: formData?.externalId,
      },
      user: formData?.user,
      product: formData?.productData,
    }),
    [formData],
  );
  return (
    <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
      <MDBox pl={2}>
        <MDTypography fontSize="1.5rem" fontWeight="regular" color="dark">
          {text("Wizard-third-step-contract")}
        </MDTypography>
        <MDTypography fontSize="1rem" fontWeight="light" color="text">
          {text("Wizard-third-step-contract-description")}
        </MDTypography>
      </MDBox>

      <MDBox display="flex" alignItems="center" justifyContent="around" ml={2} mt={4}>
        <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <MDBox height="5px" />
            </Grid>

            <Grid item xs={10}>
              <MDBox width="100%" my={1} py={1} mx={1} marginRight="5px">
                <ContractCard
                  id={formData?.productData?._id}
                  ospContractId={`${formData?.productData?.operator?.operatorCode}.${
                    formData?.productData?.operator?.operatorContractCount + 1
                  }`}
                  title={formData?.productData?.name}
                  iconLogo={formData?.productData?.rules?.credentialsTypes?.types
                    ?.find((element) => element.type === "PROVIDER_EXTERNAL_ID")
                    .provider.toLowerCase()}
                  description=""
                  currency={formData?.productData?.parameters.currency}
                  price={formData?.productData?.parameters.cents / 100}
                  type={formData?.productData?.parameters.durationUnit}
                  selected={contract.acces.status === "verified"}
                  externalId={contract.acces.externalId}
                  userData={contract.user}
                />
              </MDBox>
            </Grid>

            <Grid item xs={1}>
              <MDBox height="5px" />
            </Grid>
          </Grid>
        </FormControl>
      </MDBox>
    </MDBox>
  );
}
