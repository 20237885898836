import { createStore, compose, applyMiddleware } from "redux";
import createIdbStorage from "@piotr-cz/redux-persist-idb-storage";
import combinedReducer from "./combineReducers";
import { persistStore, persistReducer } from "redux-persist";

const customMiddleWare = (store) => (next) => (action) => {
  if (window)
    window.postMessage(
      {
        type: "EVENT_BUS_ACTION",
        payload: {
          action: {
            type: action.type,
            payload: action.payload,
          },
        },
      },
      "*",
    );

  next(action);
};

const persistConfig = {
  key: "root",
  whitelist: ["counter", "login"], // only counter will be persisted, add other reducers if needed
  storage: createIdbStorage({ name: "root", storeName: "redux" }),
};
const composeEnhancers = window.REDUX_DEVTOOLS_EXTENSION_COMPOSE || compose;
// Create a new reducer with our existing reducer
const persistedReducer = persistReducer(persistConfig, combinedReducer);

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(customMiddleWare))); // Creating the store again

// This creates a persistor object & push that persisted
// object to .__persistor, so that we can avail the persistability feature
store.__persistor = persistStore(store);

export default store;
