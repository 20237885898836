import * as React from "react";

// locals MUI
import MDBox from "assets/@ndres-ui/MDBox";

import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import { Popper } from "@mui/material";
import { SubMenu } from "./SubMenu";
import Fade from "@mui/material/Fade";

const DashboardButton = styled(({ inside, selectedLayer, ...rest }) => <MDBox {...rest} />)(() => ({
  color: "#adadad",
  "&:hover": {
    color: "#7d7d7d",
  },
}));

const MenuBoxInside = styled(MDBox)(() => ({
  background: "white",
  border: "1px solid #ababab90",
  borderRadius: "5px",
  width: "100%",
  "&:hover": {},
}));

export const ParentParkingCards = ({
  handlePopoverClose,
  handleClick,
  openMenu,
  label,
  parkingsIds,
  selectedParkings,
  openPopoverId,
  anchorEl,
  timeoutId,
}) => {
  const text = useDictionaries();

  return (
    <>
      <MDBox
        role="button"
        cursor="pointer"
        height="40px"
        border="1px solid #ababab90"
        marginTop="3px"
        marginBottom="3px"
        onMouseEnter={handlePopoverClose}
        onClick={() => handleClick(parkingsIds)}
        width={`97%`}
        borderRadius="4px"
        bgColor={JSON.stringify(selectedParkings) === JSON.stringify(parkingsIds) ? "#f2cccc" : "transparent"}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <MDBox display="flex" justifyContent="flex-start" alignItems="center" height="100%" width="100%" pt={0}>
          <MDTypography
            fontSize="1em"
            px={0}
            pt="0px"
            ml={1}
            textTransform="capitalize"
            width={`calc(100% - "40px")`}
            style={{ lineHeight: "15px", verticalAlign: "middle" }}
          >
            <span style={{ textOverflow: "ellipsis" }}>{text(label)}</span>
          </MDTypography>
        </MDBox>
        <MDBox height="100%" display="flex" alignItems="center" justifyContent="flex-end" pt={1.1}>
          <DashboardButton
            onMouseEnter={(event) => {
              openMenu(event, parkingsIds);
            }}
          >
            <MoreVertIcon style={{ width: "22px", height: "22px" }} />
          </DashboardButton>
        </MDBox>
      </MDBox>
      <Popper
        className="popper-root"
        id={"id"}
        open={openPopoverId === parkingsIds}
        anchorEl={anchorEl}
        transition
        placement="right-start"
        style={{ position: "fixed", zIndex: 9 }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [-11.3, -2],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={400}>
            <MenuBoxInside onMouseLeave={() => handlePopoverClose()} onMouseEnter={() => clearTimeout(timeoutId)}>
              <SubMenu
                onClose={handlePopoverClose}
                parkingsIds={openPopoverId}
                handleSelection={handleClick}
                isParent={true}
              />
            </MenuBoxInside>
          </Fade>
        )}
      </Popper>
    </>
  );
};
