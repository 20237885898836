import React from "react";
import PropTypes from "prop-types";

import MDTypography from "assets/@ndres-ui/MDTypography";

const Table = ({ headers, rows, onClick = () => true, sessionId, vAlign, cursor }) => {
  const lengthResize = {
    xsmall: "2%",
    xsmall2: "2.5%",
    smaller: "3%",
    small: "4%",
    medium: "5%",
    intermediate: "6%",
    large: "8%",
    xlarge: "20%",
  };

  return (
    <table style={{ width: "100%", Height: "100%" }}>
      <thead>
        <tr>
          {headers.map((headerName, index) => (
            <th
              style={{
                width: `${lengthResize[headerName.size]}`,
                textAlign: "left",
                fontWeight: "normal",
              }}
              key={`${headerName.title}`}
              scope="col"
              data-name={`${headerName.title}`}
            >
              <MDTypography
                style={{ fontSize: "14px" }}
                className="global_label"
                type="tableHeader"
                textTransform="first"
              >
                {headerName.title}
              </MDTypography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((values, rowIndex) => {
          if (Array.isArray(values)) {
            return (
              <tr className="hoverPink" style={{ cursor: cursor }} key={`${rowIndex + 1}`}>
                {values.map((value, colIndex) => (
                  <td
                    valign={vAlign}
                    key={`${colIndex + 100}-${colIndex + 20}`}
                    className="RowHeight"
                    data-label={headers[colIndex]}
                  >
                    <MDTypography component="span" type="tableData">
                      {value}
                    </MDTypography>
                  </td>
                ))}
              </tr>
            );
          }

          const { error, id } = values?.options ?? {};
          const styles = {
            color: error ? "C81400" : "inherit",
            cursor: "pointer",
          };
          const backgroundSelected = sessionId && id === sessionId ? "backgroundSelected" : "";

          return (
            <tr
              className={`hoverPink ${backgroundSelected}`}
              style={styles}
              key={`tr-${rowIndex + 1000}`}
              onClick={(ev) => onClick(id)}
            >
              {values.elements.map((value, colIndex) => {
                return (
                  <td
                    valign={vAlign}
                    key={`${id}-${colIndex + 1}`}
                    style={styles}
                    className="RowHeight"
                    data-label={headers[colIndex]}
                  >
                    <MDTypography type="status" component="span" color={error ? "error" : "inherit"}>
                      {value}
                    </MDTypography>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
Table.defaultProps = {
  onClick: () => true,
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func,
  rows: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)),
    PropTypes.arrayOf(
      PropTypes.shape({
        options: PropTypes.shape({ error: PropTypes.bool }),
        elements: PropTypes.array,
      }),
    ),
  ]).isRequired,
};

export default Table;
