/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from "react";

import useDictionaries from "assets/commonHooks/useDictionaries";
import MDTypography from "assets/@ndres-ui/MDTypography";
import { ProviderLogos } from "../../../commonFunctions/useProvider";
import MDButton from "assets/@ndres-ui/MDButton";
import axios from "axios";
import { BFF_MOTORIST_HTTP, BFF_MOTORIST_TOKEN } from "../../../../config";
import { Modal } from "@mui/material";
import MDBox from "../../../@ndres-ui/MDBox";
import { DateTime } from "luxon";
import Popup from "assets/@redapuenta-ui/popup/popup";
import { useSelector } from "@xstate/react";
import { service } from "components/MotoristDetails/reducer";
import { useDispatch } from "react-redux";
const MotoristQRTable = ({ qrCode, onDownloadPdf, onRegenerateQrcode, renderedPdf, motoristId }) => {
  const text = useDictionaries();

  const dispatch = useDispatch();

  const onClickDownload = async () => {
    await axios({
      url: `${BFF_MOTORIST_HTTP}/qrcode/downloadPdf?motoristId=${motoristId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${BFF_MOTORIST_TOKEN}`,
      },
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
    });
  };

  const [titlePopup, setTitlePopup] = useState();
  const [textPopup, setTextPopup] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [modePopup, setModePopup] = useState("choice");

  //! Selector for Popup
  const regenerateQrcode = useSelector(service, ({ context }) => context.regenerateQrcode);
  const errorRegenerateQrcode = useSelector(service, ({ context }) => context.errorRegenerateQrcode);

  //! SUCCESS for Popup
  useEffect(() => {
    if (regenerateQrcode.motoristId === motoristId && openPopup) {
      setTextPopup({ type: "success", value: text("popup-motorist-regenerate-qrcode-success") });
      setModePopup("result");
    }
  }, [regenerateQrcode]);

  //! SUCCESS for Popup
  useEffect(() => {
    if (errorRegenerateQrcode) {
      setTitlePopup({
        type: "basic",
        value: text(`global-error-title-${new String(errorRegenerateQrcode).toLowerCase()}`),
      });
      setTextPopup({
        type: "error",
        value: text(`global-error-text-${new String(errorRegenerateQrcode).toLowerCase()}`),
      });
      setModePopup("result");
    }
  }, [errorRegenerateQrcode]);

  //! Open Popup
  const popupOpen = (e) => {
    setTitlePopup({ type: "basic", value: `${text("widgets-motorist-details-regenerate-modal-first-p")}` });
    setTextPopup({ type: "basic", value: text("widgets-motorist-details-regenerate-modal-second-p") });
    setModePopup("choice");
    e.stopPropagation();
    setOpenPopup(true);
  };

  //! Exit Popup
  const popupExit = () => {
    setOpenPopup(false);
    setModePopup("choice");
  };

  //! Choice in Popup
  const popupChoice = (choice) => {
    if (choice) {
      dispatch({
        type: "MotoristDetails:reset-error",
        payload: {},
      });

      setTextPopup(undefined);
      setModePopup("load");
      onRegenerateQrcode(motoristId);
    } else {
      setModePopup("choice");
      setOpenPopup(false);
    }
  };

  return (
    <>
      <section className="section_details">
        <MDTypography className="global_xs_title" type="tableHeader" textTransform="first">
          {text("widgets-motorist-details-table-header-qrcode")}
        </MDTypography>
        <div className="global_separa"></div>
        <div className="place_rub">
          {qrCode ? (
            <div className="flexTableRows">
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-header-type")}
                </MDTypography>
              </div>
              <div className="flexTableData">
                <div>
                  <span style={{ marginRight: "10px" }}>
                    <ProviderLogos type={"QRCODE"} provider={"OSP"} width="30px" height="30px" />
                  </span>
                  <MDTypography component="span" type="tableData">
                    {text(`global-providers-osp`)}
                  </MDTypography>
                </div>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-motorist-details-table-header-generated")}
                </MDTypography>
              </div>
              <div className="flexTableData">
                <div>
                  <MDTypography type="tableData">
                    {DateTime.fromISO(qrCode?.createdAt).toFormat("dd.MM.yyyy HH:mm:ss")}
                  </MDTypography>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="button_part">
            <div style={{ marginRight: "10px" }}>
              <MDButton color="primary" size="small" onClick={() => onClickDownload(qrCode?.value)}>
                {text("widgets-motorist-details-table-button-download")}
              </MDButton>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <MDButton color="primary" size="small" onClick={(e) => popupOpen(e)}>
                {text("widgets-motorist-details-table-button-generate")}
              </MDButton>
            </div>
          </div>
        </div>
      </section>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox
          bgColor="white"
          p="3em"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "1em",
          }}
        >
          <MDTypography id="modal-modal-title" variant="h3" component="h2" textAlign="center">
            {text("widgets-motorist-details-regenerate-modal-first-p")}
          </MDTypography>
          <MDTypography id="modal-modal-description" sx={{ mt: 2 }} textAlign="center">
            {text("widgets-motorist-details-regenerate-modal-second-p")}
          </MDTypography>
          <MDBox p="2em 0 0 0" display="flex" justifyContent="space-around">
            <MDButton color="warning" onClick={() => handleRegenerate()}>
              {text("widgets-motorist-details-regenerate-modal-button-proceed")}
            </MDButton>
            <MDButton color="primary" onClick={handleClose}>
              {text("widgets-motorist-details-regenerate-modal-button-cancel")}
            </MDButton>
          </MDBox>
        </MDBox>
      </Modal> */}
      {openPopup ? (
        <Popup
          titlePopup={titlePopup}
          textPopup={textPopup}
          modePopup={modePopup}
          handlePopupChoice={popupChoice}
          handlePopupExit={popupExit}
        />
      ) : null}
    </>
  );
};

export default MotoristQRTable;
