import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import sessionDetailsGql from "./sessionDetails.gql";
import forceCloseGql from "./close-session.gql";

const widgetModel = createModel({
  token: "",
  sessionId: "",
  session: undefined,
  forceCloseSession: {},
  errorForceCloseSession: undefined,
});

const fetchSession = async ({ token, sessionId }) => {
  const { data } = await client.query({
    query: sessionDetailsGql,
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": token } },
    variables: { sessionId },
  });

  return data.session;
};

const forceClose = async ({ token, contractId, selectedParkings }, { payload }) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: forceCloseGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          sessionId: payload?.sessionId,
        },
      })
      .then((response) => {
        const { data } = response;
        resolve({ ...data, sessionId: payload?.sessionId });
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};
const sessions = createMachine(
  {
    context: widgetModel.initialContext,
    id: "SessionDetails",
    initial: "idle",
    states: {
      idle: {
        on: {
          "SessionDetails:show": { target: "loading" },
          "SessionDetails:onForceClose": { target: "closeSession" },
          "events:sessions:updated": {
            cond: "isSameSession",
            target: "updating",
          },
          "events:sessions:created": {
            cond: "isSameSession",
            target: "updating",
          },
          "SessionDetails:reset-error": { actions: "reset error" },
        },
      },
      loading: {
        entry: assign({
          token: () => localStorage.getItem("token"),
          sessionId: (_, { payload }) => payload.sessionId,
        }),
        invoke: {
          id: "getSession",
          src: (context, event) => fetchSession(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      closeSession: {
        invoke: {
          id: "closeSession",
          src: (context, event) => forceClose(context, event),
          onDone: [{ actions: "force close", target: "updating" }],
          onError: [{ actions: "error force close", target: "idle" }],
        },
      },
      updating: {
        invoke: {
          id: "getSession",
          src: (context, event) => fetchSession(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populate: assign({
        session: (_, { data }) => data,
      }),
      "force close": assign({
        forceCloseSession: ({ session }, { data }) => {
          return { id: data.sessionId };
        },
      }),
      "error force close": assign({
        errorForceCloseSession: (context, { data }) => {
          return data;
        },
      }),
      "reset error": assign({
        errorForceCloseSession: () => {
          return undefined;
        },
      }),
    },
    guards: {
      isSameSession: (ctx, { payload }) => {
        const { _id } = payload.session;
        const { sessionId } = ctx;

        return sessionId === _id;
      },
    },
  },
);

export const service = interpret(sessions).start();

const initial = {
  state: sessions.initialState.list,
  context: sessions.initialState.context,
};

const widgetReducer = (state = initial, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
