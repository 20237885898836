import { gql } from "@apollo/client";

export default gql`
  query motorists($parkingsIds: [String!]!, $page: Int, $limit: Int, $order: String) {
    motorists(parkingsIds: $parkingsIds, page: $page, limit: $limit, order: $order) {
      list {
        _id
        username
        firstName
        lastName
        isAnonymous
        credentials {
          type
          provider
          value
        }
        city
        createdAt
        contracts {
          list {
            _id
            isEnabled
            startDate
            stopDate
            parkingId
          }
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
