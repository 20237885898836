import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import localMotoristsGql from "./localMotorist.gql";

const UserProviderModel = createModel({
  token: "",
  list: [],
  paging: {},
  selectedParkings: [],
  selectedMotoristId: undefined,
  selectedFilterMotorist: undefined,
});

const fetchList = async ({ token, selectedParkings }, { payload }) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: localMotoristsGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          page: payload.newPage ? payload.newPage : 1,
          limit: 300,
        },
      })
      .then((response) => {
        resolve(response.data.localMotorists);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const UserProviderList = createMachine(
  {
    context: UserProviderModel.initialContext,
    id: "AccessPoint",
    initial: "off",
    states: {
      off: {
        on: {
          "UserProviderList:wakeup": [
            { cond: "has selected parkings", target: "loading" },
            { cond: "has no parkings", target: "idle" },
          ],
          "global:selected-filtered-motorist": {
            actions: "select motorist from focus",
          },
          "global:selected-all-motorist": {
            actions: "switch to all motorist",
          },
          "global:selected-parkings": { actions: ["switch to all motorist", "save parkings"] },
        },
      },
      idle: {
        on: {
          "events:localmotorist:created": { target: "loading" },
          "events:localmotorist:updated": { target: "loading" },
          "global:selected-filtered-motorist": {
            actions: "select motorist from focus",
          },
          "global:selected-all-motorist": {
            actions: "switch to all motorist",
          },
          "UserProviderList:onSelectPage": { target: "loading" },
          "UserProviderList:onSelectMotorist": { actions: "select motorist" },
          "UserProviderList:sleep": {
            target: "off",
          },
          "global:selected-parkings": {
            actions: ["switch to all motorist", "save parkings"],
            target: "loading",
          },
        },
      },
      loading: {
        entry: assign({
          token: () => localStorage.getItem("token"),
        }),
        invoke: {
          id: "getMotorist",
          src: (context, event) => fetchList(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ actions: "populateError", target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populate: assign({
        list: (_, { data }) => {
          return data.list;
        },
        paging: (_, { data }) => {
          return data.paging;
        },
      }),
      populateError: assign({
        list: (_, { data }) => null,
        paging: (_, { data }) => null,
      }),
      "save parkings": assign({
        selectedParkings: (ctx, { payload }) => {
          return payload.selectedParkings;
        },
      }),
      "select motorist": assign({
        selectedMotoristId: (ctx, { payload }) => {
          return payload.selectedMotoristId;
        },
      }),
      "select motorist from focus": assign({
        selectedFilterMotorist: (ctx, { payload }) => payload.selectedFilterMotorist,
        selectedMotoristId: (ctx, { payload }) => {
          return payload.selectedFilterMotorist?.motorist?._id;
        },
      }),
      "switch to all motorist": assign({
        selectedFilterMotorist: (ctx, { payload }) => null,
        selectedMotoristId: (ctx, { payload }) => null,
      }),
    },
    guards: {
      "has selected parkings": ({ selectedParkings }) => {
        return !!selectedParkings.length;
      },
      "has no parkings": ({ selectedParkings }) => {
        return !selectedParkings.length;
      },
    },
  },
);

export const service = interpret(UserProviderList).start();

const widgetReducer = (state, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
