import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "@xstate/react";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";

import { service } from "../reducer";
import useDictionaries from "../../../assets/commonHooks/useDictionaries";

const defaultContextData = {
  list: [],
  page: 1,
  totalPages: 1,
};

export const LocalContext = createContext(defaultContextData);

export const ContextProvider = ({ children, storeName, events = {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { list, paging } = useSelector(service, ({ context: { list, paging } }) => {
    return { list, paging };
  });

  const selectedFilterMotorist = useSelector(
    service,
    ({ context: { selectedFilterMotorist } }) => selectedFilterMotorist,
    (a, b) => a?.id === b?.id,
  );

  useEffect(() => {
    if (!selectedFilterMotorist) return;

    // Click on an incident, dispatch it
    dispatch({
      type: `global:selected-filtered-motorist`,
      payload: { selectedFilterMotorist: { ...selectedFilterMotorist } },
    });

    // Move to a new page
    navigate("/dashboard/contracts", { replace: true });

    // On quit, remove selected incident from reducer
    return () => dispatch({ type: `${storeName}:onSelectIncident`, payload: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterMotorist]);

  useEffect(() => {
    dispatch({ type: `${storeName}:wakeup`, payload: {} });
    return () => {
      dispatch({ type: `${storeName}:sleep`, payload: {} });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eventDispatch = (key, fn, src) => {
    const payload = fn(src);
    dispatch({ type: `${storeName}:${key}`, payload });
  };

  const eventsActions = useMemo(
    () =>
      Object.entries(events).reduce(
        (acc, [key, fn]) => ({
          [key]: (src) => eventDispatch(key, fn, src),
          ...acc,
        }),
        {},
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [events],
  );
  const text = useDictionaries();
  const technicalContext = {
    "anti-passback-reject": ({ createdAt }) => ({
      DATE: DateTime.fromISO(createdAt).toFormat("dd.LL.yy HH:mm"),
    }),
    "user-provider-reject": ({ credential }) => ({
      UUU: text(`global-providers-${credential?.provider?.toLowerCase()}`),
      RFID: credential?.value.toUpperCase(),
    }),
    "user-access-reject": ({ credential }) => ({
      EID: credential?.value.toUpperCase(),
    }),
    "incident-disabled-qrcode": ({ credential }) => ({
      UUU: text(`global-providers-${credential?.provider.toLowerCase()}`),
    }),
    "session-no-entry-plate": ({ credential }) => ({ PL: credential?.value.toUpperCase() }),
    "session-no-entry-rfid": ({ credential }) => ({ EID: credential?.value }),
    "ambiguous-contract-rfid": ({ credential }) => ({ RFID: credential?.value }),
    "force-close-session": ({ credential }) => ({ EID: credential?.value }),
    "ambiguous-contract-provider-external-id": ({ credential }) => ({ EID: credential?.value }),
    "ambiguous-contract-plate": ({ credential }) => ({ PL: credential?.value.toUpperCase() }),
    "pool-full": ({ credential, spacesAllocated }) => ({ PL: credential?.value.toUpperCase(), PN: spacesAllocated }),
    "pool-full-plate": ({ credential, spacesAllocated }) => ({
      PL: credential?.value.toUpperCase(),
      PN: spacesAllocated,
    }),
    "pool-full-rfid": ({ credential, spacesAllocated }) => ({ EID: credential?.value, PN: spacesAllocated }),
    "pool-full-qrcode": ({ credential, spacesAllocated }) => ({ QR: credential?.value, PN: spacesAllocated }),
    "anti-passback-notification": ({ openedSessions, rules: { cycleControl } }) => ({
      NNE: openedSessions?.length,
      NDAY: cycleControl?.pastDaysCount,
    }),
  };

  const renderList = useMemo(
    () =>
      list.map(({ _id, key, createdAt, payload }) => {
        const { parking, accessPoint, motorist } = payload;

        return {
          id: _id,
          parkingName: `parking-${parking?._id}-name`,
          date: createdAt,
          station: accessPoint?.shortCode,
          incident: `incident-${key}`,
          link: !!motorist?._id,
          motorist,
          context: technicalContext[key] ? technicalContext[key]({ ...payload, createdAt }) : {},
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list],
  );

  const renderPaging = useMemo(() => {
    const { count = 0, limit = 10 } = paging;
    const total = Math.ceil(count / limit);

    return {
      page: paging?.current,
      totalPages: total,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  return (
    <LocalContext.Provider
      value={{
        list: renderList,
        ...renderPaging,
        ...eventsActions,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

ContextProvider.defaultProps = {
  storeName: "",
  events: {},
};

ContextProvider.propTypes = {
  storeName: PropTypes.string,
  events: PropTypes.shape({}),
};
