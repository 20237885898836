import React, { useState, useEffect } from "react";
import { useSelector } from "@xstate/react";
import { service } from "../../../reducer";

// @mui materials
import MDInput from "assets/@ndres-ui/MDInput";
import MDButton from "assets/@ndres-ui/MDButton";

import Grid from "@mui/material/Grid";
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";

export default function Step4({ addCompleted, setData, formData, addNextBt, reload }) {
  const text = useDictionaries();

  const [showAcepted, setShowAcepted] = useState(false);

  const motorist = useSelector(service, ({ context }) => context.motorist);
  const finisProcess = useSelector(service, ({ context }) => context.finish);
  const prosessing = useSelector(service, ({ value }) => value === "dispatcher");
  const motoristAcepted = useSelector(service, ({ context }) => context.motoristAcepted);
  const motoristRejected = useSelector(service, ({ context }) => context.motoristRejected);

  const validMotoristList = Array.isArray(motorist) && motorist.length > 0;
  const errors = motorist.reduce((acc, { valid }) => (valid ? acc : acc + 1), 0);

  useEffect(() => {
    const testing = [validMotoristList, errors === 0, prosessing === false, finisProcess === true];

    const result = testing.reduce((c, o) => c && o, true);
    addNextBt(2, result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, prosessing, motoristAcepted, motoristRejected]);

  useEffect(() => {
    addCompleted(3, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData({ blockBack: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadWizard = () => {
    reload({ csv: motoristRejected.map(({ ligne }) => ligne).join("\n") });
  };

  return (
    <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
      <MDBox
        pt={0}
        style={{
          transition: "opacity ease 1s",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <MDBox mt={2} pt={1} ml={4} style={{ maxWidth: "450px" }}>
              <MDTypography textTransform="first" fontSize="1.2rem" fontWeight="regular">
                {`${text("Wizard-pool-label-acepted")}: ${motoristAcepted.length}`}
              </MDTypography>
              <MDBox pt={1}>
                {showAcepted ? (
                  <MDInput
                    id="outlined-multiline-static"
                    // label="Multiline"
                    success
                    multiline
                    value={motoristAcepted.map(({ ligne }) => ligne).join("\n")}
                    rows={9}
                    // defaultValue="Default Value"
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      service.send({
                        type: "WizardCreateLocalMotorist:",
                        payload: { value: e.target.value },
                      })
                    }
                  />
                ) : (
                  <MDButton
                    style={{ paddingLeft: 10 }}
                    variant="gradient"
                    color="light"
                    onClick={() => setShowAcepted(!showAcepted)}
                  >
                    {`${text("Wizard-pool-label-see-details")}`}
                  </MDButton>
                )}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={2} pt={1} ml={4} style={{ maxWidth: "450px" }}>
              <MDTypography textTransform="first" fontSize="1.2rem" fontWeight="regular">
                {`${text("Wizard-pool-label-reject")}: ${motoristRejected.length}`}
              </MDTypography>
              <MDBox pt={1}>
                {motoristRejected.length <= 0 ? (
                  <MDButton style={{ paddingLeft: 10 }} variant="gradient" color="light">
                    {`${text("Wizard-pool-label-no-errors")}`}
                  </MDButton>
                ) : (
                  <MDBox display="flex" alignItems="center" flexDirection="column">
                    <MDInput
                      id="outlined-multiline-static"
                      // label="Multiline"
                      error
                      multiline
                      value={motoristRejected.map(({ ligne }) => ligne).join("\n")}
                      rows={9}
                      // defaultValue="Default Value"
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        service.send({
                          type: "WizardCreateLocalMotorist:",
                          payload: { value: e.target.value },
                        })
                      }
                    />
                    <MDBox pt={2}>
                      <MDButton variant="gradient" color="primary" onClick={reloadWizard}>
                        {`${text("Wizard-pool-label-retry")}`}
                      </MDButton>
                    </MDBox>
                  </MDBox>
                )}
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}
