import React, { useContext, useMemo } from "react";
import { format } from "date-fns";
import useDictionaries from "assets/commonHooks/useDictionaries";
import Paginat from "assets/@luc-ui/Pagination";
import Table from "assets/@luc-ui/Table";
import MotoristState from "../MotoristState";
import MotoristContractState from "../MotoristContractState";
import SessionStatus from "../SessionStatus";
import { LocalContext } from "../../context";

const SessionsTable = () => {
  const text = useDictionaries();
  const { list, page, totalPages, onSelectPage, onSelectSession, selectedSession } = useContext(LocalContext);

  const listFormated = useMemo(
    () =>
      list.map(
        ({ parkingName, entry, exit, isAnonymous, motoristContractExist, status, error, sessionId = "123" }) => ({
          options: {
            error,
            id: sessionId,
          },
          elements: [
            text(parkingName),
            entry ? format(new Date(entry), "dd.MM.yyyy HH:mm:ss") : "",
            exit ? format(new Date(exit), "dd.MM.yyyy HH:mm:ss") : "",
            <div>
              <MotoristState isAnonymous={isAnonymous} />
              <MotoristContractState motoristContractExist={motoristContractExist} />
            </div>,
            <SessionStatus status={status} />,
          ],
        }),
      ),
    [list, text],
  );

  return (
    <>
      <div
        style={{
          position: "relative",
          paddingLeft: "1em",
        }}
      >
        <Table
          headers={[
            {
              title: text("widgets-sessions-table-header-parking"),
              size: "medium",
            },
            {
              title: text("widgets-sessions-table-header-entry"),
              size: "medium",
            },
            {
              title: text("widgets-sessions-table-header-exit"),
              size: "medium",
            },
            {
              title: text("widgets-sessions-table-header-details"),
              size: "small",
            },
            {
              title: text("widgets-sessions-table-header-status"),
              size: "small",
            },
          ]}
          rows={listFormated}
          onClick={(data) => onSelectSession(data)}
          sessionId={selectedSession}
          cursor="pointer"
        />
        <Paginat page={page} totalPages={totalPages} onSelectPage={onSelectPage} />
      </div>
    </>
  );
};

export default SessionsTable;
