import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";

import { client } from "../../apolloService";
import breakdownGql from "./breakdown.gql";
import updateBreakdownGql from "./updateBreakdown.gql";

const AccessPointEventModel = createModel({
  token: "",
  selectEventAccessId: "",
  currentAccessEvent: undefined,
  updateAccessEvent: {},
  errorUpdateAccessEvent: undefined,
  paging: {},
  selectedParkings: [],
});

const getAccessPointEvent = async ({ token, accessPointEventId, selectedParkings }, { payload }) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: breakdownGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          breakdownId: accessPointEventId,
        },
      })
      .then((response) => {
        resolve(response.data.breakdown);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const updateAccessPointEvent = async ({ token }, { payload }) => {
  const { endDate, description, category, _id } = payload.data;

  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: updateBreakdownGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          breakdownId: _id,
          input: {
            endDate,
            category,
            description,
          },
        },
      })
      .then((response) => {
        resolve(response.data.updateBreakdown);
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

const accessPointDetails = createMachine(
  {
    context: AccessPointEventModel.initialContext,
    id: "AccessPointDetails",
    initial: "off",
    states: {
      off: {
        on: {
          "AccessPointDetails:show": {
            target: "loading",
          },
          "global:selected-parkings": { actions: "save parkings" },
        },
      },
      idle: {
        on: {
          "AccessPointHistory:onSelectEventAccess": { actions: "select access point", target: "loading" },
          "AccessPointDetails:show": { target: "loading" },
          "AccessPointDetails:onUpdateAccessPoint": {
            target: "updateAccessPoint",
          },
          "AccessPointDetails:reset-error": {
            actions: "reset error",
          },
          "global:selected-parkings": {
            actions: "save parkings",
            target: "loading",
          },
        },
      },
      loading: {
        entry: assign({
          token: () => localStorage.getItem("token"),
          accessPointEventId: (ctx, { payload }) => {
            return payload.selectEventAccessId ? payload.selectEventAccessId : ctx.selectEventAccessId;
          },
        }),
        invoke: {
          id: "getAccessPointDetails",
          src: (context, event) => getAccessPointEvent(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      updateAccessPoint: {
        entry: assign({
          token: () => localStorage.getItem("token"),
        }),
        invoke: {
          id: "updateAccessPoint",
          src: (context, event) => updateAccessPointEvent(context, event),
          onDone: [{ actions: "update access point", target: "idle" }],
          onError: [{ actions: "error update access point", target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populate: assign({
        currentAccessEvent: (_, { data }) => {
          return data;
        },
      }),

      "update access point": assign({
        updateAccessEvent: (ctx, { data }) => data,
        currentAccessEvent: (ctx, { data }) => data,
      }),

      "error update access point": assign({
        errorUpdateAccessEvent: ({}, { data }) => {
          return data;
        },
      }),
      "save parkings": assign({
        selectedParkings: (ctx, { payload }) => payload.selectedParkings,
      }),
      "select access point": assign({
        selectEventAccessId: (ctx, { payload }) => {
          return payload.selectEventAccessId;
        },
      }),
      "reset error": assign({
        errorUpdateAccessEvent: () => undefined,
      }),
    },
  },
);

export const service = interpret(accessPointDetails).start();

const initial = {
  state: accessPointDetails.initialState.list,
  context: accessPointDetails.initialState.context,
};

const widgetReducer = (state = initial, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
