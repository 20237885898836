import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
const userModel = createModel({
  token: "",
  motoristId: "",
  selectedMotorist: "",
  paging: {},
  selectedParkings: [],
});

const BusinessTenantsParentDetails = createMachine(
  {
    context: userModel.initialContext,
    id: "BusinessTenantsDetails",
    initial: "off",
    states: {
      off: {
        on: {
          "BusinessTenantsDetails:wakeup": { target: "loading" },
          "global:selected-parkings": { actions: "save parkings" },
        },
      },
      idle: {
        on: {
          "BusinessTenantsDetails:show": { target: "loading" },

          "global:selected-parkings": {
            actions: "save parkings",
            target: "loading",
          },
        },
      },
      loading: {
        entry: assign({
          token: () => localStorage.getItem("token"),
          contractId: (ctx, { payload }) =>
            payload.selectedContract ? payload.selectedContract : ctx.selectedContract,
        }),
        invoke: {
          id: "getBusinessTenantsDetailsDetail",
          src: (context, event) => {},
          onDone: [{ target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      "save parkings": assign({
        selectedParkings: (ctx, { payload }) => payload.selectedParkings,
      }),
    },
  },
);

export const service = interpret(BusinessTenantsParentDetails).start();

const initial = {
  state: BusinessTenantsParentDetails.initialState.list,
  context: BusinessTenantsParentDetails.initialState.context,
};

const widgetReducer = (state = initial, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
