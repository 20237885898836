import * as React from "react";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// icons
import ArticleIcon from "@mui/icons-material/Article";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import PersonIcon from "@mui/icons-material/Person";

// materials ui
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { styled } from "@mui/material/styles";

// locals MUI
import MDBox from "assets/@ndres-ui/MDBox";
import DriveOut from "assets/images/icons/driveOut";
import colors from "assets/@ndres-ui/theme/base/colors";
import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";
import { ENV } from "../../../config";

const { primary } = colors;
const iconStyle = { width: "20px", height: "20px", color: primary.main };

const ListItemBox = styled(MDBox)(({ theme, open }) => ({
  height: "40px",
  paddingLeft: "10px",
  cursor: "pointer",
  userSelect: "none",
  "&:hover": {
    background: "#f2cccc",
  },
}));

const LabelMenu = styled(({ children, ...props }) => (
  <MDTypography {...props} textTransform="first">
    {children}
  </MDTypography>
))(({ theme, open }) => ({
  color: "#575757",
  fontSize: "0.9em",
}));

const ItemTextContainer = styled(MDBox)(({ theme, open }) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "flex-start",
  height: "30px",
  borderRadius: "15px",
  paddingLeft: "10px",
  transition: "all ease .25s",
  color: "#575757",
  "&:active:hover": {
    background: "#f2cccc",
    color: "#5b5b5b50",
  },
  "&:hover:not(:active)": {
    background: "#ababab28",
    color: "#2b2b2b",
  },
}));
const IconBox = styled(MDBox)(({ theme, open }) => ({
  height: "35px",
  width: "35px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#ababab",
  transition: "all ease .25s",
  borderRadius: "50%",
  "&:hover": {
    background: "#ababab50",
    color: "#5b5b5b",
  },
  "&:focus:not(:hover)": {
    background: "#f2cccc",
    color: "#5b5b5b50",
  },
  "&:active": {
    background: "#f2cccc",
    color: "#5b5b5b50",
  },
}));
export const SubMenu = ({ parkingsIds, setSelection, isParent, parking }) => {
  const dispatch = useDispatch();
  const text = useDictionaries();

  const handleClick = (evt) => {
    dispatch({
      type: "hierarchy:select-item",
      payload: { selectedParkings: parkingsIds },
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        minWidth: 180,
        maxWidth: 250,
        bgcolor: "background.paper",
        borderRadius: "5px",
      }}
    >
      <nav aria-label="main divider">
        <List>
          <ListItem disablePadding>
            <ListItemBox
              style={{ color: "#909090" }}
              display="flex"
              pt={0}
              pl={1}
              alignItems="center"
              justifyContent="flex-start"
              height="40px"
              width="100%"
            >
              <Link to={"/dashboard/"} style={{ width: "100%" }}>
                <ItemTextContainer onClick={handleClick} style={{ width: "100%" }}>
                  <DashboardIcon style={{ width: "20px", height: "20px", opacity: 0.6 }} />
                  <MDBox width="14px" />
                  <LabelMenu>{text("hierarchy-menu-dashboard")}</LabelMenu>
                </ItemTextContainer>
              </Link>
              <MDBox height="22px" width="15px" display="flex" alignItems="center" justifyContent="flex-end" pr={1} />
            </ListItemBox>
          </ListItem>

          <ListItem disablePadding>
            <ListItemBox
              style={{ color: "#909090" }}
              display="flex"
              pt={0}
              pl={1}
              alignItems="center"
              justifyContent="flex-start"
              height="40px"
              width="100%"
            >
              <Link to={"/dashboard/sessions/"} style={{ width: "100%" }}>
                <ItemTextContainer onClick={handleClick} style={{ width: "100%" }}>
                  <MDBox
                    width="26px"
                    height="20px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    margin="1vh 0px"
                  >
                    <DriveOut style={iconStyle} />
                  </MDBox>
                  <MDBox width="12px" />
                  <LabelMenu>{text("hierarchy-menu-sessions")}</LabelMenu>
                </ItemTextContainer>
              </Link>
              <MDBox height="22px" width="15px" display="flex" alignItems="center" justifyContent="flex-end" pr={1} />
            </ListItemBox>
          </ListItem>
        </List>
      </nav>

      <Divider variant="middle" />
      <nav aria-label="actions divider">
        <List>
          {!isParent && parking.withPool ? (
            <ListItem disablePadding>
              <ListItemBox
                style={{ color: "#909090" }}
                display="flex"
                pt={0}
                pl={1}
                alignItems="center"
                justifyContent="space-between"
                height="40px"
                width="100%"
              >
                <Link to="/dashboard/tenants" style={{ width: "100%" }}>
                  <ItemTextContainer onClick={handleClick} style={{ width: "100%" }}>
                    <AddBusinessIcon style={{ width: "20px", height: "20px", opacity: 0.6 }} />
                    <MDBox width="16px" />
                    <LabelMenu>{text("hierarchy-menu-business-tenants")}</LabelMenu>
                  </ItemTextContainer>
                </Link>
                <MDBox
                  height="22px"
                  width="70px"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  pr={1}
                  onClick={() => setSelection("New Pool")}
                >
                  <IconBox>
                    <AddCircleIcon
                      style={{
                        textAlign: "right",
                        height: "22px",
                        width: "22px",
                        color: "#C81400",
                      }}
                    />
                  </IconBox>
                </MDBox>
              </ListItemBox>
            </ListItem>
          ) : (
            <></>
          )}
          <ListItem disablePadding>
            <ListItemBox
              style={{ color: "#909090" }}
              display="flex"
              pt={0}
              pl={1}
              alignItems="center"
              justifyContent="space-between"
              height="40px"
              width="100%"
            >
              <Link to="/dashboard/contracts" style={{ width: "100%" }}>
                <ItemTextContainer onClick={handleClick} style={{ width: "100%" }}>
                  <ArticleIcon style={{ width: "20px", height: "20px", opacity: 0.6 }} />
                  <MDBox width="16px" />
                  <LabelMenu>{text("hierarchy-menu-contracts")}</LabelMenu>
                </ItemTextContainer>
              </Link>
              {!isParent && parking.withSubscription ? (
                <MDBox
                  height="22px"
                  width="70px"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  pr={1}
                  onClick={() => setSelection("New subscription")}
                >
                  <IconBox>
                    <AddCircleIcon
                      style={{
                        height: "22px",
                        width: "22px",
                        color: "#C81400",
                      }}
                    />
                  </IconBox>
                </MDBox>
              ) : (
                <></>
              )}
            </ListItemBox>
          </ListItem>
          {!isParent && ENV === "develop" ? (
            <ListItem disablePadding>
              <ListItemBox
                style={{ color: "#909090" }}
                display="flex"
                pt={0}
                pl={1}
                alignItems="center"
                justifyContent="space-between"
                height="40px"
                width="100%"
              >
                <Link to="/dashboard/accesspoint" style={{ width: "100%" }}>
                  <ItemTextContainer onClick={handleClick} style={{ width: "100%" }}>
                    <SyncLockIcon style={{ width: "20px", height: "20px", opacity: 0.6 }} />
                    <MDBox width="16px" />
                    <LabelMenu>{text("hierarchy-menu-report")}</LabelMenu>
                  </ItemTextContainer>
                </Link>
                <MDBox
                  height="22px"
                  width="70px"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  pr={1}
                  onClick={() => setSelection("New Create Report")}
                >
                  <IconBox>
                    <AddCircleIcon
                      style={{
                        textAlign: "right",
                        height: "22px",
                        width: "22px",
                        color: "#C81400",
                      }}
                    />
                  </IconBox>
                </MDBox>
              </ListItemBox>
            </ListItem>
          ) : (
            <></>
          )}

          {parking?.code !== "CH_WIN_001" && (
            <ListItem disablePadding>
              <ListItemBox
                style={{ color: "#909090" }}
                display="flex"
                pt={0}
                pl={1}
                alignItems="center"
                justifyContent="space-between"
                height="40px"
                width="100%"
              >
                <Link to="/dashboard/provider" style={{ width: "100%" }}>
                  <ItemTextContainer onClick={handleClick} style={{ width: "100%" }}>
                    <PersonIcon style={{ width: "20px", height: "20px", opacity: 0.6 }} />
                    <MDBox width="16px" />
                    <LabelMenu>{text("hierarchy-menu-motorists")}</LabelMenu>
                  </ItemTextContainer>
                </Link>
                {!isParent ? (
                  <MDBox
                    height="22px"
                    width="70px"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    pr={1}
                    onClick={() => setSelection("New Local Motorist")}
                  >
                    <IconBox>
                      <AddCircleIcon
                        style={{
                          height: "22px",
                          width: "22px",
                          color: "#C81400",
                        }}
                      />
                    </IconBox>
                  </MDBox>
                ) : (
                  <></>
                )}
              </ListItemBox>
            </ListItem>
          )}

          {!isParent ? (
            <ListItem disablePadding>
              <ListItemBox
                style={{ color: "#909090" }}
                display="flex"
                pt={0}
                pl={1}
                alignItems="center"
                justifyContent="space-between"
                height="40px"
                width="100%"
              >
                <Link to="/dashboard/products/" style={{ width: "100%" }}>
                  <ItemTextContainer onClick={handleClick} style={{ width: "100%" }}>
                    <Inventory2Icon style={{ width: "18px", height: "18px", opacity: 0.6 }} />
                    <MDBox width="16px" />
                    <LabelMenu>{text("hierarchy-menu-products")}</LabelMenu>
                  </ItemTextContainer>
                </Link>

                {false ? (
                  <MDBox height="22px" width="70px" display="flex" alignItems="center" justifyContent="flex-end" pr={1}>
                    <IconBox>
                      <AddCircleIcon
                        style={{
                          height: "22px",
                          width: "22px",
                          color: "#C81400",
                        }}
                      />
                    </IconBox>
                  </MDBox>
                ) : (
                  <></>
                )}
              </ListItemBox>
            </ListItem>
          ) : (
            <></>
          )}
        </List>
      </nav>
    </Box>
  );
};
