import React from "react";

// material ui
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

// @ndres-ui
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import MDBox from "assets/@ndres-ui/MDBox";

const WidgetsPlace = styled(({ children, ...rest }) => (
  <>
    <MDBox display={"flex"} height="calc(100vh - 100px)" {...rest}>
      {children}
    </MDBox>
  </>
))(() => ({
  width: "100%",
  maxWidth: "1950px",
  padding: "5px 20px",
}));

const Container = ({ children }) => {
  return (
    <MDBox width="100%" display="flex" justifyContent="space-between" height="100vh">
      <MDBox width="calc(10vw - 20px)" maxWidth="280px" minWidth="200px" />
      <MDBox width="100%" paddingTop="55px">
        <MDBox px={2} marginBottom="10px">
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="text.primary" style={{ fontSize: "14px" }}>
                &nbsp;
              </Typography>
            </Breadcrumbs>
          </div>
        </MDBox>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <WidgetsPlace>{children}</WidgetsPlace>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default Container;
