import React, { useContext, useMemo } from "react";
import useDictionaries from "assets/commonHooks/useDictionaries";
import MotoristTable from "assets/@luc-ui/SessionDetails/MotoristTable";
import ContractTable from "assets/@luc-ui/SessionDetails/ContractTable";
import SessionTable from "assets/@luc-ui/SessionDetails/SessionTable";
import SessionHistory from "assets/@luc-ui/SessionHistoryTable";
import { LocalContext } from "../../context";

const SessionDetailTable = () => {
  const text = useDictionaries();
  const { data, onForceClose } = useContext(LocalContext);
  const listReactiv = useMemo(() => data, [data, text]);
  const { motoristInfos, contractInfos, sessionInfos, eventList } = listReactiv;

  return (
    <>
      <MotoristTable motoristInfos={motoristInfos} />
      <ContractTable contractInfos={contractInfos} />
      <SessionTable sessionInfos={sessionInfos} eventList={eventList} onForceClose={onForceClose} />
      <SessionHistory eventList={eventList} />
    </>
  );
};

export default SessionDetailTable;
