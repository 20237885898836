/* eslint-disable implicit-arrow-linebreak */
import React from "react";
import PropTypes from "prop-types";

import useDictionaries from "assets/commonHooks/useDictionaries";
import SessionTypeSelector from "assets/@luc-ui/SessionHistorySelector";
import MDTypography from "assets/@ndres-ui/MDTypography";

const SessionHistory = (props) => {
  const { eventList } = props;
  const text = useDictionaries();
  if (!eventList) return <></>;
  return (
    <section className="section_details">
      <MDTypography className="global_xs_title" type="tableHeader" textTransform="first">
        {text("widgets-session-details-table-header-history")}
      </MDTypography>
      <div className="global_separa"></div>
      <div className="place_rub">
        <div className="flexTableRowsThreeMin">
          <div className="flexTableHead">
            <MDTypography type="tableHeader" textTransform="first">
              {text("widgets-session-details-table-history-header-activity")}
            </MDTypography>
          </div>

          <div className="flexTableHead">
            <MDTypography type="tableHeader" textTransform="first">
              {text("widgets-session-details-table-history-header-device")}
            </MDTypography>
          </div>
          <div className="flexTableHead">
            <MDTypography type="tableHeader" textTransform="first">
              {text("widgets-session-details-table-history-header-timestamp")}
            </MDTypography>
          </div>
        </div>

        {eventList?.map((event, index) => (
          <SessionTypeSelector event={event} type={event.state} key={`${index + 1}`} />
        ))}
      </div>
    </section>
  );
};

SessionHistory.defaultProps = {
  eventList: [],
};

SessionHistory.propTypes = {
  eventList: PropTypes.arrayOf(
    PropTypes.shape({
      state: PropTypes.string,
      credentialType: PropTypes.string,
      credentialValue: PropTypes.string,
      accessPointCode: PropTypes.string,
      createdAt: PropTypes.string,
      error: PropTypes.bool,
    }),
  ),
};

export default SessionHistory;
