import React from "react";

import { NavigationBarContainer, MenuItem, MenuBox, LogoBox, MarginedBox, MenuItemSearch } from "./style";
import Logo from "./Logo";

import UserSettings from "./UserSettings";
import LanguageSelector from "./LanguageSelector";
import { Link } from "react-router-dom";
import LogoSvg from "assets/images/icons/logo_osp.svg";
import SearchBar from "./SearchBar";

const NavigationBar = () => {
  return (
    <NavigationBarContainer>
      <MarginedBox>
        <Link to={"/dashboard"}>
          <LogoBox>
            <img src={LogoSvg} style={{ height: "90%" }} alt="website logo" />
          </LogoBox>
        </Link>
        <MenuBox>
          <MenuItemSearch style={{ padding: 0 }}>
            <SearchBar />
          </MenuItemSearch>
          <MenuItem>
            <LanguageSelector />
          </MenuItem>
          <MenuItem>
            <UserSettings />
          </MenuItem>
        </MenuBox>
      </MarginedBox>
    </NavigationBarContainer>
  );
};

export default NavigationBar;
