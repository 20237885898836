import React, { useContext, useMemo } from "react";

import BusinessTenantsAboutTable from "components/BusinessTenantsDetails/Child/About/materials/table";
import BusinessTenantsMotoristTable from "components/BusinessTenantsDetails/Child/Motorists/materials/table";
import BusinessTenantsSessions from "components/BusinessTenantsDetails/Child/Sessions/materials/table";

import { ContextProvider } from "./context";
import { LocalContext } from "../context";

const BusinessTenantsChildDetails = ({ displayAbout, displayContract, displaySessions }) => {
  const onSelectMotoristPage = (newPage) => ({ newPage });
  const onContractStatusChange = (payload) => payload;
  const onOpenWizard = (payload) => payload;
  const onOpenSingleWizard = (payload) => payload;

  const { isParent } = useContext(LocalContext);
  const memoParent = useMemo(() => isParent, [isParent]);

  if (memoParent) return <></>;
  return (
    <div style={{ display: memoParent ? "none" : "block" }}>
      <ContextProvider
        storeName="BusinessTenantsChildDetails"
        events={{
          onContractStatusChange,
          onSelectMotoristPage,
          onOpenWizard,
          onOpenSingleWizard,
        }}
      >
        <div style={{ display: `${displayAbout}` }}>
          <BusinessTenantsAboutTable />
        </div>
        <div style={{ display: `${displayContract}` }}>
          <BusinessTenantsMotoristTable />
        </div>
        <div style={{ display: `${displaySessions}` }}>
          <BusinessTenantsSessions />
        </div>
      </ContextProvider>
    </div>
  );
};

export default BusinessTenantsChildDetails;
