import { gql } from "@apollo/client";

export default gql`
  mutation createLocalMotorist($input: CreateLocalMotoristsDto!) {
    createLocalMotorist(input: $input) {
      motorist {
        _id
        email
        firstName
        lastName
      }
    }
  }
`;
