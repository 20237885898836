import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "@xstate/react";
import { service } from "../../../reducer";
import { DatePicker } from "rsuite";
import { SelectPicker } from "rsuite";
import DateRangePicker from "rsuite/DateRangePicker";
import { subDays, format, addDays } from "date-fns";
import useDictionaries from "assets/commonHooks/useDictionaries";
import { DateTime } from "luxon";

const { allowedRange, beforeToday, combine } = DateRangePicker;

export default function Step1({ addCompleted, setData, formData, addNextBt }) {
  const text = useDictionaries();

  const motoristChecked = useSelector(service, ({ context }) => context.motoristChecked);
  useEffect(() => {
    addNextBt(0, true);
    addCompleted(0, true);
  }, []);

  return (
    <div className="wizard">
      <div className="wizard__note">
        <h3 className="wizard__subtitle">{text("Wizard-user-provider-first-step-notice-title")}</h3>

        <p>{text("Wizard-user-provider-first-step-notice-text-first")}</p>
      </div>
      <div className="wizard__form">
        <div className="wizard__single">
          <div className="wizard__case">
            <div className="wizard__list">
              <div className="wizard__list__header">
                <span>{text("Wizard-user-provider-first-step-firstname-label")}</span>
                <span>{text("Wizard-user-provider-first-step-lastname-label")}</span>
              </div>
              <div className="wizard__list__contain">
                {motoristChecked.map(({ _id, firstName, lastName }) => {
                  return (
                    <div key={_id} id={_id} className="wizard__list__contain__item">
                      <span>{firstName ? firstName : "-"}</span>
                      <span>{lastName ? lastName : "-"}</span>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="wizard__stat">
              <span>
                {new String(text("Wizard-user-provider-first-step-count-contract-text")).replace(
                  "[COUNT]",
                  motoristChecked.length,
                )}
              </span>
            </div>
          </div>
        </div>
      </div>

      <style jsx="true">
        {`
          .wizard {
            width: 85%;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            height: 50vh;
            min-height: 500px;
            box-sizing: border-box;
            padding: 20px;
          }

          .wizard * {
            font-size: 14px;
          }

          .wizard__subtitle {
            font-size: 18px;
            line-height: 1;
            margin-bottom: 15px;
          }

          .wizard__note {
            width: 35%;
            min-width: 230px;
            height: 100%;
            border-radius: 6px;
            padding: 15px;
            border: 1px solid #e5e5ea;
            margin-right: 25px;
            overflow: hidden;
          }

          .wizard__form {
            margin-left: 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 65%;
          }

          .wizard__duo,
          .wizard__single {
            margin-bottom: 25px;
          }

          .wizard__duo {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .wizard__duo .wizard__case:first-of-type {
            margin-right: 20px;
          }

          .wizard__duo .wizard__case:last-of-type {
            margin-left: 20px;
          }

          .wizard label {
            margin-bottom: 4px;
          }

          .wizard__stat {
            margin-top: 7px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .wizard__single {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
          }

          .wizard__case {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }

          .wizard__list {
            border-radius: 0px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            border: 1px solid #d2d6da;
          }

          .wizard__list__header {
            padding: 5px 0px;
            display: flex;
            width: 100%;
            background-color: #f2f2f2;
          }

          .wizard__list__header span {
            flex: 1;
            font-weight: 600;
            text-align: center;
          }

          .wizard__list__contain {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 40vh;
            overflow-y: scroll;
          }

          .wizard__list__contain__item {
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid #d2d6da;
          }

          .wizard__list__contain__item * {
            flex: 1;
            text-align: center;
          }

          @media screen and (max-width: 1000px) {
            .wizard {
              width: 90%;
              padding-right: 0px;
              padding-left: 0px;
            }

            .wizard * {
              font-size: 13px;
            }

            .wizard__subtitle {
              font-size: 16px;
            }

            .wizard__duo {
              flex-direction: column;
            }

            .wizard__duo .wizard__case:first-of-type {
              margin-right: 0px;
            }

            .wizard__duo .wizard__case:last-of-type {
              margin-left: 0px;
            }

            .wizard__form {
              margin-left: 15px;
              width: 100%;
              min-width: auto;
            }

            .wizard__note {
              margin-right: 15px;
            }
          }

          .rs-picker-default .rs-picker-toggle.rs-btn-lg {
            margin-top: 0.25rem;
            padding-top: 0.7rem;
            padding-bottom: 0.35rem;
            height: 2.8rem;
          }

          .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
            top: 0.4rem;
          }

          .rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
            padding-top: 0.35rem;
          }

          .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
            top: 0.7rem;
          }

          .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
            top: 0.7rem;
          }

          .rs-picker-menu {
            position: absolute;
            text-align: left;
            z-index: 1300;
            border-radius: 6px;
            background-color: #fff;
            background-color: var(--rs-bg-overlay);
            -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            -webkit-box-shadow: var(--rs-shadow-overlay);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: var(--rs-shadow-overlay);
            overflow: hidden;
            -webkit-transition: none;
            transition: none;
            color: #ababab;
            /* stylelint-disable-next-line */
          }

          .rs-picker-toggle-textbox {
            border: 1px solid #d2d6da;
          }

          .rs-picker-toggle-active {
            box-shadow: 0 0 0 3px transparent;
          }

          .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
          .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
          .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
            border: 2px solid black;
          }

          .rs-picker-toggle-value {
            color: #ababab;
          }
        `}
      </style>
    </div>
  );
}
