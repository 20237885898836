/* eslint-disable implicit-arrow-linebreak */
import React from "react";

import useDictionaries from "assets/commonHooks/useDictionaries";
import MDTypography from "assets/@ndres-ui/MDTypography";

const MotoristTable = ({ motoristInfos }) => {
  const text = useDictionaries();
  const {
    firstName = "",
    address,
    addressDetails,
    lastName = "",
    email,
    city = "",
    mobile,
    country = "",
    state,
    addressVerified,
    isAnonymous,
    shortUid,
  } = motoristInfos;

  const codeClient = `OSP_${shortUid}`;

  const verifiedColor = addressVerified ? "success" : "warning";
  return (
    <>
      {/* Motorist  */}
      <section className="section_details">
        <MDTypography className="global_xs_title" type="tableHeader">
          {text("widgets-motorist-details-table-header-motorist-infos")}
        </MDTypography>
        <div className="global_separa"></div>
        <div className="place_rub">
          {isAnonymous ? (
            <MDTypography type="tableData">{text("widgets-motorist-details-anonymous")}</MDTypography>
          ) : (
            <div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-first-name")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {firstName}
                  </MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-address")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {address}
                  </MDTypography>
                </div>
              </div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-last-name")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {lastName}
                  </MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-address-comp")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {addressDetails}
                  </MDTypography>
                </div>
              </div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-email")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="lowercase">
                    {email}
                  </MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="capitalize">
                    {text("widgets-motorist-details-table-motorist-zip-city")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {city}
                  </MDTypography>
                </div>
              </div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-mobile")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {mobile}
                  </MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-country")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {country ? text(`global-country-${country ? country.toUpperCase() : ""}`) : ""}
                  </MDTypography>
                </div>
              </div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-address-status")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="status" color={verifiedColor} textTransform="first">
                    {text(`widgets-session-details-table-motorist-address-${state ? state?.toLowerCase() : ""}`)}
                  </MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-client")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData">{codeClient}</MDTypography>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default MotoristTable;
