import React, { useEffect, useState } from "react";
import { useSelector } from "@xstate/react";
import { service } from "../../../reducer";

import DoneAllIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";

// @mui materials

import DataTable from "assets/@ndres-ui/DataTable/index";

import Grid from "@mui/material/Grid";
import MDBox from "assets/@ndres-ui/MDBox";
import useDictionaries from "assets/commonHooks/useDictionaries";
import Popup from "../../../../../assets/@redapuenta-ui/popup/popup";
import MDTypography from "../../../../../assets/@ndres-ui/MDTypography";
// local hooks

export default function Step1({ addCompleted, formData, addNextBt }) {
  const text = useDictionaries();
  const motorist = useSelector(service, ({ context }) => context.motorist);

  const validMotoristList = Array.isArray(motorist) && motorist.length > 0;
  const errors = motorist.reduce((acc, { valid }) => (valid ? acc : acc + 1), 0);

  useEffect(() => {
    const testing = [validMotoristList, errors <= 0];

    const result = testing.reduce((c, o) => c && o, true);
    addNextBt(1, result);
  }, [formData, validMotoristList, errors]);

  useEffect(() => {
    addCompleted(1, true);
  }, []);

  const [dialogErrorDetails, setDialogErrorDetails] = useState(false);
  const openDialogErrorDetails = (params) => {
    setDialogErrorDetails(true);
  };

  const closeDialogErrorDetails = () => {
    setDialogErrorDetails(false);
  };

  return (
    <>
      <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
        <MDBox
          pt={0}
          style={{
            transition: "opacity ease 1s",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <MDBox mt={2} pt={1} ml={1} pr={2}>
                <DataTable
                  table={{
                    columns: [
                      {
                        Header: text("wizard-local-motorist-credential"),
                        accessor: "credential",
                        width: "25%",
                        align: "left",
                      },
                      {
                        Header: text("wizard-local-motorist-visible-id"),
                        accessor: "externalId",
                        width: "15%",
                        align: "left",
                      },
                      {
                        Header: text("wizard-local-motorist-plate"),
                        accessor: "plate",
                        width: "15%",
                        align: "left",
                      },
                      {
                        Header: text("wizard-local-motorist-username"),
                        accessor: "email",
                        width: "25%",
                        align: "left",
                      },
                      {
                        Header: text("wizard-local-motorist-firstName"),
                        accessor: "firstName",
                        width: "15%",
                        align: "left",
                      },
                      {
                        Header: text("wizard-local-motorist-lastName"),
                        accessor: "lastName",
                        width: "15%",
                        align: "left",
                      },
                      {
                        Header: <div />,
                        accessor: "check",
                        width: "13%",
                        align: "right",
                      },
                    ],
                    rows: motorist.map((params) => ({
                      ...params,
                      check: params.valid ? (
                        <DoneAllIcon
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "green",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            cursor: "pointer",
                          }}
                          onClick={(e) => openDialogErrorDetails()}
                        >
                          <MDTypography
                            fontSize="0.9rem"
                            style={{
                              lineHeight: 1,
                              color: "red",
                              pointerEvent: "none",
                              textDecoration: "underline",
                            }}
                          >
                            {text("global-show-error")}
                          </MDTypography>
                        </div>
                      ),
                    })),
                  }}
                  isSorted={false}
                  showTotalEntries={true}
                  entriesPerPage={{ defaultValue: 8, entries: [8] }}
                  // noEndBorder
                  canSearch
                  showTotalErrors={`${errors} Errors`}
                  pagination={{ variant: "contained", color: "info" }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <style jsx="true">
          {`
            .rs-picker-default .rs-picker-toggle.rs-btn-lg {
              padding-top: 0.4rem;
              padding-bottom: 0.2rem;
              height: 2.2rem;
            }

            .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
              top: 0.4rem;
            }

            .rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
              padding-top: 0.2rem;
            }

            .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
              top: 0.5rem;
            }

            .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
              top: 0.4rem;
            }

            .rs-picker-menu {
              position: absolute;
              text-align: left;
              z-index: 1300;
              border-radius: 6px;
              background-color: #fff;
              background-color: var(--rs-bg-overlay);
              -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
              -webkit-box-shadow: var(--rs-shadow-overlay);
              box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
              box-shadow: var(--rs-shadow-overlay);
              overflow: hidden;
              -webkit-transition: none;
              transition: none;
              color: #ababab;
              /* stylelint-disable-next-line */
            }

            .rs-picker-toggle-textbox {
              border: 1px solid #d2d6da;
            }

            .rs-picker-toggle-active {
              box-shadow: 0 0 0 3px transparent;
            }

            .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
            .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
            .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
            .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
            .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
            .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
              border-color: #8e0205;
              border: 2px solid #8e0205;
            }

            .rs-picker-toggle-value {
              color: #ababab;
            }
          `}
        </style>
      </MDBox>
      {dialogErrorDetails ? (
        <Popup
          titlePopup={{ type: "basic", value: text(`global-error-title-csv-syntax`) }}
          textPopup={{
            type: "basic",
            value: text(`global-error-text-csv-syntax`),
          }}
          handlePopupExit={closeDialogErrorDetails}
        />
      ) : null}
    </>
  );
}
