import React from "react";
import useDictionaries from "assets/commonHooks/useDictionaries";
import MDTypography from "assets/@ndres-ui/MDTypography";

const SessionStatus = (props) => {
  const { status, reason } = props;
  const text = useDictionaries();
  const statusProperties = {
    CREATED: { color: "#ffa000", translationTag: "global-state-created" },
    ENTRY_AUTHORIZED: {
      color: "success",
      translationTag: "global-state-entry-authorized",
    },
    ENTRY_DENIED: {
      color: "error",
      translationTag: "global-state-entry-denied",
    },
    ENTERED: { color: "success", translationTag: "global-state-entered" },
    EXIT_AUTHORIZED: {
      color: "success",
      translationTag: "global-state-exit-authorized",
    },
    EXIT_DENIED: {
      color: "error",
      translationTag: "global-state-exit-denied",
    },
    EXITED: { color: "success", translationTag: "global-state-exited" },
  };
  const selectedColor = statusProperties[status]?.color;
  const translatedStatus = statusProperties[status]?.translationTag;
  return (
    <>
      <MDTypography
        style={{ lineHeight: 1, marginBottom: "3px" }}
        type="status"
        color={selectedColor}
        textTransform="first"
      >
        {text(translatedStatus)}
      </MDTypography>
      <MDTypography
        style={{ lineHeight: 1, fontWeight: "bold", fontSize: "0.8em" }}
        type="status"
        color={selectedColor}
        textTransform="first"
      >
        {reason ? text(`global-reason-${`${reason}`}`) : ""}
      </MDTypography>
    </>
  );
};

export default SessionStatus;
