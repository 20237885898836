import React, { useEffect, useRef } from "react";
import { useSelector } from "@xstate/react";

import { service } from "../../../reducer";

// @mui materials
import { styled } from "@mui/system";
import MDInput from "assets/@ndres-ui/MDInput";
import MenuItem from "@mui/material/MenuItem";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

// rsuite
import DatePicker from "rsuite/DatePicker";
import DateRangePicker from "rsuite/DateRangePicker";
import { subDays, format, addDays } from "date-fns";

import Grid from "@mui/material/Grid";
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";
import { providersCredentials } from "assets/commonFunctions/useProvider";
import { isToday } from "../../../../../assets/commonFunctions/isToday";
import { DateTime } from "luxon";

// local hooks
const { allowedRange, beforeToday, combine } = DateRangePicker;

const Input = styled(MDInput)(({ theme }) => ({
  width: "calc(100% + 2px)",
  margin: "0px -5px",
  // height: "2.2rem",
  borderColor: "#d2d6da",
  [`&:hover &:focused .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#d2d6da",
  },
}));

const CredentialInput = styled(MDInput)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: "100%",
  margin: "0px -5px",
  marginTop: "5px",
  padding: "0px",
  paddingLeft: "4px",
}));

const SelectMUI = styled(({ value, ...rest }) => (
  <Input
    value={value ?? ""}
    InputProps={{
      style: {
        height: 44.125,
        // padding: '12px',
        width: "100%",
      },
    }}
    {...rest}
  />
))(({ theme }) => ({
  width: "100%",
  padding: "2px 2px",
  margin: "3px 0px 0px -3px",
  [`&:hover &:focused .${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#d2d6da",
  },
}));

export default function Step1({ addCompleted, setData, formData, addNextBt }) {
  const ref = useRef(null);
  const text = useDictionaries();
  const product = useSelector(service, ({ context }) => context.product);
  const motoristInputs = useSelector(service, ({ context }) => context.motoristInputs);

  useEffect(() => {
    const testing = [
      !!product,
      !!motoristInputs?.startDate && motoristInputs?.startDate !== "",
      motoristInputs.credentialProvider !== "",
      motoristInputs.credentialValue !== "",
      motoristInputs.success,
      !motoristInputs.loading,
      !motoristInputs.error,
      motoristInputs.exist,
      motoristInputs.credentialValue,
    ];

    const result = testing.reduce((c, o) => c && o, true);
    addNextBt(0, result);
  }, [formData, motoristInputs]);

  useEffect(() => {
    addCompleted(0, true);
  }, []);

  useEffect(() => {
    setData({
      productData: product,
    });
  }, [product]);

  if (!product?.startDate) {
    return "loading...";
  }
  return (
    <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
      <MDBox
        pt={0}
        style={{
          transition: "opacity ease 1s",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox ml={5} style={{}}></MDBox>
          </Grid>
          <Grid item xs={5}>
            <MDBox mt={0} pt={0} ml={5} style={{}}>
              <MDTypography fontSize="0.9rem" fontWeight="regular" color="dark" mb={0}>
                {text("Wizard-single-motorist-credential-type")}
              </MDTypography>
              <SelectMUI
                value={motoristInputs.credentialProvider}
                // label="provider"
                type="country"
                id="country"
                select
                selected="none"
                multiple
                onChange={(e) =>
                  service.send({
                    type: motoristInputs.credentialValue !== "" ? "motoristCredential:change" : "motorist:change",
                    payload: { credentialProvider: e.target.value, control: true },
                  })
                }
                SelectProps={{
                  MenuProps: {
                    style: {
                      height: "300px",
                    },
                  },
                }}
                InputProps={{
                  style: {
                    // height: "4.2rem",
                    width: "100%",
                    // marginTop: "-5px",
                  },
                }}
              >
                <MenuItem value="none" key="none"></MenuItem>
                {providersCredentials.map((value) => (
                  <MenuItem value={value} key={value}>
                    {text(`global-providers-${value.toLowerCase()}`)}
                  </MenuItem>
                ))}
              </SelectMUI>

              <MDTypography fontSize="0.9rem" fontWeight="regular" color="dark" mt={2}>
                {text("Wizard-single-motorist-credential-number")}
              </MDTypography>

              <CredentialInput
                required
                value={motoristInputs.credentialValue}
                autoComplete="off"
                type="credentials"
                id="credentials"
                loading={motoristInputs.loading}
                error={motoristInputs.error || !!motoristInputs.exist || motoristInputs.credentialValue === ""}
                success={
                  motoristInputs.success &&
                  motoristInputs.exist &&
                  !motoristInputs.error &&
                  // motoristInputs.credentialLinkedUsername !== "" &&
                  motoristInputs.credentialValue !== ""
                }
                onChange={(e) =>
                  service.send({
                    type: "motoristCredential:change",
                    payload: { credentialValue: e.target.value, control: true },
                  })
                }
              />
              <MDBox pl={1}>
                {!motoristInputs.error && motoristInputs.found && motoristInputs.credentialLinkedUsername ? (
                  <MDBox>
                    <MDTypography fontSize="1rem">
                      {text("Wizard-first-step-card-linked")}
                      &nbsp;
                      <span style={{ fontWeight: "bold" }}>{motoristInputs.credentialLinkedUsername}</span>
                    </MDTypography>
                    <br />
                    <MDTypography fontSize="1rem">{text("Wizard-first-step-card-validated")}</MDTypography>
                  </MDBox>
                ) : (
                  <></>
                )}
                {!motoristInputs.error &&
                motoristInputs.found &&
                motoristInputs.exist &&
                !motoristInputs.credentialLinkedUsername ? (
                  <MDBox>
                    <MDTypography fontSize="1rem">{text("Wizard-first-step-card-not-linked")}</MDTypography>
                    <br />
                    <MDTypography fontSize="1rem">{text("Wizard-first-step-card-validated")}</MDTypography>
                  </MDBox>
                ) : (
                  <></>
                )}
                {motoristInputs.error ? (
                  <MDBox>
                    <MDTypography fontSize="1rem">
                      {`${text("Wizard-first-step-card-refused-by")} ${motoristInputs.credentialProvider}`}
                    </MDTypography>
                  </MDBox>
                ) : (
                  <></>
                )}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={5}>
            <MDBox mt={0} pt={0} ml={5} style={{ maxWidth: "450px" }}>
              <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="dark" mb={0}>
                {text("Wizard-pool-start-date-picker-label")}
              </MDTypography>
              <MDBox width="100%" ref={ref} style={{ maxWidth: "350px" }} ml="0px" mb="10px">
                <DatePicker
                  size="lg"
                  oneTap
                  value={motoristInputs?.startDate ? new Date(motoristInputs.startDate) : null}
                  format="yyyy-MM-dd"
                  onChange={(date) => {
                    service.send({
                      type: "motorist:change",
                      payload: {
                        startDate: isToday(date)
                          ? new Date()
                          : new Date(DateTime.fromISO(date.toISOString()).startOf("day")),
                        endDate: null,
                      },
                    });
                  }}
                  ranges={[]}
                  placeholder={text("Wizard-pool-start-date-picker-placeholder")}
                  style={{ width: "246px" }}
                  shouldDisableDate={combine(
                    allowedRange(
                      format(new Date(product?.startDate), "yyyy-MM-dd"),
                      format(subDays(new Date(product?.stopDate), 1), "yyyy-MM-dd"),
                    ),
                    beforeToday(),
                  )}
                />
              </MDBox>
              <MDTypography textTransform="first" fontSize="0.9rem" fontWeight="regular" color="dark" mt={2}>
                {text("Wizard-pool-end-date-picker-label")}
              </MDTypography>
              <MDBox width="100%" ref={ref} style={{ maxWidth: "350px" }} ml="0px" mb="10px">
                <DatePicker
                  disabled={!motoristInputs?.startDate}
                  oneTap
                  value={motoristInputs?.endDate ? new Date(motoristInputs.endDate) : null}
                  size="lg"
                  format="yyyy-MM-dd"
                  onChange={(date) => {
                    service.send({
                      type: "motorist:change",
                      payload: { endDate: date ? new Date(DateTime.fromISO(date.toISOString()).endOf("day")) : null },
                    });
                  }}
                  ranges={[]}
                  placeholder={text("Wizard-pool-end-date-picker-placeholder")}
                  style={{ width: "246px" }}
                  shouldDisableDate={combine(
                    allowedRange(
                      format(
                        motoristInputs?.startDate
                          ? addDays(new Date(motoristInputs?.startDate), 1)
                          : new Date(product?.startDate),
                        "yyyy-MM-dd",
                      ),
                      format(subDays(new Date("2999-12-31"), 1), "yyyy-MM-dd"),
                    ),
                    beforeToday(),
                  )}
                />
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <style jsx="true">
        {`
          .rs-picker-default .rs-picker-toggle.rs-btn-lg {
            margin-top: 0.25rem;
            padding-top: 0.7rem;
            padding-bottom: 0.35rem;
            height: 2.8rem;
          }

          .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
            top: 0.4rem;
          }

          .rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
            padding-top: 0.35rem;
          }

          .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
            top: 0.7rem;
          }

          .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
            top: 0.7rem;
          }

          .rs-picker-menu {
            position: absolute;
            text-align: left;
            z-index: 1300;
            border-radius: 6px;
            background-color: #fff;
            background-color: var(--rs-bg-overlay);
            -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            -webkit-box-shadow: var(--rs-shadow-overlay);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: var(--rs-shadow-overlay);
            overflow: hidden;
            -webkit-transition: none;
            transition: none;
            color: #ababab;
            /* stylelint-disable-next-line */
          }

          .rs-picker-toggle-textbox {
            border: 1px solid #d2d6da;
          }

          .rs-picker-toggle-active {
            box-shadow: 0 0 0 3px transparent;
          }

          .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
          .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
          .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
          .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
            border-color: #8e0205;
            border: 2px solid #8e0205;
          }

          .rs-picker-toggle-value {
            color: #ababab;
          }
        `}
      </style>
    </MDBox>
  );
}
