import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import { DateTime } from "luxon";
import poolContractsAndSubGql from "./poolContractsAndSub.gql";
import localMotoristGql from "./localMotorist.gql";
import createMotoristGql from "./createMotorist.gql";

const searchPoolContractsFunction = async ({ token, parkingId, selectedParkings }, { payload }) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: poolContractsAndSubGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          page: payload?.newPage ? payload.newPage : 1,
          limit: 12,
          channels: [
            {
              channel: "parking",
              _id: parkingId[0],
            },
          ],
          category: "TENANT_POOL",
        },
      })
      .then((response) => {
        resolve(response.data.poolContractsAndSub);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const createContract = async ({ token, motoristChecked, formContract, currentIndex }, { payload }) => {
  return new Promise((resolve, reject) => {
    // Check if we still have a motorist to handle
    if (currentIndex > motoristChecked.length - 1) reject({});

    const { _id, username, lastName, firstName } = motoristChecked[currentIndex];

    client
      .query({
        query: localMotoristGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          input: { localMotoristId: _id },
        },
      })
      .then((response) => {
        const localMotorist = response?.data?.localMotorist;

        const {
          uids,
          plates,
          externalId,
          email: username,
          firstName,
          lastName,
          address1,
          address2,
          zipcode,
          city,
          country,
          phone,
          lang,
          createdAt,
        } = localMotorist;
        const {
          pool: { linkedContractId, productId },
          startDate,
          stopDate,
          freeFlagIsEnabled,
        } = formContract;

        const uidsFormatted = uids.map(({ type, value, description, provider }) => {
          return { type, value, description, provider };
        });

        const platesFormatted = plates.map(({ type, value, description }) => {
          return { type, value, description };
        });

        const externalIdFormatted = [{ type: "PROVIDER_EXTERNAL_ID", provider: "LOCAL", value: externalId }];

        const credentials = [...uidsFormatted, ...platesFormatted, ...externalIdFormatted];

        const input = {
          username,
          firstName,
          lastName,
          address1,
          address2,
          zipcode,
          city,
          country,
          phone,
          lang,
          contract: {
            productId,
            startDate,
            stopDate,
            isEnabled: true,
            linkedContractId,
            freeFlagIsEnabled: freeFlagIsEnabled ? freeFlagIsEnabled : false,
          },
          credentials,
        };

        client
          .mutate({
            mutation: createMotoristGql,
            fetchPolicy: "no-cache",
            context: { headers: { "x-access-token": token } },
            variables: {
              input: input,
            },
          })
          .then((response) => {
            resolve({
              ...response.data.createMotorist?.motorist,
              valid: true,

              errors: [],
            });
          })
          .catch((e) => {
            reject({
              username,
              firstName,
              lastName,
              valid: false,
              errors: e,
            });
          });
      })
      .catch((e) => {
        reject({
          username,
          firstName,
          lastName,
          valid: false,
          errors: e,
        });
      });
  });

  // client
  //   .mutate({
  //     mutation: createContractGQL,
  //     fetchPolicy: "no-cache",
  //     context: { headers: { "x-access-token": token } },
  //     variables: {
  //       input: {
  //         username: username && username.length > 0 ? username : null,
  //         firstName,
  //         lastName,
  //         lang,
  //         credentials,
  //         contract: {
  //           productId: productId,
  //           linkedContractId: contractId,
  //           freeFlagIsEnabled,
  //           isEnabled: true,
  //           startDate: startDate,
  //           stopDate: stopDate,
  //         },
  //       },
  //     },
  //   })
  //   .then((response) => {
  //     resolve({
  //       ...response.data.createMotorist?.motorist,
  //       credential,
  //       valid: true,
  //       ligne,
  //       errors: [],
  //     });
  //   })
  //   .catch((e) => {
  //     reject({
  //       username,
  //       firstName,
  //       lastName,
  //       credential,
  //       valid: false,
  //       ligne,
  //       errors: e,
  //     });
  //   });
  // });
};

const userProviderModel = createModel({
  error: undefined,
  errorMessage: "",
  token: "",
  queryStatus: "off",
  showWizard: false,
  parkingId: undefined,
  currentIndex: 0,
  finish: false,
  poolContracts: undefined,
  motoristChecked: [],
  formContract: {},
  motoristDispatched: [],
  motoristAcepted: [],
  motoristRejected: [],
});

const Wizard = createMachine(
  {
    context: userProviderModel.initialContext,
    id: "WizardCreateContractUserProvider",
    initial: "off",
    states: {
      off: {
        on: {
          "WizardCreateContractUserProvider:wakeup": { actions: "reload data model", target: "idle" },
        },
      },
      idle: {
        on: {
          "WizardCreateContractUserProvider:wakeup": { actions: "reload data model" },
          "WizardCreateContractUserProvider:sleep": {
            actions: "hide wizard",
            target: "off",
          },
          "WizardCreateContractUserProvider:reset": {
            actions: "wizardReset",
          },
          "WizardCreateContractUserProvider:motorists:createContract": {
            actions: ["reload set motorist"],
            target: "createContract",
          },
          "WizardCreateContractUserProvider:exit": {
            actions: "wizardExit",
          },
          "WizardCreateContractUserProvider:search:poolcontracts": {
            target: "searchPoolContracts",
          },
          "WizardCreateContractUserProvider:onSelectPage": {
            target: "searchPoolContracts",
          },

          "WizardCreateContractUserProvider:form:change": {
            actions: ["update form contract"],
          },
        },
      },
      createContract: {
        invoke: {
          id: "createContract",
          src: createContract,
          onDone: [
            {
              actions: ["save create contract response", "saveContractAccepted"],
              target: "createContract",
              cond: ({ currentIndex, motoristChecked }) => currentIndex < motoristChecked.length,
            },
            { actions: "finish create contract process", target: "idle" },
          ],
          onError: [
            {
              actions: ["save create contract response", "saveContractReject"],
              target: "createContract",
              cond: ({ currentIndex, motoristChecked }) => currentIndex < motoristChecked.length,
            },
            { actions: "finish create contract process", target: "idle" },
          ],
        },
      },
      searchPoolContracts: {
        entry: "save token",
        invoke: {
          id: "searchPoolContracts",
          src: searchPoolContractsFunction,
          onDone: [{ actions: "populateSearchPoolContracts", target: "idle" }],
          onError: [{ actions: "populateSearchPoolContractsError", target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populateSearchPoolContracts: assign({
        poolContracts: (_, { data }) => {
          return data;
        },
      }),
      populateSearchPoolContractsError: assign({
        error: (_, { data }) => true,
        errorMessage: (_, { data }) => {
          return data;
        },
      }),
      "hide wizard": assign({
        queryStatus: (ctx, { payload }) => "idle",
        showWizard: (ctx, { payload }) => false,
        parkingId: (ctx, { payload }) => undefined,
      }),
      wizardReset: assign({
        queryStatus: (ctx, { payload }) => "off",
        error: (_, { data }) => undefined,
        errorMessage: (_, { data }) => "",
        currentIndex: () => 0,
        finish: () => false,
        formContract: () => {},
        motoristDispatched: () => [],
        motoristAcepted: () => [],
        motoristRejected: () => [],
      }),
      wizardExit: assign({
        queryStatus: (ctx, { payload }) => "off",
        showWizard: (ctx, { payload }) => {
          return false;
        },
        error: (_, { data }) => undefined,
        errorMessage: (_, { data }) => "",
        currentIndex: () => 0,
        finish: () => false,
        formContract: () => {},
        motoristDispatched: () => [],
        motoristAcepted: () => [],
        motoristRejected: () => [],
      }),
      "reload data model": assign({
        showWizard: (ctx, { payload }) => {
          return true;
        },
        token: () => localStorage.getItem("token"),
        parkingId: (ctx, { payload }) => payload.selectedParking,
        motoristChecked: (ctx, { payload }) => {
          return payload.motoristChecked;
        },
        // finish: () => false,
        // currentIndex: () => 0,
      }),
      "save token": assign({
        token: () => {
          return localStorage.getItem("token");
        },
      }),
      "reload set motorist": assign({
        motoristDispatched: (_, { payload }) => [],
        motoristAcepted: (_, { payload }) => [],
        motoristRejected: (_, { payload }) => [],
        currentIndex: () => 0,
        finish: () => false,
      }),
      "save create contract response": assign({
        motoristDispatched: ({ motoristDispatched }, { data: motorist }) => {
          return [...motoristDispatched, motorist];
        },
        currentIndex: ({ currentIndex }) => currentIndex + 1,
      }),
      "finish create contract process": assign({
        finish: () => true,
      }),
      saveContractAccepted: assign({
        motoristAcepted: ({ motoristAcepted }, { data }) => [...motoristAcepted, { ...data }],
      }),
      saveContractReject: assign({
        motoristRejected: ({ motoristRejected }, { data }) => [...motoristRejected, { ...data }],
      }),

      "update form contract": assign({
        formContract: (ctx, { payload }) => {
          return { ...ctx.formContract, ...payload };
        },
      }),
    },
  },
);

export const service = interpret(Wizard)
  .start()
  .onTransition((state) => {
    if (window)
      window.postMessage(
        {
          type: "XSTATE_MACHINE_REDUCER",
          payload: {
            event: `${state?.event?.type}`,
            machine: state.machine.id,
            state: `${state.value}`,
            context: { context: state.context, payload: state?.event?.payload ?? {} },
            // type,
            // payload,
            // startState: startState,
            // endState: newState.value,
            // history: newState.history,
            // at: new Date()
          },
        },
        "*",
      );
  });

const widgetReducer = (state = service.initialState, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
