import React from "react";
import MDBox from "../../../../../assets/@ndres-ui/MDBox";
import MDTypography from "../../../../../assets/@ndres-ui/MDTypography";

const Plate = () => {
  return (
    <MDBox mt={2} pt={1} ml={2} style={{ maxWidth: "450px" }}>
      <MDTypography fontSize="0.7em" fontStyle="italic">
        Example :
        <br />
        ZH123456
        <br />
        ZH100225
      </MDTypography>
      <br />
      <MDTypography fontSize="0.7em" fontStyle="italic">
        ZH123456;;john;doe;
        <br />
        ZH100225;;alice;meyer;
      </MDTypography>
    </MDBox>
  );
};

export default Plate;
