import React, { createContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "@xstate/react";
import { service } from "../reducer";

const defaultContextData = {
  products: [],
};

export const LocalContext = createContext(defaultContextData);

export const ContextProvider = ({ children, storeName, events = {} }) => {
  const dispatch = useDispatch();

  const products = useSelector(service, ({ context: { products } }) => products);

  const showWizard = useSelector(service, ({ context: { showWizard } }) => showWizard);

  const parkingId = useSelector(service, ({ context: { selectedParking } }) => selectedParking);

  const eventDispatch = (key, fn, src) => {
    const payload = fn(src);
    dispatch({ type: `${storeName}:${key}`, payload });
  };

  const eventsActions = useMemo(
    () =>
      Object.entries(events).reduce(
        (acc, [key, fn]) => ({
          [key]: (src) => eventDispatch(key, fn, src),
          ...acc,
        }),
        {},
      ),
    [events],
  );

  const hide = () => {
    dispatch({ type: "WizardNewPool:sleep", payload: {} });
  };

  const renderProducts = useMemo(
    () =>
      products?.map((product) => ({
        _id: product._id,
        operatorId: product?.operatorId,
        parkingId: product?.parkingId,
        code: product?.code,
        createdAt: product?.createdAt,
        stopDate: product?.stopDate,
        startDate: product?.startDate,
        name: product.name,
        durationValue: product?.parameters?.durationValue,
        durationUnit: product?.parameters?.durationUnit,
        provider: product?.rules?.credentialsTypes?.types[0]?.provider,
        category: product?.category,
        price: (product?.parameters?.cents ?? 0) / 100,
        currency: product?.parameters?.currency ?? "?",
        type: product?.parameters?.durationUnit ?? "?",
        zoneCondition: product?.zoneProductConditions
          ? product.zoneProductConditions.map(({ zoneId, zoneProductConditionType }) => ({
              zoneId,
              zoneProductConditionType,
            }))
          : [],
      })),
    [products],
  );

  return (
    <LocalContext.Provider
      value={{
        parkingId,
        selection: showWizard ? "New subscription" : "",
        setSelection: hide,
        products: renderProducts,
        ...eventsActions,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

ContextProvider.defaultProps = {
  storeName: "WizardNewPool",
  events: {},
};

ContextProvider.propTypes = {
  storeName: PropTypes.string,
  events: PropTypes.shape({}),
};
