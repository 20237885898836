import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "@xstate/react";
import { service } from "../../../reducer";
import useDictionaries from "assets/commonHooks/useDictionaries";
import Paginat from "assets/@luc-ui/Pagination";

export default function Step2({ addCompleted, setData, formData, addNextBt }) {
  const text = useDictionaries();
  const contracts = useSelector(service, ({ context }) => context.contracts);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    service.send({
      type: "WizardCreateReportTechnical:search:contracts",
      payload: {},
    });

    addNextBt(1, true);
    addCompleted(1, true);
  }, []);

  useEffect(() => {
    if (contracts) setLoad(false);
  }, [contracts]);

  return (
    <>
      {load ? (
        <div className="load">
          <span className="load__text">Loading</span>
        </div>
      ) : (
        <div className="wizard">
          <div className="wizard__head">
            <h3 className="wizard__subtitle">
              {text("Wizard-report-technical-second-step-title-contract-count-before")} {contracts.paging.count}{" "}
              {text("Wizard-report-technical-second-step-title-contract-count-after")}
            </h3>
            <div className="wizard__line" style={{ padding: "5px 0" }}>
              <span className="wizard__legend">{text("Wizard-report-technical-second-step-header-firstname")}</span>
              <span className="wizard__legend">{text("Wizard-report-technical-second-step-header-lastname")}</span>
              <span className="wizard__legend media-hidden">
                {text("Wizard-report-technical-second-step-header-mail")}
              </span>
              <span className="wizard__legend media-hidden">
                {text("Wizard-report-technical-second-step-header-phone")}
              </span>
              <span className="wizard__legend">{text("Wizard-report-technical-second-step-header-neutralized")}</span>
            </div>
          </div>

          <div className="wizard__list">
            {contracts.list.map(({ motorist }, index) => {
              const { _id, firstName, lastName, username, phone } = motorist;
              return (
                <div key={index + _id} className="wizard__line">
                  <span className="wizard__field">{firstName}</span>
                  <span className="wizard__field">{lastName}</span>
                  <span className="wizard__field media-hidden">{username}</span>
                  <span className="wizard__field media-hidden">{phone}</span>
                  <div className="wizard__case">
                    <input type="checkbox" disabled checked />
                  </div>
                </div>
              );
            })}
          </div>

          <Paginat
            style={{
              bottom: "0px",
              marginTop: "0px",
              backgroundColor: "white",
              borderTop: "1px solid #e5e5ea",
              padding: "5px 0",
              zIndex: 5,
            }}
            page={contracts.paging.current}
            totalPages={Math.ceil(parseInt(contracts.paging.count) / parseInt(contracts.paging.limit))}
            position={"static"}
            onSelectPage={(newPage) =>
              service.send({
                type: "WizardCreateReportTechnical:onSelectPage",
                payload: {
                  newPage,
                },
              })
            }
          />
        </div>
      )}

      <style jsx="true">{`
        .load {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 40vh;
        }

        .load__text {
          font-size: 14px;
        }

        .wizard {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          border-radius: 6px;
          border: 1px solid #e5e5ea;
          height: 50vh;
          min-height: 500px;
          overflow-y: scroll;
          position: relative;
          width: 85%;
          box-sizing: border-box;
          margin: 20px;
        }

        .wizard * {
          line-height: 1;
          font-size: 14px;
        }

        .wizard__head {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 0px 0px 5px 0px;
          position: sticky;
          top: 0px;
          z-index: 10;
          background-color: white;
          border-bottom: 1px solid #e5e5ea;
        }

        .wizard__subtitle {
          padding: 12px 0;
          font-size: 17px;
        }

        .wizard__list {
          width: 100%;
          height: 100%;
          border-top: 1px solid #e5e5ea;
          overflow-y: scroll;
        }

        .wizard__line {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          width: 100%;
          justify-items: center;
          border-bottom: 1px solid #e5e5ea;
          padding: 10px 0;
        }

        .wizard__line:last-of-type {
          border: none;
        }

        .wizard__legend {
          display: inline-block;
          text-transform: capitalize;
          color: #b4afaa;
        }

        .wizard__line * {
          overflow: hidden;
        }

        .wizard__case {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .wizard__choice .wizard__case {
          margin-right: 15px;
        }

        .wizard label {
          margin-right: 4px;
        }

        @media screen and (max-width: 1100px) {
          .wizard {
            width: 95%;
            padding-right: 0px;
            padding-left: 0px;
          }

          .media-hidden {
            display: none;
          }

          .wizard * {
            font-size: 13px;
          }

          .wizard__subtitle {
            font-size: 14px;
          }

          .wizard__line {
            grid-template-columns: repeat(3, 1fr);
          }
        }
      `}</style>
    </>
  );
}
