import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import PropTypes from "prop-types";

const MotoristState = (props) => {
  const { isAnonymous } = props;

  const iconColor = isAnonymous ? "#b4afaa" : "#504b46";

  return <PersonIcon fontSize="medium" sx={{ color: iconColor }} />;
};

MotoristState.defaultProps = {
  isAnonymous: false,
};

MotoristState.propTypes = {
  isAnonymous: PropTypes.bool,
};

export default MotoristState;
