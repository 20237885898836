import React from "react";

import AccessPointTable from "./materials/table";

import { ContextProvider } from "./context";
import ContainerInGrid from "assets/@luc-ui/Container";

const AccessPointHistory = (props) => {
  const onSelectPage = (newPage) => {
    return { newPage };
  };
  const onSelectEventAccess = (selectEventAccessId) => ({
    selectEventAccessId,
  });

  return (
    <div style={{ height: "100%", overflow: "scroll" }}>
      <ContainerInGrid
        headerName="widgets-report-technicals-history-title"
        headerTextColor="primary"
        headerBackgroundColor="#ffffff"
      >
        <ContextProvider
          storeName="AccessPointHistory"
          events={{
            onSelectPage,
            onSelectEventAccess,
          }}
        >
          <AccessPointTable />
        </ContextProvider>
      </ContainerInGrid>
    </div>
  );
};

export default AccessPointHistory;
