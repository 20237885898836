import { gql } from "@apollo/client";

export default gql`
  query technicalEvents($parkingsIds: [String!]!, $page: Int, $limit: Int) {
    technicalEvents(parkingsIds: $parkingsIds, page: $page, limit: $limit) {
      list {
        _id
        key
        from
        createdAt
        updatedAt
        to {
          channel
          _id
        }
        payload
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
