import React from "react";
import { ContextProvider } from "./context";
import AccessPointAbout from "./About";

const AccessPointDetails = (props) => {
  const onUpdateAccessPoint = (data) => {
    return { data };
  };
  return (
    <div
      style={{
        height: "100%",
        overflow: "hidden",
        position: "relative",
        padding: "0",
      }}
    >
      <div>
        <ContextProvider
          storeName="AccessPointDetails"
          events={{
            onUpdateAccessPoint,
          }}
        >
          <AccessPointAbout />
        </ContextProvider>
      </div>
    </div>
  );
};

export default AccessPointDetails;
