import React, { useState } from "react";

import Step1 from "./stepsComponents/Step1";
import Step2 from "./stepsComponents/Step2";
import Step3 from "./stepsComponents/Step3";
import Step4 from "./stepsComponents/Step4";

// local hooks
import NewSubscriptionDialogStepper from "./DialogStepper";
import useStateCallback from "assets/commonHooks/useStateCallback/useStateCallback";
import { useDispatch } from "react-redux";

const stepsObj = {
  "Wizard-single-steps-catch-data": Step1,
  "Wizard-single-steps-verify-data": Step2,
  "Wizard-single-steps-create-contracts": Step3,
  "Wizard-single-steps-resume": Step4,
};

const initForm = {
  places: 2,
  isEnabled: true,
  lang: "none",
};

const StepperWizard = ({ dialog, setDialog, onSubmit, onClose, parkingId }) => {
  // const text = useDictionaries();
  const steps = [...Object.keys(stepsObj)];
  const [active, setActive] = useState(0);
  const [completed, setCompleted] = useState([true, false, false, false]);
  const [nextBt, setNextBt] = useState(steps.map(() => false));
  const [formData, setFormData] = useStateCallback({ ...initForm, parkingId });
  const dispatch = useDispatch();

  const Step = stepsObj[steps[active]];

  const reset = () => {
    setCompleted([true, false, false, false]);
    setActive(0);
    setFormData({ ...initForm, parkingId });
    dispatch({
      type: "WizardCreateSingleMotoristContractPool:reset",
      payload: {},
    });
  };

  const reload = ({ csv }) => {
    setCompleted([true, false, false, false]);
    setActive(0);
    setFormData({ ...initForm, parkingId });
    dispatch({
      type: "WizardCreateSingleMotoristContractPool:create-contract-reload",
      payload: { csv },
    });
  };

  const addCompleted = (index, newValue) => {
    const newCompleted = [...completed].map((currentValue, i) => (i === index ? newValue : currentValue));
    setCompleted(newCompleted);
  };

  const addNextBt = (index, newValue) => {
    const newNextBt = [...nextBt].map((currentValue, i) => (i === index ? newValue : currentValue));
    setNextBt(newNextBt);
  };

  const setData = (data) => {
    setFormData({ ...formData, ...data, parkingId });
  };

  const handleSubmit = () => {
    setActive(0);

    onSubmit({});
  };

  return (
    <NewSubscriptionDialogStepper
      dialog={dialog}
      setDialog={setDialog}
      steps={steps}
      active={active}
      setActive={setActive}
      completed={completed}
      nextBt={nextBt}
      setCompleted={setCompleted}
      onClose={onClose}
      reset={reset}
      onSubmit={handleSubmit}
      blockBack={formData.blockBack}
    >
      <Step
        addCompleted={addCompleted}
        addNextBt={addNextBt}
        setData={setData}
        formData={formData}
        reload={reload}
        reset={reset}
      />
    </NewSubscriptionDialogStepper>
  );
};

export default StepperWizard;
