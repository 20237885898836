import { gql } from "@apollo/client";

export default gql`
  query products($parkingsIds: [String]) {
    products(parkingsIds: $parkingsIds) {
      list {
        _id
        parkingId
        code
        name
        category
        startDate
        stopDate
        createdAt
        operatorId
        parameters
        rules
        operator {
          operatorCode
          operatorContractCount
        }
      }
    }
  }
`;
