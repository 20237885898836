import { gql } from "@apollo/client";

export default gql`
  mutation regenerateQrcode($motoristId: String!) {
    regenerateQrcode(motoristId: $motoristId) {
      motorist {
        credentials {
          type
          value
        }
      }
    }
  }
`;
