const hd = [
  {
    w: 84,
    h: 83,
    x: 1,
    y: 0,
    i: "Main",
  },
  {
    w: 84,
    x: 88,
    h: 83,
    y: 0,
    i: "details",
  },
];

const xl = [
  {
    w: 69,
    x: 0,
    h: 83,
    y: 0,
    i: "Main",
  },
  {
    w: 69,
    x: 72,
    h: 83,
    y: 0,
    i: "details",
  },
];

const lg = [
  {
    w: 59,
    x: 0,
    h: 83,
    y: 0,
    i: "Main",
  },
  {
    w: 59,
    x: 62,
    h: 83,
    y: 0,
    i: "details",
  },
];

const md = [
  {
    w: 47,
    x: 0,
    h: 83,
    y: 0,
    i: "Main",
  },
  {
    w: 47,
    x: 50,
    h: 83,
    y: 0,
    i: "details",
  },
];
export const layouts = {
  hd,
  xl,
  lg,
  md,
};
