import React, { useContext, useMemo } from "react";

import BusinessTenantsAboutTable from "components/BusinessTenantsDetails/Parent/About/materials/table";
import BusinessTenantsMotoristTable from "components/BusinessTenantsDetails/Parent/Motorists/materials/table";
import BusinessTenantsSessions from "components/BusinessTenantsDetails/Parent/Sessions/materials/table";

import { ContextProvider } from "./context";
import { LocalContext } from "../context";

const BusinessTenantsParentDetails = ({ displayAbout, displayContract, displaySessions }) => {
  const onContractStatusChange = (payload) => payload;
  const onContractUpdate = (payload) => payload;

  const { isParent } = useContext(LocalContext);
  const memoParent = useMemo(() => isParent, [isParent]);

  if (!memoParent) return <></>;
  return (
    <div style={{ display: memoParent ? "block" : "none" }}>
      <ContextProvider storeName="BusinessTenantsParentDetails" events={{ onContractStatusChange, onContractUpdate }}>
        <div style={{ display: `${displayAbout}` }}>
          <BusinessTenantsAboutTable />
        </div>
        <div style={{ display: `${displayContract}` }}>
          <BusinessTenantsMotoristTable />
        </div>
        <div style={{ display: `${displaySessions}` }}>
          <BusinessTenantsSessions />
        </div>
      </ContextProvider>
    </div>
  );
};

export default BusinessTenantsParentDetails;
