import React, { createContext, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "@xstate/react";
import { service } from "../reducer";

const defaultContextData = {
  list: [],
  page: 1,
  totalPages: 1,
};

export const LocalContext = createContext(defaultContextData);

export const ContextProvider = ({ children, storeName, events = {} }) => {
  const dispatch = useDispatch();

  const { list, paging } = useSelector(service, ({ context: { list, paging } }) => {
    return { list, paging };
  });

  const selectedSession = useSelector(service, ({ context: { selectedSession } }) => selectedSession);

  useEffect(() => {
    dispatch({ type: `${storeName}:wakeup`, payload: {} });
    return () => {
      dispatch({ type: `${storeName}:sleep`, payload: {} });
    };
  }, []);

  const eventDispatch = (key, fn, src) => {
    const payload = fn(src);
    dispatch({ type: `${storeName}:${key}`, payload });
  };

  const eventsActions = useMemo(
    () =>
      Object.entries(events).reduce(
        (acc, [key, fn]) => ({
          [key]: (src) => eventDispatch(key, fn, src),
          ...acc,
        }),
        {},
      ),
    [events],
  );

  const findLast = (array, filter) => {
    const result = array.filter(filter);
    return result[result.length - 1];
  };

  const renderList = useMemo(
    () =>
      list.map(
        ({ _id, isInconsistent = false, parkingId, occupancyDuration, events = [], state, motorist, contractId }) => {
          const ENTRY_AUTHORIZED = findLast(events, ({ state }) => state === "ENTRY_AUTHORIZED");
          const ENTRY_DENIED = findLast(events, ({ state }) => state === "ENTRY_DENIED");
          const ENTERED = findLast(events, ({ state }) => state === "ENTERED");
          const EXITED = findLast(events, ({ state }) => state === "EXITED");

          const eventsFiltered = events?.filter(
            (event) => !["CREATED", "EXIT_AUTHORIZE_PENDING"].includes(event.state),
          );

          const entry = ENTERED?.createdAt ?? ENTRY_AUTHORIZED?.createdAt ?? ENTRY_DENIED?.createdAt ?? undefined;
          const exit = EXITED?.createdAt ?? undefined;
          return {
            sessionId: _id,
            parkingName: `parking-${parkingId}-name`,
            entry,
            exit,
            duration: occupancyDuration,
            eventList: eventsFiltered,
            error: !!isInconsistent || state?.includes("DENIED"),
            isAnonymous: motorist?.isAnonymous ?? true,
            motoristContractExist: !!contractId,
            status: state === "EXIT_AUTHORIZE_PENDING" ? "ENTERED" : state,
            ENTRY_AUTHORIZED,
            ENTRY_DENIED,
            ENTERED,
            EXITED,
          };
        },
      ),
    [list],
  );

  const renderPaging = useMemo(() => {
    const { count = 0, limit = 10 } = paging;
    const total = Math.ceil(count / limit);

    return {
      page: paging.current,
      totalPages: total,
    };
  }, [paging]);

  return (
    <LocalContext.Provider
      value={{
        list: renderList,
        selectedSession: selectedSession,
        ...renderPaging,
        ...eventsActions,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

ContextProvider.defaultProps = {
  storeName: "",
  events: {},
};

ContextProvider.propTypes = {
  storeName: PropTypes.string,
  events: PropTypes.shape({}),
};
