import React from "react";

// Material UI
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import MDBox from "assets/@ndres-ui/MDBox";

export const ProductsWidgetLayout = styled(({ children, ...rest }) => <MDBox {...rest}>{children}</MDBox>)(
  ({ theme }) => ({
    padding: "5px 6px 10px 5px",
  }),
);

export const ProductsMapLayout = styled(({ children, ...rest }) => (
  <MDBox {...rest}>
    <Grid container spacing={2}>
      {children}
    </Grid>
  </MDBox>
))(({ theme }) => ({
  background: "white",
  overflowY: "auto",
  padding: "5px 10px 0px 10px",
}));

export const ProductsItemLayout = styled(({ children, ...rest }) => (
  <Grid item xs={12} sm={12} md={6} lg={4} xl={3} xxl={2}>
    <MDBox {...rest}>{children}</MDBox>
  </Grid>
))(({ theme }) => ({
  minHeight: "300px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

export const HeaderBox = styled(MDBox)(({}) => ({
  overflowY: "hidden",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  flexDirection: "column",
}));
