import { assign, createMachine, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import createLocalMotoristGql from "./createLocalMotorist.gql";

const verifiedEmail = (username) =>
  String(username)
    ?.toLowerCase()
    ?.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
const verifyPlate = (plate) =>
  String(plate)
    ?.toUpperCase()
    ?.match(/^[a-zA-Z0-9ÖÜÄËÏöüäëï]{1,12}$/);

const validatorErrors = (motorist, provider) => {
  let options = [
    // {
    //   name: "Format error",
    //   test: !motorist.credential && !motorist.plate.length,
    //   error: "validator-error-type-formatError",
    // },
    {
      name: "Invalid Email",
      test: motorist.username && !verifiedEmail(motorist.username),
      error: "validator-error-type-invalidEmail",
    },
  ];

  return options.reduce((acc, { test, error }) => (test ? [...acc, error] : acc), []);
};

const validatorSyntaxe = (values) => {
  const options = [
    {
      name: "Credential Not Exist",
      test: values.length < 1,
      error: "validator-error-type-credentialNotExist",
    },
    {
      name: "Chip id length error",
      test: values[1] ? values[1].length < 2 || values[1].length > 6 : false,
      error: "validator-error-type-credentialNotExist",
    },
    {
      name: "Invalid Plate",
      test: values[2] ? !verifyPlate(values[2]) : false,
      error: "validator-error-type-invalidPlate",
    },
  ];
  // if (values[1] && (values[1].length < 2 || values[1].length > 6))options.push
  return options.reduce((acc, { test, error }) => (test ? [...acc, error] : acc), []);
};

const CSVToJSON = (data, provider) => {
  const titles = ["credential", "externalId", "plate", "email", "firstName", "lastName"];

  return data?.map((ligne) => {
    const values = ligne.split(/[,;]/gi);
    const motorist = titles.reduce((obj, title, index) => ((obj[title] = values[index]), obj), {});

    const validatorCheck = validatorErrors(motorist, provider);

    const syntaxeErrors = validatorSyntaxe(values);

    return {
      ...motorist,
      ligne,
      valid: validatorCheck?.length <= 0,
      syntaxe: syntaxeErrors?.length <= 0,
      syntaxeErrors,
      errors: validatorCheck,
    };
  });
};

const createLocalMotorist = async (
  { token, motorist, currentIndex, lang },

  { payload },
) =>
  new Promise(async (resolve, reject) => {
    // Check if we still have a motorist to handle

    if (currentIndex > motorist.length - 1) return reject({});

    const { email, firstName, plate, lastName, credential, externalId, ligne } = motorist[currentIndex];

    // Handle difference between External ID and PLATE/QRCODE
    // Create contract
    client
      .mutate({
        mutation: createLocalMotoristGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          input: {
            email: email && email.length > 0 ? email : null,
            firstName,
            lastName,
            lang,
            plates: plate ? [{ type: "PLATE", value: plate.toUpperCase() }] : [],
            uids: credential ? [{ type: "RFID", value: credential, description: externalId, provider: "LOCAL" }] : [],
          },
        },
      })
      .then((response) => {
        resolve({
          ...response.data.createMotorist?.motorist,
          credential,
          externalId,
          email,
          firstName,
          lastName,
          plate: plate.toUpperCase(),
          valid: true,
          ligne,
          errors: [],
        });
      })
      .catch((e) => {
        reject({
          email,
          externalId,
          firstName,
          lastName,
          credential,
          plate: plate.toUpperCase(),
          valid: false,
          ligne,
          errors: e,
        });
      });
  });

const userModel = createModel({
  token: "",
  showWizard: false,
  selectedParking: undefined,
  parkingId: undefined,
  productId: undefined,
  contractId: undefined,
  product: undefined,
  csv: "",
  motorist: [],
  motoristDispatched: [],
  userProvider: "LOCAL",
  motoristAcepted: [],
  motoristRejected: [],
  currentIndex: 0,
  finish: false,
});

const Wizard = createMachine(
  {
    context: userModel.initialContext,
    id: "WizardCreateLocalMotorist",
    initial: "off",
    states: {
      off: {
        on: {
          "WizardCreateLocalMotorist:wakeup": { target: "loading" },
        },
      },
      idle: {
        on: {
          "WizardCreateLocalMotorist:csv:change": { actions: ["update:csv"] },
          "WizardCreateLocalMotorist:dispatch:motorists": {
            actions: ["reload motoristDispatched"],
            target: "dispatcher",
          },
          "WizardCreateLocalMotorist:sleep": {
            actions: "hide wizard",
            target: "off",
          },
          "WizardCreateLocalMotorist:create-contract-reset": {
            actions: "createContractReset",
          },
          "WizardCreateLocalMotorist:create-contract-reload": {
            actions: "createContractReload",
          },
        },
      },
      dispatcher: {
        invoke: {
          id: "createLocalMotorist",
          src: createLocalMotorist,
          onDone: [
            {
              actions: ["save response", "saveAcepted"],
              target: "dispatcher",
              cond: ({ currentIndex, motorist }) => currentIndex < motorist.length,
            },
            { actions: "end process", target: "idle" },
          ],
          onError: [
            {
              actions: ["save response", "saveReject"],
              target: "dispatcher",
              cond: ({ currentIndex, motorist }) => currentIndex < motorist.length,
            },
            { actions: "end process", target: "idle" },
          ],
        },
      },
      loading: {
        entry: ["reload data model"],
        always: {
          target: "idle",
        },
      },
    },
  },
  {
    actions: {
      "get token": assign({
        token: () => localStorage.getItem("token"),
      }),
      "hide wizard": assign({
        queryStatus: (ctx, { payload }) => "idle",
        showWizard: (ctx, { payload }) => false,
        parkingId: (ctx, { payload }) => undefined,
        contractId: (ctx, { payload }) => undefined,
      }),
      "reload data model": assign({
        showWizard: true,
        token: () => localStorage.getItem("token"),
        parkingId: (ctx, { payload }) => payload.parkingId,
        contractId: (ctx, { payload }) => payload.contractId,
        queryStatus: (ctx, { payload }) => undefined,
        csv: (_, { payload }) => "",
        motorist: (_, { payload }) => [],
        motoristDispatched: (_, { payload }) => [],
        motoristAcepted: (_, { payload }) => [],
        motoristRejected: (_, { payload }) => [],
        finish: () => false,
        currentIndex: () => 0,
      }),
      createContractReset: assign({
        error: (_, { data }) => null,
        externalId: (_, { data }) => null,
        queryStatus: (ctx, { payload }) => undefined,
        csv: (_, { payload }) => "",
        motorist: (_, { payload }) => [],
        motoristDispatched: (_, { payload }) => [],
        motoristAcepted: (_, { payload }) => [],
        motoristRejected: (_, { payload }) => [],
        finish: () => false,
        currentIndex: () => 0,
      }),
      createContractReload: assign({
        error: (_, { data }) => {
          return null;
        },
        externalId: (_, { data }) => null,
        queryStatus: (ctx, { payload }) => undefined,
        csv: (_, { payload }) => payload.csv,
        motorist: (_, { payload }) => CSVToJSON(payload.csv.split("\n").filter((ligne) => ligne !== "")),
        motoristDispatched: (_, { payload }) => [],
        motoristAcepted: (_, { payload }) => [],
        motoristRejected: (_, { payload }) => [],
        finish: () => false,
        currentIndex: () => 0,
      }),
      "update:csv": assign({
        csv: (_, { payload }) => payload?.value,
        motorist: (_, { payload }) =>
          CSVToJSON(
            payload?.value?.split("\n").filter((ligne) => ligne !== ""),
            payload?.userProvider,
          ),
        userProvider: (_, { payload }) => {
          return payload?.userProvider;
        },
        motoristDispatched: (_, { payload }) => [],
        motoristAcepted: (_, { payload }) => [],
        motoristRejected: (_, { payload }) => [],
      }),

      "save response": assign({
        motoristDispatched: ({ motoristDispatched }, payload) => {
          return [...motoristDispatched, payload.data];
        },
        currentIndex: ({ currentIndex }) => currentIndex + 1,
      }),
      saveAcepted: assign({
        motoristAcepted: ({ motoristAcepted }, { data }) => [...motoristAcepted, { ...data }],
      }),
      saveReject: assign({
        motoristRejected: ({ motoristRejected }, { data }) => [...motoristRejected, { ...data }],
      }),
      "end process": assign({
        finish: () => true,
      }),
      "reload motoristDispatched": assign({
        motoristDispatched: (_, { payload }) => [],
        motoristAcepted: (_, { payload }) => [],
        motoristRejected: (_, { payload }) => [],
        startDate: (_, { payload }) => payload?.startDate,
        stopDate: (_, { payload }) => payload?.stopDate,
        lang: (_, { payload }) => payload?.lang,
        freeFlagIsEnabled: (_, { payload }) => payload?.freeFlagIsEnabled,
        currentIndex: () => 0,
        finish: () => false,
      }),
    },
  },
);

export const service = interpret(Wizard).start();

const widgetReducer = (state = service.initialState, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
