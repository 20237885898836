/* eslint-disable implicit-arrow-linebreak */
import React, { useContext, useMemo } from "react";
import useDictionaries from "assets/commonHooks/useDictionaries";
import MotoristTable from "assets/@luc-ui/MotoristDetails/MotoristTable";
import MotoristPlatesTable from "assets/@luc-ui/MotoristDetails/MotoristPlatesTable";
import MotoristRFIDTable from "assets/@luc-ui/MotoristDetails/MotoristRFIDTable";
import { LocalContext } from "../../../context";
import MotoristQRTable from "../../../../../assets/@luc-ui/MotoristDetails/MotoristQRTable";

const MotoristAboutTable = () => {
  const text = useDictionaries();

  const { data, pdfToSAve, onDownloadPdf, onRegenerateQrcode } = useContext(LocalContext);

  const renderedData = useMemo(() => data, [data, text]);
  const renderedPdf = useMemo(() => pdfToSAve, [pdfToSAve, text]);
  if (!renderedData) return <></>;

  const { motoristDetails } = renderedData;
  const { plates } = motoristDetails;
  const { cards } = motoristDetails;
  const { qrCode } = motoristDetails;

  return (
    <>
      <MotoristTable motoristInfos={motoristDetails} />
      <MotoristPlatesTable credentials={plates} />
      <MotoristRFIDTable cards={cards} />
      <MotoristQRTable
        renderedPdf={renderedPdf}
        qrCode={motoristDetails.qrcode}
        onDownloadPdf={onDownloadPdf}
        onRegenerateQrcode={onRegenerateQrcode}
        motoristId={motoristDetails.motoristId}
      />
    </>
  );
};

export default MotoristAboutTable;
