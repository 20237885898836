import "rsuite/dist/rsuite.min.css";
import "./assets/@ndres-ui/styles/globals.css";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import "./assets/@ndres-ui/styles/tables.css";
import "/node_modules/slick-carousel/slick/slick.css";
import "/node_modules/slick-carousel/slick/slick-theme.css";

import React from "react";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./assets/@ndres-ui/theme";
import { MaterialUIControllerProvider } from "./assets/@ndres-ui";
import createEmotionCache from "./assets/@ndres-ui/theme/createEmotionCache";
import store from "./redux/store";
import { Provider } from "react-redux";

const clientSideEmotionCache = createEmotionCache();

function Layout({ children, emotionCache = clientSideEmotionCache }) {
  return (
    <Provider store={store}>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MaterialUIControllerProvider>{children}</MaterialUIControllerProvider>
        </ThemeProvider>
      </CacheProvider>
    </Provider>
  );
}

export default Layout;
