import { gql } from "@apollo/client";

export default gql`
  query products($parkingsIds: [String], $limit: Int) {
    products(parkingsIds: $parkingsIds, limit: $limit) {
      list {
        _id
        parkingId
        code
        name
        category
        startDate
        stopDate
        createdAt
        operatorId
        parameters
        rules
      }
    }
  }
`;
