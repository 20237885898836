import { gql } from "@apollo/client";

export default gql`
  mutation createBreakdown($input: CreateBreakdownDto!) {
    createBreakdown(input: $input) {
      _id
      startDate
      endDate
      description
      type
      channels {
        channel
        _id
      }
      createdAt
      updatedAt
    }
  }
`;
