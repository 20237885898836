import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Modal } from "@mui/material";

import useDictionaries from "assets/commonHooks/useDictionaries";

import { Duration } from "assets/@luc-ui/Duration";
import MDTypography from "assets/@ndres-ui/MDTypography";
import MDButton from "assets/@ndres-ui/MDButton";
import MDBox from "assets/@ndres-ui/MDBox";
import Popup from "assets/@redapuenta-ui/popup/popup";
import { useSelector } from "@xstate/react";
import { service } from "components/SessionDetails/reducer";
import { useDispatch } from "react-redux";

const SessionTable = ({ sessionInfos, onForceClose }) => {
  const text = useDictionaries();
  const dispatch = useDispatch();
  const { sessionParking, entry, duration, exit, parkingFee, isEntered, sessionId, isClosed } = sessionInfos;
  const [openModal, setOpenModal] = React.useState(false);

  const [modePopup, setModePopup] = useState("choice");

  const [openPopup, setOpenPopup] = useState(false);
  const [currentSession, setCurrentSession] = useState({});

  const [titlePopup, setTitlePopup] = useState();
  const [textPopup, setTextPopup] = useState();

  //! Selector for Popup
  const forceCloseSession = useSelector(service, ({ context }) => context.forceCloseSession);
  const errorForceCloseSession = useSelector(service, ({ context }) => context.errorForceCloseSession);

  //! SUCCESS for Popup
  useEffect(() => {
    if (currentSession.id === forceCloseSession.id && modePopup === "load") {
      setTextPopup({
        type: "success",
        value: text("popup-session-force-close-success"),
      });
      setModePopup("result");
    }
  }, [forceCloseSession]);

  //! ERROR for Popup
  useEffect(() => {
    if (errorForceCloseSession) {
      setTitlePopup({
        type: "basic",
        value: text(`global-error-title-${new String(errorForceCloseSession).toLowerCase()}`),
      });
      setTextPopup({
        type: "error",
        value: text(`global-error-text-${new String(errorForceCloseSession).toLowerCase()}`),
      });
      setModePopup("result");
    }
  }, [errorForceCloseSession]);

  //! Open Popup
  const forceCloseClick = (e) => {
    setTitlePopup({
      type: "basic",
      value: `${text("widgets-motorist-details-modal-first-p")} ${text("widgets-motorist-details-modal-second-p")}`,
    });
    setTextPopup(undefined);
    setModePopup("choice");
    setCurrentSession({ id: sessionId });
    e.stopPropagation();
    setOpenPopup(true);
  };

  //! Exit Popup
  const exitPopup = () => {
    setOpenPopup(false);
    setModePopup("choice");
  };

  //! Choice in Popup
  const choicePopup = (choice) => {
    const { id: sessionId } = currentSession;
    if (choice) {
      dispatch({
        type: "SessionDetails:reset-error",
        payload: {},
      });
      setModePopup("load");
      onForceClose(sessionId);
    } else {
      setModePopup("choice");
      setOpenPopup(false);
    }
  };

  return (
    <>
      <section className="section_details">
        <MDTypography className="global_xs_title" type="tableData" textTransform="first">
          {text("widgets-session-details-table-header-session-infos")}
        </MDTypography>
        <div className="global_separa"></div>

        <div>
          <div>
            <div className="flexTableRows">
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-session-details-table-session-parking")}
                </MDTypography>
              </div>
              <div className="flexTableData">
                <MDTypography type="tableData" textTransform="first">
                  {text(sessionParking)}
                </MDTypography>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-session-details-table-session-duration")}
                </MDTypography>
              </div>
              <div className="flexTableData">
                {isEntered ? (
                  <span style={{ fontWeight: "bolder" }}>
                    <Duration startDate={entry} endDate={exit} duration={duration} />
                  </span>
                ) : (
                  <>-</>
                )}
              </div>
            </div>
            <div className="flexTableRows">
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-session-details-table-session-entry")}
                </MDTypography>
              </div>
              <div className="flexTableData">
                <MDTypography type="tableData">
                  {entry ? format(new Date(entry), "dd.MM.yyyy HH:mm:ss") : ""}
                </MDTypography>
              </div>
              <div className="flexTableHead">
                <MDTypography className="global_label" type="tableHeader" textTransform="first">
                  {text("widgets-session-details-table-session-exit")}
                </MDTypography>
              </div>
              <div className="flexTableData">
                <MDTypography type="tableData" textTransform="first">
                  {exit ? format(new Date(exit), "dd.MM.yyyy HH:mm:ss") : ""}
                </MDTypography>
              </div>
            </div>
            {typeof parkingFee?.amount == "number" && parkingFee?.amount >= 0 ? (
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableHeader" textTransform="first">
                    {text("widgets-session-details-table-session-price")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData">
                    {(parseInt(parkingFee?.amount) / 100).toFixed(2)} {parkingFee?.currency}
                  </MDTypography>
                </div>
              </div>
            ) : null}
            <div style={{ marginTop: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              {!isClosed && (
                <MDButton size="small" color="primary" onClick={(e) => forceCloseClick(e)}>
                  {text("widgets-sessions-button-force-close")}
                </MDButton>
              )}
            </div>
          </div>
        </div>
        {openPopup ? (
          <Popup
            titlePopup={titlePopup}
            textPopup={textPopup}
            modePopup={modePopup}
            handlePopupChoice={choicePopup}
            handlePopupExit={exitPopup}
          />
        ) : null}
      </section>
    </>
  );
};

export default SessionTable;
