import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import FooterStepper from "./FooterStepper";
import StyledStepper from "./StyledStepper";
import MDBox from "assets/@ndres-ui/MDBox";
import MDButton from "assets/@ndres-ui/MDButton";
import colors from "assets/@ndres-ui/theme/base/colors";
import useDictionaries from "assets/commonHooks/useDictionaries";
import MDTypography from "assets/@ndres-ui/MDTypography";

const { primary } = colors;
const backdropstyle = {
  background: "#00000030",
};

const paperStyle = {
  // transform: 'translate(0px, -30vh)',
};

const BackdropComponent = styled(Backdrop, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => styles.backdrop,
})(backdropstyle);

const PaperComponent = styled(Paper, {
  name: "MuiModal",
  slot: "Backdrop",
  overridesResolver: (props, styles) => styles.backdrop,
})(paperStyle);

const EscTypography = styled(MDButton)(({ theme }) => ({
  ...theme.typography.body2,
  color: "#c2c2c3",
  minWidth: "40px",
  padding: "0px 10px",
  margin: "2px 5px 0px 0px",
  "&:hover": {
    color: primary.main,
  },
  "&:focus:not(:hover)": {
    color: "#c2c2c3",
  },
}));

const EscButton = styled(MDButton)(({ theme }) => {
  const { palette, functions } = theme;

  const { boxShadow } = functions;

  return {
    ...theme.typography.body2,
    color: "#c2c2c3",
    width: "33px",
    height: "33px",
    minWidth: "20px",
    padding: "0px",
    margin: "0px",
    "&:hover": {
      color: "white",
      backgroundColor: primary.main,
      boxShadow: `${boxShadow([0, 14], [26, -12], palette.primary.main, 0.4)}, ${boxShadow(
        [0, 4],
        [23, 0],
        palette.primary.main,
        0.15,
      )}, ${boxShadow([0, 8], [10, -5], palette.primary.main, 0.2)}`,
    },
  };
});

const EscIcon = styled(CloseIcon)(({ theme }) => ({
  width: "30px",
  height: "30px",
}));

export default function AlertDialog({
  dialog = "",
  setDialog,
  steps = [],
  children,
  active = 0,
  setActive,
  completed = [],
  nextBt = [],
  setCompleted = () => true,
  reset,
  onSubmit = () => true,
  onClose = () => true,
  blockBack = false,
}) {
  // const steps = [];

  const next = () => {
    if (active < steps.length - 1) setActive(active + 1);
  };

  const back = () => {
    setActive(active - 1);
  };

  const submit = () => {
    if (active >= steps.length - 1) onSubmit();
  };
  const text = useDictionaries();
  return (
    <Dialog
      open={dialog === "New Sub contract"}
      onClose={() => {
        setDialog("");
        onClose();
        reset();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg"
      PaperComponent={PaperComponent}
      BackdropComponent={BackdropComponent}
    >
      <div
        style={{
          position: "absolute",
          top: "20px",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          lineHeight: 1,
        }}
      >
        <MDTypography
          color="dark"
          style={{
            fontSize: "25px",
            fontWeight: 600,
          }}
        >
          {text("wizard-sub-contract-main-title")}
        </MDTypography>
      </div>
      <DialogContent>
        <MDBox
          width="100%"
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-end",
          }}
        >
          <EscTypography variant="text" onClick={reset}>
            {text("wizard-button-reset")}
          </EscTypography>
          <EscButton
            variant="gradient"
            onClick={() => {
              setDialog("");
              onClose();
              reset();
            }}
          >
            <EscIcon />
          </EscButton>
        </MDBox>
        <MDBox style={{ marginTop: "20px" }} width="100%">
          <StyledStepper active={active} setActive={setActive} steps={steps} completed={completed} />
          <MDBox px={4}>
            <MDBox
              style={{
                minHeight: "400px",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: "10px 0px 0px 0px",
              }}
            >
              {children}
            </MDBox>
          </MDBox>
          <FooterStepper
            active={active}
            blockBack={blockBack}
            back={back}
            nextBt={nextBt}
            next={next}
            steps={steps}
            submit={submit}
          />
        </MDBox>
      </DialogContent>
    </Dialog>
  );
}
