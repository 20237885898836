import { assign, createMachine, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import sessionsGql from "./sessions.gql";

const userModel = createModel({
  token: "",
  list: [],
  paging: {},
  selectedParkings: [],
});

const fetchList = async ({ token, selectedParkings }, { page }) => {
  const { data } = await client.query({
    query: sessionsGql,
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": token } },
    variables: {
      page,
      limit: 20,
      order: "-updatedAt",
      parkingsIds: selectedParkings,
    },
  });

  return data.sessions;
};

const lastEvents = createMachine(
  {
    context: userModel.initialContext,
    id: "lastEvents",
    initial: "off",
    states: {
      off: {
        on: {
          "global:selected-parkings": { actions: "save parkings" },
          "LastEvents:wakeup": [
            { cond: "has selected parkings", target: "loading" },
            { cond: "has no parkings", target: "idle" },
          ],
        },
      },
      idle: {
        on: {
          "events:sessions:created": { target: "loading" },
          "events:sessions:updated": { target: "loading" },
          "LastEvents:onSelectPage": { target: "changingPage" },
          "LastEvents:sleep": { target: "off" },
          "global:selected-parkings": {
            actions: "save parkings",
            target: "loading",
          },
        },
      },
      loading: {
        entry: assign({
          token: () => localStorage.getItem("token"),
        }),
        invoke: {
          id: "getLastSessions",
          src: (context, event) => fetchList(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      changingPage: {
        invoke: {
          id: "getNewPage",
          src: (context, { payload }) => fetchList(context, payload),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populate: assign({
        list: (_, { data }) => data.list,
        paging: (_, { data }) => data.paging,
      }),
      "save parkings": assign({
        selectedParkings: (ctx, { payload }) => payload.selectedParkings,
      }),
    },
    guards: {
      "has selected parkings": ({ selectedParkings }) => !!selectedParkings.length,
      "has no parkings": ({ selectedParkings }) => !selectedParkings.length,
    },
  },
);

export const service = interpret(lastEvents).start();

const initial = {
  state: lastEvents.initialState.list,
  context: lastEvents.initialState.context,
};

const widgetReducer = (state = initial, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
