import { gql } from "@apollo/client";

export default gql`
  query product($productId: String!) {
    product(productId: $productId) {
      _id
      parkingId
      code
      name
      category
      startDate
      stopDate
      createdAt
      operatorId
      parameters
      rules
    }
  }
`;
