import { createMachine, assign, interpret } from "xstate";
import { createModel } from "xstate/lib/model";
import { client } from "../../apolloService";
import i18nGql from "./i18n.gql";

const userModel = createModel({
  token: "",
  value: {},
  operatorId: "",
  selectedLang: "en_EN",
  globalDictionnary: {},
  operatorDictionnary: {},
  translations: {},
  countries: {},
  langs: [],
});

const fetchList = async (context, key) => {
  const { data } = await client.query({
    query: i18nGql,
    variables: { key },
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": context.token } },
  });
  return data.i18n;
};

const i18nMachine = createMachine(
  {
    context: userModel.initialContext,
    id: "dictionaries-machine",
    type: "parallel",
    states: {
      global: {
        initial: "idle",
        states: {
          idle: {
            on: {
              "I18n:initialize": { target: "loading" },
              "I18n:change": { target: "changingLang" },
            },
          },
          loading: {
            entry: assign({
              token: () => localStorage.getItem("token"),
            }),
            invoke: {
              src: (context, event) => fetchList(context, "bff-operators-global"),
              id: "getDictionnaries",
              onDone: [{ actions: "populate global", target: "idle" }],
              onError: [{ target: "idle" }],
            },
          },
          changingLang: {
            entry: ["change lang"],
            always: {
              target: "idle",
            },
          },
        },
      },
      custom: {
        initial: "idle",
        states: {
          idle: {
            on: {
              "I18n:operator:initialize": { target: "loading" },
            },
          },
          loading: {
            entry: assign({
              operatorId: (_, { payload }) => payload.operatorId,
            }),
            invoke: {
              id: "getCustomDictionnaries",
              src: (context, { payload }) => {
                const operatorId = payload.operatorId;
                return fetchList(context, `bff-operators-${operatorId}`);
              },
              onDone: [{ actions: "populate custom", target: "idle" }],
              onError: [{ target: "idle" }],
            },
          },
        },
      },
    },
  },
  {
    actions: {
      "populate global": assign({
        langs: (ctx, { data }) => data.languages,
        globalDictionnary: (ctx, { data }) =>
          data.documents.reduce(
            (acc, { lang, translations }) => ({
              ...acc,
              [lang]: translations,
            }),
            {},
          ),
        translations: (ctx, { data }) => {
          const selectedLang = ctx.selectedLang;
          const document = data.documents.find((doc) => doc.lang === selectedLang);
          return { ...ctx.translations, ...document.translations };
        },
      }),
      "populate custom": assign({
        operatorDictionnary: (ctx, { data }) =>
          data.documents.reduce(
            (acc, { lang, translations }) => ({
              ...acc,
              [lang]: translations,
            }),
            {},
          ),
        translations: (ctx, { data }) => {
          const selectedLang = ctx.selectedLang;
          const document = data.documents.find((doc) => doc.lang === selectedLang);

          return { ...ctx.translations, ...document.translations };
        },
      }),
      "change lang": assign({
        selectedLang: (ctx, { payload: { language } }) => language,
        translations: (ctx, { payload: { language } }) => {
          const globals = ctx.globalDictionnary[language];
          const customs = ctx.operatorDictionnary[language];
          return { ...globals, ...customs };
        },
      }),
    },
  },
);

export const service = interpret(i18nMachine).start();

const initial = {
  state: i18nMachine.initialState.value,
  context: i18nMachine.initialState.context,
};

const widgetReducer = (state = initial, { type, payload }) => {
  const newState = service.send({ type, payload });

  return { state: newState.value, context: newState.context };
};

export default widgetReducer;
