import React from "react";

// material-ui
import Grid from "@mui/material/Grid";

// @ndres-ui
import MDBox from "assets/@ndres-ui/MDBox";

const TokenNotValidated = (props) => {
  return (
    <MDBox
      py={0}
      px={0}
      width="100vw"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDirection="column"
    >
      <MDBox
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
        width="100%"
        height="55px"
        bgColor="#C81400"
        position="fixed"
        left="0px"
        top="0px"
        zIndex="10"
      >
        <MDBox
          height="28px"
          width="94.4px"
          position="relative"
          left="15px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {/* <Image
        src="/image/illustrations/logoOSP.png"
        alt="container logo"
        layout="fill"
        objectFit="contain"
      /> */}
        </MDBox>
      </MDBox>
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="column"
        width="100%"
        mt="55px"
      >
        <MDBox width="100%">
          <Grid container spacing={1}>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={8}>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="around"
                ml={2}
                p={0.5}
                mt={4}
                bgColor="#C81400"
                borderRadius="6px"
                style={{ boxShadow: "-2px 6px 11px -3px rgba(0,0,0,0.11)" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "1.5em",
                  }}
                >
                  Account not validated
                </div>
              </MDBox>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "2em",
                  fontSize: "2em",
                }}
              >
                Something went wrong during the validation process, try again or contact your operator
              </div>
            </Grid>
            <Grid item xs={12} md={2} />
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default TokenNotValidated;
