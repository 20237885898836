import React from "react";
import WizardCreateSubContractPool from "./assets";
import { ContextProvider } from "./context";

const Wizard = () => {
  return (
    <ContextProvider storeName="WizardCreateSubContractPool" events={{}}>
      <WizardCreateSubContractPool />
    </ContextProvider>
  );
};

export default Wizard;
