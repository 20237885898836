import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import createIdbStorage from "@piotr-cz/redux-persist-idb-storage";

import loginReducer from "components/Login/reducer";
import i18nReducer from "components/I18n/reducer";
import hierarchyReducer from "components/Hierarchy/reducer";

import Search from "../DashboardElements/components/layout/NavigationBar/SearchBar/reducer";
import LastIncident from "components/LastIncidents/reducer";
import LastEvents from "components/LastEvents/reducer";
import Sessions from "components/Sessions/reducer";
import SessionDetails from "components/SessionDetails/reducer";
import Motorists from "components/Motorists/reducer";
import MotoristDetails from "components/MotoristDetails/reducer";
import Products from "components/Products/reducer";
import Transactions from "components/Transactions/reducer";
import Wizard from "components/WizardNewSubscription/reducer";
import WizardNewPool from "components/WizardNewPool/reducer";
import WizardCreateMotoristContractPool from "components/WizardCreateMotoristContractPool/reducer";
import WizardCreateLocalMotorist from "components/WizardCreateLocalMotorist/reducer";
import WizardCreateSingleMotoristContractPool from "components/WizardCreateSingleMotoristContractPool/reducer";
import WizardCreateSubContractPool from "components/WizardCreateSubContractPool/reducer";
import WizardCreateReportTechnical from "components/WizardCreateReportTechnical/reducer";
import MotoristValidation from "components/MotoristValidation/reducer";
import BusinessTenants from "components/BusinessTenants/reducer";
import BusinessTenantsDetails from "components/BusinessTenantsDetails/reducer";
import BusinessTenantsParentDetails from "components/BusinessTenantsDetails/Parent/reducer";
import AccessPointHistory from "components/AccessPointHistory/reducer";
import AccessPointDetails from "components/AccessPointDetails/reducer";
import UserProviderList from "components/UserProviderList/reducer";
import UserProviderDetails from "components/UserProviderDetails/reducer";
import WizardCreateContractUserProvider from "components/WizardCreateContractUserProvider/reducer";

const configLogin = {
  key: "login",
  storage: createIdbStorage({ name: "login", storeName: "redux" }),
  whitelist: ["data", "update"],
  timeout: null,
  serialize: false,
};

// COMBINING REDUCERS
const combinedReducer = combineReducers({
  // Services
  login: persistReducer(configLogin, loginReducer),
  dictionaries: i18nReducer,
  MotoristValidation,

  // LAYOUT STORES
  hierarchy: hierarchyReducer,

  // WITGETS STORES
  Search,
  LastIncident,
  LastEvents,
  Sessions,
  SessionDetails,
  Motorists,
  MotoristDetails,
  Products,
  Wizard,
  WizardNewPool,
  WizardCreateMotoristContractPool,
  WizardCreateLocalMotorist,
  WizardCreateSingleMotoristContractPool,
  WizardCreateSubContractPool,
  WizardCreateReportTechnical,
  WizardCreateContractUserProvider,
  Transactions,
  BusinessTenants,
  BusinessTenantsDetails,
  BusinessTenantsParentDetails,
  AccessPointHistory,
  AccessPointDetails,
  UserProviderList,
  UserProviderDetails,
});

export default combinedReducer;
