/* eslint-disable implicit-arrow-linebreak */
import React from "react";

import MDTypography from "../../../@ndres-ui/MDTypography";

const PoolUsersTable = ({ users, title }) => {
  if (!users) return <></>;

  return (
    <>
      {/* Motorist  */}
      <section className="section_details">
        <MDTypography className="global_xs_title" type="tableHeader" textTransform="first">
          {title}
        </MDTypography>
        <div className="global_separa"></div>
        <div className="place_rub">
          {users?.map(({ firstName = "-", lastName = "-", phone = "-", username = "-" }, index) => {
            return (
              <div className="flexTableRowsPool" key={`${username}-${index}`}>
                <div className="flexTableHead">
                  <MDTypography type="tableData">{username}</MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {firstName}
                  </MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography type="tableData" textTransform="first">
                    {lastName}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {phone}
                  </MDTypography>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default PoolUsersTable;
