import React, { useContext } from "react";

// @ndres-ui
import ProfileInfoCard from "assets/@ndres-ui/Cards/ProductInfoCard";
import { ProductsItemLayout, ProductsMapLayout, ProductsWidgetLayout } from "./items";
import MDBox from "assets/@ndres-ui/MDBox";
import { LocalContext } from "../context";

const Products = () => {
  const { list } = useContext(LocalContext);

  return (
    <ProductsWidgetLayout>
      <ProductsMapLayout>
        {list.map(({ name, price, type, currency, provider, _id }) => {
          return (
            <ProductsItemLayout key={`${_id}`}>
              <MDBox width="100%" height="350px" p="5px">
                <ProfileInfoCard
                  productId={_id}
                  iconLogo={provider}
                  currency={currency}
                  price={price}
                  type={type}
                  action={{ route: "", tooltip: "Edit Profile" }}
                />
              </MDBox>
            </ProductsItemLayout>
          );
        })}
      </ProductsMapLayout>
    </ProductsWidgetLayout>
  );
};

export default Products;
