import { gql } from "@apollo/client";

export default gql`
  query poolContractAndSub(
    $contractId: String!
    $motoristPage: Int
    $motoristLimit: Int
    $sessionPage: Int
    $sessionLimit: Int
  ) {
    poolContractAndSub(contractId: $contractId) {
      _id
      operatorId
      startDate
      stopDate
      name1
      name2
      address1
      address2
      zipcode
      city
      country
      isEnabled
      spacesOccupied
      spacesAllocated
      ospContractId
      category
      sessions(page: $sessionPage, limit: $sessionLimit) {
        list {
          _id
          parking {
            name
            _id
          }
          entryDate
          exitDate
          state
          occupancyDuration
          isInconsistent
          product {
            _id
            name
          }
          events {
            state
            accessPointId
            createdAt
            reason
            credentials {
              type
              provider
              value
              description
            }
          }
        }
        paging {
          current
          count
          limit
        }
      }
      poolUsers {
        list {
          lastName
          username
          firstName
          phone
        }
      }
      operatorContract {
        _id
        ospContractId
        name
        startDate
        spacesAllocated
        spacesOccupied
        isEnabled
        name1
        name2
        address1
        address2
        zipcode
        city
        country
        stopDate
        product {
          startDate
          stopDate
          name
          _id
        }
        poolUsers {
          list {
            lastName
            username
            firstName
            phone
          }
        }
      }
      productId
      product {
        _id
        name
        startDate
        stopDate
      }
      parkingId
      parking {
        _id
      }
      tenantContracts {
        _id
        name
        category
        name1
        name2
        address1
        address2
        zipcode
        city
        country
        spacesAllocated
        spacesOccupied
        product {
          _id
          name
          startDate
          stopDate
        }
      }
      motoristsContracts(page: $motoristPage, limit: $motoristLimit) {
        list {
          _id
          startDate
          stopDate
          isEnabled
          ospContractId
          credentials {
            type
            provider
            description
            value
          }
          motorist {
            _id
            username
            firstName
            lastName
            credentials {
              type
              provider
              description
              value
            }
          }
        }
        paging {
          current
          count
          limit
        }
      }
    }
  }
`;
