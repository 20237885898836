import React, { useState, useMemo, useContext, useEffect } from "react";
import { useSelector } from "@xstate/react";
import { service } from "../reducer";
import MDBox from "assets/@ndres-ui/MDBox";
import NewReportTechnicalDialogStepper from "./stepsScreen";
import SweetAlert2 from "react-sweetalert2";

import { ContextProvider, LocalContext } from "../context";
import useDictionaries from "assets/commonHooks/useDictionaries";
import { useDispatch } from "react-redux";
let timerInterval;

export default function SpringPopper() {
  const text = useDictionaries();

  const [swalAwait, setSwalAwait] = useState();
  const [swalSuccess, setSwalSuccess] = useState();
  const [swalError, setSwalError] = useState();

  const { selection, setSelection, parkingId } = useContext(LocalContext);
  const dispatch = useDispatch();
  const error = useSelector(service, ({ context }) => context.error);
  const errorMessage = useSelector(service, ({ context }) => context.errorMessage);
  const memoError = useMemo(() => error, [error]);
  const memoerrorMessage = useMemo(() => errorMessage, [errorMessage]);

  const onClose = () => {
    setSelection("");
    service.send({
      type: "WizardCreateContractUserProvider:exit",
      payload: {},
    });
  };

  const handleSubmit = (event) => {
    dispatch(event);
    onClose();

    // setSwalAwait({
    //   show: false,
    //   allowOutsideClick: false,
    //   title: "setSwalAwait Title!",
    //   html: "setSwalAwait Text>",
    //   timerProgressBar: true,
    //   allowEscapeKey: false,
    //   customClass: {
    //     title: "SwalTitle",
    //   },
    //   didOpen: () => {
    //     // Swal.showLoading();
    //   },
    //   willClose: () => {
    //     clearInterval(timerInterval);
    //     setSwalAwait({ show: false });
    //   },
    // });
  };

  useEffect(() => {
    if (memoError !== undefined && memoError) {
      const code = memoerrorMessage.message.toLowerCase();

      setSwalError({
        show: true,

        title: "setSwalError title", // 'An error occurred and the contract could not be created.',

        html: `<p><span style="font-weight:bold">${text("global-error-cause")} :</span> ${text(
          `global-error-text-${code}`,
        )}</p>`,
        icon: "error",
        showConfirmButton: false,
        cancelButtonText: "Agree",
        showCancelButton: true,
        cancelButtonColor: "#c8161b",
        timer: 8000,
        customClass: {
          title: "SwalTitle",
          container: "swalZindex",
        },
        didOpen: () => {
          // Swal.showLoading();
          setSelection("");
        },
        willClose: () => {
          clearInterval(timerInterval);
          setSwalError({ show: false });
        },
      });
      service.send({
        type: "WizardCreateContractUserProvider:exit",
        payload: {},
      });
    } else if (memoError === false) {
      setSwalSuccess({
        show: true,
        title: "setSwalSuccess title",
        icon: "success",
        showConfirmButton: false,
        timer: 1000,
        customClass: {
          title: "SwalTitle",
          container: "swalZindex",
        },
        didOpen: () => {
          setSelection("");
          // Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
          setSwalSuccess({ show: false });
        },
      });
      setSelection("");
      service.send({
        type: "WizardCreateContractUserProvider:exit",
        payload: {},
      });
    }
  }, [memoError]);

  return (
    <>
      <ContextProvider storeName="WizardCreateContractUserProvider" events={{}}>
        <SweetAlert2 {...swalAwait} />
        <SweetAlert2 {...swalSuccess} />
        <SweetAlert2 {...swalError} />
        <NewReportTechnicalDialogStepper
          dialog={selection}
          setDialog={setSelection}
          parkingId={parkingId}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
        <MDBox onClick={() => setSelection("New Create Motorist With Local Motorist")}></MDBox>
      </ContextProvider>
    </>
  );
}
