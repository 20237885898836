import React from "react";

// Material UI
import { styled } from "@mui/material/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import useDictionaries from "assets/commonHooks/useDictionaries";

// @ndres-ui
import MDBox from "assets/@ndres-ui/MDBox";

export const MotoristsTableWidgetLayout = styled(({ children, ...rest }) => (
  <MDBox coloredShadow="dark" {...rest}>
    {children}
  </MDBox>
))(({ theme, height }) => ({
  height,
  maxHeight: height,
  background: "white",
  overflowY: "hidden",
  borderRadius: "0.5rem",
  padding: "5px 6px 10px 5px",
}));

export const HeaderBox = styled(MDBox)(({ theme }) => ({
  overflowY: "hidden",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  flexDirection: "column",
}));

export const BodyBox = styled(MDBox)(({ theme }) => ({
  height: "calc(100% - 100px)",
  overflowY: "auto",
}));

const selectedColor = "#f6e6e6";
const defaultBorderColor = "#d0c8c8";
const lightBorderColor = "#eae9e9";
const selectBorder = "orange";
const defaultBackgroundColor = "#f6e6e690";

export const MotoristRow = styled(MDBox)(({ theme, index, last, selected, prev }) => {
  const borderColor = selected ? selectBorder : defaultBorderColor;
  const backgroundColor = selected ? selectedColor : index % 2 === 0 ? "#e2e2e73d" : "#ffffff";
  const hoverBackgroundColor = selected ? selectedColor : defaultBackgroundColor;
  return {
    width: "100%",
    background: backgroundColor,
    borderTop: prev ? `1px solid ${selectBorder}` : `1px solid ${borderColor}`,
    padding: "5px 5px 5px 5px",
    borderBottom: last ? `1px solid ${borderColor}` : "none",
    transition: "background ease .5s",
    "&:hover": {
      background: hoverBackgroundColor,
      cursor: "pointer",
    },
  };
});

export const MotoristTableHead = styled(MDBox)(({ theme }) => ({
  width: "100%",
  padding: "5px 10px 10px 5px",
}));

export const MotoristTableBody = styled(MDBox)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  flexDirection: "column",
}));

export const ContractTableHead = styled(MDBox)(({ theme }) => ({
  width: "100%",
  padding: "5px 10px 10px 5px",
}));

export const ContractTableBody = styled(MDBox)(({ theme, height }) => ({
  width: "100%",
  padding: "5px 10px 10px 5px",
  background: "white",
  height: `calc(${height} - 190px)`,
  overflowY: "scroll",
}));

export const ContractHeaderRow = styled(MDBox)(({ theme, index, open }) => ({
  background: index % 2 === 0 ? "#f8f8f9" : "white",
  height: "50px",
  display: "flex",
  padding: "0px 0px 0px 10px",
  transition: "all ease .5s",
  borderTop: index === 0 ? `1px solid ${defaultBorderColor}` : "none",
  borderBottom: open ? `1px solid ${lightBorderColor}` : `1px solid ${defaultBorderColor}`,
  "&:hover": {
    background: defaultBackgroundColor,
    cursor: "pointer",
  },
}));

export const Collapse = styled(MDBox)(({ theme, open, index, height }) => ({
  background: index % 2 === 0 ? "#f8f8f9" : "white",
  width: "100%",
  transition: "height ease .5s",
  height: open ? height ?? "50px" : "0px",
  overflowY: "hidden",
  borderBottom: open ? `1px solid ${defaultBorderColor}` : "none",
}));

export const CellLeftCenter = styled(MDBox)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  flexDirection: "column",
  textTransform: "uppercase",
}));

export const CellCenterCenter = styled(MDBox)(({ theme, index, last }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  textTransform: "uppercase",
}));

export const CellRightCenter = styled(MDBox)(({ theme, index, last }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  flexDirection: "column",
  textTransform: "uppercase",
}));

export const BadgeState = styled(MDBox)(({ theme, color }) => ({
  border: `1px solid ${color}`,
  padding: "3px 10px",
  borderRadius: "0.8rem",
  backgroundColor: "white",
}));

const iconsConfig = ({ theme, color }) => ({
  width: "20px",
  height: "20px",
  color,
});

const stateColors = {
  DISABLED: "#A91809",
  AWAIT: "#0980A9",
  ENABLED: "#1FA909",
};

const AwaitIcon = styled(TimelapseIcon)(iconsConfig);
const CheckIcon = styled(CheckCircleOutlineIcon)(iconsConfig);
const CancelIcon = styled(DoDisturbOnIcon)(iconsConfig);

const ToVerifyBadge = () => {
  const text = useDictionaries();
  return <BadgeState color="#FF9800">{text("motorist-status-type-toVerify").toUpperCase()}</BadgeState>;
};

const VerifiedBadge = () => {
  const text = useDictionaries();
  return <BadgeState color="#4CAF50">{text("motorist-status-type-verified").toUpperCase()}</BadgeState>;
};

export const states = {
  TO_VERIFY: <ToVerifyBadge />,
  VERIFIED: <VerifiedBadge />,
  ENABLED: <CheckIcon color={stateColors.ENABLED} />,
  DISABLED: <CancelIcon color={stateColors.DISABLED} />,
  AWAIT: <AwaitIcon color={stateColors.AWAIT} />,
};
