import { assign, createMachine, interpret } from "xstate";
import { createModel } from "xstate/lib/model";

import { client } from "../../apolloService";
import addFreeflagGql from "./add-freeflag.gql";
import forceCloseGql from "./close-session.gql";
import consumeTokenGql from "./consumeToken.gql";
import motoristGql from "./motorist.gql";
import updateContract from "./motorist.update.contract.gql";
import regenerateQrcodeGql from "./regenerate-qrcode.gql";
import removeFreeflagGql from "./remove-freeflag.gql";
import sendConfirmationGql from "./send-mail.gql";
const userModel = createModel({
  token: "",
  motoristId: "",
  selectedMotorist: "",
  paging: {},
  selectedParkings: [],
  pdf: null,
  forceCloseSession: {},
  errorForceCloseSession: undefined,
  regenerateQrcode: {},
  errorRegenerateQrcode: undefined,
  freeflagContract: {},
  errorFreeflagContract: undefined,
  verifLinkContract: {},
  errorVerifLinkContract: undefined,
});

const fetchMotorist = async ({ token, motoristId, selectedParkings }, { payload }) => {
  const { data } = await client.query({
    query: motoristGql,
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": token } },
    variables: {
      motoristId,
      parkingsIds: selectedParkings,
      sessionLimit: 6,
      contractLimit: 9,
      sessionPage: payload.sessionPage,
      contractPage: payload.contractPage,
    },
  });
  return data.motorist;
};

const updateMotoristContract = async ({ token }, { payload }) => {
  const { data } = await client.query({
    query: updateContract,
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": token } },
    variables: {
      contractId: payload.contractId,
      input: { isEnabled: payload.event },
    },
  });
  return data;
};

const forceClose = async ({ token, contractId, selectedParkings }, { payload }) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: forceCloseGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          sessionId: payload?.sessionId,
        },
      })
      .then((response) => {
        const { data } = response;
        resolve({ ...data, sessionId: payload?.sessionId });
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

const sendConfirmationMail = async ({ token }, { payload }) => {
  const { data } = await client.query({
    query: sendConfirmationGql,
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": token } },
    variables: {
      input: payload,
    },
  });
  return data;
};

const regenerateQrcode = async ({ token }, { payload }) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: regenerateQrcodeGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          motoristId: payload.id,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

const freeFlagContract = async ({ token, selectedParkings }, { payload }) => {
  const { contractId, parkingId, mode } = payload;

  return new Promise((resolve, reject) => {
    switch (mode) {
      case "flag":
        client
          .mutate({
            mutation: addFreeflagGql,
            fetchPolicy: "no-cache",
            context: { headers: { "x-access-token": token } },
            variables: {
              contractId,
              parkingId,
              type: "EXCEPTION",
            },
          })
          .then((response) => {
            resolve({ count: response.data.addFreeFlagContract, contractId, flag: true });
          })
          .catch((e) => {
            reject(e.message);
          });

        break;
      case "deflag":
        client
          .mutate({
            mutation: removeFreeflagGql,
            fetchPolicy: "no-cache",
            context: { headers: { "x-access-token": token } },
            variables: {
              contractId,
              parkingId,
            },
          })
          .then((response) => {
            resolve({ count: response.data.removeFreeflagGql, contractId, flag: false });
          })
          .catch((e) => {
            reject(e.message);
          });

        break;
    }
  });
};

const verifLinkContract = async ({ token }, { payload }) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: consumeTokenGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          contractId: payload.contractId,
        },
      })
      .then((response) => {
        resolve(response.data.consumeToken);
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

const motoristsDetails = createMachine(
  {
    context: userModel.initialContext,
    id: "MotoristDetails",
    initial: "off",
    states: {
      off: {
        on: {
          "MotoristDetails:show": { target: "loading" },
          "global:selected-parkings": { actions: "save parkings" },
        },
      },
      idle: {
        on: {
          "MotoristDetails:show": { target: "loading" },
          "MotoristDetails:onSelectSessionPage": { target: "changingPage" },
          "MotoristDetails:onSelectContractPage": { target: "changingPage" },
          "MotoristDetails:onForceClose": { target: "closeSession" },
          "MotoristDetails:onContractStatusChange": {
            target: "updateContractStatus",
          },
          "MotoristDetails:onSendMail": {
            actions: "sendConfirmationMail",
          },
          "MotoristDetails:onRegenerateQrcode": {
            target: "regenerateQrcode",
          },
          "MotoristDetails:onFreeFlag": {
            target: "freeFlagContract",
          },
          "MotoristDetails:onVerifLink": {
            target: "verifLinkContract",
          },
          "events:sessions:updated": {
            cond: "isSameMotorist",
            target: "updating",
          },
          "events:sessions:created": {
            cond: "isSameMotorist",
            target: "updating",
          },
          "global:selected-parkings": {
            actions: "save parkings",
            target: "loading",
          },
          "MotoristDetails:reset-error": {
            actions: "reset error",
          },
        },
      },
      loading: {
        entry: assign({
          token: () => localStorage.getItem("token"),
          motoristId: (ctx, { payload }) => {
            return payload.motoristId ? payload.motoristId : ctx.motoristId;
          },
        }),
        invoke: {
          id: "getMotoristDetail",
          src: (context, event) => fetchMotorist(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      changingPage: {
        invoke: {
          id: "getNewPage",
          src: (context, event) => fetchMotorist(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      regenerateQrcode: {
        invoke: {
          id: "regenerateQR",
          src: (context, event) => regenerateQrcode(context, event),
          onDone: [{ actions: "regenerate qrcode", target: "idle" }],
          onError: [{ actions: "error regenerate qrcode", target: "idle" }],
        },
      },
      closeSession: {
        entry: assign({
          token: () => localStorage.getItem("token"),
        }),
        invoke: {
          id: "closeSession",
          src: (context, event) => forceClose(context, event),
          onDone: [{ actions: "force close session", target: "idle" }],
          onError: [{ actions: "error force close session", target: "idle" }],
        },
      },
      updating: {
        invoke: {
          id: "getSession",
          src: (context, event) => fetchMotorist(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      updateContractStatus: {
        invoke: {
          id: "updateMotorist",
          src: (context, event) => updateMotoristContract(context, event),
          onDone: [{ actions: "update contract", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      freeFlagContract: {
        invoke: {
          id: "freeFlagContract",
          src: (context, event) => freeFlagContract(context, event),
          onDone: [{ actions: "freeflag contract", target: "idle" }],
          onError: [{ actions: "error freeflag contract", target: "idle" }],
        },
      },

      verifLinkContract: {
        invoke: {
          id: "verifLinkContract",
          src: (context, event) => verifLinkContract(context, event),
          onDone: [{ actions: "verif link contract", target: "idle" }],
          onError: [{ actions: "error verif link contract", target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populate: assign({
        selectedMotorist: (_, { data }) => {
          return data;
        },
        sessionPaging: (_, { data }) => data.sessionPaging,
        contractPaging: (_, { data }) => data.contractPaging,
      }),

      "save pdf": assign({
        pdf: (_, { data }) => data.generateQrPdf,
      }),

      "update contract": assign({
        selectedMotorist: (ctx, { data }) => {
          const { _id: contractId, isEnabled } = data.updateContract;
          const motorist = ctx.selectedMotorist;
          const contract = motorist.contracts.list.find(({ _id }) => _id === contractId);
          contract.isEnabled = isEnabled;
          return { ...motorist };
        },
      }),

      sendConfirmationMail: (context, event) => sendConfirmationMail(context, event),

      "force close session": assign({
        forceCloseSession: (context, { data }) => {
          return { id: data.sessionId };
        },
      }),

      "error force close session": assign({
        errorForceCloseSession: (context, { data }) => {
          return data;
        },
      }),

      "regenerate qrcode": assign({
        regenerateQrcode: ({ motoristId }, value) => {
          return { motoristId };
        },
      }),

      "error regenerate qrcode": assign({
        errorRegenerateQrcode: ({}, { data }) => {
          return data;
        },
      }),

      "freeflag contract": assign({
        freeflagContract: (ctx, { data }) => {
          return data;
        },
        selectedMotorist: ({ selectedMotorist }, { data }) => {
          const { contractId, flag } = data;
          const motorist = selectedMotorist;
          const contract = motorist.contracts.list.find(({ _id }) => _id === contractId);
          contract.freeflag = flag;
          return { ...motorist };
        },
      }),

      "error freeflag contract": assign({
        errorFreeflagContract: ({}, { data }) => {
          return data;
        },
      }),

      "verif link contract": assign({
        verifLinkContract: (ctx, { data }) => {
          return data;
        },
      }),

      "error verif link contract": assign({
        errorVerifLinkContract: ({}, { data }) => {
          return data;
        },
      }),

      "save parkings": assign({
        selectedParkings: (ctx, { payload }) => payload.selectedParkings,
      }),

      "reset error": assign({
        errorUpdateContract: () => undefined,
        errorRegenerateQrcode: () => undefined,
        errorForceCloseSession: () => undefined,
        errorFreeflagContract: () => undefined,
        errorVerifLinkContract: () => undefined,
      }),

      "events update contract": assign({
        selectedMotorist: ({ selectedMotorist }, { payload }) => {
          if (selectedMotorist._id === payload.motorist._id) {
          }
        },
      }),
    },
    guards: {
      isSameMotorist: (ctx, { payload }) => {
        const { motoristId: _id } = payload.session;
        const { motoristId } = ctx;

        return motoristId === _id;
      },
    },
  },
);

export const service = interpret(motoristsDetails).start();

const initial = {
  state: motoristsDetails.initialState.list,
  context: motoristsDetails.initialState.context,
};

const widgetReducer = (state = initial, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
