import React, { useEffect, useMemo } from "react";
import { useSelector } from "@xstate/react";
import { service } from "../../../reducer";
import { service as serviceHierarchy } from "components/I18n/reducer";

// @mui materials
import MenuItem from "@mui/material/MenuItem";

import Grid from "@mui/material/Grid";
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";

import countries from "assets/store/countries.json";

import MDInput from "assets/@ndres-ui/MDInput";
import { styled } from "@mui/system";
// local hooks

const Input = styled(MDInput)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: "100%",
  margin: "0px -5px",
  marginTop: "5px",
  padding: "0px",
  paddingLeft: "4px",
}));
const Select = styled(({ ...rest }) => (
  <Input
    {...rest}
    InputProps={{
      style: {
        height: 44.125,
        // padding: '12px',
        width: "100%",
      },
    }}
  />
))(({ theme }) => ({
  width: "100%",
  padding: "2px 2px",
  margin: "8px 0px 0px 0px",
}));

const verifiedEmail = (username) =>
  !!String(username)
    ?.toLowerCase()
    ?.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export default function Step1({ addCompleted, setData, formData, addNextBt }) {
  const text = useDictionaries();
  const product = useSelector(service, ({ context }) => context.product);
  const motoristInputs = useSelector(service, ({ context }) => context.motoristInputs);
  const selectedLang = useSelector(serviceHierarchy, ({ context }) => context.selectedLang);

  const mailIsValid = useMemo(() => verifiedEmail(motoristInputs.username), [motoristInputs.username]);
  const selectedLangMemo = useMemo(() => selectedLang, [selectedLang]);

  useEffect(() => {
    const testing = [
      !!product,
      !!motoristInputs?.startDate && motoristInputs?.startDate !== "",
      motoristInputs.credentialProvider !== "",
      motoristInputs.credentialValue !== "",
      motoristInputs.success,
      mailIsValid,
      !motoristInputs.verifing,
      !motoristInputs.error,
    ];

    const result = testing.reduce((c, o) => c && o, true);
    addNextBt(1, result);
  }, [formData, motoristInputs]);

  useEffect(() => {
    service.send({
      type: "motorist:change",
      payload: { lang: motoristInputs.lang ? motoristInputs.lang : selectedLangMemo },
    });
    addCompleted(1, true);
  }, []);

  if (!product?.startDate) {
    return "loading...";
  }
  function compare(a, b) {
    if (a[1][selectedLangMemo ?? "en_EN"] < b[1][selectedLangMemo ?? "en_EN"]) {
      return -1;
    }
    if (a[1][selectedLangMemo ?? "en_EN"] > b[1][selectedLangMemo ?? "en_EN"]) {
      return 1;
    }
    return 0;
  }
  return (
    <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
      <MDBox
        pt={0}
        style={{
          transition: "opacity ease 1s",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MDBox ml={2} mt={2} pl={2}>
              <MDTypography fontSize="1.5rem" fontWeight="regular" color="dark">
                {text("Wizard-second-step-motorist")}
              </MDTypography>
              <MDTypography fontSize="1rem" fontWeight="light" color="text">
                {text("Wizard-second-step-motorist-description")}
                {formData.verificationEmailStatus}
              </MDTypography>
            </MDBox>
          </Grid>

          <Grid item xs={5}>
            <MDBox ml={2} mt={2} pl={2}>
              <Input
                required
                value={motoristInputs.username ?? ""}
                label={text("Wizard-second-step-motorist-form-label-email")}
                autoComplete="off"
                type="email"
                id="email"
                loading={motoristInputs.loading}
                error={!mailIsValid}
                success={mailIsValid}
                disabled={
                  motoristInputs.state === "VERIFIED" ||
                  (motoristInputs.state === "TO_VERIFY" &&
                    motoristInputs._id !== "" &&
                    motoristInputs.credentialLinkedUsername !== null &&
                    motoristInputs.credentialLinkedUsername === motoristInputs.username) ||
                  (!!motoristInputs._id && motoristInputs._id !== "") ||
                  (!!motoristInputs._id && mailIsValid)
                }
                onChange={(e) =>
                  service.send({
                    type: mailIsValid ? "motoristUsername:change" : "motorist:change",
                    payload: { username: e.target.value, control: true },
                  })
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={7}></Grid>

          <Grid item xs={3}>
            <MDBox ml={2} mt={2} pl={2}>
              <Select
                label={text("Wizard-second-step-motorist-form-label-lang")}
                required
                value={motoristInputs.lang ?? selectedLangMemo ?? "en_EN"}
                autoComplete="off"
                type="lang"
                id="lang"
                select
                disabled={!!motoristInputs._id && motoristInputs.state !== "TO_VERIFY" && !motoristInputs.loading}
                onChange={(e) =>
                  service.send({
                    type: "motorist:change",
                    payload: { lang: e.target.value },
                  })
                }
              >
                <MenuItem value="en_EN">EN - English</MenuItem>
                <MenuItem value="fr_FR">FR - Français</MenuItem>
                <MenuItem value="it_IT">IT - Italiano</MenuItem>
                <MenuItem value="de_DE">DE - Deutsch</MenuItem>
              </Select>
            </MDBox>
          </Grid>
          <Grid item xs={3}>
            <MDBox mt={2}>
              <Select
                label={text("Wizard-second-step-motorist-form-label-gender")}
                required
                value={motoristInputs.gender === "" || !motoristInputs.gender ? "UNKNOWN" : motoristInputs.gender}
                autoComplete="off"
                type="gender"
                id="gender"
                select
                disabled={!!motoristInputs._id && motoristInputs.state !== "TO_VERIFY" && !motoristInputs.loading}
                onChange={(e) =>
                  service.send({
                    type: "motorist:change",
                    payload: { gender: e.target.value },
                  })
                }
              >
                <MenuItem value="MALE">{text(`Wizard-second-step-motorist-form-gender-male`)}</MenuItem>
                <MenuItem value="FEMALE">{text(`Wizard-second-step-motorist-form-gender-female`)}</MenuItem>
                <MenuItem value="UNKNOWN">{text(`Wizard-second-step-motorist-form-gender-unknown`)}</MenuItem>
              </Select>
            </MDBox>
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={6}>
            <MDBox ml={2} mt={1} pl={2}>
              <Input
                required
                label={text("Wizard-second-step-motorist-form-label-first-name")}
                value={motoristInputs.firstName ?? ""}
                autoComplete="off"
                type="firstName"
                id="firstName"
                disabled={!!motoristInputs._id && motoristInputs.state !== "TO_VERIFY" && !motoristInputs.loading}
                onChange={(e) =>
                  service.send({
                    type: "motorist:change",
                    payload: { firstName: e.target.value },
                  })
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox mt={1}>
              <Input
                label={text("Wizard-second-step-motorist-form-label-last-name")}
                required
                value={motoristInputs.lastName ?? ""}
                autoComplete="off"
                type="lastName"
                id="lastName"
                disabled={!!motoristInputs._id && motoristInputs.state !== "TO_VERIFY" && !motoristInputs.loading}
                onChange={(e) =>
                  service.send({
                    type: "motorist:change",
                    payload: { lastName: e.target.value },
                  })
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox ml={2} mt={1} pl={2}>
              <Input
                label={text("Wizard-second-step-motorist-form-label-address1")}
                required
                value={motoristInputs.address1 ?? ""}
                autoComplete="off"
                type="address1"
                id="address1"
                disabled={!!motoristInputs._id && motoristInputs.state !== "TO_VERIFY" && !motoristInputs.loading}
                onChange={(e) =>
                  service.send({
                    type: "motorist:change",
                    payload: { address1: e.target.value },
                  })
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={3}>
            <MDBox mt={1}>
              <Input
                label={text("Wizard-second-step-motorist-form-label-city")}
                required
                value={motoristInputs.city ?? ""}
                autoComplete="off"
                type="city"
                id="city"
                disabled={!!motoristInputs._id && motoristInputs.state !== "TO_VERIFY" && !motoristInputs.loading}
                onChange={(e) =>
                  service.send({
                    type: "motorist:change",
                    payload: { city: e.target.value },
                  })
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={3}>
            <MDBox mt={1}>
              <Input
                label={text("Wizard-second-step-motorist-form-label-zip")}
                required
                value={motoristInputs.zipcode ?? ""}
                autoComplete="off"
                type="zipcode"
                id="zipcode"
                disabled={!!motoristInputs._id && motoristInputs.state !== "TO_VERIFY" && !motoristInputs.loading}
                onChange={(e) =>
                  service.send({
                    type: "motorist:change",
                    payload: { zipcode: e.target.value },
                  })
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={6}>
            <MDBox ml={2} mt={1} pl={2}>
              <Input
                label={text("Wizard-second-step-motorist-form-label-address2")}
                required
                value={motoristInputs.address2 ?? ""}
                autoComplete="off"
                type="address2"
                id="address2"
                disabled={!!motoristInputs._id && motoristInputs.state !== "TO_VERIFY" && !motoristInputs.loading}
                onChange={(e) =>
                  service.send({
                    type: "motorist:change",
                    payload: { address2: e.target.value },
                  })
                }
              />
            </MDBox>
          </Grid>
          <Grid item xs={3}>
            <MDBox mt={0.4}>
              <Select
                label={text("Wizard-second-step-motorist-form-label-country")}
                required
                value={motoristInputs.country ?? ""}
                autoComplete="off"
                type="country"
                id="country"
                select
                selected="none"
                disabled={!!motoristInputs._id && motoristInputs.state !== "TO_VERIFY" && !motoristInputs.loading}
                onChange={(e) =>
                  service.send({
                    type: "motorist:change",
                    payload: { country: e.target.value },
                  })
                }
              >
                <MenuItem value="none" key="none"></MenuItem>
                {Object.entries(countries)
                  .sort(compare)
                  .map(([code, traductions]) => (
                    <MenuItem value={code} key={code}>
                      {traductions[selectedLangMemo ?? "en_EN"] ?? traductions.en_EN}
                    </MenuItem>
                  ))}
              </Select>
            </MDBox>
          </Grid>
          <Grid item xs={3}>
            <MDBox mt={1}>
              <Input
                label={text("Wizard-second-step-motorist-form-label-phone")}
                required
                value={motoristInputs.phone ?? ""}
                autoComplete="off"
                type="phone"
                id="phone"
                disabled={!!motoristInputs._id && motoristInputs.state !== "TO_VERIFY" && !motoristInputs.loading}
                onChange={(e) =>
                  service.send({
                    type: "motorist:change",
                    payload: { phone: e.target.value },
                  })
                }
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}
