import { gql } from "@apollo/client";

export default gql`
  query existsMotorist($username: String) {
    existsMotorist(username: $username) {
      found
      motorist {
        _id
        username
        lastName
        firstName
        address1
        address2
        zipcode
        city
        country
        phone
        lang
        gender
        state
      }
    }
  }
`;
