import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from "@apollo/client";

import { BFF_HOST, BFF_TOKEN } from "./config";

// Create link to BFF
const httpLink = createHttpLink({
  uri: BFF_HOST,
});

// Add context headers for authorization
const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }) => ({
    headers: {
      Authorization: `Bearer ${BFF_TOKEN}`,
      ...headers,
    },
  }));
  return forward(operation);
});

// Handle Apollo client
export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
