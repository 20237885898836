const types = ({ theme }, type) => {
  const classes = {
    widgetHeader: {
      fontWeight: "bold",
      fontSize: "1.5em",
      paddingLeft: "1rem",
      /*
      [theme.breakpoints.up("md")]: {
        backgroundColor: "red",
      },
      */
    },
    status: {
      fontSize: "1em",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      [theme.breakpoints.up("xs")]: {
        fontSize: "0.8em",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "0.9em",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1em",
      },
    },
    smStatus: {
      fontSize: "0.1em",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    smallStatus: {
      fontSize: "0.9em",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    tableHeader: {
      fontSize: "1em",
      color: "#b4afaa",
      textAlign: "left",
      /*
      [theme.breakpoints.up("md")]: {
        backgroundColor: "red",
      },
      */
    },
    providerLight: {
      fontSize: "0.8em",
      color: "#b4afaa",
      textAlign: "left",
    },
    smProviderLight: {
      fontSize: "0.5em",
      color: "#b4afaa",
      textAlign: "left",
    },
    tableData: {
      fontSize: "1em",
      textAlign: "left",
      color: "#344767",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      [theme.breakpoints.up("xs")]: {
        fontSize: "0.8em",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "0.9em",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1em",
      },
    },
    tableDataFull: {
      fontSize: "1em",
      textAlign: "left",
      color: "#344767",
      overflow: "visible",
      position: "absolute",
      [theme.breakpoints.up("xs")]: {
        fontSize: "0.8em",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "0.9em",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1em",
      },
    },
    tableDataVisible: {
      fontSize: "1em",
      width: "100%",
      textAlign: "left",
      color: "#344767",
      overflow: "visible",
      [theme.breakpoints.up("xs")]: {
        fontSize: "0.8em",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "0.9em",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1em",
      },
    },
    cardTitle: {
      textAlign: "center",
      fontSize: "1.2em",
      color: "#C81400",
      [theme.breakpoints.up("xs")]: {
        fontSize: "0.8em",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1em",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1.2em",
      },
    },
    cardPara: {
      textAlign: "center",
      fontSize: "1.2em",
      color: "#344767",
      [theme.breakpoints.up("xs")]: {
        fontSize: "0.8em",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1em",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1.2em",
      },
    },
  };

  return classes[type];
};

export default types;
