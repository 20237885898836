import { useSelector } from "@xstate/react";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { service } from "../Hierarchy/reducer";

export const I18n = () => {
  const dispatch = useDispatch();

  const operatorId = useSelector(service, ({ context }) => context.operatorId);

  useEffect(() => {
    dispatch({ type: "I18n:initialize" });
  }, []);

  useEffect(() => {
    if (operatorId) {
      dispatch({ type: "I18n:operator:initialize", payload: { operatorId } });
    }
  }, [operatorId]);

  return <></>;
};
