/* eslint-disable implicit-arrow-linebreak */
import React from "react";
import PropTypes from "prop-types";

import SessionTypeSelector from "assets/@luc-ui/SessionHistorySelector";

const PopOverBody = (props) => {
  const { eventList } = props;

  return eventList.map((event, index) => (
    <div style={{ width: "100%", minWidth: "500px" }} key={`${event.state}-${index + 1}`}>
      <SessionTypeSelector event={event} type={event.state} />
    </div>
  ));
};
PopOverBody.propTypes = {
  eventList: PropTypes.arrayOf(
    PropTypes.shape({
      state: PropTypes.string,
      credentialType: PropTypes.string,
      credentialValue: PropTypes.string,
      accessPointCode: PropTypes.string,
      createdAt: PropTypes.string,
      error: PropTypes.bool,
    }),
  ),
};
export default PopOverBody;
