const hd = [
  {
    w: 170,
    h: 83,
    x: 1,
    y: 0,
    i: "Products",
  },
];

const xl = [
  {
    w: 140,
    x: 0,
    h: 83,
    y: 0,
    i: "Products",
  },
];

const lg = [
  {
    w: 120,
    x: 0,
    h: 83,
    y: 0,
    i: "Products",
  },
];

const md = [
  {
    w: 96,
    x: 0,
    h: 83,
    y: 0,
    i: "Products",
  },
];
export const layouts = {
  hd: hd,
  xl: xl,
  lg: lg,
  md: md,
};
