/* eslint-disable implicit-arrow-linebreak */
import React, { useContext, useMemo } from "react";

import useDictionaries from "assets/commonHooks/useDictionaries";

import MotoristTable from "assets/@luc-ui/MotoristDetails/MotoristTable";
import ContractDetailsTable from "assets/@luc-ui/MotoristDetails/ContractsDetailsTable";

import { LocalContext } from "../../../context";

const MotoristContractsTable = () => {
  const text = useDictionaries();
  const { data, onContractStatusChange, onSelectContractPage, onSendMail, onFreeFlag, onVerifLink } =
    useContext(LocalContext);
  const listReactiv = useMemo(() => data, [data, text, onContractStatusChange, onFreeFlag]);
  if (!listReactiv) return <></>;

  const { motoristContracts, motoristDetails, contractPaging } = listReactiv;

  return (
    <>
      <MotoristTable motoristInfos={motoristDetails} />
      <ContractDetailsTable
        motoristDetails={motoristDetails}
        motoristContracts={motoristContracts}
        onSendMail={onSendMail}
        onFreeFlag={onFreeFlag}
        onVerifLink={onVerifLink}
        onContractSwitch={onContractStatusChange}
        contractPaging={contractPaging}
        onSelectPage={onSelectContractPage}
      />
    </>
  );
};

export default MotoristContractsTable;
