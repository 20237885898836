/* eslint-disable implicit-arrow-linebreak */
import React, { useContext, useMemo } from "react";
import { format } from "date-fns";

import useDictionaries from "assets/commonHooks/useDictionaries";
import Paginat from "assets/@luc-ui/Pagination";
import Table from "assets/@luc-ui/Table";
import TableLink from "assets/@luc-ui/TableLink";

import { LocalContext } from "../../context";

const IncidentsTable = () => {
  const text = useDictionaries();
  const { list, page, totalPages, onSelectPage, onSelectIncident } = useContext(LocalContext);

  const listFormated = useMemo(
    () =>
      list.map((technicalEvent) => {
        const { parkingName, date, station, incident, link, context } = technicalEvent;

        return [
          text(parkingName),
          format(new Date(date), "dd.MM HH:mm:ss"),
          station,
          text(incident, context),
          link ? (
            <div style={{ width: "100%", textAlign: "center" }}>
              <TableLink onClick={() => onSelectIncident(technicalEvent)} />
            </div>
          ) : (
            <></>
          ),
        ];
      }),
    [list, text],
  );

  return (
    <>
      <div
        style={{
          height: "100%",
          paddingLeft: "1rem",
          position: "relative",
        }}
      >
        <Table
          headers={[
            {
              title: text("widgets-incidents-table-header-parking"),
              size: "intermediate",
            },
            {
              title: text("widgets-incidents-table-header-date"),
              size: "medium",
            },
            {
              title: text("widgets-incidents-table-header-station"),
              size: "smaller",
            },
            {
              title: text("widgets-incidents-table-header-incident"),
              size: "xlarge",
            },
            { title: "", size: "xsmall" },
          ]}
          rows={listFormated}
        />
        <Paginat page={page} totalPages={totalPages} onSelectPage={onSelectPage} />
      </div>
    </>
  );
};

export default IncidentsTable;
