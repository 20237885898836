import React, { useEffect } from "react";
import { styled } from "@mui/system";

import MenuItem from "@mui/material/MenuItem";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import MDTypography from "assets/@ndres-ui/MDTypography";
import MDBox from "assets/@ndres-ui/MDBox";
import useDictionaries from "assets/commonHooks/useDictionaries";

const Select = styled(({ ...rest }) => (
  <TextField
    {...rest}
    InputProps={{
      style: {
        height: 44.125,
        // padding: '12px',
        width: "100%",
      },
    }}
  />
))(({ theme }) => ({
  width: "100%",
  padding: "2px 2px",
  margin: "8px 0px 0px 0px",
}));

const Android12Switch = styled(({ label, ...rest }) => (
  <MDBox pt="12px" m="0px" width="100%" display="flex" alignItems="center" justifyContent="flex-start" height="100%">
    <Switch {...rest} />
    <MDTypography fontSize="0.9rem" fontWeight="light" pb="4px">
      {label}
    </MDTypography>
  </MDBox>
))(({ theme }) => ({
  padding: 10,
  width: "auto",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 5,
    transitionDuration: "300ms",
    "& .Mui-checked": {
      transform: "translateX(36px)",
      color: "#fff",
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        width: 25,
        height: 25,
        margin: 0,
        top: "-15px",
      },
      "& .Mui-disabled .MuiSwitch-track": {
        opacity: 0.5,
      },
      "& .MuiSwitch-track": {
        backgroundColor: "#4caf50!important",
        borderColor: "#4caf50!important",
      },
    },
    "& .Mui-focusVisible .MuiSwitch-thumb": {
      border: "6px solid #fff",
    },
    "& .Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "& .Mui-disabled .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    width: "40px",
    height: "15px",
    backgroundColor: theme.palette.grey[400],
    transition: theme.transitions.create(["background-color"], {
      duration: 300,
    }),
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "15%",
      transform: "translateY(-40%)",
      width: 32,
      height: 32,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 25,
    height: 25,
    margin: 0,
    top: "-15px",
  },
  "& .Mui-checked+.MuiSwitch-track": {
    backgroundColor: "#4caf50!important",
    borderColor: "#4caf50!important",
  },
}));

export default function Step2({ addCompleted, setData, formData, addNextBt }) {
  const text = useDictionaries();
  const setContractData = (data) => {
    setData({
      contract: {
        ...formData.contract,
        ...data,
      },
    });
  };

  useEffect(() => {
    const testing = [formData?.user?.username !== undefined, true];

    const result = testing.reduce((c, o) => c && o, true);
    addNextBt(3, result);
  }, [formData]);

  useEffect(() => {
    addCompleted(3, true);
  }, []);

  return (
    <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
      <MDBox pl={2}>
        <MDTypography fontSize="1.5rem" fontWeight="regular" color="dark">
          {text("Wizard-forth-step-activation")}
        </MDTypography>
        <MDTypography fontSize="1rem" fontWeight="light" color="text">
          {text("Wizard-forth-step-activation-description")}
        </MDTypography>
      </MDBox>

      <MDBox display="flex" alignItems="center" justifyContent="around" ml={2} mt={4}>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Select
                  label="Payment method"
                  type="paymen"
                  id="paymenMethod"
                  select
                  value={formData?.contract?.paymentMethod ?? ""}
                  onChange={(evt) => setContractData({ paymentMethod: evt.target.value })}
                >
                  <MenuItem value="Payment in cash">Payment in cash</MenuItem>
                  <MenuItem value="Bank transfer">Bank transfer</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" height="100%" width="100%">
                  <Android12Switch
                    checked={formData?.contract?.isEnabled}
                    label={text("Wizard-forth-step-toggle-activation")}
                    color="success"
                    onChange={(evt) =>
                      setContractData({
                        isEnabled: !formData?.contract?.isEnabled,
                      })
                    }
                  />
                </MDBox>
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" height="100%" width="100%">
                  <Android12Switch
                    checked={formData?.contract?.freeFlagIsEnabled}
                    label={text("Wizard-forth-step-toggle-activation-freeflag")}
                    color="success"
                    onChange={(evt) =>
                      setContractData({
                        freeFlagIsEnabled: !formData?.contract?.freeFlagIsEnabled,
                      })
                    }
                  />
                </MDBox>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <MDBox height="5px" />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}
