import { gql } from "@apollo/client";

export default gql`
  mutation updateContract($contractId: String!, $input: UpdateContractDto!) {
    updateContract(contractId: $contractId, input: $input) {
      _id
      isEnabled
      motoristId
      startDate
      stopDate
    }
  }
`;
