import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { layouts } from "./layoutHalf.conf";
import { GRID_ACTIVE } from "../../config";
import UserProviderList from "components/UserProviderList";
import UserProviderDetails from "components/UserProviderDetails";

const ResponsiveGridLayout = WidthProvider(Responsive);

const UserProviderPage = () => {
  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ hd: 1700, xl: 1400, lg: 1200, md: 960 }}
        cols={{ hd: 170, xl: 140, lg: 120, md: 96 }}
        rowHeight={10}
        isResizable={GRID_ACTIVE}
        isDraggable={GRID_ACTIVE}
        isBounded
        margin={[0, 0]}
        items={2}
        compactType={null}
        onLayoutChange={(e) => console.debug(e)}
        // preventCollision
        containerPadding={[0, 0]}
      >
        <div key="List" className="containerInGrid">
          <UserProviderList />
        </div>
        <div key="Details" className="containerInGrid">
          <UserProviderDetails />
        </div>
      </ResponsiveGridLayout>
    </div>
  );
};

export default UserProviderPage;
