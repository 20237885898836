import { gql } from "@apollo/client";

export default gql`
  mutation createMotorist($input: CreateMotoristDto!) {
    createMotorist(input: $input) {
      motorist {
        _id
        username
        firstName
        lastName
        credentials {
          type
          value
        }
      }
      contracts {
        _id
        category
        productId
        ospContractId
        motoristId
      }
    }
  }
`;
