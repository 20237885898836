import { gql } from "@apollo/client";

export default gql`
  query contracts($page: Int!, $limit: Int!, $parkingsIds: [String!]!, $isActive: Boolean) {
    contracts(page: $page, limit: $limit, parkingsIds: $parkingsIds, isActive: $isActive) {
      list {
        category
        motorist {
          _id
          username
          firstName
          lastName
          phone
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
