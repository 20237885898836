import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "@xstate/react";

import { checkContractValidity } from "assets/commonFunctions/checkContractValidity";

import { service as tenantsDetailsService } from "../../reducer";
import { service as contractService } from "../../../BusinessTenants/reducer";
import { DateTime } from "luxon";

const defaultContextData = {
  data: {},
};

export const LocalContext = createContext(defaultContextData);

export const ContextProvider = ({ children, storeName, events = {} }) => {
  const dispatch = useDispatch();

  // Motorist actually displayed
  const selectedContract = useSelector(contractService, ({ context }) => context?.selectedContract);
  const poolContract = useSelector(tenantsDetailsService, ({ context }) => context?.poolContract);
  useEffect(() => {
    dispatch({
      type: `${storeName}:wakeup`,
      payload: { selectedContract },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: `${storeName}:show`,
      payload: { selectedContract },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContract]);

  const eventDispatch = (key, fn, src) => {
    const payload = fn(src);
    dispatch({ type: `${storeName}:${key}`, payload });
  };
  const findLast = (array, filter) => {
    const result = array.filter(filter);
    return result[result.length - 1];
  };
  const eventsActions = useMemo(
    () =>
      Object.entries(events).reduce(
        (acc, [key, fn]) => ({
          [key]: (src) => eventDispatch(key, fn, src),
          ...acc,
        }),
        {},
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [events],
  );

  const renderContract = useMemo(() => {
    if (!poolContract) return;

    let {
      parking,
      name1,
      name2,
      address1,
      address2,
      zipcode,
      city,
      country,
      product,
      startDate,
      stopDate,
      spacesAllocated,
      spacesOccupied,
      category,
      isEnabled,
      poolUsers,
      sessions: { list: sessions, paging: sessionPaging },
      ospContractId,
      motoristsContracts: { list: motoristContractslist, paging: motoristContractsPaging },
      _id,
    } = poolContract;

    const { contractStatus, isEnabled: contractIsEnabled } = checkContractValidity({
      isEnabled,
      startDate,
      stopDate,
    });

    const formatedMotoristList = motoristContractslist.map(
      ({ motorist, _id, startDate, stopDate, isEnabled, ospContractId, credentials }) => {
        const { _id: motoristId, username, firstName, lastName } = motorist;
        const externalProvider = credentials.filter(({ type }) => type === "PROVIDER_EXTERNAL_ID");
        const allCredentials = credentials.filter(({ type }) => type !== "RFID");
        let contractStatus = "";
        if (DateTime.fromISO(stopDate) < Date.now()) {
          contractStatus = "motorist-contract-status-expired";
          isEnabled = false;
        }
        if (DateTime.fromISO(stopDate) > Date.now() && isEnabled) {
          contractStatus = "motorist-contract-status-active";
        }
        if (DateTime.fromISO(stopDate) > Date.now() && !isEnabled) {
          contractStatus = "motorist-contract-status-suspended";
        }
        const { isValid } = checkContractValidity({
          isEnabled,
          startDate,
          stopDate,
        });

        return {
          contractId: _id,
          ospContractId,
          motoristId,
          isActive: isEnabled,
          status: contractStatus,
          username,
          motorist,
          firstName,
          lastName,
          externalProvider: externalProvider,
          credentials: allCredentials,
          isValid,
          startDate,
          endDate: stopDate,
          isEnabled,
        };
      },
    );

    return {
      tenant: {
        _id,
        name1,
        name2,
        city,
        zipcode,
        country,
        address1,
        address2,
      },
      contract: {
        ospContractId,
        _id,
        startDate,
        stopDate,
        spacesAllocated,
        spacesOccupied,
        category,
        parking,
        isEnabled: contractIsEnabled,
        contractStatus,
        product,
      },
      sessions: sessions?.map(
        ({ _id, parking, entryDate, exitDate, state, occupancyDuration, isInconsistent, product, events }) => {
          const ENTRY_AUTHORIZED = findLast(events, ({ state }) => state === "ENTRY_AUTHORIZED");
          const ENTRY_DENIED = findLast(events, ({ state }) => state === "ENTRY_DENIED");
          const ENTERED = findLast(events, ({ state }) => state === "ENTERED");
          const EXITED = findLast(events, ({ state }) => state === "EXITED");
          const isEntered = ENTERED ? true : false;
          const isClosed = EXITED || ENTRY_DENIED ? true : false;

          const entry = ENTERED?.createdAt ?? ENTRY_AUTHORIZED?.createdAt ?? ENTRY_DENIED?.createdAt ?? undefined;
          const exit = EXITED?.createdAt ?? undefined;

          const eventsFiltered = events?.filter(
            (event) => !["CREATED", "EXIT_AUTHORIZE_PENDING"].includes(event.state),
          );

          const credentialToShow = ENTRY_AUTHORIZED?.credentials?.find(({ type }) => type !== "RFID");
          const credentialLabel = credentialToShow?.provider ?? credentialToShow?.type ?? credentialToShow?.value;
          const { reason } = findLast(events, ({ state }) => state !== "");

          return {
            sessionID: _id,
            isClosed,
            parkingName: `parking-${parking._id}-name`,
            entry: entry,
            exit: exit,
            reason,
            error: !!isInconsistent,
            duration: occupancyDuration,
            status: state === "EXIT_AUTHORIZE_PENDING" ? "ENTERED" : state,
            credentialValue: credentialToShow?.value || undefined,
            credentialType: credentialLabel || undefined,
            eventList: eventsFiltered,
            productName: product ? `product-${product._id}-name` : "",
            isEntered,
          };
        },
      ),
      sessionPaging: {
        page: sessionPaging?.current,
        total: Math.ceil(sessionPaging?.count / sessionPaging?.limit),
      },
      poolUsers: poolUsers.list,
      motoristsContracts: formatedMotoristList,
      motoristsPaging: {
        page: motoristContractsPaging.current,
        total: Math.ceil(motoristContractsPaging.count / motoristContractsPaging.limit),
      },
    };
  }, [poolContract, poolContract.spacesOccupied]);

  return (
    <LocalContext.Provider
      value={{
        data: selectedContract,
        poolContract: renderContract,
        ...eventsActions,
      }}
    >
      {children}
    </LocalContext.Provider>
  );
};

ContextProvider.defaultProps = {
  storeName: "BusinessTenantsParentDetails",
  events: {},
};

ContextProvider.propTypes = {
  storeName: PropTypes.string,
  events: PropTypes.shape({}),
};
