import React from "react";

import UserProviderTable from "./materials/table";

import { ContextProvider } from "./context";
// import ContainerInGrid from "assets/@luc-ui/Container";

const UserProviderList = (props) => {
  const onSelectPage = (newPage) => {
    return { newPage };
  };
  const onSelectMotorist = (selectedMotoristId) => ({
    selectedMotoristId,
  });

  return (
    <div style={{ height: "100%", overflow: "scroll" }}>
      <ContextProvider
        storeName="UserProviderList"
        events={{
          onSelectPage,
          onSelectMotorist,
        }}
      >
        <UserProviderTable />
      </ContextProvider>
    </div>
  );
};

export default UserProviderList;
