import React from "react";
import MDBox from "../../../../../assets/@ndres-ui/MDBox";
import MDTypography from "../../../../../assets/@ndres-ui/MDTypography";

const LocalProvider = () => {
  return (
    <MDBox mt={9} pt={1} ml={2} style={{ maxWidth: "450px" }}>
      <MDTypography fontSize="0.7em" fontStyle="italic">
        Example :
        <br />
        aabbccddee21;081151;ZH123456;john.doe@company.ch;john;doe
        <br />
        aabbccddee22;081152;;jules.doe@company.ch;jules;doe
        <br />
        ;;ZH234567;gustav.doe@company.ch;gustav;doe
        <br />
        aabbccddee24;;;;brigitte;adams
        <br />
        aabbccddee25;081155;;;;
      </MDTypography>
    </MDBox>
  );
};

export default LocalProvider;
