import React, { useContext, useMemo } from "react";

import { format } from "date-fns";
import useDictionaries from "assets/commonHooks/useDictionaries";

import Paginat from "assets/@luc-ui/Pagination";
import Table from "assets/@luc-ui/Table";
import PopOver from "assets/@luc-ui/PopOver";
import PopOverHeader from "assets/@luc-ui/PopOver/header";
import PopOverBody from "assets/@luc-ui/PopOver/body";
import { Duration } from "assets/@luc-ui/Duration";
import MDTypography from "assets/@ndres-ui/MDTypography";

import { LocalContext } from "../../context";

const SessionsTable = () => {
  const text = useDictionaries();
  const { list, page, totalPages, onSelectPage } = useContext(LocalContext);

  const listFormated = useMemo(
    () =>
      list.map(({ parkingName, entry, exit, duration, isEntered, eventList, error }) => {
        const FormatedEntryDate = entry ? format(new Date(entry), "dd.MM.yyyy HH:mm:ss") : <span />;
        const FormatedExitDate = exit ? format(new Date(exit), "dd.MM.yyyy HH:mm:ss") : <span />;
        const showDuration = isEntered ? <Duration startDate={entry} endDate={exit} duration={duration} /> : <>-</>;
        return {
          options: {
            error,
          },
          elements: [
            text(parkingName),
            FormatedEntryDate,
            FormatedExitDate,
            showDuration,
            <div style={{ width: "100%", textAlign: "center" }}>
              <PopOver>
                <PopOverHeader parkingName={parkingName} entry={FormatedEntryDate} exit={FormatedExitDate} />
                <div>
                  <MDTypography padding="0.5em 0 0.3em 0" textTransform="first" type="tableData">
                    {text("widgets-latest-events-popover-label-history")}
                  </MDTypography>

                  <PopOverBody eventList={eventList} />
                </div>
              </PopOver>
            </div>,
          ],
        };
      }),
    [list, text],
  );

  return (
    <div
      style={{
        height: "100%",
        paddingLeft: "1rem",
        position: "relative",
      }}
    >
      <Table
        headers={[
          {
            title: text("widgets-latest-events-table-header-parking"),
            size: "large",
          },
          {
            title: text("widgets-latest-events-table-header-entry"),
            size: "large",
          },
          {
            title: text("widgets-latest-events-table-header-exit"),
            size: "large",
          },
          {
            title: text("widgets-latest-events-table-header-duration"),
            size: "small",
          },
          { title: "", size: "xsmall" },
        ]}
        rows={listFormated}
      />
      <Paginat page={page} totalPages={totalPages} onSelectPage={onSelectPage} />
    </div>
  );
};

export default SessionsTable;
