import { gql } from "@apollo/client";

export default gql`
  query breakdowns($page: Int!, $limit: Int!, $order: String, $parkingsIds: [String!]!) {
    breakdowns(page: $page, limit: $limit, order: $order, parkingsIds: $parkingsIds) {
      list {
        _id
        startDate
        endDate
        category
        description
        type
        createdAt
        updatedAt
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
