/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Card from "@mui/material/Card";

// Material UI
import { styled } from "@mui/material/styles";

// Material Dashboard 2 React base styles
import CheckIcon from "@mui/icons-material/Check";
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";
import { ProviderLogos } from "../../../commonFunctions/useProvider";

const CardBox = styled(MDBox)(({ theme, selected }) => ({
  background: selected ? "#ababab10" : "transparent",
  // boxShadow: selected ? '0px 0px 8px 0px rgba(138,138,138,0.25)' : 'none',
  cursor: "pointer",
  userSelect: "none",
  borderRadius: "10px",
  border: selected ? "2px solid #ababab20" : "2px solid #ababab00",
  "&:hover": {
    background: "#ababab10",
  },
  "&:focus:not(:hover)": {
    color: "#c2c2c3",
  },
}));

const Check = styled(CheckIcon)(({ theme, selected }) => ({
  position: "relative",
  top: 7,
  right: -5,
  width: "25px",
  height: "25px",
  background: "white",
  borderRadius: "50%",
  border: "2px solid green",
  color: "green",
  margin: "0px",
  padding: "2px",
  "&:hover": {
    background: "#ababab10",
  },
  "&:focus:not(:hover)": {
    color: "#c2c2c3",
  },
}));

function ProductInfoCard({ productId, currency, title, price, type, selected = false, onClick, iconLogo }) {
  const text = useDictionaries();

  return (
    <Card sx={{ height: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
      <CardBox
        onClick={onClick}
        height="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="column"
        selected={selected}
      >
        <ProviderLogos provider={iconLogo} width="80px" height="80px" />

        <MDBox width="100%" display="flex" justifyContent="flex-start" alignItems="center" pt={2} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            {`${text(`product-${productId}-name`)}`.slice(0, 25)}
            {selected ? <Check /> : null}
          </MDTypography>
        </MDBox>
        <MDBox width="100%" display="flex" justifyContent="center" alignItems="center" mt={2} mb={1}>
          <MDBox height="calc(100% - 20px)" display="flex" justifyContent="flex-start" alignItems="flex-start">
            <MDTypography color="secondary" variant="caption" fontWeight="medium" textTransform="capitalize">
              {currency}
            </MDTypography>
          </MDBox>
          <MDTypography color="secondary" variant="h2" fontWeight="medium" textTransform="capitalize">
            {price}
          </MDTypography>
          <MDBox height="calc(100% - 20px)" display="flex" justifyContent="flex-end" alignItems="flex-end">
            <MDTypography color="secondary" variant="caption" fontWeight="medium" textTransform="capitalize">
              {`/${type}`}
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox px={2} py={1} overflow="hidden">
          <MDBox mb={0} lineHeight={1}>
            <MDTypography variant="button" color="text" fontWeight="light">
              <span style={{ fontSize: "0.9em" }}>{text(`product-${productId}-description`)}</span>
            </MDTypography>
          </MDBox>
        </MDBox>
      </CardBox>
    </Card>
  );
}

// Setting default props for the ProductInfoCard
ProductInfoCard.defaultProps = {
  shadow: false,
};

// Typechecking props for the ProductInfoCard
ProductInfoCard.propTypes = {};

export default ProductInfoCard;
