import React, { useState, useEffect, useMemo, useContext } from "react";
import { useSelector } from "@xstate/react";
import { service } from "../reducer";
import MDBox from "assets/@ndres-ui/MDBox";
import NewSubscriptionDialogStepper from "./stepsScreen";
import { useDispatch } from "react-redux";
import SweetAlert2 from "react-sweetalert2";

import { ContextProvider, LocalContext } from "../context";
import useDictionaries from "assets/commonHooks/useDictionaries";
let timerInterval;

export default function SpringPopper() {
  const text = useDictionaries();
  const { selection, setSelection, parkingId } = useContext(LocalContext);
  const dispatch = useDispatch();
  const [swalAwait, setSwalAwait] = useState();
  const [swalSuccess, setSwalSuccess] = useState();
  const [swalError, setSwalError] = useState();

  const error = useSelector(service, ({ context }) => context.error);
  const lastCreatedContracts = useSelector(service, ({ context }) => context.lastCreatedContracts);

  const memoError = useMemo(() => error, [error]);

  const onClose = () => true;

  const handleSubmit = (event) => {
    dispatch(event);

    onClose();
    setSwalAwait({
      show: true,
      allowOutsideClick: false,
      showConfirmButton: false,
      title: "Creating the Contrat!",
      html: "We are doing everything necessary to create the contract <b></b>",
      timerProgressBar: true,
      allowEscapeKey: false,
      customClass: {
        title: "SwalTitle",
      },
      didOpen: () => {
        // Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
        setSwalAwait({ show: false });
      },
    });
  };

  useEffect(() => {
    if (memoError !== undefined && memoError) {
      setSwalError({
        show: true,
        title: text(`global-error-contract-create`), // 'An error occurred and the contract could not be created.',
        // html: `<p><span style="font-weight:bold">because:</span> ${memoerrorMessage}</p>`,
        icon: "error",
        showConfirmButton: false,
        cancelButtonText: "Agree",
        showCancelButton: true,
        cancelButtonColor: "#c8161b",
        customClass: {
          title: "SwalTitle",
          container: "swalZindex",
        },
        didOpen: () => {
          // Swal.showLoading();
          setSelection("");
        },
        willClose: () => {
          clearInterval(timerInterval);
          setSwalError({ show: false });
        },
      });
      dispatch({
        type: "Wizard:NewSubscription:create-contract-reset",
        payload: { lastCreatedContracts },
      });
    } else if (memoError === false) {
      setSwalSuccess({
        show: true,
        title: text(`global-success-contract-create`),
        icon: "success",
        showConfirmButton: false,
        timer: 1000,
        customClass: {
          title: "SwalTitle",
          container: "swalZindex",
        },
        didOpen: () => {
          setSelection("");
          // Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
          setSwalSuccess({ show: false });
        },
      });
      dispatch({
        type: "Wizard:NewSubscription:create-contract-reset",
        payload: { lastCreatedContracts },
      });
      setSelection("");
    }
  }, [memoError]);

  return (
    <>
      <ContextProvider storeName="Wizard" events={{}}>
        <SweetAlert2 {...swalAwait} />
        <SweetAlert2 {...swalSuccess} />
        <SweetAlert2 {...swalError} />
        <NewSubscriptionDialogStepper
          dialog={selection}
          setDialog={setSelection}
          parkingId={parkingId}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
        <MDBox onClick={() => setSelection("New Pool")}></MDBox>
      </ContextProvider>
    </>
  );
}
