import React, { useContext, useEffect, useMemo, useState } from "react";

import useDictionaries from "assets/commonHooks/useDictionaries";

import { LocalContext } from "../../context";
import { useDispatch } from "react-redux";
import Table from "assets/@luc-ui/Table";
import Paginat from "assets/@luc-ui/Pagination";
import { DateTime } from "luxon";
import { format } from "date-fns";
import MDBox from "../../../../assets/@ndres-ui/MDBox";
import { Tabs, Tab, Checkbox } from "@mui/material";
import { useSelector } from "@xstate/react";
import { service } from "../../../Hierarchy/reducer";
import MDTypography from "../../../../assets/@ndres-ui/MDTypography";

const UserProviderTable = () => {
  const text = useDictionaries();
  const dispatch = useDispatch();

  const selectedParkings = useSelector(service, ({ context }) => context.selectedParkings);

  const {
    list,
    page,
    totalPages,
    localMotorist,
    onSelectPage,
    onSelectMotorist,
    selectedMotoristId,
    showFilteredMotorists,
  } = useContext(LocalContext);
  const [tab, setTab] = useState(localMotorist ? 1 : 0);
  const handleChange = (_, t) => setTab(t);

  useEffect(() => {
    setTab(localMotorist ? 1 : 0);
  }, [localMotorist]);

  const [checkboxList, setCheckboxList] = useState([]);

  const [wizardCC, setWizardCC] = useState(false);

  const rightChecker = (x) => {
    return true; //!!(!x || x === "-" || x === "?");
  };

  const checkAll = (state) => {
    if (state) {
      setCheckboxList(() => []);
      list
        .filter(({ status }) => !!rightChecker(status))
        .forEach(({ _id, firstName, lastName, username }) => {
          setCheckboxList((prevCheckboxList) => [...prevCheckboxList, { _id, firstName, lastName, username }]);
        });
    } else {
      setCheckboxList([]);
    }
  };

  useEffect(() => {
    setWizardCC(!!checkboxList.length);
  }, [checkboxList]);

  const renderLine = ({ _id, firstName, lastName, email, status }) => {
    let statusFormatted;

    if (status === "VERIFIED") {
      statusFormatted = (
        <span style={{ color: "green", textTransform: "uppercase" }}>
          {text("widgets-session-details-table-motorist-address-verified")}
        </span>
      );
    } else if (status !== "VERIFIED" && status !== "-") {
      statusFormatted = (
        <span style={{ color: "#fb8c00", textTransform: "uppercase" }}>
          {text("widgets-session-details-table-motorist-address-to_verify")}
        </span>
      );
    } else {
      statusFormatted = <span>{status}</span>;
    }

    let checkbox;

    rightChecker(status)
      ? (checkbox = (
          <Checkbox
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.target.checked
                ? setCheckboxList((prevCheckboxList) => [
                    ...prevCheckboxList,
                    { _id, firstName, lastName, email, status },
                  ])
                : setCheckboxList((prevCheckboxList) => [
                    ...prevCheckboxList.filter(({ _id: motoristId }) => motoristId !== _id),
                  ]);
            }}
            checked={checkboxList.find(({ _id: motoristId }) => motoristId === _id) !== undefined}
            type="checkbox"
          />
        ))
      : (checkbox = <div></div>);

    return {
      options: {
        error: false,
        id: _id,
      },
      elements: [
        checkbox,
        email ? email : "-",
        firstName ? firstName : "-",
        lastName ? lastName : "-",
        statusFormatted,
      ],
    };
  };

  const listFormated = useMemo(() => list.map(renderLine), [list, checkboxList, text]);
  const filteredFormated = useMemo(() => localMotorist && [renderLine(localMotorist)], [localMotorist, text]);
  return (
    <>
      <div
        className="rarara"
        style={{
          position: "relative",
          padding: "0 1em",
        }}
      >
        <MDBox sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Checkbox
            type="checkbox"
            checked={list.filter(({ status }) => !!rightChecker(status)).length === checkboxList.length}
            onChange={(e) => checkAll(e.target.checked)}
            className="input_checkbox-mt"
          />

          <Tabs
            style={{ height: "60px", borderRadius: "0px", backgroundColor: "transparent" }}
            value={tab}
            onChange={handleChange}
            aria-label="motorists tabs"
          >
            <Tab label={text("widgets-motorists-tab-all")} />
            <Tab label={text("widgets-motorists-tab-filtered")} disabled={!showFilteredMotorists} />
          </Tabs>
          {wizardCC ? (
            <span
              onClick={() => {
                dispatch({
                  type: "WizardCreateContractUserProvider:wakeup",
                  payload: {
                    selectedParking: selectedParkings,
                    motoristChecked: checkboxList,
                  },
                });
              }}
              className="btn_wizard-cc"
            >
              <i className="fa-solid fa-plus"></i>
            </span>
          ) : null}
        </MDBox>
        <div role="tabpanel" hidden={tab !== 0} index={0}>
          <Table
            headers={[
              {
                title: "",
                size: "xsmall",
              },
              {
                title: text("widgets-user-provider-details-table-header-email"),
                size: "intermediate",
              },
              {
                title: text("widgets-user-provider-details-table-header-firstname"),
                size: "small",
              },
              {
                title: text("widgets-user-provider-details-table-header-lastname"),
                size: "small",
              },
              {
                title: text("widgets-user-provider-details-table-header-status"),
                size: "small",
              },
            ]}
            rows={listFormated}
            onClick={(id) => {
              onSelectMotorist(id);
            }}
            sessionId={selectedMotoristId}
            vAlign="top"
          />
          <Paginat page={page} totalPages={totalPages} onSelectPage={onSelectPage} />
        </div>
        <div role="tabpanel" hidden={tab !== 1} index={1}>
          {showFilteredMotorists && (
            <Table
              headers={[
                {
                  title: "",
                  size: "xsmall",
                },
                {
                  title: text("widgets-user-provider-details-table-header-email"),
                  size: "small",
                },
                {
                  title: text("widgets-user-provider-details-table-header-firstname"),
                  size: "small",
                },
                {
                  title: text("widgets-user-provider-details-table-header-lastname"),
                  size: "small",
                },
                {
                  title: text("widgets-user-provider-details-table-header-status"),
                  size: "small",
                },
              ]}
              rows={filteredFormated}
              onClick={(id) => {
                onSelectMotorist(id);
              }}
              sessionId={selectedMotoristId}
              vAlign="top"
            />
          )}
        </div>
      </div>
      <style jsx="true">
        {`
          .input_checkbox-mt {
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 1000;
          }

          .btn_wizard-cc {
            z-index: 1000;
            position: absolute;
            top: 10px;
            right: 5px;
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;
            line-height: 1;
            transition: all 0.3s ease-in-out;
            background-color: #c81400;
            border-radius: 100%;
            height: 30px;
            aspect-ratio: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 0px 5px #ccc;
          }

          .btn_wizard-cc i {
            transition: all 0.3s ease-in-out;
            color: #fff;
          }
        `}
      </style>
    </>
  );
};

export default UserProviderTable;
