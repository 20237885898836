import { useSelector } from "@xstate/react";
import InputHidable from "assets/@luc-ui/InputHidable";
import MDButton from "assets/@ndres-ui/MDButton";
import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Input, SelectPicker } from "rsuite";
import Popup from "../../../../../assets/@redapuenta-ui/popup/popup";
import { LocalContext } from "../../../context";
import { service } from "../../../reducer";

const plateRegex = /^[a-zA-Z0-9ÖÜÄËÏöüäëï]{1,12}$/;

const validatePlates = (plates) => plates.every(({ value }) => plateRegex.test(value));

const UserProviderAboutTable = () => {
  const text = useDictionaries();
  const dispatch = useDispatch();

  const { data, onUpdateMotorist } = useContext(LocalContext);
  const [modePopup, setModePopup] = useState("choice");
  const [openPopup, setOpenPopup] = useState(false);
  const [focusId, setFocusId] = useState();

  const [titlePopup, setTitlePopup] = useState();
  const [textPopup, setTextPopup] = useState();
  const [isEditable, setEditable] = useState(false);
  const [formData, setFormData] = useState(undefined);
  const [authorizeSubmit, setAuthorizeSubmit] = useState(false);

  //! Selector for Popup
  const updateMotorist = useSelector(service, ({ context }) => context.updateMotorist);
  const errorUpdateMotorist = useSelector(service, ({ context }) => context.errorUpdateMotorist);

  useEffect(() => {
    const dataToTest = [];

    const resultOfTest =
      dataToTest.reduce((acc, value) => {
        if (value) acc = acc + 1;
        return acc;
      }, 0) === dataToTest.length;

    const uids = formData?.uids || [];

    const resultOfCredential = uids
      .filter(({ value }) => !!value)
      .filter(({ description }) => {
        const desc = description.trim().length;
        return desc !== 0 && (desc < 2 || desc > 6);
      });
    setAuthorizeSubmit(!(resultOfTest && !resultOfCredential.length));
  }, [formData]);

  //! SUCCESS for Popup
  useEffect(() => {
    if (updateMotorist?._id && updateMotorist?._id === formData?._id) {
      setEditable(false);
      setTextPopup({ type: "success", value: text("popup-user-provider-details-update-success") });
      setModePopup("result");
    }
  }, [updateMotorist]);

  //! ERROR for Popup
  useEffect(() => {
    if (errorUpdateMotorist) {
      setTitlePopup({
        type: "basic",
        value: text(`global-error-title-${new String(errorUpdateMotorist).toLowerCase()}`),
      });
      setTextPopup({
        type: "error",
        // TODO: gérer le paramètre
        value: text(`global-error-text-${new String(errorUpdateMotorist).toLowerCase()}`, { ID: "" }),
      });
      setModePopup("result");
    }
  }, [errorUpdateMotorist]);

  //! Open Popup
  const submitClick = (e) => {
    if (!validatePlates(formData.plates)) {
      setTitlePopup({
        type: "basic",
        value: ` ${text("global-error-title-aoff_13")}`,
      });
      setModePopup("result");
    } else {
      setTitlePopup({
        type: "basic",
        value: ` ${text("widgets-user-provider-details-modal-second-p")}`,
      });
      setModePopup("choice");
      setTextPopup(undefined);
    }

    e.stopPropagation();
    setOpenPopup(true);
  };

  //! Exit Popup
  const popupExit = () => {
    setOpenPopup(false);
    setModePopup("choice");
  };

  //! Choice in Popup
  const popupChoice = (choice) => {
    if (choice) {
      dispatch({
        type: "UserProviderDetails:reset-error",
        payload: {},
      });

      setModePopup("load");
      onUpdateMotorist(formData);
    } else {
      setModePopup("choice");
      setOpenPopup(false);
    }
  };

  useEffect(() => {
    setEditable(false);
    if (data) setFormData(data.UserProviderDetails);
  }, [data]);

  return (
    <>
      {formData ? (
        <section className="section_details">
          <MDTypography className="global_xs_title" type="tableHeader">
            {text("widgets-user-provider-details-table-header-motorist-infos")}
          </MDTypography>

          <div className="global_separa"></div>
          <div>
            <div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-first-name")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography component="span" type="tableData">
                    {isEditable ? (
                      <InputHidable
                        data={formData.firstName}
                        type="text"
                        isEditable={true}
                        onChange={(data) => setFormData({ ...formData, firstName: data })}
                        width="90%"
                      />
                    ) : (
                      formData.firstName
                    )}
                  </MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-address")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {isEditable ? (
                      <InputHidable
                        data={formData.address1}
                        type="text"
                        isEditable={true}
                        onChange={(data) => setFormData({ ...formData, address1: data })}
                        width="90%"
                      />
                    ) : (
                      formData.address1
                    )}
                  </MDTypography>
                </div>
              </div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-last-name")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {isEditable ? (
                      <InputHidable
                        data={formData.lastName}
                        type="text"
                        isEditable={true}
                        onChange={(data) => setFormData({ ...formData, lastName: data })}
                        width="90%"
                      />
                    ) : (
                      formData.lastName
                    )}
                  </MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-address-comp")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {isEditable ? (
                      <InputHidable
                        data={formData.address2}
                        type="text"
                        isEditable={true}
                        onChange={(data) => setFormData({ ...formData, address2: data })}
                        width="90%"
                      />
                    ) : (
                      formData.address2
                    )}
                  </MDTypography>
                </div>
              </div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-email")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="lowercase">
                    {isEditable && formData.status === "-" ? (
                      <InputHidable
                        data={formData.email}
                        type="text"
                        isEditable={true}
                        onChange={(data) => setFormData({ ...formData, email: data })}
                        width="90%"
                      />
                    ) : (
                      formData.email
                    )}
                  </MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="capitalize">
                    {text("widgets-motorist-details-table-motorist-zip-city")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {isEditable ? (
                      <InputHidable
                        data={formData.city}
                        type="text"
                        isEditable={true}
                        onChange={(data) => setFormData({ ...formData, city: data })}
                        width="90%"
                      />
                    ) : (
                      formData.city
                    )}
                  </MDTypography>
                </div>
              </div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-mobile")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {isEditable ? (
                      <InputHidable
                        data={formData.phone}
                        type="text"
                        isEditable={true}
                        onChange={(data) => setFormData({ ...formData, phone: data })}
                        width="90%"
                      />
                    ) : (
                      formData.phone
                    )}
                  </MDTypography>
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-country")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData" textTransform="first">
                    {isEditable ? (
                      <InputHidable
                        data={formData.country}
                        type="text"
                        isEditable={true}
                        onChange={(data) => setFormData({ ...formData, country: new String(data).toUpperCase() })}
                        width="90%"
                      />
                    ) : formData.country ? (
                      text(`global-country-${formData.country ? formData.country.toUpperCase() : ""}`)
                    ) : (
                      ""
                    )}
                  </MDTypography>
                </div>
              </div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-lang")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  {isEditable ? (
                    <SelectPicker
                      value={formData.lang}
                      searchable={false}
                      data={[
                        { label: text("global-select-lang-en_EN"), value: "en_EN" },
                        { label: text("global-select-lang-fr_FR"), value: "fr_FR" },
                        { label: text("global-select-lang-de_DE"), value: "de_DE" },
                        { label: text("global-select-lang-it_IT"), value: "it_IT" },
                      ]}
                      onChange={(field) => {
                        setFormData({ ...formData, lang: field });
                      }}
                      placeholder={text("global-placeholder-default")}
                      style={{ width: "90%" }}
                    />
                  ) : (
                    text(`global-select-lang-${formData.lang}`)
                  )}
                </div>
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData">
                    {text("widgets-motorist-details-table-motorist-externalId")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  <MDTypography type="tableData">{formData.externalId.toUpperCase()}</MDTypography>
                </div>
              </div>
              <div className="flexTableRows">
                <div className="flexTableHead">
                  <MDTypography className="global_label" type="tableData" textTransform="first">
                    {text("widgets-motorist-details-table-motorist-status")}
                  </MDTypography>
                </div>
                <div className="flexTableData">
                  {formData.status === "VERIFIED" ? (
                    <span style={{ color: "green" }} type="tableData">
                      {formData.status}
                    </span>
                  ) : (
                    <MDTypography
                      style={{ color: `${formData.status === "-" ? "" : "#fb8c00"}` }}
                      textTransform="first"
                      type="tableData"
                    >
                      {formData.status === "VERIFIED"
                        ? text("widgets-session-details-table-motorist-address-verified")
                        : null}
                      {formData.status !== "VERIFIED" && formData.status !== "-"
                        ? text("widgets-session-details-table-motorist-address-to_verify")
                        : null}
                      {formData.status === "-" ? "-" : null}
                    </MDTypography>
                  )}
                </div>
                <div className="flexTableHead"></div>
                <div className="flexTableData"></div>
              </div>
              {formData.status === "VERIFIED" ? (
                <div
                  style={{
                    marginBottom: "10px",
                    width: "80%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    paddingLeft: "20px",
                  }}
                >
                  <span style={{ whiteSpace: "break-all", fontSize: "13px", color: `#adaaa6` }} type="tableData">
                    {text("widgets-session-details-table-motorist-address-verified-explain")}
                  </span>
                </div>
              ) : null}
            </div>
          </div>

          <MDTypography className="global_xs_title" type="tableHeader">
            {text("widgets-user-provider-details-table-header-motorist-plates")}
          </MDTypography>
          <div className="global_separa"></div>
          <div className="place_rub" style={{ paddingBottom: "15px" }}>
            {formData.plates
              .filter(({ value }) => {
                return !isEditable ? value !== "" : true;
              })
              .map(({ value, description }, index) => (
                <div
                  key={`${value}-${description}-${index}`}
                  className="flexTableRows"
                  style={{ position: "relative", overflow: "visible" }}
                >
                  <div className="flexTableHead">
                    <MDTypography className="global_label" type="tableHeader" textTransform="first">
                      {text("widgets-motorist-details-table-motorist-plates")}
                    </MDTypography>
                  </div>
                  <div className="flexTableData">
                    <>
                      {isEditable ? (
                        <div
                          key={value + index}
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            marginBottom: "5px",
                          }}
                        >
                          <Input
                            onFocus={() => setFocusId(`plate-value-${index}`)}
                            autoFocus={focusId === `plate-value-${index}`}
                            onChange={(newValue) => {
                              const newValueUpper = `${newValue}`.toUpperCase();
                              let newplates = [...formData?.plates];

                              newplates[index].value = newValueUpper;
                              setFormData({ ...formData, plates: newplates });
                            }}
                            value={value ? value : ""}
                            style={{ width: "90%" }}
                          />
                        </div>
                      ) : null}
                    </>

                    <>
                      {!isEditable ? (
                        <div key={value + index}>
                          <MDTypography component="span" type="tableData">
                            {value}
                          </MDTypography>
                        </div>
                      ) : null}
                    </>
                  </div>
                  <div className="flexTableHead">
                    <MDTypography className="global_label" type="tableHeader" textTransform="first">
                      {text("widgets-motorist-details-table-motorist-description")}
                    </MDTypography>
                  </div>
                  <div className="flexTableData">
                    <>
                      {isEditable ? (
                        <div
                          key={value + index}
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            marginBottom: "5px",
                          }}
                        >
                          <Input
                            onFocus={() => setFocusId(`plate-description-${index}`)}
                            autoFocus={focusId === `plate-description-${index}`}
                            onChange={(newValue) => {
                              let newplates = [...formData?.plates];
                              newplates[index].description = newValue;

                              setFormData({ ...formData, plates: newplates });
                            }}
                            value={description}
                            style={{ width: "90%" }}
                          />
                        </div>
                      ) : null}
                    </>

                    <>
                      {!isEditable ? (
                        <div key={value + index}>
                          <MDTypography component="span" type="tableData">
                            {description}
                          </MDTypography>
                        </div>
                      ) : null}
                    </>
                  </div>
                  {isEditable ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "20px",
                        aspectRatio: "1",
                        backgroundColor: "#C81400",
                        cursor: "pointer",
                        position: "absolute",
                        right: "-10px",
                        top: "50%",
                        transform: "translateY(-16px)",
                      }}
                      onClick={() => {
                        let newplates;

                        formData?.plates?.length === 1
                          ? (newplates = [])
                          : (newplates = formData.plates.filter(({ value: valuePlate }) => valuePlate !== value));

                        setFormData({ ...formData, plates: newplates });
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          pointerEvents: "none",
                          lineHeight: "1",
                          color: "white",
                        }}
                      >
                        -
                      </span>
                    </div>
                  ) : null}
                </div>
              ))}
            {isEditable ? (
              <div
                style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}
              >
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "8px 25px",
                    backgroundColor: "#C81400",
                    cursor: "pointer",
                    borderRadius: "20px",
                    boxShadow: "0px 0px 5px #ddd",
                  }}
                  onClick={() => {
                    if ([...formData?.plates].find(({ value: valuePlate }) => valuePlate === "") === undefined)
                      setFormData({
                        ...formData,
                        plates: [...formData?.plates, { type: "RFID", value: "", description: "" }],
                      });
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      pointerEvents: "none",
                      lineHeight: "1",
                      color: "white",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    {text("widgets-user-provider-details-table-action-add-plate")}
                  </span>
                </div>
              </div>
            ) : null}
          </div>

          <MDTypography className="global_xs_title" type="tableHeader">
            {text("widgets-user-provider-details-table-header-motorist-uids")}
          </MDTypography>
          <div className="global_separa"></div>
          <div className="place_rub" style={{ paddingBottom: "15px" }}>
            {formData.uids
              .filter(({ value }) => {
                return !isEditable ? value !== "" : true;
              })
              .map(({ value, description }, index) => (
                <div
                  key={`${value}-${description}-${index}`}
                  className="flexTableRows"
                  style={{ position: "relative", overflow: "visible" }}
                >
                  <div className="flexTableHead">
                    <MDTypography className="global_label" type="tableHeader" textTransform="first">
                      {text("widgets-motorist-details-table-motorist-uids")}
                    </MDTypography>
                  </div>
                  <div className="flexTableData">
                    <>
                      {isEditable ? (
                        <div
                          key={value + index}
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            marginBottom: "5px",
                          }}
                        >
                          <Input
                            onFocus={() => setFocusId(`uid-value-${index}`)}
                            autoFocus={focusId === `uid-value-${index}`}
                            onChange={(newValue) => {
                              let newuids = [...formData?.uids];

                              newuids[index].value = newValue;
                              setFormData({ ...formData, uids: newuids });
                            }}
                            type="text"
                            value={value ? value : ""}
                            style={{ width: "90%" }}
                          />
                        </div>
                      ) : null}
                    </>

                    <>
                      {!isEditable ? (
                        <div key={value + index}>
                          <MDTypography component="span" type="tableData">
                            {value}
                          </MDTypography>
                        </div>
                      ) : null}
                    </>
                  </div>
                  <div className="flexTableHead">
                    <MDTypography className="global_label" type="tableHeader" textTransform="first">
                      {text("widgets-motorist-details-table-motorist-visible-id")}
                    </MDTypography>
                  </div>
                  <div className="flexTableData">
                    <>
                      {isEditable ? (
                        <div
                          key={value + index}
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            marginBottom: "5px",
                          }}
                        >
                          <Input
                            minLength={2}
                            maxLength={6}
                            onFocus={() => setFocusId(`uid-description-${index}`)}
                            autoFocus={focusId === `uid-description-${index}`}
                            onChange={(newValue) => {
                              let newuids = [...formData?.uids];
                              newuids[index].description = newValue;

                              setFormData({ ...formData, uids: newuids });
                            }}
                            value={description ? description : ""}
                            style={{ width: "90%" }}
                          />
                        </div>
                      ) : null}
                    </>

                    <>
                      {!isEditable ? (
                        <div key={value + index}>
                          <MDTypography component="span" type="tableData">
                            {description}
                          </MDTypography>
                        </div>
                      ) : null}
                    </>
                  </div>
                  {isEditable ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "20px",
                        aspectRatio: "1",
                        backgroundColor: "#C81400",
                        cursor: "pointer",
                        position: "absolute",
                        right: "-10px",
                        top: "50%",
                        transform: "translateY(-16px)",
                      }}
                      onClick={() => {
                        let newuids;

                        formData?.uids?.length === 1
                          ? (newuids = [])
                          : (newuids = formData.uids.filter(({ value: valueUid }) => valueUid !== value));

                        setFormData({ ...formData, uids: newuids });
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "18px",
                          pointerEvents: "none",
                          lineHeight: "1",
                          color: "white",
                        }}
                      >
                        -
                      </span>
                    </div>
                  ) : null}
                </div>
              ))}
            {isEditable ? (
              <div
                style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}
              >
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "8px 25px",
                    backgroundColor: "#C81400",
                    cursor: "pointer",
                    borderRadius: "20px",
                    boxShadow: "0px 0px 5px #ddd",
                  }}
                  onClick={() => {
                    if ([...formData?.uids].find(({ value: valueUid }) => valueUid === "") === undefined)
                      setFormData({
                        ...formData,
                        uids: [...formData?.uids, { type: "RFID", value: "", description: "", provider: "LOCAL" }],
                      });
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      pointerEvents: "none",
                      lineHeight: "1",
                      color: "white",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    {text("widgets-user-provider-details-table-action-add-uid")}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              paddingTop: "20px",
              paddingBottom: "10px",
            }}
          >
            {!isEditable ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                {formData?.status ? (
                  <MDButton color="primary" size="small" onClick={() => setEditable(true)}>
                    {text("wizard-button-edit")}
                  </MDButton>
                ) : (
                  <MDButton color="primary" size="small" onClick={() => setEditable(true)}>
                    {text("wizard-button-edit")}
                  </MDButton>
                )}
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", width: "100%" }}>
                <MDButton
                  color="primary"
                  size="small"
                  onClick={() => {
                    setEditable(false);
                    setFormData(data.UserProviderDetails);
                  }}
                >
                  {text("widgets-validator-cancel-button")}
                </MDButton>
                <MDButton
                  disabled={authorizeSubmit}
                  color="primary"
                  size="small"
                  onClick={(e) => {
                    submitClick(e);
                  }}
                >
                  {text("widgets-validator-submit")}
                </MDButton>
              </div>
            )}{" "}
          </div>
        </section>
      ) : (
        <div></div>
      )}
      {openPopup ? (
        <Popup
          titlePopup={titlePopup}
          textPopup={textPopup}
          modePopup={modePopup}
          handlePopupChoice={popupChoice}
          handlePopupExit={popupExit}
        />
      ) : null}
    </>
  );
};

export default UserProviderAboutTable;
