import { gql } from "@apollo/client";

export default gql`
  query transactions($parkingsIds: [String], $page: Int, $limit: Int) {
    transactions(parkingsIds: $parkingsIds, page: $page, limit: $limit) {
      list {
        _id
        type
        psp
        amount
        currency
        status
        pspTransactionDate
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
