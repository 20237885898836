import React, { useEffect } from "react";
import { useSelector } from "@xstate/react";
import { service } from "../../../reducer";

import Grid from "@mui/material/Grid";
import SubContractCard from "assets/@ndres-ui/Cards/SubContractCard";
import MDBox from "assets/@ndres-ui/MDBox";
import MDTypography from "assets/@ndres-ui/MDTypography";
import useDictionaries from "assets/commonHooks/useDictionaries";

export default function Step2({ addCompleted, formData, addNextBt }) {
  const text = useDictionaries();
  const motoristInputs = useSelector(service, ({ context }) => context.motoristInputs);

  useEffect(() => {
    const testing = [!!motoristInputs?.startDate && motoristInputs?.startDate !== "", !motoristInputs.error];

    const result = testing.reduce((c, o) => c && o, true);
    addNextBt(2, result);
  }, [motoristInputs]);

  useEffect(() => {
    addCompleted(2, true);
  }, []);

  return (
    <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
      <MDBox
        pt={0}
        style={{
          transition: "opacity ease 1s",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MDBox ml={2} mt={2} pl={2}>
              <MDTypography fontSize="1.5rem" fontWeight="regular" color="dark">
                {text("wizard-sub-contract-second-step-contract")}
              </MDTypography>
              <MDTypography fontSize="1rem" fontWeight="light" color="text">
                {text("wizard-sub-contract-second-step-description")}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <MDBox height="5px" />
          </Grid>

          <Grid item xs={10}>
            <MDBox width="100%" my={1} py={1} mx={1} marginRight="5px">
              <SubContractCard
                id={""}
                title={"product.name"}
                iconLogo={"product.iconLogo"}
                description={"product?.description"}
                currency={""}
                price={""}
                type={""}
                selected={motoristInputs.state !== "TO_VERIFY"}
                userData={motoristInputs}
              />
            </MDBox>
          </Grid>

          <Grid item xs={1}>
            <MDBox height="5px" />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}
