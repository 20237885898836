// import { useSelector } from "react-redux";

import { useSelector } from "@xstate/react";
import { service } from "components/I18n/reducer";

const useDictionaries = () => {
  const translations = useSelector(
    service,
    ({ context }) => context.translations,
    (a, b) => {
      const ac = Object.keys(a).length;
      const bc = Object.keys(b).length;
      return a.lang === b.lang && ac === bc;
    },
  );

  const defaultTranslations = useSelector(
    service,
    ({ context }) => context.globalDictionnary.en_EN || {},
    (a, b) => {
      const ac = Object.keys(a).length;
      const bc = Object.keys(b).length;
      return a.lang === b.lang && ac === bc;
    },
  );

  const text = (key, replacements) => {
    const word = translations ? `${translations[key] ?? `#${defaultTranslations[key] ?? key}`}` : `##${key}`;

    if (replacements) {
      const replaced = Object.entries(replacements).reduce(
        (acc, [param, value]) => `${acc}`.replaceAll(`[${param}]`, value),
        word,
      );
      return replaced;
    }

    return word;
  };

  return text;
};

export default useDictionaries;
