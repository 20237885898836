import { gql } from "@apollo/client";

export default gql`
  query motorist(
    $motoristId: String!
    $parkingsIds: [String]
    $sessionPage: Int
    $sessionLimit: Int
    $contractPage: Int
    $contractLimit: Int
  ) {
    motorist(motoristId: $motoristId) {
      _id
      state
      username
      firstName
      lastName
      isAnonymous
      address1
      address2
      zipcode
      city
      shortUid
      credentials {
        type
        provider
        value
        description
      }
      qrcode {
        token
        createdAt
        type
        disabledAt
      }
      state
      country
      phone
      lang
      gender
      createdAt
      contracts(parkingsIds: $parkingsIds, page: $contractPage, limit: $contractLimit) {
        list {
          _id
          isEnabled
          ospContractId
          startDate
          stopDate
          parkingId
          credentials {
            type
            value
            provider
            description
          }
          parking {
            _id
            name
          }
          product {
            _id
            category
          }
          freeflag {
            _id
          }
        }
        paging {
          current
          count
          limit
        }
      }
      sessions(parkingsIds: $parkingsIds, page: $sessionPage, limit: $sessionLimit) {
        list {
          _id
          parking {
            name
            _id
          }
          entryDate
          exitDate
          state
          occupancyDuration
          isInconsistent
          product {
            _id
            name
          }
          events {
            state
            accessPointId
            createdAt
            reason
            credentials {
              type
              provider
              value
              description
            }
          }
        }
        paging {
          current
          count
          limit
        }
      }
    }
  }
`;
