import { gql } from "@apollo/client";

export default gql`
  query me {
    me {
      _id
      name
      code
      translation
      display {
        logoMain
        logoSecondary
      }
    }
  }
`;
