import { gql } from "@apollo/client";

export default gql`
  query localMotorists($page: Int!, $limit: Int!) {
    localMotorists(page: $page, limit: $limit) {
      list {
        _id
        operatorId
        externalId
        plates {
          type
          value
          description
        }
        uids {
          type
          value
          description
        }
        email
        firstName
        lastName
        address1
        address2
        zipcode
        city
        country
        phone
        lang
        status
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
