import { gql } from "@apollo/client";

export default gql`
  query consumeToken($contractId: String!) {
    consumeToken(contractId: $contractId) {
      contractId
      verifLink
    }
  }
`;
