/* eslint-disable implicit-arrow-linebreak */
import React, { useContext, useMemo } from "react";

import useDictionaries from "assets/commonHooks/useDictionaries";

import MotoristTable from "assets/@luc-ui/MotoristDetails/MotoristTable";
import SessionsDetailsTable from "assets/@luc-ui/MotoristDetails/SessionsDetailsTable";

import { LocalContext } from "../../../context";

const MotoristSessionsTable = () => {
  const text = useDictionaries();
  const { data, onSelectSessionPage, onForceClose } = useContext(LocalContext);
  const listReactiv = useMemo(() => data, [data, text]);
  if (!listReactiv) return <></>;

  const { motoristDetails } = listReactiv;
  const { sessions } = listReactiv;
  const { sessionPaging } = listReactiv;

  return (
    <>
      <MotoristTable motoristInfos={motoristDetails} />
      <SessionsDetailsTable
        sessions={sessions}
        onSelectPage={onSelectSessionPage}
        sessionPaging={sessionPaging}
        onForceClose={onForceClose}
      />
    </>
  );
};

export default MotoristSessionsTable;
