import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

import Motorists from "components/Motorists";
import MotoristDetails from "components/MotoristDetails";

import { layouts } from "./layoutHalf.conf";
import { GRID_ACTIVE } from "../../config";

const ResponsiveGridLayout = WidthProvider(Responsive);

const ContractPage = () => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ hd: 1700, xl: 1400, lg: 1200, md: 960 }}
        cols={{ hd: 170, xl: 140, lg: 120, md: 96 }}
        rowHeight={10}
        isResizable={GRID_ACTIVE}
        isDraggable={GRID_ACTIVE}
        isBounded
        margin={[0, 0]}
        items={2}
        compactType={null}
        onLayoutChange={(e) => console.debug(e)}
        // preventCollision
        containerPadding={[0, 0]}
      >
        <div key="Sessions" className="containerInGrid">
          <Motorists />
        </div>
        <div key="details" className="containerInGrid">
          <MotoristDetails />
        </div>
      </ResponsiveGridLayout>
    </div>
  );
};

export default ContractPage;
