import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./Layout";
import Login from "./components/Login";
import MDBox from "./assets/@ndres-ui/MDBox";
import NavigationBar from "./DashboardElements/components/layout/NavigationBar";
import LeftBarTopology from "./components/Hierarchy";
import Container from "./DashboardElements/components/layout/Container";

import { Dashboard } from "./components/DashboardPage";
import SessionPage from "./components/SessionsPage";
import MotoristValidation from "./components/MotoristValidation/";
import { I18n } from "./components/I18n";
import { service } from "./components/Login/reducer";
import { useSelector } from "@xstate/react";
import ContractPage from "components/ContractsPage";
import ProductsPage from "./components/ProductsPage";
import WizardNewSubscription from "./components/WizardNewSubscription";
import WizardNewPool from "./components/WizardNewPool";

import WizardCreateMotoristContractPool from "./components/WizardCreateMotoristContractPool";
import WizardCreateLocalMotorist from "./components/WizardCreateLocalMotorist";
import WizardCreateSingleMotoristContractPool from "./components/WizardCreateSingleMotoristContractPool";
import WizardCreateSubContractPool from "./components/WizardCreateSubContractPool";
import WizardCreateReportTechnical from "components/WizardCreateReportTechnical";
import WizardCreateContractUserProvider from "components/WizardCreateContractUserProvider";
import TokenInvalid from "components/MotoristValidation/materials/TokenInvalid";
import TokenNotValidated from "components/MotoristValidation/materials/TokenNotValidated";
import BusinessTenantPage from "components/BusinessTenantPage";
import { useSocket } from "./socket";
import AccessPointPage from "components/AccessPointPage";
import UserProviderPage from "./components/UserProviderPage";

const LoggedWrapper = ({ children }) => {
  const navigate = useNavigate();
  const socket = useSocket();
  const connected = useSelector(service, ({ context }) => context.connected);

  useEffect(() => {
    if (!connected) {
      console.log("NAVIGATE");
      navigate("/", { replace: true });
    }
  }, [connected]);

  return (
    <MDBox width="100%" minHeight="calc(100vh)">
      <NavigationBar />

      <MDBox pl="1vw">
        <LeftBarTopology />
        <Suspense fallback={<div>Loading</div>}>
          <Container>{children}</Container>
        </Suspense>
      </MDBox>
    </MDBox>
  );
};

export const Router = () => {
  return (
    <BrowserRouter>
      <Layout>
        <I18n />
        <WizardNewSubscription />
        <WizardNewPool />
        <WizardCreateMotoristContractPool />
        <WizardCreateLocalMotorist />
        <WizardCreateSingleMotoristContractPool />
        <WizardCreateReportTechnical />
        <WizardCreateSubContractPool />
        <WizardCreateContractUserProvider />
        <Routes>
          <Route path="/verificator/:token" element={<MotoristValidation />} />
          <Route path="/" element={<Login />} />
          <Route path="/verificator/invalidToken" element={<TokenInvalid />} />
          <Route path="/verificator/notValidated" element={<TokenNotValidated />} />

          <Route
            path="/dashboard"
            element={
              <LoggedWrapper>
                <Dashboard />
              </LoggedWrapper>
            }
          />
          <Route
            path="/dashboard/sessions"
            element={
              <LoggedWrapper>
                <SessionPage />
              </LoggedWrapper>
            }
          />
          <Route
            path="/dashboard/contracts"
            element={
              <LoggedWrapper>
                <ContractPage />
              </LoggedWrapper>
            }
          />
          <Route
            path="/dashboard/products"
            element={
              <LoggedWrapper>
                <ProductsPage />
              </LoggedWrapper>
            }
          />
          <Route
            path="/dashboard/tenants"
            element={
              <LoggedWrapper>
                <BusinessTenantPage />
              </LoggedWrapper>
            }
          />
          <Route
            path="/dashboard/accesspoint"
            element={
              <LoggedWrapper>
                <AccessPointPage />
              </LoggedWrapper>
            }
          />
          <Route
            path="/dashboard/provider"
            element={
              <LoggedWrapper>
                <UserProviderPage />
              </LoggedWrapper>
            }
          />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};
