import React from "react";
import { ContextProvider } from "./context";
import About from "./About";

const UserProviderDetails = (props) => {
  const onUpdateMotorist = (data) => {
    return { data };
  };
  return (
    <div
      style={{
        height: "100%",
        overflow: "hidden",
        position: "relative",
        padding: "0",
      }}
    >
      <div>
        <ContextProvider
          storeName="UserProviderDetails"
          events={{
            onUpdateMotorist,
          }}
        >
          <About />
        </ContextProvider>
      </div>
    </div>
  );
};

export default UserProviderDetails;
