import React, { useEffect, useMemo } from "react";

// @mui materials
import FormControl from "@mui/material/FormControl";

import Grid from "@mui/material/Grid";
import MDTypography from "assets/@ndres-ui/MDTypography";
import ContractCardPool from "assets/@ndres-ui/Cards/ContractCardPool";
import MDBox from "assets/@ndres-ui/MDBox";
import useDictionaries from "assets/commonHooks/useDictionaries";

export default function Step3({ addCompleted, formData, addNextBt }) {
  const text = useDictionaries();
  useEffect(() => {
    const testing = [formData?.email !== undefined, true];

    const result = testing.reduce((c, o) => c && o, true);
    addNextBt(2, result);
  }, [formData]);

  useEffect(() => {
    addCompleted(2, true);
  }, []);

  const contract = useMemo(
    () => ({
      acces: {
        status: formData?.verificationCardStatus,
      },
      user: formData,
      product: formData?.productData,
    }),
    [formData],
  );

  return (
    <MDBox width="100%" style={{ maxWidth: "1040px", height: "550px" }}>
      <MDBox pl={2}>
        <MDTypography fontSize="1.2rem" fontWeight="regular" color="dark" textTransform="first">
          {text("Wizard-pool-third-step-title")}
        </MDTypography>
        <MDTypography fontSize="1rem" fontWeight="light" color="text" textTransform="first">
          {text("Wizard-pool-third-step-description")}
        </MDTypography>
      </MDBox>

      <MDBox display="flex" alignItems="center" justifyContent="around" ml={2} mt={1}>
        <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <MDBox height="5px" />
            </Grid>

            <Grid item xs={10}>
              <MDBox width="100%" my={1} py={1} mx={1} marginRight="5px">
                <ContractCardPool
                  id={formData?.productData?._id}
                  title={formData?.productData?.name}
                  iconLogo={formData?.productData?.iconLogo}
                  description=""
                  // currency={formData?.productData?.parameters.currency}
                  // price={formData?.productData?.parameters.cents / 100}
                  type={formData?.productData?.category}
                  // selected={contract.acces.status === "verified"}
                  // externalId={contract.acces.externalId}
                  userData={contract.user}
                />
              </MDBox>
            </Grid>

            <Grid item xs={1}>
              <MDBox height="5px" />
            </Grid>
          </Grid>
        </FormControl>
      </MDBox>
    </MDBox>
  );
}
