import { gql } from "@apollo/client";

export default gql`
  query breakdown($breakdownId: String!) {
    breakdown(breakdownId: $breakdownId) {
      _id
      startDate
      endDate
      category
      description
      type
      createdAt
      updatedAt
    }
  }
`;
