import React from "react";
import WizardCreateSingleMotoristContractPool from "./assets";
import { ContextProvider } from "./context";

const Wizard = () => {
  return (
    <ContextProvider storeName="WizardCreateSingleMotoristContractPool" events={{}}>
      <WizardCreateSingleMotoristContractPool />
    </ContextProvider>
  );
};

export default Wizard;
