import { client } from "apolloService";
import { assign, createMachine, interpret } from "xstate";
import { createModel } from "xstate/lib/model";

import updateContractGql from "./motorist.update.contract.gql";
import poolsContractGql from "./pool-contracts.gql";
import updatePoolContractGql from "./update-pool-contract.gql";

const userModel = createModel({
  token: "",
  motoristId: "",
  selectedMotorist: "",
  paging: {},
  selectedParkings: [],
  errorUpdateContract: undefined,
});

const fetchBusinessTenantsDetails = async (context, { payload }) => {
  if (payload.selectedContract || context.contractId) {
    const { data } = await client.query({
      query: poolsContractGql,
      fetchPolicy: "no-cache",
      context: { headers: { "x-access-token": context.token } },
      variables: {
        contractId: payload.selectedContract ?? context.contractId,
        motoristLimit: 8,
        motoristPage: payload.newPage,
        sessionPage: payload.sessionPage,
      },
    });

    return data;
  }

  return null;
};

const toggleContractStatus = async ({ token }, { payload }) => {
  const { data } = await client.query({
    query: updateContractGql,
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": token } },
    variables: {
      contractId: payload.contractId,
      input: { isEnabled: payload.event },
    },
  });

  return data;
};

const updateContract = async ({ token }, { payload }) => {
  return new Promise((resolve, reject) => {
    client
      .query({
        query: updateContractGql,
        fetchPolicy: "no-cache",
        context: { headers: { "x-access-token": token } },
        variables: {
          contractId: payload.contractId,
          input: { ...payload.input },
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e.message);
      });
  });
};

const updatePoolContract = async ({ token }, { payload }) => {
  const { data } = await client.query({
    query: updatePoolContractGql,
    fetchPolicy: "no-cache",
    context: { headers: { "x-access-token": token } },
    variables: {
      contractId: payload.contractId,
      input: { ...payload.input },
    },
  });

  return data;
};

const BusinessTenantsDetails = createMachine(
  {
    context: userModel.initialContext,
    id: "BusinessTenantsDetails",
    initial: "off",
    states: {
      off: {
        on: {
          "BusinessTenantsDetails:wakeup": { target: "loading" },
          "global:selected-parkings": { actions: "save parkings" },
        },
      },
      idle: {
        on: {
          "events:contracts:created": { target: "loading" },
          "events:contracts:updated": { target: "selectContract" },
          "events:pools:created": { target: "loading" },
          "events:pools:updated": {
            target: "selectContract",
          },
          "events:motorists:updated": { target: "loading" },
          "BusinessTenantsDetails:show": { target: "selectContract" },
          "BusinessTenantsDetails:onContractStatusChange": {
            target: "updateContractStatus",
          },
          "BusinessTenantsDetails:onContractPoolUpdate": {
            target: "updatePoolContract",
          },
          "BusinessTenantsDetails:onContractUpdate": {
            target: "updateContract",
          },
          "BusinessTenantsDetails:onSelectMotoristPage": {
            target: "changingPage",
          },
          "BusinessTenantsDetails:onSelectSessionPage": {
            target: "changingPage",
          },
          "global:selected-parkings": {
            actions: "save parkings",
            target: "loading",
          },
          "WizardCreateMotoristContractPool:reset-error": {
            actions: "reset error",
          },
        },
      },
      selectContract: {
        entry: assign({
          token: () => localStorage.getItem("token"),
          contractId: (ctx, { payload }) => (payload?.selectedContract ? payload.selectedContract : ctx.contractId),
        }),
        invoke: {
          id: "selectContract",
          src: (context, event) => fetchBusinessTenantsDetails(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      loading: {
        entry: assign({
          token: () => localStorage.getItem("token"),
        }),
        invoke: {
          id: "machineLoading",
          src: (context, event) => fetchBusinessTenantsDetails(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      changingPage: {
        invoke: {
          id: "getNewPage",
          src: (context, event) => fetchBusinessTenantsDetails(context, event),
          onDone: [{ actions: "populate", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      updateContractStatus: {
        invoke: {
          id: "updateBusinessTenantsDetails",
          src: (context, event) => toggleContractStatus(context, event),
          onDone: [{ actions: "update contract", target: "idle" }],
          onError: [{ target: "idle" }],
        },
      },
      updatePoolContract: {
        invoke: {
          id: "updateBusinessTenantsPoolContract",
          src: (context, event) => updatePoolContract(context, event),
          onDone: [{ actions: "update pool contract", target: "loading" }],
          onError: [{ target: "idle" }],
        },
      },
      updateContract: {
        invoke: {
          id: "updateBusinessTenantsContract",
          src: (context, event) => updateContract(context, event),
          onDone: [{ actions: "update contract", target: "idle" }],
          onError: [{ actions: "error update contract", target: "idle" }],
        },
      },
    },
  },
  {
    actions: {
      populate: assign({
        poolContract: (_, { data }) => {
          return data?.poolContractAndSub;
        },
        sessionPaging: (_, { data }) => data?.sessionPaging,
        contractPaging: (_, { data }) => data?.contractPaging,
        refreshError: (_, { data }) => false,
      }),
      "update pool contract": assign({
        poolContract: (ctx, { data }) => {
          const {
            isEnabled,
            startDate,
            stopDate,
            spacesAllocated,
            spacesOccupied,
            name1,
            name2,
            city,
            zipcode,
            country,
            address1,
            address2,
          } = data.updatePoolContract;
          const contract = ctx.poolContract;
          contract.isEnabled = isEnabled;
          contract.startDate = startDate;
          contract.stopDate = stopDate;
          contract.spacesAllocated = spacesAllocated;
          console.log("🚀 ~ file: reducer.js:216 ~ data:", data);

          console.log("🚀 ~ file: reducer.js:216 ~ spacesOccupied:", spacesOccupied);

          contract.spacesOccupied = spacesOccupied;

          contract.name1 = name1;
          contract.name2 = name2;
          contract.city = city;
          contract.zipcode = zipcode;
          contract.country = country;
          contract.address1 = address1;
          contract.address2 = address2;
          return { ...contract };
        },
      }),
      "update contract": assign({
        poolContract: (ctx, { data }) => {
          const { isEnabled, startDate, stopDate, _id } = data.updateContract;
          const contracts = ctx.poolContract;

          let contract = contracts.motoristsContracts.list.find(({ _id }) => {
            return _id === data.updateContract._id;
          });
          contract.isEnabled = isEnabled;
          contract.startDate = startDate;
          contract.stopDate = stopDate;

          return { ...contracts, contractUpdateTarget: _id };
        },
      }),

      "error update contract": assign({
        errorUpdateContract: (ctx, { data }) => {
          return data;
        },
      }),

      "save parkings": assign({
        selectedParkings: (ctx, { payload }) => payload.selectedParkings,
      }),

      "reset error": assign({
        errorUpdateContract: () => undefined,
      }),
    },
  },
);

export const service = interpret(BusinessTenantsDetails).start();

const initial = {
  state: BusinessTenantsDetails.initialState.list,
  context: BusinessTenantsDetails.initialState.context,
};

const widgetReducer = (state = initial, { type, payload }) => {
  const newState = service.send({ type, payload });
  return {
    state: newState.value,
    context: newState.context,
  };
};

export default widgetReducer;
