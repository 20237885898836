/* eslint-disable implicit-arrow-linebreak */
import React, { useContext, useMemo } from "react";

import useDictionaries from "assets/commonHooks/useDictionaries";
import MotoristDetailsTable from "assets/@luc-ui/BusinessTenantsDetails/MotoristDetailsTable";

import { LocalContext } from "../../../context";
import { LocalContext as WidgetContext } from "../../../../context";

import Paginat from "assets/@luc-ui/Pagination";

const MotoristContractsTable = () => {
  const text = useDictionaries();
  const { poolContract } = useContext(LocalContext);

  const { onSelectMotoristPage, onContractStatusChange, onContractUpdate } = useContext(WidgetContext);

  const motoristsListFormated = useMemo(() => poolContract, [poolContract, text]);
  const { motoristsContracts, motoristsPaging, contract } = motoristsListFormated;

  if (!motoristsListFormated) return <></>;
  const { page, total } = motoristsPaging;

  return (
    <>
      <div
        style={{
          position: "relative",
          padding: "0.1em",
        }}
      >
        <MotoristDetailsTable
          motoristContracts={motoristsContracts}
          onContractSwitch={onContractStatusChange}
          onContractUpdate={onContractUpdate}
          contractPaging={motoristsPaging}
          subContract={contract}
          onSelectMotoristPage={onSelectMotoristPage}
          isParent={true}
        />

        <Paginat page={page} totalPages={total} onSelectPage={onSelectMotoristPage} />
      </div>
    </>
  );
};

export default MotoristContractsTable;
